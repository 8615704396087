import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Confirm,
  useRefresh,
  useNotify,
} from 'react-admin';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const ApiCommandButton = ({ command, query, label, title, content }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    const url = adminCommandApiUrl(command, query);
    setLoading(true);
    try {
      const body = {};
      await httpClient(url, { method: 'POST', body: JSON.stringify(body) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>{label?.toUpperCase()}</strong>
      </a>
      <Confirm
        isOpen={open}
        loading={loading}
        title={title}
        content={content}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

ApiCommandButton.propTypes = {
  command: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  query: PropTypes.object,
};

ApiCommandButton.defaultProps = {
  query: {},
};

export default ApiCommandButton;
