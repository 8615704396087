import React, { useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'admin/assets/js/lib/httpClient';
import { useNotify, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { adminApiUrl } from 'admin/urls';

const IgnoreButton = ({ remoteId, ignored }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  if (!remoteId) {
    return null;
  }

  const handleToggleIgnored = async () => {
    const valuesToSend = {
      ignored: !ignored,
    };

    const url = adminApiUrl('inbound_transfers', remoteId);
    setLoading(true);
    try {
      await httpClient(url, { method: 'PUT', body: JSON.stringify(valuesToSend) });
      await refresh();
    } catch (e) {
      notify(e.toString(), 'warning');
      setLoading(false);
    }
  };

  const buttonLabel = ignored ? 'Stop ignoring' : 'Ignore';
  const title = ignored ? `Stop ignoring transfer ${remoteId}` : `Ignore transfer ${remoteId}`;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
      >
        {buttonLabel}
      </Button>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            {title}
            <Button
              style={{ padding: 0, minWidth: 'initial' }}
              onClick={() => setOpen(false)}
            >
              <Icon>close</Icon>
            </Button>
          </div>
        </DialogTitle>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleToggleIgnored}
        >
          {buttonLabel}
        </Button>
      </Dialog>
    </>
  );
};

IgnoreButton.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
  ignored: PropTypes.bool,
};

IgnoreButton.defaultProps = {
  remoteId: null,
  ignored: false,
};

export default IgnoreButton;
