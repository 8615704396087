import React, { useState } from 'react';
import { useNotify } from 'react-admin';

import {
  adminCommandApiUrl,
} from 'admin/urls';
import httpClient from 'admin/assets/js/lib/httpClient';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import { DEPLOYMENTS_STATUS, DEPLOYMENTS_STATUS_LABEL } from 'admin/assets/js/constants';
import useFetchDeploymentsStatus from 'admin/assets/js/components/hooks/useFetchDeploymentStatus.jsx';
import { capitalizeFirstLetter } from 'core/assets/js/lib/utils';
import { WINDOW_RELOAD } from 'core/assets/js/config/settings';

const PauseDeploymentsButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const notify = useNotify();

  const { deploymentsStatus, loadingDeploymentStatus } = useFetchDeploymentsStatus();

  if (loadingDeploymentStatus) {
    return (
      <a
        className="btn btn-outline-danger m-1 text-uppercase"
      >
        <strong>
          Loading status...
        </strong>
      </a>
    );
  }

  if (!deploymentsStatus) {
    return null;
  }
  const statusLabel = DEPLOYMENTS_STATUS_LABEL[deploymentsStatus];
  const nextActionLabel = deploymentsStatus === DEPLOYMENTS_STATUS.ENABLED ? 'pause' : 'enable';

  const buttonLabel = `${nextActionLabel} deployments`;


  const onConfirm = async () => {
    setLoadingUpdateStatus(true);
    try {
      const updateToStatus = (deploymentsStatus === DEPLOYMENTS_STATUS.ENABLED)
        ? DEPLOYMENTS_STATUS.PAUSED
        : DEPLOYMENTS_STATUS.ENABLED;
      await httpClient(adminCommandApiUrl('toggle-deployments'), {
        method: 'PUT', body: JSON.stringify({ updateToStatus }),
      });
      setModalOpen(false);
      notify('Deployments status updated', 'success');
    } catch (e) {
      notify('Deployments status update failed', 'warning');
    } finally {
      setLoadingUpdateStatus(false);
      WINDOW_RELOAD();
    }
  };

  return (
    <>
      <a
        className="btn btn-outline-danger m-1 text-uppercase"
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => setModalOpen(true)}
      >
        <strong>{buttonLabel}</strong>
      </a>
      <ConfirmModal
        confirm={nextActionLabel}
        content={(
          <>
            <p>
              {`Deployments are currently ${statusLabel.toLowerCase()}.`}
            </p>

            <p>
              {`Do you want to ${nextActionLabel.toLowerCase()} deployments?`}
            </p>

            <p>
              <b>Proceed with caution!</b>
            </p>
          </>
        )}
        disableCtas={loadingUpdateStatus}
        onClose={() => setModalOpen(false)}
        onConfirm={onConfirm}
        open={modalOpen}
        title={capitalizeFirstLetter(buttonLabel)}
      />
    </>
  );
};

export default PauseDeploymentsButton;
