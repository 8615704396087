import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, useRefresh, useNotify } from 'react-admin';
import {
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

export const FREEZE_SEGMENT = {
  ALL: 'all',
  INVOICING_SETTINGS: 'invoicingSettings',
  SERVICE_METRICS: 'serviceMetrics',
  SERVICE_ORDERS: 'serviceOrders',
  FX_RATES: 'fxRates',
};

const buttonLabels = {
  [FREEZE_SEGMENT.ALL]: 'Freeze all data',
  [FREEZE_SEGMENT.INVOICING_SETTINGS]: 'Freeze invoicing settings',
  [FREEZE_SEGMENT.SERVICE_METRICS]: 'Freeze service metrics',
  [FREEZE_SEGMENT.SERVICE_ORDERS]: 'Freeze service orders',
  [FREEZE_SEGMENT.FX_RATES]: 'Freeze fx rates',
};

const descriptions = {
  [FREEZE_SEGMENT.ALL]: 'Freeze all data for this organization billing, including invoicing settings, service metrics, fx rates, service orders and their financial entities',
  [FREEZE_SEGMENT.INVOICING_SETTINGS]: 'Freeze the latest invoicing settings for this organization billing',
  [FREEZE_SEGMENT.SERVICE_METRICS]: 'Freeze the service metrics for this organization billing',
  [FREEZE_SEGMENT.SERVICE_ORDERS]: 'Freeze the service orders for this organization billing',
  [FREEZE_SEGMENT.FX_RATES]: 'Freeze fx rates for this organization billing',
};

const commands = {
  [FREEZE_SEGMENT.ALL]: 'org-billing-freeze-all',
  [FREEZE_SEGMENT.INVOICING_SETTINGS]: 'org-billing-freeze-invoicing-settings',
  [FREEZE_SEGMENT.SERVICE_METRICS]: 'org-billing-freeze-metrics',
  [FREEZE_SEGMENT.SERVICE_ORDERS]: 'org-billing-freeze-service-orders',
  [FREEZE_SEGMENT.FX_RATES]: 'org-billing-freeze-fx-rates',
};

const FreezeOrgBillingButton = ({ segment, orgId, billingProcessId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async (values) => {
    const command = commands[segment];
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify({
        ...values,
        orgId,
        billingProcessId,
      }) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  const label = buttonLabels[segment];
  const title = buttonLabels[segment];
  const description = descriptions[segment];

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>{label.toUpperCase()}</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={label}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
            {segment === FREEZE_SEGMENT.SERVICE_ORDERS && 'Enter service order ids you want to target'}
          </DialogContentText>
          <Form
            initialValues={{ targetServiceOrderIds: '' }}
            onSubmit={handleGenerateClick}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {segment === FREEZE_SEGMENT.SERVICE_ORDERS && (
                  <div>
                    <Field name="targetServiceOrderIds">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Service order ids</label>
                          <br />
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="text"
                            placeholder="ie: 2022"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                )}
                <div>
                  <Button variant="contained" disabled={loading} label="Freeze" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

FreezeOrgBillingButton.propTypes = {
  orgId: PropTypes.number.isRequired,
  billingProcessId: PropTypes.number.isRequired,
  segment: PropTypes.string.isRequired,
};

export default FreezeOrgBillingButton;
