import React from 'react';

import {
  FunctionField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import { Icon } from '@material-ui/core';

import { useStyles } from 'admin/assets/js/layout/themes';
import PreformattedField from 'admin/assets/js/components/fields/PreformattedField.jsx';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { STRIPE_SUBSCRIPTION_STATUS_LABEL } from 'services/assets/js/constants';
import { getStripeStatusColor, formatStripeTimestamp } from 'services/assets/js/lib/utils';

const DimStripeSubscriptionShow = (props) => {
  const classes = useStyles();

  return (
    <Show
      {...props}
    >
      <SimpleShowLayout>
        <FunctionField
          source="status"
          label="Stripe subscription"
          render={record => (
            <>
              <h5>
                Stripe subscription details (#
                <span>{record.details.id}</span>
                )
                <BulkApiActionButton
                  title={`Sync stripe subscription #${record.details.id}`}
                  content={`
                    Stripe subscription ${record.details.id} will be synced. Proceed with caution.
                  `}
                  confirm
                  icon={<Icon>refresh</Icon>}
                  ids={[record.details.id]}
                  resource="dim_stripe_subscriptions"
                  action="sync-stripe-subscriptions"
                  cancelLabel="Close"
                />
                <br />
              </h5>
              <div className="d-flex">
                <span className={classes.discreet}>status</span>
                <span className={`pl-4 ml-auto ${getStripeStatusColor(record.details.status)}`}>
                  {STRIPE_SUBSCRIPTION_STATUS_LABEL[record.details.status]}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>created</span>
                <span className="pl-4 ml-auto">
                  {formatStripeTimestamp(record.details.created)}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>start date</span>
                <span className="pl-4 ml-auto">
                  {formatStripeTimestamp(record.details.start_date)}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>current period start</span>
                <span className="pl-4 ml-auto">
                  {formatStripeTimestamp(record.details.current_period_start)}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>current period end</span>
                <span className="pl-4 ml-auto">
                  {formatStripeTimestamp(record.details.current_period_end)}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>plan</span>
                <span className="pl-4 ml-auto">
                  {record.details.plan.nickname}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>currency</span>
                <span className="pl-4 ml-auto">
                  {record.details.plan.currency}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>amount</span>
                <span className="pl-4 ml-auto">
                  {record.details.plan.amount}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>interval</span>
                <span className="pl-4 ml-auto">
                  {record.details.plan.interval}
                </span>
              </div>
              <div className="d-flex">
                <span className={classes.discreet}>quantity</span>
                <span className="pl-4 ml-auto">
                  {record.details.quantity}
                </span>
              </div>
              <hr />
            </>
          )}
        />
        <PreformattedField source="details_dump" />
      </SimpleShowLayout>
    </Show>
  );
};

export default DimStripeSubscriptionShow;
