import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { TRANSACTION_STATUS_LABEL } from 'finance/assets/js/constants';
import { TRANSACTION_STATUS_COLOR } from 'admin/assets/js/constants';

const useStyles = makeStyles({
  transactionStatusLabel: {
    color: '#FFF',
    marginLeft: '10px',
    border: 'none',
    width: '100px',
  },
});

const TransactionStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.transactionStatusLabel}
      style={{
        backgroundColor: TRANSACTION_STATUS_COLOR[status] || 'lightgray',
      }}
      label={TRANSACTION_STATUS_LABEL[status] || status}
      variant="outlined"
      color="primary"
      size="small"
    />
  );
};

TransactionStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

export default TransactionStatus;
