import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-admin';
import Banner from 'admin/assets/js/components/Banner.jsx';
import { BANNER_TYPE } from 'admin/assets/js/constants';

const CashoutRequestBanner = ({ invoice }) => {
  const { deferredInvoicePayment } = invoice;
  return deferredInvoicePayment && deferredInvoicePayment.cashoutRequestId ? (
    <Banner
      type={BANNER_TYPE.INFO}
      content={(
        <>
          {'The invoice is part of a provider Cashout Request '}
          <Link to={`/cashout_requests/${deferredInvoicePayment.cashoutRequestId}/show`}>
            #
            {deferredInvoicePayment.cashoutRequestId}
          </Link>
          {'. Some actions are unavailable and should be performed on the linked transaction details views.'}
        </>
      )}
    />
  ) : null;
};

CashoutRequestBanner.propTypes = {
  invoice: PropTypes.object,
};

CashoutRequestBanner.defaultProps = {
  invoice: {},
};

export default CashoutRequestBanner;
