import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from 'react-admin';
import FreezeOrgBillingButton, { FREEZE_SEGMENT } from 'admin/assets/js/components/buttons/FreezeOrgBillingButton.jsx';

const OrganizationBillingFreeze = ({ record }) => {
  if (!record) {
    return null;
  }

  const { organization_id: orgId, billing_process_id: billingProcessId } = record;

  return (
    <Toolbar>
      <FreezeOrgBillingButton
        orgId={orgId}
        billingProcessId={billingProcessId}
        segment={FREEZE_SEGMENT.ALL}
      />
      <FreezeOrgBillingButton
        orgId={orgId}
        billingProcessId={billingProcessId}
        segment={FREEZE_SEGMENT.INVOICING_SETTINGS}
      />
      <FreezeOrgBillingButton
        orgId={orgId}
        billingProcessId={billingProcessId}
        segment={FREEZE_SEGMENT.SERVICE_METRICS}
      />
      <FreezeOrgBillingButton
        orgId={orgId}
        billingProcessId={billingProcessId}
        segment={FREEZE_SEGMENT.SERVICE_ORDERS}
      />
      <FreezeOrgBillingButton
        orgId={orgId}
        billingProcessId={billingProcessId}
        segment={FREEZE_SEGMENT.FX_RATES}
      />
    </Toolbar>
  );
};

OrganizationBillingFreeze.propTypes = {
  record: PropTypes.object,
};

OrganizationBillingFreeze.defaultProps = {
  record: {},
};

export default OrganizationBillingFreeze;
