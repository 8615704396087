import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Datagrid, FunctionField,
} from 'react-admin';
import { formatDate } from 'admin/assets/js/lib/utils';
import InvoiceInfoField from 'admin/assets/js/resources/invoices/InvoiceInfoField.jsx';
import InvoiceAmountsFieldPlain from 'admin/assets/js/resources/invoices/InvoiceAmountsFieldPlain.jsx';

const Empty = () => (
  <p>No records</p>
);

const PerSubscriptionList = ({ basePath, subscriptionId }) => (
  subscriptionId ? (
    <List
      basePath={basePath}
      resource="invoices"
      hasShow={false}
      hasCreate={false}
      hasEdit={false}
      hasList
      bulkActionButtons={false}
      actions={false}
      sort={{ field: 'id', order: 'DESC' }}
      filter={{ subscription_id: subscriptionId }}
      pagination={false}
      empty={<Empty />}
    >
      <Datagrid
        optimized
      >
        <InvoiceInfoField label="Invoice Number" showMeta={false} />
        <InvoiceAmountsFieldPlain source="total" label="Amount" showFees={false} />
        <FunctionField
          label="Due date"
          render={record => formatDate(record.dueAt)}
        />
        <FunctionField
          label="Creation date"
          render={record => formatDate(record.date)}
        />
      </Datagrid>
    </List>
  ) : null
);

PerSubscriptionList.propTypes = {
  basePath: PropTypes.string.isRequired,
  subscriptionId: PropTypes.number.isRequired,
};

export default PerSubscriptionList;
