import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Datagrid,
  FunctionField,
  Link,
  List,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { API_DATE_FORMAT, DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import {
  BANNER_TYPE,
  RUNNING_INVOICING_INVOICE_TYPE_LABEL,
  RUNNING_INVOICING_STATUS,
} from 'admin/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';
import ListFilter from 'admin/assets/js/resources/run_invoicing/filters.jsx';
import ListAction from 'admin/assets/js/resources/run_invoicing/actions.jsx';
import InvoicingStatus from 'admin/assets/js/resources/run_invoicing/InvoicingStatus.jsx';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { INVOICED_METHOD, INVOICED_METHOD_LABEL } from 'finance/assets/js/constants';
import useFetchOrgIdsForMissedBilling from 'admin/assets/js/components/hooks/useFetchOrgIdsForMissedBilling';
import Banner from 'admin/assets/js/components/Banner.jsx';


const useStyles = makeStyles({
  buttonContainer: {
    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
});

const RunInvoicingList = (props) => {
  const classes = useStyles();
  const today = useMemo(() => formatDate(new Date().toISOString(), API_DATE_FORMAT), []);

  const { missedDates, loading, error } = useFetchOrgIdsForMissedBilling(today);

  return (
    <>
      <h2 className="mb-2">Organizations requiring invoice generation</h2>
      {!loading && !error && Object
        .keys(missedDates)
        // sort dates desc, so oldest is first banner
        .sort((a, b) => a.localeCompare(b))
        .map((d) => {
          // convert 'deadline' to invoicing date for display and linking
          const invoicingDate = moment(d).add(1, 'day').format(API_DATE_FORMAT);
          return (
            <Banner
              type={BANNER_TYPE.DANGER}
              key={d}
              content={(
                <>
                  <p>{`Invoices were missed for ${invoicingDate}`}</p>
                  <Link
                    to={`/run_invoicing?displayFilters={}&filter={"invoicingProcessDate"%3A"${invoicingDate}"}&order=ASC&page=1&perPage=10&sort=organizationName`}
                  >
                    View them now.
                  </Link>
                  <p>{`This includes organization ids - ${missedDates[d].join(', ')}.`}</p>
                </>
              )}
            />
          );
        })
      }
      <List
        {...props}
        actions={<ListAction />}
        bulkActionButtons={<ListAction />}
        exporter={false}
        filterDefaultValues={{ invoicingProcessDate: today }}
        filters={<ListFilter />}
        sort={{ field: 'organizationName', order: 'ASC' }}
      >
        <Datagrid
          hover={false}
        >
          <FunctionField
            label="Organization"
            sortBy="organizationName"
            render={({ organizationName, organizationId }) => (
              <Link to={`/organizations/${organizationId}/show`}>
                {organizationName}
              </Link>
            )}
          />
          <FunctionField
            label="Invoice date"
            sortBy="invoiceDate"
            render={({ invoiceDate }) => formatDate(
              new Date(invoiceDate),
              API_DATE_FORMAT,
            )}
          />
          <FunctionField
            label="Invoicing process date"
            sortBy="invoicingProcessDate"
            render={({ invoicingProcessDate }) => formatDate(
              new Date(invoicingProcessDate),
              API_DATE_FORMAT,
            )}
          />
          <FunctionField
            sortBy="invoiceType"
            label="Invoice type"
            render={({ invoiceType }) => RUNNING_INVOICING_INVOICE_TYPE_LABEL[invoiceType]}
          />
          <FunctionField
            label="Status"
            sortBy="invoicingStatus"
            render={({ invoicingStatus }) => (
              <InvoicingStatus status={invoicingStatus} />
            )}
          />
          <FunctionField
            label="Performed by"
            sortBy="performedByName"
            render={({ performedByName, performedById }) => (performedById ? (
              <Link to={`/full_users/${performedById}/show`}>
                {performedByName}
              </Link>
            ) : '-')}
          />
          <FunctionField
            label="Generated at"
            sortBy="generatedAt"
            render={({ generatedAt }) => (generatedAt ? formatDate(new Date(generatedAt), DATETIME_FORMAT_DEFAULT) : '-')}
          />
          <FunctionField
            label="Generated with"
            sortBy="invoicedMethod"
            render={({ invoicedMethod }) => (invoicedMethod ? INVOICED_METHOD_LABEL[invoicedMethod] : '-')}
          />
          <FunctionField
            label=""
            sortable={false}
            render={({
              organizationId, organizationName, invoicingProcessDate, invoicingStatus,
            }) => (
              <div className={classes.buttonContainer}>
                <BulkApiActionButton
                  variant="outlined"
                  size="small"
                  successNotifMsg="Invoicing started"
                  label="Generate"
                  title={`Run invoicing for ${organizationName}`}
                  content={`Are you sure you want to run invoicing for ${organizationName} ?`}
                  icon={null}
                  ids={[organizationId]}
                  resource="run_invoicing"
                  action="generate"
                  payload={{
                    date: moment(invoicingProcessDate).format(API_DATE_FORMAT),
                    invoicedMethod: INVOICED_METHOD.TOOLING,
                  }}
                  disabled={invoicingStatus !== RUNNING_INVOICING_STATUS.PASSED_AUDIT
                    || moment().isBefore(invoicingProcessDate)}
                />
                <Link to={`/fact_audit_log_groups?filter={"orgId"%3A${organizationId}%2C"billingProcessDate"%3A"${moment(invoicingProcessDate).subtract(1, 'day').format(API_DATE_FORMAT)}"}`}>
                  View
                </Link>
              </div>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default RunInvoicingList;
