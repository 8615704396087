import React from 'react';
import PropTypes from 'prop-types';
import {
  ListContextProvider,
  Datagrid, FunctionField,
  ReferenceField,
  Link,
  useListController,
} from 'react-admin';
import { Typography } from '@material-ui/core';

import Money from 'finance/assets/js/lib/Money';
import UnallocateButton from 'admin/assets/js/resources/inbound_transfers/UnallocateButton.jsx';
import InvoiceAmountField from 'admin/assets/js/resources/inbound_transfers/InvoiceAmountField.jsx';
import TransactionAttemptTitle from 'admin/assets/js/resources/inbound_transfers/TransactionAttemptTitle.jsx';

const TransactionAttemptsList = ({ remoteId, organization, onUnallocation }) => {
  const context = useListController({
    resource: 'transactions',
    basePath: '/transactions',
    filter: { remote_id: remoteId, hide_removed: true },
  });

  if (context.loading) {
    return (<Typography variant="subtitle1">Loading allocations...</Typography>);
  }

  return (
    <div className="p-3">
      <div
        className="font-weight-bold"
      >
        Displaying allocated invoices for
        {' '}
        <Link
          to={`/organizations/${organization.id}/show`}
        >
          {organization.name}
        </Link>
        :
      </div>
      {context.loaded && context.ids.length === 0 && (
        <Typography variant="subtitle2">No allocated invoices found.</Typography>
      )}
      <ListContextProvider
        value={context}
      >
        <Datagrid>
          <TransactionAttemptTitle
            label={<Typography variant="subtitle2" className="font-weight-bold">Invoice</Typography>}
          />
          <ReferenceField
            source="invoice_id"
            reference="invoices"
            link={false}
            label={<Typography variant="subtitle2" className="font-weight-bold">Amount</Typography>}
          >
            <InvoiceAmountField />
          </ReferenceField>
          <FunctionField
            source="allocated_amount"
            label={<Typography variant="subtitle2" className="font-weight-bold">Allocated to payment</Typography>}
            render={(tx) => {
              const allocated = new Money(tx.allocated_amount, tx.allocated_currency);
              const detracted = new Money(tx.detracted_amount, tx.detracted_currency);
              const allocatedIsSameAsDetracted = (
                allocated.getCurrency() === detracted.getCurrency()
                && allocated.eq(detracted)
              );
              return (
                <>
                  {allocated.toString({ withSymbol: true, humanizeAmount: true })}
                  {!allocatedIsSameAsDetracted && (
                    <>
                      <br />
                      <small>
                        <span className="hint">
                          {detracted.toString({ withSymbol: true, humanizeAmount: true })}
                        </span>
                      </small>
                    </>
                  )}
                </>
              );
            }}
          />
          <FunctionField
            label="Unallocate"
            render={transaction => (
              <UnallocateButton
                transactionId={transaction.id}
                onSuccess={() => onUnallocation(remoteId)}
              />
            )}
          />
        </Datagrid>
      </ListContextProvider>
    </div>
  );
};

TransactionAttemptsList.propTypes = {
  remoteId: PropTypes.number.isRequired,
  organization: PropTypes.object,
  onUnallocation: PropTypes.func,
};

TransactionAttemptsList.defaultProps = {
  organization: {},
  onUnallocation: () => {},
};

export default TransactionAttemptsList;
