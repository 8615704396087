import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from 'admin/assets/js/layout/themes';
import { formatDate } from 'core/assets/js/lib/utils';
import {
  ReferenceField, TextField,
} from 'react-admin';

const TransactionReviewerField = (props) => {
  const { record } = props;
  const classes = useStyles();
  return (
    <span>
      <ReferenceField
        {...props}
        source="reviewed_by"
        reference="users"
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <br />
      <small>
        <span className={classes.discreet}>
          {formatDate(record.reviewed_at)}
        </span>
      </small>
    </span>
  );
};

TransactionReviewerField.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
};

TransactionReviewerField.defaultProps = {
  record: {},
};

export default TransactionReviewerField;
