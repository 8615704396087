import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const ClearFiltersButton = ({ onClearFilters }) => (
  <Button
    color="primary"
    onClick={() => onClearFilters({})}
  >
    Clear filters
  </Button>
);

ClearFiltersButton.propTypes = {
  onClearFilters: PropTypes.func.isRequired,
};

export default ClearFiltersButton;
