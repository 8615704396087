import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Create, useNotify, useRefresh } from 'react-admin';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import SubscriptionForm from 'admin/assets/js/resources/subscriptions/SubscriptionForm.jsx';

const CreateSubscriptionButton = ({ basePath }) => {
  const [showDialog, setShowDialog] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSuccess = () => {
    handleCloseClick();
    refresh();
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label="Add subscription"
        startIcon={<AddCircleIcon />}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Add subscription</DialogTitle>
        <DialogContent>
          <Create
            resource="subscriptions"
            basePath={basePath}
            onFailure={error => {
              notify(error.body?._error || error.message, 'warning');
            }}
            onSuccess={handleSuccess}
          >
            <SubscriptionForm />
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

CreateSubscriptionButton.propTypes = {
  basePath: PropTypes.string,
};

CreateSubscriptionButton.defaultProps = {
  basePath: null,
};

export default CreateSubscriptionButton;
