import React from 'react';
import PropTypes from 'prop-types';

import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';
import { TRANSACTION_MODE_SHORT_LABEL } from 'finance/assets/js/constants';

const InvoiceTransactionModeField = ({ record }) => {
  const invoicingSettingsPOJO = new InvoicingSettings(record.invoicingSettings);
  const transactionMode = invoicingSettingsPOJO.getTransactionMode();
  return <span>{TRANSACTION_MODE_SHORT_LABEL[transactionMode]}</span>;
};

InvoiceTransactionModeField.propTypes = {
  record: PropTypes.object.isRequired,
};

export default InvoiceTransactionModeField;
