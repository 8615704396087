import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import TransactionModalField from 'admin/assets/js/resources/transactions/TransactionModalField.jsx';

const InvoiceTransactionsField = ({ ...props }) => (
  <FunctionField
    {...props}
    render={(record) => {
      const { resource, basePath } = props;
      return (
        <TransactionModalField
          resource={resource}
          basePath={basePath}
          record={record}
        />
      );
    }}
  />
);

InvoiceTransactionsField.propTypes = {
  resource: PropTypes.string,
  basePath: PropTypes.string,
};

InvoiceTransactionsField.defaultProps = {
  resource: null,
  basePath: null,
};

export default InvoiceTransactionsField;
