import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';

import { UTM_KEYS } from 'core/assets/js/constants';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
}));
const UTMSCard = ({ organization }) => {
  const classes = useStyles();
  if (!organization) {
    return null;
  }
  const utm_metadata = organization?.ownerUserCard?.user?.utm_metadata;
  const utmCampaign = utm_metadata?.[UTM_KEYS.CAMPAIGN] || '-';
  const utmMedium = utm_metadata?.[UTM_KEYS.MEDIUM] || '-';
  const utmSource = utm_metadata?.[UTM_KEYS.SOURCE] || '-';
  const utmTerm = utm_metadata?.[UTM_KEYS.TERM] || '-';
  return (
    <Card variant="outlined">
      <CardHeader title="UTMS Tags" />
      <CardContent sx={{ m: 0, p: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>Source</Typography>
            <Typography className={classes.fieldValue}>{utmSource}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>Medium</Typography>
            <Typography className={classes.fieldValue}>{utmMedium}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>Campaign</Typography>
            <Typography className={classes.fieldValue}>{utmCampaign}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>Term</Typography>
            <Typography className={classes.fieldValue}>{utmTerm}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

UTMSCard.propTypes = {
  organization: PropTypes.shape({
    ownerUserCard: PropTypes.shape({
      user: PropTypes.shape({
        utm_metadata: PropTypes.object,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default UTMSCard;
