import React, { Fragment, useState } from 'react';

import { Button, Create } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import InvoicingEntityCreateForm from 'admin/assets/js/resources/invoicing_entities/InvoicingEntityForm.jsx';
import PropTypes from 'prop-types';

const CreateInvoicingEntityButton = ({ basePath }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const title = 'Create a new invoicing entity';

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label="+ Add entity"
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Create
            resource="invoicing_entity"
            basePath={basePath}
          >
            <InvoicingEntityCreateForm
              onDialogClose={handleDialogClose}
            />
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

CreateInvoicingEntityButton.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default CreateInvoicingEntityButton;
