import React from 'react';
import { Datagrid, FunctionField, ReferenceField } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { useStyles } from 'admin/assets/js/layout/themes';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { EXTENDED_PAYMENT_METHODS, EXTENDED_PAYMENT_METHOD_DETAILS } from 'settings/assets/js/constants';
import LinearProgressWithLabel from 'admin/assets/js/components/LinearProgressWithLabel.jsx';
import Payer from 'finance/assets/js/lib/Payer';
import TransferwiseTransfer from 'services/assets/js/lib/TransferwiseTransfer';

const wiseDetails = EXTENDED_PAYMENT_METHOD_DETAILS[EXTENDED_PAYMENT_METHODS.WISE];

const InboundTransferDataGrid = ({ ...props }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const searchParams = qs.parse(location.search);
  const { selectedTransferId } = searchParams;
  return (
    <Datagrid
      {...props}
      rowClick={(id, basePath, record) => {
        const search = qs.stringify({
          ...searchParams,
          selectedTransferId: record.id,
        });
        history.push({
          pathname: location.pathname,
          search,
        });
      }}
      rowStyle={record => (
        selectedTransferId === record.id.toString()
          ? ({ backgroundColor: '#f6f6f6' })
          : null
      )}
    >
      <FunctionField
        label="Payment Date"
        sortable
        source="paymentDate"
        render={(record) => {
          const transfer = new TransferwiseTransfer(record.transfer);
          return transfer.getTransactionRaisedAt().format('YYYY/MM/DD');
        }}
      />
      <FunctionField
        source="amount"
        label="Amount"
        render={(record) => {
          const transfer = new TransferwiseTransfer(record.transfer);
          // we will be using 'target' fields because this is a transfer TD received
          const money = transfer.getTargetMoney();
          return (
            <NumberTpl
              value={money.toString()}
              prefix={money.getCurrencySymbol()}
            />
          );
        }}
      />
      <FunctionField
        label="Method"
        render={record => (
          <>
            <img style={{ width: '50px' }} alt={wiseDetails.alias} src="/img/payment-options/wise-logo.png" className="company-logo" />
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>
              #
              {record.id}
            </span>
          </>
        )}
      />
      <FunctionField
        label="Reference"
        sortable={false}
        render={(record) => {
          const transfer = new TransferwiseTransfer(record.transfer);
          return transfer.getReference();
        }}
      />
      <ReferenceField
        label="Payer"
        source="payerId"
        reference="dim_payers"
        link={false}
      >
        <FunctionField
          render={(dimPayer) => {
            if (!dimPayer) {
              return (<span>Unknown payer</span>);
            }
            const payer = new Payer(dimPayer.details);
            return (
              <>
                <span>
                  {payer.getName()}
                </span>
                <br />
                <small>
                  <span className={classes.discreet}>
                    {payer.getObfuscatedAccount()}
                  </span>
                </small>
              </>
            );
          }}
        />
      </ReferenceField>
      <FunctionField
        label="Allocated"
        render={(record) => {
          const ratio = record.allocatedRatio;
          return (
            <LinearProgressWithLabel
              valuePerCent={ratio * 100}
              textClassName="text-danger"
            />
          );
        }}
      />
    </Datagrid>
  );
};

export default InboundTransferDataGrid;
