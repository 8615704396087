import React, { useState } from 'react';
import { Title } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import Form from 'admin/assets/js/components/Form.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { reloadTable } from 'admin/assets/js/lib/utils';
import {
  adminApiBlockedEmailDomainCreateUrl, adminApiBlockedEmailDomainDeleteUrl,
} from 'admin/urls';

export const BlockedEmailDomainsView = () => {
  const [loading, setLoading] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const notifications = getNotifications();

  const onCreateClose = () => setCreateDialogOpen(false);

  const columns = [
    {
      label: 'Domain',
      name: 'domain',
      options: { filter: false, sort: true },
    },
    {
      label: 'Actions',
      name: 'domain',
      options: {
        cellRender: (_, { id }) => {
          return (
            <Button
              color="primary"
              onClick={async () => {
                try {
                  await apiClient({
                    method: 'DELETE', url: adminApiBlockedEmailDomainDeleteUrl(id),
                  });
                  reloadTable();
                } catch (e) {
                  notifications.error(e.message);
                }
              }}
              variant="contained"
            >
              Delete
            </Button>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <>
      <Title title="Blocked email domains" />
      <div className="d-flex justify-content-end my-4">
        <Button
          color="primary"
          onClick={() => setCreateDialogOpen(true)}
          variant="contained"
        >
          Add domain
        </Button>
      </div>
      <p>
        These email domains are blocked, when organization owners try to sign up to our platform.
      </p>
      <ApiTable
        columns={columns}
        defaultSortBy="domain"
        defaultSortDirection="ASC"
        resource="blocked_email_domains"
      />
      <Dialog onClose={onCreateClose} open={createDialogOpen}>
        <DialogTitle>Add domain</DialogTitle>
        <DialogContent>
          <Form
            fields={[
              {
                Component: TextField,
                disabled: loading,
                label: 'Domain',
                name: 'domain',
                required: true,
                validate: value => (!value ? 'Enter a valid domain' : undefined),
              },
            ]}
            onCancel={onCreateClose}
            onSubmit={async data => {
              setLoading(true);
              try {
                await apiClient({
                  data,
                  method: 'POST',
                  url: adminApiBlockedEmailDomainCreateUrl(),
                });
                onCreateClose();
                notifications.success('New blocked email domain added successfully');
                // refresh is required to reload the list
                reloadTable();
              } catch (e) {
                notifications.error(
                  e.response?.data?._error || e.message || 'Adding domain failed',
                );
              } finally {
                setLoading(false);
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withRouter(BlockedEmailDomainsView);
