import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCreate, useNotify, useRefresh, useUpdate, SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';
import moment from 'moment';

import { get } from 'lodash';

import { API_DATE_FORMAT } from 'core/assets/js/constants';

const RecordSubscriptionTransactionButton = ({ ...props }) => {
  const [create, { error: createError }] = useCreate('subscription_transaction');
  const notify = useNotify();
  const refresh = useRefresh();
  const formState = useFormState();
  const [update] = useUpdate('subscriptions', get(formState.values, 'subscriptionId'));

  const { subscription } = props;

  // lock submit when have submitted, however unlock when an error occurs
  // this should allow re-submitting to resolve validation errors
  // but prevent accidental resubmission of the form if a submission is in progress
  const [submitDisabled, setSubmitDisabled] = useState(false);
  useEffect(() => {
    // re-enable submit when an error occurs,
    // this allows resubmission after resolving validation errors
    if (createError) {
      setSubmitDisabled(false);
    }
  }, [createError]);

  const handleClick = useCallback(() => {
    // disable immediate resubmission
    setSubmitDisabled(true);

    const data = { ...formState.values };

    create({ payload: { data } }, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        refresh();
      },
    });

    // update sub renewal with the day after periodEnd from sub modal
    // but only if it's a later renewal date, in case several
    // transaction are recorded out of order :/
    const renewalDate = moment(data.periodEnd)
      .add(1, 'day')
      .format(API_DATE_FORMAT);
    if (!subscription.renewal_date
      || moment(renewalDate).isAfter(moment(subscription.renewal_date))) {
      update({ payload: { data: { ...subscription, renewal_date: renewalDate } } });
    }
  }, [formState.values, create, update, notify]);

  return (
    <div className="d-flex flex-column mt-4 mb-4">
      <div>
        {createError && createError.body && createError.body._error && (
          <p className="text-danger">{createError.body._error}</p>
        )}
      </div>
      <SaveButton
        {...props}
        redirect={false}
        handleSubmitWithRedirect={handleClick}
        disabled={submitDisabled}
      >
        Record Subscription Transaction
      </SaveButton>
    </div>
  );
};

RecordSubscriptionTransactionButton.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default RecordSubscriptionTransactionButton;
