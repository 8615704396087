import { lowerCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const DeactivateOrgButton = ({ label, record, reloadApiTable, resource }) => {
  const [modalOpen, setModalOpen] = useState(false);

  if (!record) {
    return null;
  }
  const outstanding = [];
  const isDirectMode = record.invoicing_mode === INVOICING_MODE.DIRECT;
  if (record.outstandingCounts) {
    const ignoreCounts = [];
    if (isDirectMode) {
      ignoreCounts.push('outstandingContractorInvoices');
    }
    Object.keys(record.outstandingCounts).filter(k => !ignoreCounts.includes(k)).forEach(item => {
      if (record.outstandingCounts[item] > 0) {
        outstanding.push(`${lowerCase(item)} (${record.outstandingCounts[item]})`);
      }
    });
  }
  if (outstanding.length) {
    return (
      <>
        <ConfirmModal
          confirm="Ok"
          content={(
            <>
              <p>You cannot deactivate this organisation, because they have outstanding:</p>
              <ul>
                {outstanding.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </>
          )}
          onClose={() => setModalOpen(false)}
          onConfirm={() => setModalOpen(false)}
          open={modalOpen}
          title="Cannot deactivate organisation"
        />
        <MenuItem className="organization_view_tab__card__header--dropdown-menu-item-danger" onClick={() => setModalOpen(true)}>
          {label}
        </MenuItem>
      </>
    );
  }
  const isDeactivated = ORGANIZATION_STATUS.DEACTIVATED === record.status;

  return (
    <BulkApiActionButton
      action="deactivate-org"
      content={(
        <>
          <p>
            Once you deactivate this organisation, none of their users will have access to it. Are
            you sure you want to deactivate this organisation?
          </p>
          {isDirectMode && (
            <p>
              Because this organization&apos;s invoicing mode is direct, we will mark all of their
              invoices as paid.
            </p>
          )}
        </>
      )}
      disabled={isDeactivated}
      ids={record.id}
      label={label}
      reloadApiTable={reloadApiTable}
      resource={resource}
      title="Deactivate Organisation"
    />
  );
};

DeactivateOrgButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  reloadApiTable: PropTypes.bool,
  resource: PropTypes.string,
};

DeactivateOrgButton.defaultProps = {
  label: 'Deactivate organisation',
  reloadApiTable: false,
  record: null,
  resource: null,
};

export default DeactivateOrgButton;
