
import CustomBankAccountsView from 'admin/assets/js/CustomBankAccountsView.jsx';
import { BANK_ACCOUNT_TABS } from 'admin/assets/js/constants';

const CustomSystemBankAccountsView = ({ ...props }) => {
  return CustomBankAccountsView({
    ...props,
    selectedTab: BANK_ACCOUNT_TABS.SYSTEM,
  });
};

export default CustomSystemBankAccountsView;
