import React from 'react';
import {
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
} from 'react-admin';
import { Typography } from '@material-ui/core';

import OrgBillingAnalyticsShowDetails from 'admin/assets/js/resources/org_billing_analytics/components/OrgBillingAnalyticsShowDetails.jsx';
import OrgBillingInvoicesAnalyticsList from 'admin/assets/js/resources/org_billing_invoices_analytics/OrgBillingInvoicesAnalyticsList.jsx';

/**
 * Layout for show page.
 *
 * Uses tabs to separate different REST entities onto separate pages.
 * Each with their own unique URL.
 *
 * There's a details page ( info ) which shows overall statistics for the invoice run.
 * And a list page ( invoices ) which lists each invoice in the run.
 * @param {object} props - component properties.
 * @return {JSX.Element} rendered component.
 */
const OrgBillingAnalyticsShow = props => (
  <Show
    {...props}
  >
    <FunctionField
      addLabel={false}
      render={record => (
        <TabbedShowLayout title="" spacing={2}>
          <Tab label="Info">
            <OrgBillingAnalyticsShowDetails analytics={record} />
          </Tab>
          <Tab label="invoices" path="invoices">
            <Typography className="mt-4" variant="h4">Invoice Run Details - Invoices</Typography>
            <Typography variant="h7">
              {`Invoices for period ${record.periodStart} - ${record.periodEnd}`}
            </Typography>
            <OrgBillingInvoicesAnalyticsList analyticsId={record.id} />
          </Tab>
        </TabbedShowLayout>
      )}
    />
  </Show>
);

export default OrgBillingAnalyticsShow;
