import { debounce, omit } from 'lodash';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { adminApiRootUrl } from 'admin/urls';
import { CREATE_BLOB, DOCUMENT_BODY, DOCUMENT_CREATE_ELEMENT } from 'core/assets/js/config/settings';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE = 10;

export const ApiTable = ({
  columns,
  className,
  defaultFilters,
  defaultSortBy,
  defaultSortDirection,
  downloadEnabled,
  draggableColumns,
  excludeColumnsFromDownload,
  extraResourceQueryParams,
  history,
  location,
  noMatchText,
  resource,
  searchEnabled,
  sortFilterList,
  useQueryParams,
  viewColumnsEnabled,
}) => {
  const { pathname, search } = location;
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  const [internalPage, setInternalPage] = useState(DEFAULT_PAGE);
  const [internalRowsPerPage, setInternalRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [internalSortBy, setInternalSortBy] = useState(defaultSortBy);
  const [internalSortDirection, setInternalSortDirection] = useState(defaultSortDirection);
  const [internalFilters, setInternalFilters] = useState(defaultFilters);

  const notifications = getNotifications();

  if (!defaultSortBy) {
    throw new Error('No defaultSortBy specified');
  }

  let page = internalPage;
  let rowsPerPage = internalRowsPerPage;
  let sortBy = internalSortBy;
  let sortDirection = internalSortDirection;
  let filters = internalFilters;

  const parsedSearch = qs.parse(search);
  const { reload } = parsedSearch;
  if (useQueryParams) {
    page = parsedSearch.page || DEFAULT_PAGE;
    rowsPerPage = parsedSearch.perPage || DEFAULT_ROWS_PER_PAGE;
    sortBy = parsedSearch.sort || defaultSortBy;
    sortDirection = parsedSearch.order || defaultSortDirection;
    filters = parsedSearch.filter || defaultFilters;
  }

  if (typeof filters === 'string') {
    filters = JSON.parse(filters);
  }
  const searchQuery = filters.q || '';
  filters = omit(filters, 'q');

  const updateUrl = (changes = {}) => {
    if (useQueryParams) {
      const newQuery = qs.stringify({
        filter: JSON.stringify({ ...filters, q: searchQuery }),
        page,
        perPage: rowsPerPage,
        sort: sortBy,
        order: sortDirection,
        ...changes,
        ...extraResourceQueryParams,
      });
      const newUrl = `${pathname}?${newQuery}`;
      history.push(newUrl);
      return;
    }
    if (changes.page) {
      setInternalPage(changes.page);
    }
    if (changes.perPage) {
      setInternalRowsPerPage(changes.perPage);
    }
    if (changes.sort) {
      setInternalSortBy(changes.sort);
    }
    if (changes.order) {
      setInternalSortDirection(changes.order);
    }
    if (changes.filter) {
      setInternalFilters(JSON.parse(changes.filter));
    }
  };

  const apiRequest = async (allResults = false) => {
    const thisQuery = {
      _end: allResults ? count : page * rowsPerPage,
      _order: sortDirection,
      _sort: sortBy,
      _start: allResults ? 0 : (page - 1) * rowsPerPage,
      q: searchQuery,
      ...filters,
      ...extraResourceQueryParams,
    };
    return apiClient({ url: `${adminApiRootUrl()}/${resource}?${qs.stringify(thisQuery)}` });
  };

  const loadData = async () => {
    const response = await apiRequest();
    setData(response.data);
    setCount(parseInt(response.headers['x-total-count'] || 0, 10));
  };

  const debouncedSearch = useRef(
    debounce(q => updateUrl({ filter: JSON.stringify({ ...filters, q }) }), 300),
  ).current;

  const filtersString = qs.stringify(filters);

  const extraextraResourceQueryParamsString = JSON.stringify(extraResourceQueryParams);

  useEffect(
    () => {
      loadData();
    },
    [
      filtersString,
      page,
      reload,
      rowsPerPage,
      searchQuery,
      sortBy,
      sortDirection,
      extraextraResourceQueryParamsString,
    ],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const parsedColumns = columns.map(column => {
    const { options = {} } = column;
    if (options.customBodyRender || options.customBodyRenderLite) {
      /*
        customBodyRender changes filter dropdown values and customBodyRenderLite requires having
        access to the returned API data.
        So we don't use either of them directly and instead use a cellRender option
      */
      throw new Error('Please use cellRender(value, record) instead of customBodyRender(Lite)');
    }
    const { cellRender, setCellProps } = options;
    return {
      ...column,
      options: {
        ...omit(options, 'cellRender'),
        customBodyRenderLite: !cellRender ? undefined : (_, rowIndex) => {
          const record = data[rowIndex];
          return cellRender(record[column.name], record);
        },
        setCellProps: !setCellProps ? undefined : (cellValue, rowIndex) => {
          return setCellProps(data[rowIndex]);
        },
        ...(
          options.filter && filters[column.name] !== undefined
            ? { filterList: [filters[column.name]] }
            : {}
        ),
      },
    };
  });

  const getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableFilter: {
        root: {
          padding: 36,
        },
      },
      MuiFormLabel: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
  });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        className={className}
        columns={parsedColumns}
        data={data}
        options={{
          count,
          sortFilterList,
          download: downloadEnabled,
          draggableColumns: {
            enabled: draggableColumns,
            transitionTime: 300,
          },
          filter: parsedColumns.some(c => !c.options || c.options.filter),
          jumpToPage: true,
          onChangePage: newPage => updateUrl({ page: newPage + 1 }),
          onChangeRowsPerPage: newRowsPerPage => updateUrl({ perPage: newRowsPerPage }),
          onColumnSortChange: (changedColumn, direction) => {
            updateUrl({ sort: changedColumn, order: direction.toUpperCase() });
          },
          onDownload: () => {
            // The default download only saves the visible rows, this loads all rows
            apiRequest(true)
              .then(response => {
                const columnCells = [];
                const rowNames = [];
                columns.forEach(column => {
                  if (!excludeColumnsFromDownload.includes(column.label)) {
                    columnCells.push(column.label);
                    rowNames.push({
                      cellRender: column.options?.downloadCellRender || column.options?.cellRender,
                      name: column.name,
                    });
                  }
                });
                const rows = response.data.map(record => {
                  const row = [];
                  rowNames.forEach(({ cellRender, name }) => {
                    const value = record[name];
                    row.push(cellRender ? cellRender(value, record) : value);
                  });
                  return row;
                });
                const csvData = [
                  columnCells.join(';'), ...rows.map(row => row.join(';')),
                ].join('\n');
                const a = DOCUMENT_CREATE_ELEMENT('a');
                a.href = URL.createObjectURL(
                  CREATE_BLOB([csvData], { type: 'application/octet-stream' }),
                );
                a.setAttribute('download', `${resource}.csv`);
                DOCUMENT_BODY.appendChild(a);
                a.click();
                DOCUMENT_BODY.removeChild(a);
              })
              .catch(e => {
                notifications.error(e.response?.data?._error || e.message || 'Download failed');
              });
            return false;
          },
          onFilterChange: (_, filterList) => {
            const newFilters = filterList.reduce(
              (acc, [value], columnIndex) => {
                if (value !== undefined) {
                  acc[columns[columnIndex].name] = value;
                }
                return acc;
              },
              {},
            );
            updateUrl({ filter: JSON.stringify({ ...newFilters, q: searchQuery }) });
          },
          onSearchChange: debouncedSearch,
          onSearchClose: () => updateUrl({ q: '' }),
          page: page - 1,
          print: false,
          resizableColumns: true,
          rowsPerPage: parseInt(rowsPerPage, 10),
          search: searchEnabled,
          searchText: searchQuery,
          selectableRows: 'none',
          serverSide: true,
          setTableProps: () => ({ size: 'small' }),
          sortOrder: { direction: sortDirection.toLowerCase(), name: sortBy },
          textLabels: { body: { noMatch: noMatchText } },
          viewColumns: viewColumnsEnabled,
        }}
      />
    </ThemeProvider>
  );
};

ApiTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  defaultFilters: PropTypes.object,
  defaultSortBy: PropTypes.string.isRequired,
  defaultSortDirection: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  downloadEnabled: PropTypes.bool,
  draggableColumns: PropTypes.bool,
  excludeColumnsFromDownload: PropTypes.arrayOf(PropTypes.string),
  extraResourceQueryParams: PropTypes.object,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  noMatchText: PropTypes.string,
  resource: PropTypes.string.isRequired,
  searchEnabled: PropTypes.bool,
  sortFilterList: PropTypes.bool,
  useQueryParams: PropTypes.bool,
  viewColumnsEnabled: PropTypes.bool,
};

ApiTable.defaultProps = {
  className: null,
  defaultFilters: {},
  draggableColumns: true,
  downloadEnabled: true,
  excludeColumnsFromDownload: ['Actions'],
  extraResourceQueryParams: {},
  noMatchText: 'Sorry, no matching records found',
  searchEnabled: true,
  sortFilterList: true,
  useQueryParams: true,
  viewColumnsEnabled: true,
};

export default withRouter(ApiTable);
