import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import Big from 'big.js';

import { formatAmountString, determineAmountColour } from 'admin/assets/js/lib/utils';

Big.RM = 1;
Big.DP = 2;

/**
 * Display a currency amount prefixed by the currency symbol.
 * Show absolute values, but colour the text red when it's a negative value.
 * Normal ( positive ) values are shown in black.
 * Values are also formatted to .2 to ensure 2 decimal places so, 0.00
 * is shown correctly, and not as 0.0, and we format the number according to locale
 * to insert thousand separators etc.
 * @param {source} string - property from record to display
 * @return {JSX.Element} rendered component.
 */
const CurrencyAmountField = ({ source, ...props }) => (
  <FunctionField
    {...props}
    render={record => (
      <span style={{ color: determineAmountColour(record[source] || '') }}>
        {formatAmountString(record[source] || '0.00', record.currency)}
      </span>
    )}
  />
);

CurrencyAmountField.propTypes = {
  source: PropTypes.string.isRequired,
};

export default CurrencyAmountField;
