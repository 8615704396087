import axios from 'axios';
import { get } from 'lodash';
import { NODE_ENV, ENV_PRODUCTION, WINDOW_REDIRECT } from 'core/assets/js/config/settings';
import { COOKIE_MAX_AGE } from 'core/assets/js/constants';
import { adminLoginUrl, adminApiLoginUrl } from 'admin/urls';
import Cookies from 'cookies-js';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';

const expires = NODE_ENV !== ENV_PRODUCTION ? COOKIE_MAX_AGE : undefined;

const authProvider = {
  login: ({ username, password, captcha }) => {
    const url = adminApiLoginUrl();
    return new Promise((resolve, reject) => {
      axios.post(url, { email: username, password, captcha })
        .then((response) => {
          if (response.data._error) {
            throw new Error(response.data._error);
          } else {
            const { canViewTdFinances, role, token } = response.data;
            Cookies.set('token', token, { expires });
            Cookies.set('role', role, { expires });
            Cookies.set('canViewTdFinances', canViewTdFinances ? 1 : 0, { expires });
            WINDOW_REDIRECT('/rattlecage');
            resolve();
          }
        })
        .catch((error) => {
          const msg = get(error, 'response.data._error');
          if (msg) {
            reject(new Error(msg));
          } else {
            reject(error);
          }
        });
    });
  },
  logout: () => {
    Cookies.expire('token');
    Cookies.expire('role');
    Cookies.expire('canViewTdFinances');
    WINDOW_REDIRECT(adminLoginUrl());
    return Promise.resolve();
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      Cookies.expire('token');
      Cookies.expire('role');
      Cookies.expire('canViewTdFinances');
      return Promise.reject({ redirectTo: adminLoginUrl() }); //eslint-disable-line
    }
    return Promise.resolve();
  },
  checkAuth: ({ isLogin } = {}) => {
    if (isLogin) {
      return Promise.resolve();
    }
    // console.log('ROUTE', route, resource);
    // if (!resource && route === 'login') {
    //   return Promise.resolve();
    // }
    const stored = Cookies.get('token');
    if (stored) {
      return Promise.resolve();
    }
    return Promise.reject({ redirectTo: adminLoginUrl() }); //eslint-disable-line
  },
  getPermissions: () => {
    const storedRole = Cookies.get('role');
    const canViewTdFinances = Cookies.get('canViewTdFinances') === '1';
    const role = storedRole || USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.ADMIN];
    return Promise.resolve({ role, canViewTdFinances });
  },
};

export default authProvider;
