import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  banner: {
    backgroundColor: '#ebf5fb',
    color: '#36a0dc',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
    display: 'flex',
    fontSize: '12px',
  },
  textContainer: {
    paddingTop: '3px',
    paddingLeft: '8px',
  },
  parentLink: {
    color: 'rgba(0,0,0,0.54)',
    marginBottom: '0',
  },
});

const ChildOrgBanner = ({ parent }) => {
  const classes = useStyles();

  if (!parent) {
    return null;
  }

  return (
    <div className={classes.banner}>
      <Info />
      <div className={classes.textContainer}>
        <p>
          {'This organization is a sub-account.  Licence fee pricing can only be controlled on the Master account.'}
        </p>
        <p className={classes.parentLink}>
          The master account of this organization is
          <Link to={`/organizations/${parent.id}/show`}>
            {` ${parent.name}`}
          </Link>
        </p>
      </div>
    </div>
  );
};

ChildOrgBanner.propTypes = {
  parent: PropTypes.object,
};

ChildOrgBanner.defaultProps = {
  parent: null,
};

export default ChildOrgBanner;
