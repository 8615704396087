import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import ProcessingFeeScheme from 'finance/assets/js/lib/ProcessingFeeScheme';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import { formatAmountString } from 'admin/assets/js/lib/utils';
import {
  ProcessingFeeSpec,
} from 'finance/assets/js/components/invoicing/FeeScheme.jsx';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';
import { PROCESSING_FEES_MODE_LABEL } from 'finance/assets/js/constants';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
    fontWeight: 600,
  },
}));
const FeesCard = ({ organization }) => {
  const classes = useStyles();
  if (!organization) {
    return null;
  }
  const scheme = new InvoicingFeeScheme(organization.invoicing_fee_scheme);
  const processingFeeScheme = scheme.getProcessingFeeSpec();
  const parsedProcFeeScheme = new ProcessingFeeScheme(processingFeeScheme);
  const processingFloor = parsedProcFeeScheme.getFloor();
  const processingCurrency = parsedProcFeeScheme.getCurrency();
  const processingModeLabel = PROCESSING_FEES_MODE_LABEL[parsedProcFeeScheme.getMode()];

  // licence
  const licenceFeeScheme = scheme.getLicenceFeeSpec();
  const parsedLicenceFeeScheme = new LicenceFeeScheme(licenceFeeScheme);
  const baseLicenceFee = parsedLicenceFeeScheme.getBaseLicenceFee();
  const licenceCurrency = parsedLicenceFeeScheme.getCurrency();
  const managerSeats = parsedLicenceFeeScheme.getBaseManagerSeats();
  const providerSeats = parsedLicenceFeeScheme.getBaseProviderSeats();
  const perProviderSeat = parsedLicenceFeeScheme.getPerProviderSeat();
  const perManagerSeat = parsedLicenceFeeScheme.getPerManagerSeat();

  return (
    <Card variant="outlined">
      <CardHeader title="Fees" />
      <CardContent sx={{ m: 0, p: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Processing Fees Mode
            </Typography>
            <Typography className={classes.fieldValue}>
              {processingModeLabel}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Processing Fees Floor
            </Typography>
            <Typography
              className={classes.fieldValue}
            >
              {formatAmountString(processingFloor || '0.00', processingCurrency)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Processing Fees Scales
            </Typography>
            <Grid style={{ textAlign: 'right', fontWeight: 600, fontSize: '1rem' }}>
              <ProcessingFeeSpec scheme={processingFeeScheme} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Base Licence Fees
            </Typography>
            <Typography className={classes.fieldValue}>
              {formatAmountString(baseLicenceFee || '0.00', licenceCurrency)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Included Manager Seats
            </Typography>
            <Typography className={classes.fieldValue}>{managerSeats}</Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Additional Manager Seats
            </Typography>
            <Typography className={classes.fieldValue}>
              {formatAmountString(perManagerSeat || '0.00', licenceCurrency)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Included Provider Seats
            </Typography>
            <Typography className={classes.fieldValue}>{providerSeats}</Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.column}>
            <Typography>
              Additional Provider Seats
            </Typography>
            <Typography className={classes.fieldValue}>
              {formatAmountString(perProviderSeat || '0.00', licenceCurrency)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

FeesCard.propTypes = {
  organization: PropTypes.shape({
    invoicing_fee_scheme: PropTypes.object.isRequired,
  }).isRequired,
};

export default FeesCard;
