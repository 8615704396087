import React from 'react';
import {
  FunctionField,
  Datagrid,
  List,
  Link,
  ReferenceField,
  TextField,
} from 'react-admin';

import { formatDate } from 'admin/assets/js/lib/utils';
import ListFilter from 'admin/assets/js/resources/deferred_invoice_payments/filters.jsx';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import InvoiceStatus from 'admin/assets/js/components/InvoiceStatus.jsx';
import { INVOICE_STATUS } from 'finance/assets/js/constants';

const DeferredInvoicePaymentsList = props => (
  <List
    {...props}
    filters={<ListFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid>
      <TextField label="#" source="id" />
      <ReferenceField
        source="invoiceId"
        reference="invoices"
        link="show"
        sortable={false}
      >
        <FunctionField
          render={({ uniqueNumber, id }) => (
            <Link to={`/children_invoices?filter=%7B"parent_id"%3A${id}%7D`}>
              {uniqueNumber}
            </Link>
          )}
        />
      </ReferenceField>
      <FunctionField
        source="createdAt"
        label="Created at"
        render={record => formatDate(record.createdAt)}
      />
      <FunctionField
        label="Invoice status"
        render={({ invoice }) => (
          <InvoiceStatus
            raisedStatus={invoice?.status === INVOICE_STATUS.RAISED ? invoice.raisedStatus : null}
            status={invoice?.status}
            size="small"
          />
        )}
      />
      <FunctionField
        label="Provider name"
        render={({ invoice }) => {
          const ownerFE = new FinancialEntity(invoice.ownerFE);
          return (
            <Link to={`/full_users/${ownerFE.getUserId()}/show`}>
              {ownerFE.getFullProfileName()}
            </Link>
          );
        }}
      />
      <ReferenceField
        label="Organization"
        source="organization.id"
        reference="organizations"
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default DeferredInvoicePaymentsList;
