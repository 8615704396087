import React from 'react';
import { Filter, DateInput, SelectInput } from 'react-admin';
import { RUNNING_INVOICING_STATUS_LABEL } from 'admin/assets/js/constants';

const statusOptions = Object.keys(RUNNING_INVOICING_STATUS_LABEL).map(k => ({
  id: k, name: RUNNING_INVOICING_STATUS_LABEL[k],
}));

const ListFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statusOptions}
    />
    <DateInput
      label="Date"
      alwaysOn
      source="invoicingProcessDate"
    />
  </Filter>
);

export default ListFilter;
