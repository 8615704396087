import Money from 'finance/assets/js/lib/Money';
/**
 * Wise statement.
 *
 * This pojo matches the DimTransferwiseStatements table.
 *
 * It represents a single payment on our Wise accounts.
 */
class TransferwiseStatement {
  /**
   * Constructor.
   *
   * @see https://docs.wise.com/api-docs/api-reference/balance-statement
   *
   * @param {object} details - the remote details.
   */
  constructor({ ...details }) {
    this.details = details;
  }

  /**
   * Serialize the pojo instance.
   *
   */
  serialize() {
    return {
      ...this.details,
    };
  }

  /**
   * Get the Wise reference number associated with this statement.
   *
   * If no reference number is present it will return null.
   *
   * Reference number format 'AAA-NNN', where NNN appears to be a transfer id,
   * when AAA is 'TRANSFER'.
   *
   * @return {string|null} the reference number of this statement, if available.
   */
  getReferenceNumber() {
    return this.details?.referenceNumber || null;
  }

  /**
   * Parse the reference number to determine the associated transfer id.
   *
   * The reference number is in the format <TYPE>-<ID>
   * When <TYPE> is "TRANSFER", then <ID> should be a Wise transfer id.
   * ie "CARD-249281" or "TRANSFER-34188888"
   * @see https://docs.wise.com/api-docs/api-reference/balance-statement
   *
   * If we can't parse the reference number, or it's not of the expected type
   * "TRANSFER" we will return null.
   *
   * @return {number|null} transfer id if it can be parsed, else null.
   */
  getTransferId() {
    // parse the reference number into it's component parts ( ie <TYPE>-<ID> )
    const referenceNumber = this.getReferenceNumber();
    const refComponents = (referenceNumber || '').split('-');

    // did we parse the reference number, and is it a TRANSFER ?
    // note, this could be other types, like CARD etc.
    if (refComponents && refComponents[0] === 'TRANSFER') {
      // parse the transfer id, or return null if unabled
      return parseInt(refComponents[1], 10) || null;
    }

    // failed to parse reference number to determine transfer id
    // probably not a 'transfer'
    return null;
  }

  /**
   * Get the payer name for this statement.
   *
   * If no merchant or the merchant has no name it will return null.
   *
   * @return {string|null} the payer name of this statement, if available.
   */
  getPayerName() {
    return this.details?.details?.merchant?.name || null;
  }

  /**
   * Get the type of this statement.
   *
   * A statement maybe a credit or a debit.
   *
   * @return {WISE_STATEMENT_TYPE|null} statement type, if available.
   */
  getType() {
    return this.details?.type || null;
  }

  getTransactionType() {
    return this.details?.details?.type || null;
  }

  /**
   * Get the sender name for the statement.
   *
   * If the sender name is missing it will return null.
   *
   * @return {string|null} sender name, if available.
   */
  getSenderName() {
    return this.details?.details?.senderName || null;
  }

  /**
   * Get the sender account for the statement.
   *
   * If the sender account is missing it will return null.
   *
   * @return {string} send account, if available.
   */
  getSenderAccount() {
    return this.details?.details?.senderAccount || null;
  }

  isDeposit() {
    return this.details?.details?.type === 'DEPOSIT';
  }

  getTotalFee() {
    const { value, currency } = this.details.totalFees;
    return new Money(value, currency);
  }
}

export default TransferwiseStatement;
