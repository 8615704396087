import React from 'react';
import { Grid } from '@material-ui/core';

import CashoutRequestViewDetails
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewDetails.jsx';
import CashoutRequestViewInvoices
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewInvoices.jsx';
import CashoutRequestViewTransactions
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewTransactions.jsx';
import CashoutRequestViewHeader
  from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewHeader.jsx';
import CashoutRequestViewActions from 'admin/assets/js/resources/cashout_requests/CashoutRequestViewActions.jsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(2),
  },
}));


const CustomShowLayout = ({ record: cashoutRequest }) => {
  const classes = useStyles();
  if (!cashoutRequest) {
    return null;
  }
  const { invoiceTotalAmount, invoiceTotalCurrency, cashoutInvoices, id } = cashoutRequest;
  const transactions = cashoutRequest?.cashoutInvoices?.map(invoice => invoice.transactions).flat();
  const transactionIds = [...new Set(transactions.map(tr => tr.id))];
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CashoutRequestViewHeader cashoutRequestId={id} />
        <CashoutRequestViewActions
          cashoutInvoices={cashoutInvoices}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.gridItem}>
              <CashoutRequestViewDetails
                cashoutRequest={cashoutRequest}
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              <CashoutRequestViewInvoices
                invoiceTotalAmount={invoiceTotalAmount}
                invoiceTotalCurrency={invoiceTotalCurrency}
                cashoutInvoices={cashoutInvoices}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6} className={classes.gridItem}>
          <CashoutRequestViewTransactions
            transactionIds={transactionIds}
          />
        </Grid>
      </Grid>
    </>
  );
};

CustomShowLayout.propTypes = {
  record: PropTypes.object,
};

CustomShowLayout.defaultProps = {
  record: {},
};

export default CustomShowLayout;
