import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, useRefresh, useNotify,
} from 'react-admin';
import { isEmpty } from 'lodash';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Dialog, DialogContentText, DialogTitle, DialogContent } from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import Storage from 'core/assets/js/lib/Storage';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const OnDemandPayoutsApproveTransactionsButton = (props) => {
  const { transactionIds, total, disabled } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleApproveClick = async (values) => {
    const command = 'approve-reviewed-transactions';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    Storage.set('wiseToken', values.token);
    Storage.set('wisePrivateKey', values.privateKey);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify({
        transactionIds,
        ...values,
      }) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  const initialValues = {
    token: Storage.get('wiseToken'),
    privateKey: Storage.get('wisePrivateKey'),
  };

  const label = !disabled
    ? `Mark ${isEmpty(transactionIds) ? total : transactionIds.length} transactions as approved`
    : 'Mark transactions as approved';
  return (
    <>
      <Button
        onClick={handleClick}
        icon={<MonetizationOnIcon />}
        label={label}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={label}
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want mark all reviewed transactions as approved?
          </DialogContentText>
          <Form onSubmit={handleApproveClick} initialValues={initialValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="token">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Token</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '500px',
                          }}
                          {...input}
                          type="text"
                          placeholder="Your Wise token"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  <Field name="privateKey">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Private key</label>
                        <br />
                        <textarea
                          placeholder="Your Wise private key"
                          {...input}
                          style={{
                            padding: '10px',
                            width: '500px',
                          }}
                          rows="20"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Approve" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

OnDemandPayoutsApproveTransactionsButton.propTypes = {
  total: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  transactionIds: PropTypes.array,
};

OnDemandPayoutsApproveTransactionsButton.defaultProps = {
  transactionIds: [],
};

export default OnDemandPayoutsApproveTransactionsButton;
