import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';


import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { INVOICE_STATUS, TRANSACTION_STATUS } from 'finance/assets/js/constants';
import TransactionModalField from 'admin/assets/js/resources/transactions/TransactionModalField.jsx';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    '& :not(:last-child)': {
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiButton-root': {
        marginBottom: '5px',
      },
    },
  },
}));

const CashoutRequestViewActions = ({
  cashoutInvoices,
}) => {
  const classes = useStyles();

  const firstDeferredInvoice = cashoutInvoices?.[0];
  const firstDeferredInvoiceTransaction = firstDeferredInvoice.transactions?.[0];
  const invoicesArePaid = cashoutInvoices.every(inv => inv.status === INVOICE_STATUS.PAID);
  const transactions = cashoutInvoices.map(inv => inv.transactions).flat();
  const transactionsNotExecuted = transactions
    .every(({ status }) => status !== TRANSACTION_STATUS.EXECUTED);
  const canRecordTransaction = !invoicesArePaid && transactionsNotExecuted;
  const disabledMessage = `Transaction can't be recorded because there is at least one ${invoicesArePaid ? 'invoice paid.' : 'transaction executed.'}`;
  return (
    <div className={classes.container}>
      <TransactionModalField
        resource="transaction_groups"
        basePath="/transaction_groups"
        cashoutInvoices={cashoutInvoices}
        record={firstDeferredInvoice}
        disabledInternalCtas={!canRecordTransaction}
        disabledMessage={!canRecordTransaction ? disabledMessage : null}
        showTransactionsListIds={false}
        isIconCta={false}
      />
      <BulkApiActionButton
        variant="outlined"
        size="medium"
        label="Recreate transaction"
        title="Recreate transaction"
        content="Are you sure you want to recreate a transaction for all invoices in the group?"
        ids={[firstDeferredInvoiceTransaction?.id]}
        resource="transactions"
        action="recreate_transactions"
        disabled={firstDeferredInvoiceTransaction?.status === TRANSACTION_STATUS.SUCCEEDED}
      />
    </div>
  );
};

CashoutRequestViewActions.propTypes = {
  cashoutInvoices: PropTypes.array,
};

CashoutRequestViewActions.defaultProps = {
  cashoutInvoices: [],
};

export default CashoutRequestViewActions;
