import React from 'react';
import { Show } from 'react-admin';

import SystemBankAccountsCustomShowLayout from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountsCustomShowLayout.jsx';


const SystemBankAccountsShow = (props) => {
  return (
    <Show
      component="div"
      {...props}
    >
      <SystemBankAccountsCustomShowLayout />
    </Show>
  );
};

export default SystemBankAccountsShow;
