import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Icon } from '@material-ui/core';

const DownloadErrorField = ({ record }) => {
  if (!record || !record.error) {
    return null;
  }
  const [errorStackAnchorEl, setErrorStackAnchorEl] = useState(null);
  const errorStackPopoverId = errorStackAnchorEl
    ? 'error-popover'
    : undefined;
  const isErrorStackOpen = Boolean(errorStackAnchorEl);
  return (
    <span className="text-danger">
      {record.error._error}
      <Icon
        aria-describedby={errorStackPopoverId}
        onClick={
          event => setErrorStackAnchorEl(event.currentTarget)
        }
        fontSize="small"
      >
        visibility
      </Icon>
      <Popover
        id={errorStackPopoverId}
        open={isErrorStackOpen}
        anchorEl={errorStackAnchorEl}
        onClose={
          () => setErrorStackAnchorEl(null)
        }
      >
        <span className="d-inline-block text-danger p-4 m-auto ">
          {record.error._meta.stack}
        </span>
      </Popover>
    </span>
  );
};

DownloadErrorField.propTypes = {
  record: PropTypes.object,
};

DownloadErrorField.defaultProps = {
  record: {},
};

export default DownloadErrorField;
