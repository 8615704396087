import React from 'react';
import { Title } from 'react-admin';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import {
  BACKGROUND_JOBS_BULK_IMPORT_TYPE_PREFIX,
  BACKGROUND_JOBS_STATUS_COLOR,
  BACKGROUND_JOBS_STATUS_LABEL,
  BACKGROUND_JOBS_TYPES,
} from 'admin/assets/js/constants';
import { getOrganizationsList } from 'admin/assets/js/lib/utils';
import { DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { IMPORT_TYPE_LABEL } from 'importer/assets/js/constants';

const renderType = type => {
  if (type.startsWith(BACKGROUND_JOBS_BULK_IMPORT_TYPE_PREFIX)) {
    return `Bulk import (${IMPORT_TYPE_LABEL[type.split('_')[1]]})`;
  }
  return type;
};

const BackgroundJobsView = () => {
  const { ids, keyedById } = getOrganizationsList();

  return (
    <>
      <Title title="Background jobs" />
      <ApiTable
        columns={[
          {
            label: 'Organization',
            name: 'organization_id',
            options: {
              cellRender: (orgId, { organization_name: orgName }) => (
                <Link to={`/organizations/${orgId}/show`}>{orgName}</Link>
              ),
              customFilterListOptions: {
                render: orgId => `Organization: ${keyedById[orgId]}`,
              },
              filter: true,
              filterOptions: {
                fullWidth: true,
                names: ids,
                renderValue: orgId => keyedById[orgId],
              },
              sort: false,
            },
          },
          {
            label: 'Name',
            name: 'type',
            options: {
              cellRender: renderType,
              customFilterListOptions: {
                render: type => `Type: ${renderType(type)}`,
              },
              filter: true,
              filterOptions: {
                fullWidth: true,
                names: Object.values(BACKGROUND_JOBS_TYPES),
                renderValue: type => renderType(type),
              },
              sort: false,
            },
          },
          {
            label: 'Status',
            name: 'status',
            options: {
              cellRender: status => (
                <Chip
                  clickable={false}
                  label={BACKGROUND_JOBS_STATUS_LABEL[status]}
                  size="small"
                  style={{ backgroundColor: BACKGROUND_JOBS_STATUS_COLOR[status], color: 'white' }}
                />
              ),
              filter: false,
              sort: false,
            },
          },
          {
            label: 'Start time',
            name: 'started_at',
            options: {
              cellRender: startedAt => formatDate(startedAt, DATETIME_FORMAT_DEFAULT),
              filter: false,
              sort: false,
            },
          },
          {
            label: 'End time',
            name: 'completed_at',
            options: {
              cellRender: completedAt => formatDate(completedAt, DATETIME_FORMAT_DEFAULT),
              filter: false,
              sort: false,
            },
          },
          {
            label: 'Elapsed time',
            name: 'elapsed_time',
            options: { filter: false, sort: false },
          },
        ]}
        defaultSortBy="created_at"
        defaultSortDirection="DESC"
        downloadEnabled={false}
        draggableColumns={false}
        resource="background_jobs"
        searchEnabled={false}
        sortFilterList={false}
      />
    </>
  );
};

export default BackgroundJobsView;
