import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListContextProvider, Datagrid, FunctionField, Link, useNotify, useUpdate, useListController,
  FormDataConsumer, useUnselectAll,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { Form } from 'react-final-form';

import {
  getAllocationFormInputCurrencyFieldName,
  getAllocationFormInputFieldName,
  parseFormValues,
  getRemainingInvoiceAllocationAmount,
  getAvailableTransferAllocationMoney,
} from 'admin/assets/js/resources/inbound_transfers/utils';
import InvoiceAmountField from 'admin/assets/js/resources/inbound_transfers/InvoiceAmountField.jsx';
import InvoicePendingField from 'admin/assets/js/resources/inbound_transfers/InvoicePendingField.jsx';
import AllocationFormHeader from 'admin/assets/js/resources/inbound_transfers/AllocationFormHeader.jsx';
import { logger } from 'core/assets/js/lib/Logger';
import Money from 'finance/assets/js/lib/Money';
import { formatDate } from 'admin/assets/js/lib/utils';

import { CURRENCY } from 'core/assets/js/constants';


const getAmountToAllocateForInvoice = (
  formAmountMoney, transferAmount, transferCurrency, totalAllocatedAmount, amountToAllocate,
) => {
  const availableTransferAllocationMoney = getAvailableTransferAllocationMoney(
    transferAmount, transferCurrency,
    totalAllocatedAmount, amountToAllocate,
  );
  if (availableTransferAllocationMoney.gte(formAmountMoney)) {
    return formAmountMoney;
  }
  if (
    availableTransferAllocationMoney.lt(formAmountMoney)
    && availableTransferAllocationMoney.isNonZero()
  ) {
    return availableTransferAllocationMoney;
  }
  return new Money(0, transferCurrency);
};

const getAmountByInvoiceId = (invoiceId, formData) => {
  const formFieldName = getAllocationFormInputFieldName(invoiceId);
  const formCurrencyFieldName = getAllocationFormInputCurrencyFieldName(invoiceId);
  const formFieldCurrency = (formData[formCurrencyFieldName] || CURRENCY.GBP).toUpperCase();

  return new Money(parseFloat(formData[formFieldName]) || 0, formFieldCurrency);
};

const InvoicesList = ({
  remoteId,
  organization,
  transferAmount,
  transferAmountCurrency,
  totalAllocatedAmount,
  fxMap,
  onAllocation,
}) => {
  const notify = useNotify();
  const [update, { loading }] = useUpdate();
  const [selectedInvoiceAllocations, setSelectedInvoiceAllocations] = useState([]);
  const totalAmountChosen = selectedInvoiceAllocations.reduce(
    (previousValue, invoiceAllocation) => {
      return previousValue.add(invoiceAllocation.amount);
    },
    new Money(0, transferAmountCurrency),
  );
  const context = useListController({
    resource: 'invoices',
    basePath: '/invoices',
    filter: { suggestedForTransfer: remoteId },
    perPage: 100,
  });
  const { data, selectedIds, onToggleItem, onSelect } = context;
  const unselectAll = useUnselectAll('invoices');

  // unselect any previously selected invoices when the
  // selected transfer changes ( remoteId )
  useEffect(() => {
    unselectAll();
  }, [unselectAll, remoteId]);

  const initialFormValues = useMemo(() => {
    const invoices = Object.values(data);
    const initialValues = {};
    invoices.forEach((inv) => {
      const fieldName = getAllocationFormInputFieldName(inv.id);
      const fieldCurrencyName = getAllocationFormInputCurrencyFieldName(inv.id);
      // When initializing the form, we set the "Pending amount"
      // to the portion of the invoice amount that has not yet been allocated.
      initialValues[fieldName] = getRemainingInvoiceAllocationAmount(inv);
      initialValues[fieldCurrencyName] = inv.currency;
    });

    return initialValues;
  }, [data]);

  const getUpdatedInvoiceAllocations = useCallback((invoiceId, formData) => {
    const invoiceIdIsRemoved = selectedIds.includes(invoiceId);
    const formCurrencyFieldName = getAllocationFormInputCurrencyFieldName(invoiceId);
    const formCurrency = (formData[formCurrencyFieldName] || CURRENCY.GBP).toUpperCase();
    const exchangeRate = fxMap[formCurrency] || 1;
    const formAmountMoney = getAmountByInvoiceId(
      invoiceId, formData,
    ).convert(
      transferAmountCurrency, { reverseRate: exchangeRate },
    );

    if (invoiceIdIsRemoved) {
      const updatedList = selectedInvoiceAllocations.filter(
        invoiceAllocations => invoiceAllocations.invoiceId !== invoiceId,
      );
      return updatedList;
    }
    const amountToAllocateForInvoice = getAmountToAllocateForInvoice(
      formAmountMoney, transferAmount, transferAmountCurrency,
      totalAllocatedAmount, totalAmountChosen,
    );
    const updatedList = [
      ...selectedInvoiceAllocations,
      { invoiceId,
        amount: amountToAllocateForInvoice.convert(
          transferAmountCurrency, { reverseRate: exchangeRate },
        ).toString(),
      },
    ];

    return updatedList;
  }, [
    selectedInvoiceAllocations, selectedIds, fxMap,
    totalAllocatedAmount, totalAmountChosen, transferAmount,
    transferAmountCurrency,
  ]);

  const getUpdatedInvoicesAllocations = useCallback((invoiceIds, formData) => {
    if (invoiceIds.length === 0) {
      return [];
    }
    const mergedInvoiceIds = [...new Set([...invoiceIds, ...selectedIds])];
    let _totalAmountChosen = new Money(0, transferAmountCurrency);
    return mergedInvoiceIds.map((invoiceId) => {
      const formCurrencyFieldName = getAllocationFormInputCurrencyFieldName(invoiceId);
      const formCurrency = (formData[formCurrencyFieldName] || CURRENCY.GBP).toUpperCase();
      const exchangeRate = fxMap[formCurrency] || 1;
      const formAmountMoney = getAmountByInvoiceId(
        invoiceId, formData,
      ).convert(
        transferAmountCurrency, { reverseRate: exchangeRate },
      );
      const amountToAllocateForInvoice = getAmountToAllocateForInvoice(
        formAmountMoney, transferAmount, transferAmountCurrency,
        totalAllocatedAmount, _totalAmountChosen,
      );
      const convertedAmount = amountToAllocateForInvoice.convert(
        transferAmountCurrency, { reverseRate: exchangeRate },
      );
      _totalAmountChosen = _totalAmountChosen.add(convertedAmount);
      return {
        invoiceId,
        amount: convertedAmount.toString(),
      };
    });
  }, [
    selectedIds, fxMap,
    totalAllocatedAmount, totalAmountChosen, transferAmount,
    transferAmountCurrency,
  ]);
  const handleGenerateClick = useCallback((values) => {
    const allocationRequest = parseFormValues(values, {
      transferId: remoteId,
      selectedInvoiceAllocations,
    });

    // don't update if we ended up with 'no valid' request
    if (allocationRequest) {
      update(
        'inbound_transfers', remoteId, { allocationRequest }, {}, {
          onSuccess: () => {
            notify('Payment allocated!', { type: 'success' });
            onAllocation();
          },
          onFailure: (error) => {
            logger.error(error);
            notify('Payment allocation failed!', { type: 'error' });
          },
        },
      );
    }
  }, [notify, update, remoteId, selectedIds, onAllocation]);

  // note - adding an empty form validator forces validation for each field to be run
  //        at the form level ( when selecting all rows for example ), which is what we need
  //        we can't run validation at the form level as we don't know which
  //        rows are selected
  return (
    <Form
      onSubmit={handleGenerateClick}
      initialValues={initialFormValues}
      validate={() => {}}
    >
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <AllocationFormHeader
              organization={organization}
              loading={loading}
              invalid={invalid}
              selectedIds={selectedIds}
              transferAmount={transferAmount}
              transferAmountCurrency={transferAmountCurrency}
              totalAllocatedAmount={totalAllocatedAmount}
              amountToAllocate={totalAmountChosen}
            />
            <ListContextProvider
              value={context}
            >
              <FormDataConsumer>
                {({ formData }) => (
                  <Datagrid
                    hasBulkActions
                    onToggleItem={(invoiceId) => {
                      onToggleItem(invoiceId);
                      const updatedInvoiceAllocations = getUpdatedInvoiceAllocations(
                        invoiceId, formData,
                      );
                      setSelectedInvoiceAllocations(updatedInvoiceAllocations);
                    }}
                    onSelect={(invoiceIds) => {
                      onSelect(invoiceIds);
                      const updatedInvoicesAllocations = getUpdatedInvoicesAllocations(
                        invoiceIds, formData,
                      );
                      setSelectedInvoiceAllocations(updatedInvoicesAllocations);
                    }}
                  >
                    <FunctionField
                      label={(
                        <Typography variant="subtitle2" className="font-weight-bold">
                          Date
                        </Typography>
                      )}
                      render={inv => formatDate(inv.createdAt, 'YYYY/MM/DD')}
                    />
                    <FunctionField
                      label={(
                        <Typography variant="subtitle2" className="font-weight-bold">
                          Description
                        </Typography>
                      )}
                      render={inv => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link style={{ whiteSpace: 'nowrap' }} to={`/invoices/${inv.id}`}>{inv.uniqueNumber}</Link>
                          <div className="hint">{organization.name}</div>
                        </div>
                      )}
                    />
                    <InvoicePendingField
                      selectedInvoiceAllocations={selectedInvoiceAllocations}
                      label={<Typography variant="subtitle2" className="font-weight-bold">Pending</Typography>}
                    />
                    <InvoiceAmountField
                      label={<Typography variant="subtitle2" className="font-weight-bold">Amount</Typography>}
                    />
                  </Datagrid>
                )}
              </FormDataConsumer>
            </ListContextProvider>
          </div>
        </form>
      )}
    </Form>
  );
};

InvoicesList.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  organization: PropTypes.object,
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  totalAllocatedAmount: PropTypes.string,
  fxMap: PropTypes.object,
  onAllocation: PropTypes.func,
};

InvoicesList.defaultProps = {
  remoteId: null,
  organization: {},
  transferAmount: null,
  transferAmountCurrency: null,
  totalAllocatedAmount: '0.00',
  fxMap: {},
  onAllocation: () => {},
};

export default InvoicesList;
