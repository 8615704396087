/* eslint react/no-multi-comp: "off" */
// in posts.js
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { crudGetManyAccumulate as crudGetManyAccumulateAction, Show } from 'react-admin';

// app
import { REPORT_TYPES, RAISED_BY } from 'finance/assets/js/constants';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import Invoice from 'finance/assets/js/components/Invoice.jsx';


const Title = ({ record }) => (
  <span>
    {record && record.raised_by === RAISED_BY.PROVIDER ? 'Provider Invoice' : 'Invoice'}
    &nbsp;
    {record ? `#${record.number}` : ''}
  </span>
);

Title.propTypes = {
  record: PropTypes.object,
};

Title.defaultProps = {
  record: {},
};


class InvoiceEmbeddedView extends React.Component {
  constructor(props) {
    super(props);
    this.fetchReference = this.fetchReference.bind(this);
  }

  componentDidMount() {
    this.fetchReference(this.props);
  }

  componentDidUpdate(prevProps) {
    const { record } = this.props;
    if (record.id !== prevProps.record.id) {
      this.fetchReference(this.props);
    }
  }

  fetchReference(props) {
    const { crudGetManyAccumulate } = this.props;
    const source = get(props.record, 'orgId');
    if (source !== null && typeof source !== 'undefined') {
      crudGetManyAccumulate('organizations', [source]);
    }
  }

  render() {
    const { record, organization } = this.props;
    if (!(record
      && record.ownerFE && record.recipientFE && organization && organization.unique_alias)) {
      return null;
    }
    return (
      <div style={{ paddingTop: '15px' }}>
        <ReportViewLayout reportType={REPORT_TYPES.INVOICE} withHeader={false}>
          {organization && organization.unique_alias && (
            <Invoice
              orgAlias={organization.unique_alias}
              invoice={record}
              ownerFE={record.ownerFE}
              systemFE={record.systemFE}
              recipientFE={record.recipientFE}
              invoicingSettings={record.invoicingSettings}
            />
          )}
        </ReportViewLayout>
      </div>
    );
  }
}

InvoiceEmbeddedView.propTypes = {
  record: PropTypes.object,
  organization: PropTypes.object,
  crudGetManyAccumulate: PropTypes.func.isRequired,
};

InvoiceEmbeddedView.defaultProps = {
  record: {},
  organization: {},
};

const mapStateToProps = (state, props) => ({
  organization: state.admin.resources.organizations
    && state.admin.resources.organizations.data[props.record.orgId],
});

const mapDispatchToProps = dispatch => ({
  crudGetManyAccumulate: (...args) => dispatch(crudGetManyAccumulateAction(...args)),
});

const ConnectedInvoiceView = connect(mapStateToProps, mapDispatchToProps)(InvoiceEmbeddedView);

const InvoiceEdit = props => (
  <Show
    {...props}
    title={<Title />}
    hasList={false}
    hasEdit={false}
    actions={null}
  >
    <ConnectedInvoiceView />
  </Show>
);

export default InvoiceEdit;
