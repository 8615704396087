import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';

import httpClient from 'admin/assets/js/lib/httpClient';
import { reloadTable } from 'admin/assets/js/lib/utils';
import { adminBulkActionApiUrl } from 'admin/urls';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';

const BulkApiActionButton = ({
  action,
  cancelLabel,
  color,
  confirm,
  confirmLabel,
  content,
  disabled,
  disableInternalCtas,
  errorNotifMsg,
  extraActions,
  extraContent,
  icon,
  ids,
  label,
  onSuccess,
  payload,
  reloadApiTable,
  resource,
  successNotifMsg,
  title,
  variant,
  size,
  tooltipTitle,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleConfirm = async (
    extraAction, body = { ids, payload },
  ) => {
    const url = adminBulkActionApiUrl(resource, extraAction || action);
    setLoading(true);
    const successNotificationMessage = successNotifMsg || `${resource} updated`;
    const errorNotificationMessage = errorNotifMsg || `Error: ${resource} not updated`;
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(body) });
      onSuccess();
      notify(successNotificationMessage);
      if (reloadApiTable) {
        reloadTable();
      } else {
        refresh();
        unselectAll(resource);
      }
    } catch (e) {
      notify(e.body && e.body._error ? e.body._error : errorNotificationMessage, 'warning');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (confirm) {
      setOpen(true);
    } else {
      handleConfirm();
    }
  };
  const handleDialogClose = () => setOpen(false);

  return (
    <Fragment>
      {icon ? (
        <Button
          disabled={disabled || loading}
          label={label}
          onClick={handleClick}
          variant={variant}
          color={color}
          size={size}
        >
          <Tooltip
            title={tooltipTitle}
          >
            {icon}
          </Tooltip>
        </Button>
      ) : (
        <Button
          disabled={disabled || loading}
          label={label}
          onClick={handleClick}
          variant={variant}
          color={color}
          size={size}
        />
      )}
      {title && content && (
        <ConfirmModal
          open={open}
          disableCtas={disableInternalCtas}
          loading={loading}
          title={title}
          content={content}
          onConfirm={handleConfirm}
          confirm={confirmLabel}
          onClose={handleDialogClose}
          cancel={cancelLabel}
          extraActions={extraActions}
          extraContent={extraContent}
          disabled={loading}
        />
      )}
    </Fragment>
  );
};

BulkApiActionButton.propTypes = {
  action: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  color: PropTypes.string,
  confirm: PropTypes.bool,
  confirmLabel: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  disabled: PropTypes.bool,
  disableInternalCtas: PropTypes.bool,
  errorNotifMsg: PropTypes.string,
  extraActions: PropTypes.array,
  extraContent: PropTypes.node,
  icon: PropTypes.element,
  ids: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
  onSuccess: PropTypes.func,
  payload: PropTypes.object,
  reloadApiTable: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  successNotifMsg: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  tooltipTitle: PropTypes.string,
};


BulkApiActionButton.defaultProps = {
  color: 'primary',
  cancelLabel: 'Cancel',
  confirm: true,
  confirmLabel: 'Confirm',
  content: null,
  disabled: false,
  disableInternalCtas: false,
  errorNotifMsg: null,
  extraActions: [],
  extraContent: null,
  icon: null,
  label: null,
  onSuccess: () => {},
  payload: {},
  reloadApiTable: false,
  successNotifMsg: null,
  title: null,
  variant: 'text',
  size: 'small',
  tooltipTitle: null,
};

export default BulkApiActionButton;
