import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-admin';

import InvoiceBankDetails from 'admin/assets/js/resources/invoices/InvoiceBankDetails.jsx';
import { CURRENCY_LABEL, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';


const InvoiceBankField = ({ record }) => {
  if (!record || isEmpty(record)) {
    return null;
  }


  const ownerFE = new FinancialEntity(record.ownerFE);
  const bank = ownerFE.getBank();
  if (!bank) {
    return null;
  }
  const currency = bank.getCurrency();

  return (
    <React.Fragment>
      <h5>Bank Account</h5>
      <div>
        <span className="mr-1">Bank account ID:</span>
        <Link to={`/bank_accounts/${bank.getBankAccountId()}/show`}>{bank.getBankAccountId()}</Link>
      </div>
      <div className="tw-bank-details--currency">
        {`Bank currency: ${CURRENCY_LABEL[currency]} (${CURRENCY_SYMBOL[currency]})`}
      </div>
      <InvoiceBankDetails
        owner={record.ownerFE}
        system={record.systemFE}
        invoicingSettings={record.invoicingSettings}
        showLabel={false}
      />
    </React.Fragment>
  );
};

InvoiceBankField.propTypes = {
  record: PropTypes.object,
};

InvoiceBankField.defaultProps = {
  record: {},
};

export default InvoiceBankField;
