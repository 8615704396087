import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Toolbar } from 'react-admin';

const SystemBankAccountFormButtons = (props) => {
  const { dialogClose, handleSubmit } = props;
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'right',
        backgroundColor: 'transparent',
        borderTop: '1px solid lightgray',
      }}
    >
      <Button
        color="secondary"
        onClick={handleSubmit}
        size="large"
      >
        Create
      </Button>
      <Button
        color="secondary"
        onClick={dialogClose}
        size="large"
      >
        Cancel
      </Button>
    </Toolbar>
  );
};

SystemBankAccountFormButtons.propTypes = {
  dialogClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SystemBankAccountFormButtons;
