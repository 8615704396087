import moment from 'moment';

import { DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import {
  AUDIT_LEVEL,
  AUDIT_LEVEL_LABELS,
  AUDIT_MESSAGE_LABELS,
} from 'finance/assets/js/constants';

/**
 * Encapsulates an audit log.
 */
class AuditLog {
  /**
   * Get the level label for a log's level.
   * @return {string} the label for a logs level.
   */
  static getLevelLabel(level) {
    return AUDIT_LEVEL_LABELS[level];
  }

  /**
   * Get the display colour for a message based on it's level.
   * @return {string} colour name to display message with.
   */
  static getMessageColour(level) {
    const colours = {
      [AUDIT_LEVEL.CRITICAL]: 'text-danger',
      [AUDIT_LEVEL.SEVERE]: 'text-warning',
      [AUDIT_LEVEL.PASS]: 'text-success',
    };

    return colours[level] || 'text-secondary';
  }

  /**
   * Built a summary message for a group of audit logs of the same message.
   * @param {AuditLog|Object} log - first log in the group, from which to take shared details.
   * @return {String} summary log line.
   */
  static getMessageSummaryForGroup(log, level) {
    if (!log) {
      throw new Error('log is required');
    }

    // ensure we have an instance of an AuditLog

    const parsedLog = (log instanceof AuditLog) ? log : new AuditLog(log);

    // build summary line

    return `${parsedLog.getTime().format(DATETIME_FORMAT_DEFAULT)} - ${parsedLog.getMessageString()} - ${AuditLog.getLevelLabel(level)}`;
  }

  /**
   * Create an AuditLog instance.
   * @param {AUDIT_MESSAGES} message - audit message.
   * @param {AUDIT_LEVEL} level - audit severity level.
   * @param {moment|string} ts - time stamp of audit.
   */
  constructor(props) {
    if (props instanceof AuditLog) {
      return props;
    }
    this.init(props);
  }

  /**
   * Initialise an AuditLog instance.
   * @param {number} auditRunId - id of the run this log belongs to.
   * @param {AUDIT_MESSAGES} message - audit message.
   * @param {number|null} orgId - org audit was run for.
   * @param {AUDIT_LEVEL} level - audit severity level.
   * @param {[moment|string]} ts - time stamp of audit.
   */
  init({ auditRunId, message, orgId, level, group, context, ts } = {}) {
    if (!message) {
      throw new Error('message is required');
    }
    if (!level) {
      throw new Error('level is required');
    }

    this.auditRunId = auditRunId;
    this.message = message;
    this.orgId = orgId;
    this.level = level;
    this.group = group;
    this.context = context;
    this.ts = ts || moment();
  }

  /**
   * Get the audit run id this logs belongs to.
   * @return {number} audit run id this log belongs to.
   */
  getAuditRunId() {
    return this.auditRunId;
  }

  /**
   * Get the audit message.
   * @return {AUDIT_MESSAGES} audit message.
   */
  getMessage() {
    return this.message;
  }

  /**
   * Get just the message string for the message.
   * Rather than a fully formatted string with timestamp, org, level etc.
   * @return {string} message string for message.
   */
  getMessageString() {
    return AUDIT_MESSAGE_LABELS[this.getMessage()];
  }

  /**
   * Get a formatted message for the audit
   * @return {string} formatted audit message for display.
   */
  getFormattedMessage() {
    return `${this.getTime().format(DATETIME_FORMAT_DEFAULT)} - ${this.getMessageString()} - Org id ${this.getOrgId()} - ${this.getLevelLabel()}`;
  }

  /**
   * Get the id of the org the log relates to, if it relates to an org.
   * @return {number|null} the org id or null if this audit wasn't for an org.
   */
  getOrgId() {
    return this.orgId;
  }

  /**
   * Get the audit level.
   * @return {AUDIT_LEVEL} audit level.
   */
  getLevel() {
    return this.level;
  }

  /**
   * Get the level label for this log's level.
   * @return {string} the label for this logs level.
   */
  getLevelLabel() {
    return AuditLog.getLevelLabel(this.getLevel());
  }

  /**
   * Get the display colour for a message based on it's level.
   * @return {string} colour name to display message with.
   */
  getMessageColour() {
    return AuditLog.getMessageColour(this.getLevel());
  }

  /**
   * Determine if audit is higher enough to throw and error.
   * @return {boolean} if audit should throw an error ( true ) or not ( false ).
   */
  shouldThrow() {
    return this.getLevel() === AUDIT_LEVEL.CRITICAL;
  }

  /**
   * Get the audit time stamp.
   * @return {moment} timestamp of audit as a moment date.
   */
  getTime() {
    return moment(this.ts);
  }

  /**
   * Get the group string.
   *
   * The group string should be the same for all audits on the
   * same invoice within a given invoicing run.
   *
   * @return {string} group string.
   */
  getGroup() {
    return this.group;
  }

  /**
   * Get the context string for a given fact audit log.
   *
   * This will include any additional information that may add context to the audit logs,
   * to help indentify where the issue is.
   *
   * @return {string} JSON string of context object.
   */
  getContextAsString() {
    const context = this.context;
    return JSON.stringify(context);
  }
}

export default AuditLog;
