import React from 'react';
import { Filter, SelectInput } from 'react-admin';

import { PRICING_SCALE_STATUS_LABEL } from 'finance/assets/js/constants';

const statusOptions = Object.keys(PRICING_SCALE_STATUS_LABEL).map(k => ({
  id: k, name: PRICING_SCALE_STATUS_LABEL[k],
}));

const PricingScaleListFilter = props => (
  <Filter {...props}>
    <SelectInput label="Status" alwaysOn allowEmpty source="status" choices={statusOptions} />
  </Filter>
);

export default PricingScaleListFilter;
