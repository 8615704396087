import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import {
  CASHOUT_REQUESTS_TABS, CASHOUT_REQUESTS_TABS_LABEL,
} from 'admin/assets/js/constants';
import DeferredInvoicePaymentsList from 'admin/assets/js/resources/deferred_invoice_payments/list.jsx';
import CashoutRequestsList from 'admin/assets/js/resources/cashout_requests/list.jsx';

const CustomCashoutRequestsView = (props) => {
  const { selectedTab } = props;
  const history = useHistory();

  const handleChange = useCallback((event, newValue) => {
    history.push(newValue);
  }, [history]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
      >
        { Object.entries(CASHOUT_REQUESTS_TABS)
          .map(([key]) => (
            <Tab
              key={CASHOUT_REQUESTS_TABS[key]}
              value={CASHOUT_REQUESTS_TABS[key]}
              label={CASHOUT_REQUESTS_TABS_LABEL[CASHOUT_REQUESTS_TABS[key]]}
            />
          ))}
      </Tabs>
      { selectedTab === CASHOUT_REQUESTS_TABS.REQUESTS && (
        <CashoutRequestsList
          resource="cashout_requests"
          basePath="/cashout_requests"

        />
      )}

      { selectedTab === CASHOUT_REQUESTS_TABS.DEFERRED_INVOICES && (
        <DeferredInvoicePaymentsList
          resource="deferred_invoice_payments"
          basePath="/deferred_invoice_payments"

        />
      )}
    </>
  );
};

CustomCashoutRequestsView.propTypes = {
  selectedTab: PropTypes.string,
};

CustomCashoutRequestsView.defaultProps = {
  selectedTab: CASHOUT_REQUESTS_TABS.REQUESTS,
};

export default CustomCashoutRequestsView;
