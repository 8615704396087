import React from 'react';
import {
  Show,
} from 'react-admin';

import CustomShowLayout from 'admin/assets/js/resources/cashout_requests/CustomShowLayout.jsx';


export const CashoutRequestsShow = props => (
  <Show
    component="div"
    {...props}
  >
    <CustomShowLayout />
  </Show>
);

export default CashoutRequestsShow;
