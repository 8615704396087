import PropTypes from 'prop-types';
import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const DialogCloseButton = ({ onClose }) => (
  <IconButton onClick={() => onClose()}>
    <CloseIcon />
  </IconButton>
);

DialogCloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DialogCloseButton;
