/* eslint max-len: "off", react/no-multi-comp: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { BulkActionsToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import AllocationAggregatedInfo
  from 'admin/assets/js/resources/inbound_transfers/AllocationAggregatedInfo.jsx';
import AllocationButton from 'admin/assets/js/resources/inbound_transfers/AllocationButton.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    '& > :first-child': {
      height: '64px !important',
      [theme.breakpoints.down(1350)]: {
        height: '130px !important', // Height for smaller screens
      },
      '.actions-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
      '& .actions-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
}));

const ReconciliationBulkActions = ({
  selectedIds,
  transferAmount,
  transferAmountCurrency,
  disabled,
  totalAllocatedAmount,
  amountToAllocate,
}) => {
  const classes = useStyles();
  return (
    <div className={`allocation-bulk-actions-container mb-3 ${classes.root}`}>
      <BulkActionsToolbar
        disabled={disabled}
        selectedIds={selectedIds}
        classes={{ root: classes.root }}
      >
        <div className="actions-wrapper">
          <AllocationAggregatedInfo
            selectedIds={selectedIds}
            transferAmount={transferAmount}
            transferAmountCurrency={transferAmountCurrency}
            totalAllocatedAmount={totalAllocatedAmount}
            amountToAllocate={amountToAllocate}
          />
          <AllocationButton disabled={disabled} selectedIds={selectedIds} />
        </div>
      </BulkActionsToolbar>
    </div>
  );
};

ReconciliationBulkActions.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  selectedInvoices: PropTypes.arrayOf(PropTypes.object),
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  disabled: PropTypes.bool,
  totalAllocatedAmount: PropTypes.string,
  amountToAllocate: PropTypes.string,
};

ReconciliationBulkActions.defaultProps = {
  selectedIds: [],
  selectedInvoices: [],
  transferAmount: null,
  transferAmountCurrency: null,
  disabled: false,
  totalAllocatedAmount: '0.00',
  amountToAllocate: '0.00',
};

export default ReconciliationBulkActions;
