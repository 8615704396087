import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

import { USER_ROLE_ICONS } from 'admin/assets/js/constants';
import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import {
  USER_CARD_STATUS, USER_CARD_STATUS_LABEL, USER_TYPE, USER_TYPE_LABEL,
} from 'core/assets/js/constants';
import { USER_EMPLOYMENT_TYPE, USER_EMPLOYMENT_TYPE_LABEL } from 'people/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';

export const USER_CARD_STATUS_COLORS = {
  [USER_CARD_STATUS.PENDING]: '#fab152',
  [USER_CARD_STATUS.PENDING_APPROVAL]: '#fab152',
  [USER_CARD_STATUS.AMEND_REQUESTED]: '#fab152',
  [USER_CARD_STATUS.APPROVED]: '#22c55e',
  [USER_CARD_STATUS.REJECTED]: 'tomato',
  [USER_CARD_STATUS.DEACTIVATED]: '#7b7b7b',
  [USER_CARD_STATUS.PENDING_MANAGER_ANSWERS]: '#fab152',
};

const statusValues = Object.values(USER_CARD_STATUS);
const roleValues = Object.values(USER_TYPE);
const typeValues = Object.values(USER_EMPLOYMENT_TYPE);

const TeamTab = ({ organization }) => (
  <ApiTable
    columns={[
      {
        label: 'Name',
        name: 'name',
        options: {
          cellRender: (_, userCard) => {
            let displayName = `${userCard.user?.profile?.lastName}, ${userCard.user?.profile?.firstName}`;
            if (userCard.user?.profile?.middleName) {
              displayName += ` ${userCard.user.profile.middleName}`;
            }
            if (!userCard.user?.id) {
              return displayName;
            }
            return (
              <Link
                to={`/full_users/${userCard.user.id}/show?backUrl=/organizations/${organization.id}/show/team`}
              >
                {displayName}
              </Link>
            );
          },
          downloadCellRender: (_, userCard) => userCard.user?.profile?.name,
          filter: false,
          sort: true,
        },
      },
      {
        label: 'Email',
        name: 'email',
        options: {
          cellRender: (_, userCard) => userCard.user?.email,
          filter: false,
          sort: false,
        },
      },
      {
        label: 'Joined At',
        name: 'joined_at',
        options: {
          cellRender: (_, userCard) => formatDate(userCard.joinedAt),
          filter: false,
          sort: true,
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          cellRender: (_, uc) => (
            <Chip
              label={USER_CARD_STATUS_LABEL[uc.status]}
              size="small"
              style={{ backgroundColor: USER_CARD_STATUS_COLORS[uc.status], color: 'white' }}
            />
          ),
          downloadCellRender: (_, uc) => USER_CARD_STATUS_LABEL[uc.status],
          customFilterListOptions: {
            render: value => `Status: ${USER_CARD_STATUS_LABEL[value]}`,
          },
          filter: true,
          filterOptions: {
            names: statusValues,
            renderValue: status => USER_CARD_STATUS_LABEL[status],
          },
          sort: false,
        },
      },
      {
        label: 'Role',
        name: 'role',
        options: {
          cellRender: (_, uc) => (
            <>
              <i
                className={USER_ROLE_ICONS[uc.userRole.ofType]}
                style={{ color: '#3f51b5', display: 'inline', marginRight: '10px' }}
              />
              <span>{uc.userRole.title}</span>
            </>
          ),
          downloadCellRender: (_, uc) => uc.userRole?.title,
          customFilterListOptions: {
            render: value => `Role: ${USER_TYPE_LABEL[value]}`,
          },
          filter: true,
          filterOptions: {
            names: roleValues,
            renderValue: value => USER_TYPE_LABEL[value],
          },
          sort: false,
        },
      },
      {
        label: 'Type',
        name: 'type',
        options: {
          cellRender: (_, uc) => (uc.isEmployee ? 'Employee' : 'Contractor'),
          customFilterListOptions: {
            render: value => `Type: ${USER_EMPLOYMENT_TYPE_LABEL[value]}`,
          },
          filter: true,
          filterOptions: {
            names: typeValues,
            renderValue: value => USER_EMPLOYMENT_TYPE_LABEL[value],
          },
          sort: false,
        },
      },
      {
        label: 'AOR',
        name: 'aor_enabled',
        options: {
          cellRender: (_, uc) => (uc.aorEnabled ? 'Yes' : 'No'),
          filter: false,
          sort: true,
        },
      },
      {
        label: 'Country',
        name: 'country',
        options: {
          cellRender: (_, uc) => uc.user?.profile?.address_components?.country,
          filter: false,
          sort: false,
        },
      },
    ]}
    defaultSortBy="name"
    defaultSortDirection="ASC"
    extraResourceQueryParams={{ organization_id: organization.id }}
    resource="user_cards"
  />
);

TeamTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default TeamTab;

