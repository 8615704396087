import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, ReferenceField,
} from 'react-admin';
import { Button, Chip } from '@material-ui/core';

import { twTransferUrl } from 'services/urls';
import QuoteDetailsField from '../dim_transferwise_quotes/QuoteDetailsField.jsx';
import RecipientDetailsField from '../dim_transferwise_recipients/RecipientDetailsField.jsx';

const TransferDetailsField = (props) => {
  const { record, expectedAmount } = props;
  if (!record) {
    return null;
  }
  const { id, status, created, details: { reference } } = record.details;
  const url = twTransferUrl(id);
  const isIncomingWaiting = status === 'incoming_payment_waiting';
  const STATUS_LABEL = {
    incoming_payment_waiting: 'Waiting incoming payment',
    cancelled: 'Cancelled',
  };
  return (
    <div>
      <div className={`mb-2 p-2 ${!isIncomingWaiting ? 'bg-light discreet' : ''}`}>
        <h5>
          Transfer details (#
          <ReferenceField
            {...props}
            source="id"
            reference="dim_transferwise_transfers"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          )
          <br />
          <span style={{ fontSize: '0.8em' }}>{record.quote_id}</span>
        </h5>
        <div className="d-flex">
          <span>status</span>
          <span className="pl-4 ml-auto">
            <Chip
              size="small"
              label={STATUS_LABEL[status] || status}
              color={isIncomingWaiting ? 'primary' : 'default'}
              variant="outlined"
            />
          </span>
        </div>
        <div className="d-flex">
          <span>created at</span>
          <span className="pl-4 ml-auto">
            {created}
          </span>
        </div>
        <div className="d-flex">
          <span>reference</span>
          <span className="pl-4 ml-auto">
            {reference}
          </span>
        </div>
        <ReferenceField
          {...props}
          source="quote_id"
          reference="dim_transferwise_quotes"
          link={false}
        >
          <QuoteDetailsField expectedAmount={expectedAmount} />
        </ReferenceField>
        <hr />
        <ReferenceField
          {...props}
          source="recipient_id"
          reference="dim_transferwise_recipients"
          link={false}
        >
          <RecipientDetailsField />
        </ReferenceField>
        { isIncomingWaiting && (
          <div className="pt-2 mt-2 d-flex">
            <Button
              style={{ color: '#FFF' }}
              className="pt-10 ml-auto"
              variant="contained"
              color="primary"
              href={url}
              target="_blank"
            >
              Pay
            </Button>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

TransferDetailsField.propTypes = {
  record: PropTypes.object,
  expectedAmount: PropTypes.string,
};

TransferDetailsField.defaultProps = {
  record: {},
  expectedAmount: '0.00',
};

export default TransferDetailsField;
