import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  downloadCSV,
  DateField,
  Datagrid,
  List,
  ShowButton,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import CurrencyAmountField from 'admin/assets/js/resources/org_billing_analytics/CurrencyAmountField.jsx';
import { ORG_BILLING_ANALYTICS_CURRENCIES } from 'admin/assets/js/constants';

const InvoiceRunsTab = ({ organization }) => {
  const exporter = useCallback((billingProcesses) => {
    const rows = billingProcesses
      .map(bp => ({
        Date: bp.date,
        'Total Invoiced': bp.totalInvoiced,
        'Payments Reconciled': bp.paymentsReconciled,
        'Payments Due': bp.paymentsDue,
        'Contractor invoices': bp.contractorInvoices,
        'Amount paid': bp.amountPaid,
        'Payouts Due': bp.payoutsDue,
        VAT: bp.vat,
        'Licence Fees': bp.licenceFees,
        'Processing Fees': bp.processingFees,
        'Bank Fees': bp.bankFees,
        'FX Costs': bp.fxCosts,
        Balance: bp.balance,
      }));
    jsonExport(rows, {
      headers: [
        'Date',
        'Total Invoiced',
        'Payments Reconciled',
        'Payments Due',
        'Contractor invoices',
        'Amount paid',
        'Payouts Due',
        'VAT',
        'Licence Fees',
        'Processing Fees',
        'Bank Fees',
        'FX Costs',
        'Balance',
      ],
    }, (err, csv) => {
      downloadCSV(csv, 'billing_processes');
    });
  }, []);

  return (
    <List
      basePath="/org_billing_analytics"
      resource="org_billing_analytics"
      exporter={exporter}
      bulkActionButtons={false}
      hasCreate={false}
      hasEdit={false}
      hasList
      hasShow={false}
      filter={{
        organization_id: organization.id,
        currency: ORG_BILLING_ANALYTICS_CURRENCIES.ORG_CURRENCY,
      }}
      sort={{ field: 'date', order: 'DESC' }}
      title=" - Billing Process Analytics"
    >
      <Datagrid>
        <DateField label="Date" source="date" />
        <CurrencyAmountField label="Total Invoiced" source="totalInvoiced" />
        <CurrencyAmountField label="Payments Reconciled" source="paymentsReconciled" />
        <CurrencyAmountField label="Payments Due" source="paymentsDue" />
        <CurrencyAmountField label="Contractor invoices" source="contractorInvoices" />
        <CurrencyAmountField label="Amount paid" source="amountPaid" />
        <CurrencyAmountField label="Payouts Due" source="payoutsDue" />
        <CurrencyAmountField label="VAT" source="vat" />
        <CurrencyAmountField label="Licence Fees" source="licenceFees" />
        <CurrencyAmountField label="Processing Fees" source="processingFees" />
        <CurrencyAmountField label="Bank Fees" source="bankFees" />
        <CurrencyAmountField label="FX Costs" source="fxCosts" />
        <CurrencyAmountField label="Balance" source="balance" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

InvoiceRunsTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default InvoiceRunsTab;
