import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

const DownloadButton = ({ record }) => {
  if (!record || (!record.url && !record.error)) {
    return (
      <Fragment>
        <p>Download not ready yet. Please come back later...</p>
      </Fragment>
    );
  }

  if (record.error) {
    return null;
  }

  return (
    <Fragment>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={record.url}
        className="d-flex justify-content-center align-items-center text-decoration-none"
      >
        <Icon style={{ color: '#3f51b5' }}>cloud_download</Icon>
      </a>
    </Fragment>
  );
};

DownloadButton.propTypes = {
  record: PropTypes.object,
};

DownloadButton.defaultProps = {
  record: null,
};

export default DownloadButton;
