import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import SystemBankAccountForm from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountForm.jsx';

const SystemBankAccountCreateFormModal = ({
  isOpen, handleClose,
}) => {
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          Create account
          <Button
            style={{ padding: 0, minWidth: 'initial' }}
            onClick={handleClose}
          >
            <Icon>close</Icon>
          </Button>
        </div>
      </DialogTitle>
      <SystemBankAccountForm
        dialogClose={handleClose}
      />
    </Dialog>
  );
};

SystemBankAccountCreateFormModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  basePath: PropTypes.string.isRequired,
};

SystemBankAccountCreateFormModal.defaultProps = {
  isOpen: false,
};

export default SystemBankAccountCreateFormModal;
