import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useNotify, useRefresh, useUpdate,
} from 'react-admin';
import { capitalize } from 'lodash';
import { makeStyles } from '@material-ui/core';

import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import { SYSTEM_BANK_ACCOUNT_STATUS } from 'finance/assets/js/constants';

const useStyles = makeStyles({
  button: {
    '& span': {
      padding: '0 5px',
      textTransform: 'capitalize',
      color: '#000',
    },
  },
});

const ArchiveButton = ({ record, resource }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [update] = useUpdate(resource, record.id);
  const refresh = useRefresh();
  const notify = useNotify();
  const isActive = record.status === SYSTEM_BANK_ACCOUNT_STATUS.ACTIVE;
  const actionLabel = isActive ? 'archive' : 'activate';

  const handleConfirm = useCallback(() => {
    const data = {
      status: isActive
        ? SYSTEM_BANK_ACCOUNT_STATUS.ARCHIVED
        : SYSTEM_BANK_ACCOUNT_STATUS.ACTIVE,
    };
    update({ payload: { data } }, {
      onSuccess: () => {
        notify('System bank account updated');
        refresh();
      },
    });
  }, [notify, refresh, isActive]);

  const handleDialogClose = useCallback(() => setOpen(false), [setOpen]);
  const handleDialogOpen = useCallback(() => setOpen(true), [setOpen]);

  return (
    <>
      <Button
        label={actionLabel}
        onClick={handleDialogOpen}
        className={classes.button}
      />
      <ConfirmModal
        label={actionLabel}
        title={capitalize(actionLabel)}
        open={open}
        content={`Are you sure you want to ${actionLabel} this system bank account?`}
        onConfirm={handleConfirm}
        confirm={actionLabel}
        onClose={handleDialogClose}
        cancel="Cancel"
      />
    </>
  );
};

ArchiveButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
};

ArchiveButton.defaultProps = {
  record: {},
  resource: null,
};

export default ArchiveButton;
