import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardContent, Grid, Chip, makeStyles } from '@material-ui/core';
import { Loading } from 'react-admin';

import OrgActionsMenu from 'admin/assets/js/resources/organizations/OrgActionsMenu.jsx';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import { ORGANIZATION_STATUS, ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';

// cards
import InvoicingCard from 'admin/assets/js/resources/organizations/components/InvoicingCard.jsx';
import FeesCard from 'admin/assets/js/resources/organizations/components/FeesCard.jsx';
import GeneralInfoCard from 'admin/assets/js/resources/organizations/components/GeneralInfoCard.jsx';
import UTMSCard from 'admin/assets/js/resources/organizations/components/UTMSCard.jsx';

const RESOURCE = 'organizations';
const useStyles = makeStyles({
  organizationStatusLabel: {
    color: '#FFF',
    margin: '0px',
    padding: '0px',
    border: 'none',
  },
  organizationHeaderLogoStyle: {
    maxHeight: '46px',
    margin: '0px',
    padding: '0px 16px 0px 0px',
  },
  organizationHeaderTitleStyle: {
    fontWeight: 400,
    margin: '0px',
  },
  organizationHeaderIdStyle: {
    margin: '0px',
    padding: '0px 6px 0px 8px',
  },
});

const organizationStatusStyles = {
  active: '#60DC35',
  deactivated: '#DC3545',
};

const OrganizationHeader = ({ organization }) => {
  const imageUrl = organization?.logo;
  const orgName = organization?.name ?? '-';
  const status = organization?.status === ORGANIZATION_STATUS.ACTIVE;
  const classes = useStyles();
  return (
    <div className="d-flex align-items-center">
      <BackButton />
      {imageUrl
        ? <img alt={`Logo of the organizatio ${orgName}`} src={imageUrl} className={classes.organizationHeaderLogoStyle} />
        : null}
      <h1 className={classes.organizationHeaderTitleStyle}>
        {orgName}
        <span className={classes.organizationHeaderIdStyle}>
          (
          {organization.id}
          )
        </span>
      </h1>
      <Chip
        className={classes.organizationStatusLabel}
        style={{
          backgroundColor: status
            ? organizationStatusStyles.active : organizationStatusStyles.deactivated,
        }}
        label={ORGANIZATION_STATUS_LABEL[organization?.status]}
        variant="outlined"
        color="primary"
        size="small"
      />
    </div>
  );
};

OrganizationHeader.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string, status: PropTypes.number,
  }),
};
OrganizationHeader.defaultProps = {
  organization: null,
};
const InfoTab = ({ organization }) => {
  if (!organization) {
    return <Loading />;
  }
  return (
    <div className="organization_view_tab">
      <Card className="organization_view_tab__card">
        <CardHeader
          action={<OrgActionsMenu resource={RESOURCE} record={organization} />}
          className="organization_view_tab__card__header"
          title={<OrganizationHeader organization={organization} />}
        />
        <CardContent className="organization_view_tab__content__infotab">
          <Grid container item spacing={2}>
            <Grid item lg={6} xs={12}>
              <InvoicingCard organization={organization} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <FeesCard organization={organization} />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item lg={6} xs={12}>
              <GeneralInfoCard organization={organization} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <UTMSCard organization={organization} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

InfoTab.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logo: PropTypes.string, name: PropTypes.string.isRequired,
    status: PropTypes.number,
  }).isRequired,
};

export default InfoTab;
