import Big from 'big.js';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import { useStyles } from 'admin/assets/js/layout/themes';
import { PAYMENT_METHOD_DETAILS } from 'settings/assets/js/constants';

Big.RM = 1;
Big.DP = 2;

const InvoiceOwnerFields = ({ showTitle, record }) => {
  const classes = useStyles();
  if (!record || isEmpty(record)) {
    return null;
  }

  const ownerFE = new FinancialEntity(record.ownerFE);
  const paymentMethod = ownerFE.getPaymentMethod();

  return (
    <React.Fragment>
      {showTitle && (
        <h5>Invoice Owner details</h5>
      )}
      <div className="d-flex">
        <span className={classes.discreet}>Payment method:</span>
        <span className="pl-1">
          {PAYMENT_METHOD_DETAILS[paymentMethod].name}
        </span>
      </div>
    </React.Fragment>
  );
};

InvoiceOwnerFields.propTypes = {
  record: PropTypes.object,
  showTitle: PropTypes.bool,
};

InvoiceOwnerFields.defaultProps = {
  record: {},
  showTitle: true,
};

export default InvoiceOwnerFields;
