import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  FunctionField,
} from 'react-admin';
import DownloadButton from 'admin/assets/js/components/buttons/DownloadButton.jsx';
import DownloadErrorField from 'admin/assets/js/resources/downloads/DownloadErrorField.jsx';

const DownloadShow = props => (
  <Show
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="description" />
      <FunctionField
        source="requester_id"
        label="Requester"
        render={(_record) => {
          return (
            <div>
              {_record?.user?.profile?.name || ''}
            </div>
          );
        }}
      />
      <TextField source="url" />
      <DateField source="created_at" />
      <DownloadButton />
      <DownloadErrorField
        label="Error"
        source="error"
        sortable={false}
      />
    </SimpleShowLayout>
  </Show>
);

export default DownloadShow;
