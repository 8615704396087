import React from 'react';

import CreatePricingScaleButton from 'admin/assets/js/resources/pricing_scales/CreatePricingScaleButton.jsx';

const PricingScaleActions = (props) => (
  <div>
    <CreatePricingScaleButton {...props} />
  </div>
);

export default PricingScaleActions;
