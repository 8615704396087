import PropTypes from 'prop-types';
import React from 'react';
import Chip from '@material-ui/core/Chip';

import {
  SMART_SEARCH_RESULT_COLOR, SMART_SEARCH_RESULT_LABEL,
} from 'organizations/assets/js/constants';

const AMLResultChip = ({ amlCheck }) => {
  let label = 'In progress';
  let backgroundColor = '#dc9935';
  if (amlCheck.result) {
    label = SMART_SEARCH_RESULT_LABEL[amlCheck.result];
    backgroundColor = SMART_SEARCH_RESULT_COLOR[amlCheck.result];
  } else if (amlCheck.expired_at) {
    label = 'Expired';
  }
  return (
    <Chip
      clickable={false}
      label={label}
      size="small"
      style={{ backgroundColor, color: 'white' }}
    />
  );
};

AMLResultChip.propTypes = {
  amlCheck: PropTypes.object.isRequired,
};

export default AMLResultChip;
