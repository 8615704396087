import React from 'react';
import { useGetOne } from 'react-admin';

import OnDemandPayoutsTabs from 'admin/assets/js/resources/on_demand_payouts/components/OnDemandPayoutsTabs.jsx';
import CashoutList from 'admin/assets/js/resources/on_demand_payouts/components/CashoutList.jsx';

/**
 * Show a tabbed lists of cashout requests in different stages.
 *
 * @param {*} props - component properties.
 * @return {JSX.Element} rendered component.
 */
const OnDemandPayoutsList = props => {
  // fetch analytics ( tab counts )
  const { data, loading, error } = useGetOne('on_demand_payouts', 1);
  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>
      <OnDemandPayoutsTabs {...props} analytics={data?.analytics} />
      <CashoutList {...props} resource="on_demand_payouts" />
    </>
  );
};

export default OnDemandPayoutsList;
