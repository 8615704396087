import React from 'react';
import { ReferenceInput, SelectInput, Create, SimpleForm } from 'react-admin';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const PayerMappingCreate = props => (
  <Create
    basePath="/payer_mappings"
    resource="payer_mappings"
    {...props}
  >
    <SimpleForm>
      <ReferenceInput
        label="Organization"
        source="organization_id"
        reference="organizations_lite"
        perPage={MAX_ORGANIZATIONS_FETCHED}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default PayerMappingCreate;
