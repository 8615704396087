import { useEffect, useState } from 'react';
import axios from 'axios';

import { adminApiExchangeRateUrl } from 'admin/urls';

const useExchangeRateHook = ({ currency = 'gbp' } = {}) => {
  const [exchangeRates, setExchangeRates] = useState({});

  useEffect(() => {
    const getExchangeRate = async () => {
      if (!Object.keys(exchangeRates).length) {
        // get exchange rates from api for currency
        const url = adminApiExchangeRateUrl(currency);
        const res = await axios.get(url);
        // store exchange rates
        setExchangeRates(res.data || {});
      }
    };

    getExchangeRate();
  }, [exchangeRates]);

  return exchangeRates;
};

export default useExchangeRateHook;
