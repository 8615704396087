import React from 'react';
import PropTypes from 'prop-types';

import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';

const InvoicingModeField = ({ record }) => {
  if (!record) {
    return null;
  }
  const invoicingFrequencyPOJO = InvoicingFrequency.fromDump(record.invoicing_frequency_dump);
  return (
    <span className="discreet">
      {`${record.invoicing_mode} / ${invoicingFrequencyPOJO.toHumanizedString()} `}
    </span>
  );
};

InvoicingModeField.propTypes = {
  record: PropTypes.object,
};

InvoicingModeField.defaultProps = {
  record: {},
};

export default InvoicingModeField;
