import React, { useState } from 'react';
import { Title } from 'react-admin';
import { isEmail } from 'validator';
import { Button, Dialog, DialogContent, DialogTitle, Icon, TextField } from '@material-ui/core';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import Form from 'admin/assets/js/components/Form.jsx';
import SelectInput from 'admin/assets/js/components/inputs/SelectInput.jsx';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { getIsSuperAdmin, reloadTable } from 'admin/assets/js/lib/utils';
import { adminBulkActionApiUrl } from 'admin/urls';

const roleValueToLabel = value => USER_ADMIN_ROLES_LABEL[
  value ? USER_ADMIN_ROLES.SUPER : USER_ADMIN_ROLES.ADMIN
];

const roleValues = Object.values(USER_ADMIN_ROLES);
const roleChoices = roleValues.map(value => ({ label: USER_ADMIN_ROLES_LABEL[value], value }));

export const TeamView = () => {
  const [loading, setLoading] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const onClose = () => setSelectedAdmin(null);
  const onCreateClose = () => setCreateDialogOpen(false);
  const notifications = getNotifications();
  const isSuperAdmin = getIsSuperAdmin();

  const columns = [
    {
      label: 'Name',
      name: 'name',
      options: { filter: false, sort: true },
    },
    {
      label: 'Email',
      name: 'email',
      options: { filter: false, sort: true },
    },
    {
      label: 'Role',
      name: 'is_super',
      options: {
        cellRender: roleValueToLabel,
        customFilterListOptions: {
          render: value => `Role: ${roleValueToLabel(value)}`,
        },
        filter: true,
        filterOptions: {
          names: roleValues,
          renderValue: roleValueToLabel,
        },
        sort: true,
      },
    },
  ];

  if (isSuperAdmin) {
    columns.push({
      label: 'Actions',
      name: 'is_super',
      options: {
        cellRender: (_, record) => (
          <Button
            color="primary"
            onClick={() => setSelectedAdmin(record)}
            startIcon={<Icon>edit</Icon>}
          >
            Edit
          </Button>
        ),
        filter: false,
        sort: false,
      },
    });
  }

  const onToggleRole = async (adminId, isSuper) => {
    setLoading(true);
    try {
      await apiClient({
        data: { adminId },
        method: 'POST',
        url: adminBulkActionApiUrl('admins', 'toggle-role'),
      });
      onClose();
      const beforeRole = USER_ADMIN_ROLES_LABEL[
        isSuper ? USER_ADMIN_ROLES.SUPER : USER_ADMIN_ROLES.ADMIN
      ];
      const afterRole = USER_ADMIN_ROLES_LABEL[
        isSuper ? USER_ADMIN_ROLES.ADMIN : USER_ADMIN_ROLES.SUPER
      ];
      notifications.success(`Changed from ${beforeRole} to ${afterRole} successfully`);
      // refresh is required to reload the list
      reloadTable();
    } catch (e) {
      notifications.error(e.response?.data?._error || e.message || 'Change role failed');
    } finally {
      setLoading(false);
    }
  };

  const onRevokeAccess = async adminId => {
    setLoading(true);
    try {
      await apiClient({
        data: { adminId },
        method: 'POST',
        url: adminBulkActionApiUrl('admins', 'revoke-access'),
      });
      onClose();
      notifications.success('User removed as an admin successfully');
      // refresh is required to reload the list
      reloadTable();
    } catch (e) {
      notifications.error(e.response?.data?._error || e.message || 'Revoking admin access failed');
    } finally {
      setLoading(false);
    }
  };

  const onAdd = async data => {
    setLoading(true);
    try {
      await apiClient({
        data,
        method: 'POST',
        url: adminBulkActionApiUrl('admins', 'add'),
      });
      onCreateClose();
      notifications.success('User added as an admin successfully');
      // refresh is required to reload the list
      reloadTable();
    } catch (e) {
      notifications.error(e.response?.data?._error || e.message || 'Adding user failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title title="Team" />
      <div className="d-flex justify-content-end my-4">
        <Button
          color="primary"
          onClick={() => setCreateDialogOpen(true)}
          variant="contained"
        >
          Add user
        </Button>
      </div>
      <ApiTable
        columns={columns}
        defaultSortBy="name"
        defaultSortDirection="ASC"
        resource="admins"
      />
      <Dialog onClose={onClose} open={!!selectedAdmin}>
        <DialogTitle>{`Edit ${selectedAdmin?.name}`}</DialogTitle>
        <DialogContent>
          <div className="d-flex flex-column">
            <Button
              className="mb-5"
              color="primary"
              disabled={loading}
              onClick={() => onToggleRole(selectedAdmin.id, selectedAdmin.is_super)}
              variant="contained"
            >
              {'Change role to '}
              {USER_ADMIN_ROLES_LABEL[
                selectedAdmin?.is_super ? USER_ADMIN_ROLES.ADMIN : USER_ADMIN_ROLES.SUPER
              ]}
            </Button>
            <Button
              className="mb-5"
              color="secondary"
              disabled={loading}
              onClick={() => onRevokeAccess(selectedAdmin.id)}
              variant="contained"
            >
              Remove access
            </Button>
            <Button color="primary" disabled={loading} onClick={onClose}>Cancel</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog onClose={onCreateClose} open={createDialogOpen}>
        <DialogTitle>Add user</DialogTitle>
        <DialogContent>
          <p>Add email address and role of the user you wish to add:</p>
          <Form
            fields={[
              {
                Component: TextField,
                disabled: loading,
                label: 'Email address',
                name: 'email',
                required: true,
                validate: value => (!isEmail(value) ? 'Enter a valid email' : undefined),
              },
              {
                Component: SelectInput,
                disabled: loading,
                label: 'Role',
                name: 'isSuper',
                options: roleChoices,
                required: true,
                validate: value => (
                  !roleValues.includes(value) ? 'Select a valid role' : undefined
                ),
              },
            ]}
            initialValues={{ email: '', isSuper: USER_ADMIN_ROLES.ADMIN }}
            onCancel={onCreateClose}
            onSubmit={onAdd}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TeamView;
