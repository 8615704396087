import React from 'react';
import PropTypes from 'prop-types';

const AbbreviatedField = ({ record, source, maxLength }) => {
  const partSize = (maxLength - 3) / 2;
  const fullText = record[source] || '';
  let text;
  if (fullText.length > maxLength) {
    const chars = fullText.split('');
    const partChars = [...chars.slice(0, partSize), '...', ...chars.slice(chars.length - partSize)];
    text = partChars.join('');
  } else {
    text = fullText;
  }
  return (
    <span>
      {text}
    </span>
  );
};

AbbreviatedField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
  maxLength: PropTypes.number,
};

AbbreviatedField.defaultProps = {
  record: {},
  maxLength: 10,
};

export default AbbreviatedField;
