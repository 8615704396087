import englishMessages from 'ra-language-english';

const messages = {
  ...englishMessages,
  td: {
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
            'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to react-admin demo',
        subtitle:
            "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        aor_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      billables: 'Billables',
      integrations: 'Integrations',
      legal: 'Legal',
      payments: 'Payments',
    },
  },
  resources: {
    billingProcesses: {
      downloadReport: 'report',
    },
    pricings: {
      fields: {
        amount: 'Amount',
        percent: 'Percentage',
      },
    },
    organizations: {
      name: 'Organization |||| Organizations',
      fields: {
        name: 'Name',
      },
      page: {
        delete: 'Delete organizations',
      },
      errors: {
        password_mismatch:
              'The password confirmation is not the same as the password.',
      },
    },
  },
};

export default messages;
