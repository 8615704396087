/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Table, TableBody, TableCell, TableContainer, Paper, TableRow, TableHead,
} from '@material-ui/core';

import { CURRENCY_SYMBOL } from 'core/assets/js/constants';

const BankFeesTableAnalysis = ({
  childrenBankFeesAnalysis, classes, currency,
}) => (
  <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell><strong>Name</strong></TableCell>
          <TableCell align="right"><strong>{`Fee (${CURRENCY_SYMBOL[currency]})`}</strong></TableCell>
          <TableCell align="right"><strong>Country</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {childrenBankFeesAnalysis.map(row => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.fee}</TableCell>
            <TableCell align="right">{row.country || '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

BankFeesTableAnalysis.propTypes = {
  childrenBankFeesAnalysis: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    fee: PropTypes.string,
    country: PropTypes.string,
  })).isRequired,
  classes: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

export default BankFeesTableAnalysis;
