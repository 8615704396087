import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Select, Paper, MenuItem } from '@material-ui/core';

import UserOrgInfo from 'admin/assets/js/resources/full_users/UserOrgInfo.jsx';

const UserOrgInfoContainer = ({ record: user, ...props }) => {
  const { profile, userCards } = user;
  const defaultUc = userCards[0];
  const [selectedUc, selectUc] = useState(defaultUc);
  const userOrgs = userCards.map(uc => uc.organization);
  useEffect(() => {
    selectUc(defaultUc);
  }, [defaultUc, selectUc]);
  if (isEmpty(userOrgs)) {
    return (
      <Paper style={{ padding: '15px' }}>
        User is not member of an organization
      </Paper>
    );
  }

  const ucMenuItems = userCards.map(uc => (
    <MenuItem key={uc.organization.id} value={uc.organization.id}>{uc.organization.name}</MenuItem>
  ));
  return (
    <div>
      <h4 className="mt-4" style={{ fontWeight: 600 }}>User info within selected organisation:</h4>
      <Select
        value={selectedUc.organization.id}
        onChange={e => selectUc(userCards.find(uc => uc.organization.id === e.target.value))}
        style={{ marginBottom: '20px' }}
      >
        {ucMenuItems}
      </Select>

      <UserOrgInfo userCard={selectedUc} profile={profile} {...props} />
    </div>
  );
};

UserOrgInfoContainer.propTypes = {
  record: PropTypes.object,
};

UserOrgInfoContainer.defaultProps = {
  record: {},
};

export default UserOrgInfoContainer;
