/* eslint react/no-multi-comp: "off" */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid, ListButton, FunctionField, Filter, useGetList, SelectInput, useNotify, Button } from 'react-admin';
import ChevronRight from '@material-ui/icons/ChevronRight';
import queryString from 'query-string';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import httpClient from 'admin/assets/js/lib/httpClient';
import { formatDate } from 'core/assets/js/lib/utils';

import Invoice from 'finance/assets/js/components/Invoice.jsx';
import { financeInvoicePreviewUpcomingOutbound } from 'finance/urls';
import OrgNameField from 'admin/assets/js/components/OrgNameField.jsx';
import GenerateOrgInvoices from 'admin/assets/js/resources/invoices/GenerateOrgInvoices.jsx';
import DownloadNextInvoicesButton from 'admin/assets/js/components/buttons/DownloadNextInvoicesButton.jsx';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import ViewNextInvoicesLogsButton from 'admin/assets/js/resources/invoices/ViewOrgNextInvoicingLogs.jsx';

const PreviewOrgUpcomingOutbound = (props) => {
  const { record: { unique_alias: uniqueAlias, invoicing_mode: mode }, billingDeadline } = props;
  const [invoiceModal, setInvoiceModal] = useState({
    outboundInvoice: null, billingDate: null, isLoading: false, isOpen: false,
  });
  const notify = useNotify();
  async function handlePreviewOutbound() {
    const url = financeInvoicePreviewUpcomingOutbound(uniqueAlias, billingDeadline);
    setInvoiceModal({
      isLoading: true,
      isOpen: true,
      outboundInvoice: null,
      billingDate: null,
    });
    try {
      const res = await httpClient(url, { method: 'GET' });
      const { outboundInvoice, billingDate } = res.json;
      setInvoiceModal(prevState => ({
        ...prevState,
        isLoading: false,
        outboundInvoice,
        billingDate,
      }));
    } catch (err) {
      setInvoiceModal(prevState => ({ ...prevState, isOpen: false, isLoading: false }));
      notify(err.message);
    }
  }
  if (mode === INVOICING_MODE.DIRECT) {
    return null;
  }
  return (
    <>
      <Button
        onClick={() => handlePreviewOutbound()}
        title="Preview upcoming outbound invoice"
        disabled
      >
        <Icon>description</Icon>
      </Button>
      <Dialog
        open={invoiceModal.isOpen}
        maxWidth="lg"
        onClose={() => setInvoiceModal(prevState => ({
          ...prevState, isOpen: false, isLoading: false,
        }))}
      >
        <DialogTitle id="alert-dialog-title">
          {`Outbound Invoice ${invoiceModal.billingDate ? `produced on ${invoiceModal.billingDate}` : ''}`}
        </DialogTitle>
        <DialogContent>
          { invoiceModal.isLoading && (
            <div style={{ height: '100px', width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          {
            (invoiceModal.outboundInvoice)
              ? (
                <Invoice
                  invoice={invoiceModal.outboundInvoice}
                  ownerFE={invoiceModal.outboundInvoice.ownerFE}
                  systemFE={invoiceModal.outboundInvoice.systemFE}
                  recipientFE={invoiceModal.outboundInvoice.recipientFE}
                  invoicingSettings={invoiceModal.outboundInvoice.invoicingSettings}
                />
              ) : null
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setInvoiceModal(prevState => ({
              ...prevState, isOpen: false, isLoading: false,
            }))}
            color="primary"
          >
            <>
              <Icon>close</Icon>
              Close
            </>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PreviewOrgUpcomingOutbound.propTypes = {
  record: PropTypes.object,
  billingDeadline: PropTypes.string.isRequired,
};

PreviewOrgUpcomingOutbound.defaultProps = {
  record: {},
};

const PageHeader = ({ billingDeadline }) => {
  const formattedBillingDeadline = formatDate(billingDeadline);
  return (
    <h3>
      Previewing invoicing organizations for
      {' '}
      {formattedBillingDeadline}
    </h3>
  );
};

PageHeader.propTypes = {
  billingDeadline: PropTypes.string.isRequired,
};

const NextOrgBillingSelectInput = (props) => {
  const { filterValues: { billingDeadline } } = props;
  if (!billingDeadline) {
    return null;
  }
  const { loading, data, ids } = useGetList(
    'next_billing_organizations',
    { page: 1, perPage: 1000 },
    { field: 'name', order: 'ASC' },
    { billingDeadline },
  );

  const choices = useMemo(() => ids.map(id => ({ id, name: data[id].name })), [ids]);

  if (loading) {
    return null;
  }
  return (
    <SelectInput
      resource="next_billing_organizations"
      source="id"
      label="Billing organization"
      allowEmpty
      choices={choices}
    />
  );
};

NextOrgBillingSelectInput.propTypes = {
  filterValues: PropTypes.object.isRequired,
};

const ListFilter = props => (
  <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
    <Filter {...props}>
      <NextOrgBillingSelectInput {...props} alwaysOn allowEmpty />
    </Filter>
  </div>
);

const NextBillingOrganizations = props => {
  const { location } = props;
  const parsedHistory = queryString.parse(location.search);
  const { billingDeadline } = JSON.parse(parsedHistory.filter);
  return (
    <>
      <PageHeader billingDeadline={billingDeadline} />
      <List
        {...props}
        bulkActionButtons={false}
        actions={null}
        filters={<ListFilter />}
      >
        <Datagrid
          expand={(
            <ViewNextInvoicesLogsButton
              billingDeadline={billingDeadline}
            />
          )}
        >
          <OrgNameField />
          <PreviewOrgUpcomingOutbound billingDeadline={billingDeadline} />
          <FunctionField
            render={({ id }) => {
              const queryOptions = queryString.stringify({
                order: 'DESC',
                sort: 'id',
                filter: JSON.stringify({
                  organizationId: id,
                  billingDeadline,
                }),
              });
              return (
                <ListButton
                  basePath={`/next_billing_invoices?${queryOptions}`}
                  label="See next invoices"
                  icon={<ChevronRight />}
                  disabled
                />
              );
            }}
          />
          <DownloadNextInvoicesButton billingDeadline={billingDeadline} />
          <GenerateOrgInvoices />
        </Datagrid>
      </List>
    </>
  );
};

NextBillingOrganizations.propTypes = {
  location: PropTypes.object.isRequired,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  NextBillingOrganizations as List,
};
