import { useEffect, useState } from 'react';

import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { adminApiGetCurrentClassificationQuestionnaireUrl } from 'admin/urls';

// eslint-disable-next-line import/prefer-default-export
export const getClassificationQuestionnaireCurrentVersion = () => {
  const [loading, setLoading] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  const notifications = getNotifications();

  useEffect(() => {
    setLoading(true);
    apiClient({ url: adminApiGetCurrentClassificationQuestionnaireUrl() })
      .then(({ data }) => {
        setQuestionnaire(data);
      })
      .catch(e => {
        notifications.error(e.response?.data?._error || e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, questionnaire };
};
