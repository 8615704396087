import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import { ORGANIZATION_FEATURE_USAGE_TABS } from 'admin/assets/js/constants';
import { DATETIME_FORMAT_HUMAN_FRIENDLY } from 'core/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import {
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS,
  DOCUMENT_ASSIGNMENT_STATUS_COLORS,
  DOCUMENT_ASSIGNMENT_STATUS_LABEL,
} from 'documents/assets/js/constants';

const thisFormatDate = date => formatDate(`${date}-01`, 'MMMM YYYY');

const tab = ORGANIZATION_FEATURE_USAGE_TABS.E_SIGNATURES;

export const ESignaturesTab = ({ history, location: { pathname, search }, organization }) => {
  const { date: selectedDate } = qs.parse(search);

  if (selectedDate) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-left">
          <BackButton />
          <h2 className="m-0">{thisFormatDate(selectedDate)}</h2>
        </div>
        <ApiTable
          columns={[
            {
              label: 'ID',
              name: 'id',
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Contract template',
              name: 'document_title',
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Created at',
              name: 'created_at',
              options: {
                cellRender: createdAt => formatDate(createdAt),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Status',
              name: 'status',
              options: {
                cellRender: status => (
                  <Chip
                    label={DOCUMENT_ASSIGNMENT_STATUS_LABEL[status]}
                    size="small"
                    style={{
                      backgroundColor: DOCUMENT_ASSIGNMENT_STATUS_COLORS[status],
                      color: 'white',
                    }}
                  />
                ),
                downloadCellRender: status => status,
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Recipient',
              name: 'user_name',
              options: {
                cellRender: (userName, signature) => (
                  !signature.user_id ? userName : (
                    <Link
                      to={`/full_users/${signature.user_id}/show?backUrl=${pathname}${search}`}
                    >
                      {userName}
                    </Link>
                  )
                ),
                downloadCellRender: userName => userName,
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Completed at',
              name: 'updated_at',
              options: {
                cellRender: (_, signature) => {
                  if (signature.status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.SIGNED) {
                    return formatDate(signature.updated_at, DATETIME_FORMAT_HUMAN_FRIENDLY);
                  }
                  return null;
                },
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Usage',
              name: 'type',
              options: {
                filter: false,
                sort: true,
              },
            },
          ]}
          defaultSortBy="created_at"
          defaultSortDirection="DESC"
          extraResourceQueryParams={{ date: selectedDate, organization_id: organization.id, tab }}
          resource="document_assignment_signatures"
        />
      </>
    );
  }

  return (
    <ApiTable
      columns={[
        {
          label: 'Date',
          name: 'date',
          options: {
            cellRender: thisFormatDate,
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Created',
          name: 'created',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Completed',
          name: 'completed',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Details',
          name: 'date',
          options: {
            cellRender: date => (
              <Button
                color="primary"
                onClick={() => {
                  history.push(`${pathname}?date=${date}&tab=${tab}`);
                }}
              >
                View
              </Button>
            ),
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="date"
      defaultSortDirection="DESC"
      excludeColumnsFromDownload={['Details']}
      extraResourceQueryParams={{ organization_id: organization.id, tab }}
      noMatchText="No e-signature usage by this organisation"
      resource="document_assignment_signatures"
      searchEnabled={false}
    />
  );
};

ESignaturesTab.propTypes = {
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
};

export default withRouter(ESignaturesTab);
