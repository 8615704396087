import React, { useEffect, useState } from 'react';
import { SelectInput, SimpleForm, TextInput, Title, useNotify } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { SETTINGS, SETTINGS_DESCRIPTIONS, SETTINGS_VALUES } from 'admin/assets/js/constants';
import { getIsSuperAdmin } from 'admin/assets/js/lib/utils';
import httpClient from 'admin/assets/js/lib/httpClient';
import { validate } from 'admin/assets/js/lib/settingsValidation';
import { adminCommandApiUrl } from 'admin/urls';
import { TAX_METHOD_LABEL } from 'finance/assets/js/constants';

const Settings = () => {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  const isSuperAdmin = getIsSuperAdmin();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await httpClient(adminCommandApiUrl('settings'), { method: 'GET' });
        setSettings(response.json);
      } catch (e) {
        notify('Error: Loading settings failed', 'warning');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return null;
  }

  const filteredSystemSettings = SETTINGS_VALUES.filter(setting => (
    setting !== SETTINGS.INVOICING_TAX_METHOD
  ));
  const invoicingTaxMethodChoices = Object.entries(TAX_METHOD_LABEL)
    .map(([id, name]) => ({ id, name }));
  return (
    <>
      <Title title="Settings" />
      {isSuperAdmin && (
        <SimpleForm
          initialValues={settings}
          redirect={false}
          save={async newValues => {
            setSaving(true);
            const changes = {};
            Object.entries(newValues).forEach(([key, value]) => {
              if (value !== settings[key]) {
                changes[key] = value;
              }
            });
            if (Object.keys(changes).length === 0) {
              return;
            }
            try {
              const response = await httpClient(
                adminCommandApiUrl('update-settings'),
                { method: 'PUT', body: JSON.stringify(changes) },
              );
              setSettings(response.json);
              notify('Settings updated');
            } catch (e) {
              notify(e.message || 'Error: Updating settings failed', 'warning');
            } finally {
              setSaving(false);
            }
          }}
          saving={saving}
        >
          {filteredSystemSettings.map(key => (
            <TextInput
              fullWidth
              helperText={SETTINGS_DESCRIPTIONS[key]}
              key={key}
              source={key}
              validate={value => {
                try {
                  validate(key, value);
                } catch (e) {
                  return e.message;
                }
                return undefined;
              }}
            />
          ))}
          <SelectInput
            fullWidth
            resource="settings"
            source="invoicing_tax_method"
            label="Invoicing tax method"
            required
            validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
            helperText={SETTINGS_DESCRIPTIONS[SETTINGS.INVOICING_TAX_METHOD]}
            allowEmpty
            choices={invoicingTaxMethodChoices}
          />
        </SimpleForm>
      )}
      {!isSuperAdmin && (
        <>
          <p className="mt-4">
            You do not have permission to change these settings. Please contact a super admin.
          </p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Value</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SETTINGS_VALUES.map(key => (
                  <TableRow key={key}>
                    <TableCell>
                      <strong>{key}</strong>
                      <br />
                      {SETTINGS_DESCRIPTIONS[key]}
                    </TableCell>
                    <TableCell>{settings[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default Settings;
