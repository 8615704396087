import React from 'react';
import PropTypes from 'prop-types';

import PaymentMethodList from 'admin/assets/js/resources/payment_methods/list.jsx';

const PaymentMethodsTab = ({ organization }) => (
  <PaymentMethodList
    orgId={organization.id}
  />
);

PaymentMethodsTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default PaymentMethodsTab;
