import { PropTypes } from 'prop-types';
import React from 'react';
import { FormControl, TextField } from '@material-ui/core';

import Form from 'admin/assets/js/components/Form.jsx';
import CheckboxGroupInput from 'admin/assets/js/components/inputs/CheckboxGroupInput.jsx';
import DateInput  from 'admin/assets/js/components/inputs/DateInput.jsx';
import MarkdownInput from 'admin/assets/js/components/inputs/MarkdownInput.jsx';
import RadioGroupInput from 'admin/assets/js/components/inputs/RadioGroupInput.jsx';
import SelectInput from 'admin/assets/js/components/inputs/SelectInput.jsx';
import YesNoInput from 'admin/assets/js/components/inputs/YesNoInput.jsx';
import { getOrganizationsList } from 'admin/assets/js/lib/utils';
import { API_DATE_FORMAT, USER_TYPE_LABEL } from 'core/assets/js/constants';
import { getDatetime, parseDate } from 'core/assets/js/lib/utils';
import { INVOICING_MODE_LABEL } from 'finance/assets/js/constants';
import SiteWideBanner from 'core/assets/js/components/SiteWideBanner.jsx';
import {
  SITE_WIDE_BANNER_TYPE, SITE_WIDE_BANNER_TYPE_LABEL,
} from 'organizations/assets/js/constants';

const typeValues = Object.values(SITE_WIDE_BANNER_TYPE);
const typeChoices = typeValues.map(value => ({
  label: SITE_WIDE_BANNER_TYPE_LABEL[value], value,
}));

export const BannerForm = ({ initialValues, loading, onCancel, onSubmit, submitButtonText }) => {
  const { options: organizations } = getOrganizationsList();

  if (organizations.length === 0) {
    return null;
  }

  return (
    <Form
      FormFieldsRendererComponent={({ errors, setError, updateValue, values }) => {
        const onChange = (name, validate) => ({ target: { value } }) => {
          setError(name, null);
          updateValue(name, value);
          if (validate) {
            const error = validate(value);
            if (error) {
              setError(name, error);
            }
          }
        };
        return (
          <div className="content">
            <div className="row">
              <div className="col-12 d-flex">
                <FormControl className="mb-4 w-100">
                  <TextField
                    disabled={loading}
                    error={!!errors.title}
                    helperText={
                      `Give a friendly name for your banner so you can distinguish it from other
                      banners`
                    }
                    label="Title"
                    name="title"
                    onChange={onChange(
                      'title', value => (value.length === 0 ? 'Enter a valid title' : undefined),
                    )}
                    required
                    value={values.title}
                  />
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 d-flex flex-column">
                <h4>Banner content</h4>
                <MarkdownInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.content}
                  name="content"
                  onChange={onChange('content')}
                  placeholder="Banner text"
                  value={values.content}
                />
                <FormControl className="mb-4">
                  <TextField
                    disabled={loading}
                    error={!!errors.button_label}
                    helperText="Leave empty if you don't need a button"
                    label="Button label"
                    name="button_label"
                    onChange={onChange('button_label')}
                    value={values.button_label}
                  />
                </FormControl>
                <FormControl className="mb-4">
                  <TextField
                    disabled={loading}
                    error={!!errors.button_url}
                    helperText="Leave empty if you don't need a button"
                    label="Button URL"
                    name="button_url"
                    onChange={onChange('button_url')}
                    value={values.button_url}
                  />
                </FormControl>
                <h4 className="mb-4">Target audience</h4>
                <CheckboxGroupInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.user_roles}
                  label="User roles"
                  name="user_roles"
                  onChange={onChange('user_roles')}
                  options={
                    Object.entries(USER_TYPE_LABEL).map(([value, label]) => ({ label, value }))
                  }
                  required
                  value={values.user_roles}
                />
                <CheckboxGroupInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.invoicing_modes}
                  horizontal
                  label="Invoicing modes"
                  name="invoicing_modes"
                  onChange={onChange('invoicing_modes')}
                  options={
                    Object.entries(INVOICING_MODE_LABEL).map(([value, label]) => ({ label, value }))
                  }
                  required
                  value={values.invoicing_modes}
                />
                <SelectInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.organizations}
                  label="Organizations"
                  name="organizations"
                  onChange={onChange('organizations')}
                  options={[
                    { label: 'All', value: 0 },
                    ...organizations,
                  ]}
                  value={values.organizations}
                />
              </div>
              <div className="col-12 col-lg-6 d-flex flex-column">
                <h4>Settings</h4>
                <SelectInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.type}
                  label="Type"
                  name="type"
                  onChange={onChange(
                    'type', value => (!typeValues.includes(value) ? 'Select a valid type' : undefined),
                  )}
                  options={typeChoices}
                  required
                  value={values.type}
                />
                <YesNoInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.dismissable}
                  horizontal
                  label="Dismissable?"
                  name="dismissable"
                  onChange={onChange('dismissable')}
                  required
                  value={values.dismissable}
                />
                {values.dismissable && (
                  <YesNoInput
                    containerClassName="mb-4"
                    disabled={loading}
                    error={!!errors.show_after_dismissed}
                    horizontal
                    label="Show again after dismissed?"
                    name="show_after_dismissed"
                    onChange={onChange('show_after_dismissed')}
                    required
                    value={values.show_after_dismissed}
                  />
                )}
                <RadioGroupInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.definite}
                  horizontal
                  label="Term"
                  name="definite"
                  onChange={onChange('definite')}
                  options={[
                    { label: 'Definite', value: 'yes' }, { label: 'Indefinite', value: 'no' },
                  ]}
                  required
                  value={values.definite}
                />
                <DateInput
                  containerClassName="mb-4"
                  disabled={loading}
                  error={!!errors.start_date}
                  isValidDate={currentDate => (
                    parseDate(currentDate, API_DATE_FORMAT).diff(getDatetime(), 'days') >= 0
                  )}
                  label="Term start date"
                  name="start_date"
                  onChange={onChange('start_date')}
                  required
                  value={values.start_date}
                />
                {values.definite === 'yes' && (
                  <DateInput
                    containerClassName="mb-4"
                    disabled={loading}
                    error={!!errors.end_date}
                    isValidDate={currentDate => (
                      values.start_date
                      && (
                        parseDate(currentDate, API_DATE_FORMAT)
                          .diff(parseDate(values.start_date, API_DATE_FORMAT), 'days') >= 0
                      )
                    )}
                    label="Term end date"
                    name="end_date"
                    onChange={onChange('end_date')}
                    required
                    value={values.end_date}
                  />
                )}
              </div>
            </div>
            <h4>Preview</h4>
            {(!values.content || !values.type) && (
              <p>Preview will be available once you add banner content and select banner type</p>
            )}
            {values.content && values.type && (
              <div className="site-wide-banners d-flex flex-column w-100 mb-5">
                <SiteWideBanner
                  banner={{
                    buttonLabel: values.button_label,
                    buttonUrl: values.button_url,
                    content: values.content,
                    dismissable: values.dismissable,
                    id: 1,
                    type: values.type,
                  }}
                />
              </div>
            )}
          </div>
        );
      }}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitButtonText={submitButtonText}
    />
  );
};

BannerForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
};

BannerForm.defaultProps = {
  initialValues: {},
  submitButtonText: 'Create',
};

export default BannerForm;
