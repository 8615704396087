import { get, isEmpty } from 'lodash';

import { INVOICE_STATUS, INVOICING_MODE, RAISED_BY, TRANSACTION_STATUS } from 'finance/assets/js/constants';
import {
  CUSTOM_BANK_ACCOUNT_TYPE,
  CUSTOM_BANK_ACCOUNT_TYPE_LABEL,
  PAYMENT_METHODS,
} from 'settings/assets/js/constants';

// eslint-disable-next-line import/prefer-default-export
export const getInvoiceStatusReasonInfo = (invoice) => {
  if (isEmpty(invoice)) {
    return '';
  }
  const { status, raisedBy, transactions, invoicingSettings: { mode } } = invoice;

  const isOutbound = raisedBy === RAISED_BY.TALENTDESK;
  const isInbound = !isOutbound;
  const isDirect = mode === INVOICING_MODE.DIRECT;
  const isRaised = status === INVOICE_STATUS.RAISED;
  const isPaypal = invoice.paymentMethod === PAYMENT_METHODS.PAYPAL;
  const isBankTransfer = invoice.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER;
  const bankAccountType = get(invoice, 'ownerFE.bank.bankAccountType', null);
  const isCustomBankAccount = CUSTOM_BANK_ACCOUNT_TYPE
    .includes(bankAccountType);
  let customBankAccountLabel = 'NO BANK';
  if (bankAccountType) {
    customBankAccountLabel = (isCustomBankAccount
      && CUSTOM_BANK_ACCOUNT_TYPE_LABEL[bankAccountType])
      || `${bankAccountType}`;
  }
  const hasTransactions = !isEmpty(transactions);
  const hasExecutedTransactions = hasTransactions
    && Array.isArray(transactions)
    && transactions.some(trans => [
      TRANSACTION_STATUS.EXECUTED,
      TRANSACTION_STATUS.SUCCEEDED,
    ].includes(trans.status));
  const successColor = '#28a745';
  const errorColor = 'tomato';

  let showReason = false;
  let reason = 'No reason added yet';
  let statusStyling = {};

  switch (true) {
    case (
      isInbound && !isDirect && !hasTransactions && isRaised && isBankTransfer
      && isCustomBankAccount
    ):
      showReason = true;
      reason = `This is an INBOUND invoice where a custom BANK ACCOUNT form was used by the contractor ( ${customBankAccountLabel} ). It is normal not to have a transaction number generated by the system.`;
      statusStyling = { color: successColor };
      break;
    case (
      isInbound && !isDirect && !hasTransactions && isRaised && isBankTransfer
    ):
      showReason = true;
      reason = 'This is an INBOUND invoice where a BANK ACCOUNT was used by the contractor. It should have a transaction number generated by the system. Investigation is needed';
      statusStyling = { color: errorColor };
      break;
    case (
      isInbound && isDirect && !hasTransactions && isRaised
    ):
      showReason = true;
      reason = 'This is an INBOUND invoice of an organization with DIRECT mode. It is normal not to have a transaction number generated by the system since our client is payment the contractor.';
      statusStyling = { color: successColor };
      break;
    case isInbound && !isDirect && hasTransactions && !hasExecutedTransactions && isBankTransfer:
      showReason = true;
      reason = 'This is an INBOUND invoice where a BANK ACCOUNT was used by the contractor. Although it has transactions, none of them is in EXECUTED state.';
      statusStyling = { color: errorColor };
      break;
    case (
      isInbound && isRaised && isPaypal
    ):
      showReason = true;
      reason = 'This is an INBOUND invoice where a PAYPAL account was used by the contractor. It is normal not to have a transaction number generated by the system.';
      statusStyling = { color: successColor };
      break;
    case (
      isOutbound && isRaised
    ):
      showReason = true;
      reason = 'This is an OUTBOUND invoice. It is normal not to have a transaction number generated by the system.';
      statusStyling = { color: successColor };
      break;
    default:
      break;
  }

  return {
    showReason,
    reason,
    statusStyling,
  };
};
