import React from 'react';
import {
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import { Typography } from '@material-ui/core';

/**
 * Expanded view for audit run table rows.
 *
 * Show a summary of all org ids included in this audit run,
 * along with a summary of all leg levels.
 *
 * @param {Object} props - component props.
 * @return {JSX.Element} rendered JSX component.
 */
const FactAuditRunsExpandedDetails = props => (
  <SimpleShowLayout {...props}>
    <FunctionField
      label="Log levels"
      sortable={false}
      render={(record) => {
        // insert spaces, so browser can break line
        const levels = (record && record.levels && record.levels.split(',').join(', ')) || '';
        return (<Typography variant="body2">{ levels }</Typography>);
      }}
    />
    <FunctionField
      label="Organization ids"
      sortable={false}
      render={(record) => {
        // insert spaces, so browser can break line
        const ids = (record && record.orgIds && record.orgIds.split(',').join(', ')) || '';
        // don't let browser give all width to this column,
        // as that can make other columns look ugly
        return (<Typography variant="body2" style={{ maxWidth: '600px' }}>{ ids }</Typography>);
      }}
    />
  </SimpleShowLayout>
);

export default FactAuditRunsExpandedDetails;
