import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useNotify, useDelete,
} from 'react-admin';
import { Button, Icon } from '@material-ui/core';
import { logger } from 'core/assets/js/lib/Logger';


import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';

const UnallocateButton = ({ transactionId, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [deleteOne, { loading }] = useDelete();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = useCallback(() => {
    deleteOne(
      'transactions', transactionId, {}, {
        onSuccess: () => {
          notify('Payment unallocated!', { type: 'success' });
          onSuccess();
          handleDialogClose();
        },
        onFailure: (error) => {
          logger.error(error);
          notify('Payment unallocation failed!', { type: 'error' });
          handleDialogClose();
        },
      },
    );
  }, [notify, deleteOne, onSuccess, transactionId]);

  if (!transactionId) {
    return null;
  }

  const title = 'Are you sure you want to unallocate payment?';

  const content = (
    <>
      You are about to unallocate payment from the invoice.
      <br />
      Any executed payments that are associated with the allocation will be cancelled.
    </>
  );

  return (
    <Fragment>
      <Button
        size="small"
        color="primary"
        onClick={handleClick}
        disabled={loading}
      >
        <Icon>delete_forever</Icon>
      </Button>
      <ConfirmModal
        open={open}
        title={title}
        content={content}
        onConfirm={() => handleConfirm()}
        confirm="Yes, do it"
        onClose={handleDialogClose}
        cancel="No, Dont"
        showCtaIcons={false}
      />
    </Fragment>
  );
};

UnallocateButton.propTypes = {
  transactionId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  onSuccess: PropTypes.func,
};

UnallocateButton.defaultProps = {
  transactionId: null,
  onSuccess: () => {},
};

export default UnallocateButton;
