import React  from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceManyField,
} from 'react-admin';

import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import { ON_DEMAND_PAYOUT_SEGMENT } from 'finance/assets/js/constants';
import OnDemandPayoutsApproveTransactionsButton from 'admin/assets/js/resources/on_demand_payouts/components/OnDemandPayoutsApproveTransactionsButton';

const OnDemandPayoutsApproveButton = (props) => {
  const { selectedIds, permissions, basePath } = props;
  const canApprove = (
    permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
  );
  const transactionIds = selectedIds || [];
  const disabled = !canApprove;
  return (
    <ReferenceManyField
      label="Approve transactions"
      reference="on_demand_payouts"
      basePath={basePath}
      filter={{
        segment: ON_DEMAND_PAYOUT_SEGMENT.PENDING_APPROVAL,
      }}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <OnDemandPayoutsApproveTransactionsButton
        disabled={disabled}
        transactionIds={transactionIds}
      />
    </ReferenceManyField>
  );
};

OnDemandPayoutsApproveButton.propTypes = {
  selectedIds: PropTypes.array,
  basePath: PropTypes.string.isRequired,
  permissions: PropTypes.object,
};

OnDemandPayoutsApproveButton.defaultProps = {
  selectedIds: [],
  permissions: {},
};

export default OnDemandPayoutsApproveButton;
