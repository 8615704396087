import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  banner: {
    backgroundColor: '#369fdc',
    color: 'white',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
});

const NextTransactionBanner = ({ transaction }) => {
  const classes = useStyles();
  const { nextTransactionId } = transaction;
  return nextTransactionId ? (
    <div className={classes.banner}>
      <Info />
      {' This transaction has been recreated as transaction '}
      <Link to={`/transactions/${nextTransactionId}/details`}>
        #
        {nextTransactionId}
      </Link>
    </div>
  ) : null;
};

NextTransactionBanner.propTypes = {
  transaction: PropTypes.object,
};

NextTransactionBanner.defaultProps = {
  transaction: {},
};

export default NextTransactionBanner;
