/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { Icon } from '@material-ui/core';

const OrgBillingTransactionsButton = (passthroughProps) => {
  const {
    record: { id: orgBillingId },
  } = passthroughProps;
  return (
    <BulkApiActionButton
      title="Replay billing"
      tooltipTitle="Replaying a billing process allows for the simulation of a past billing cycle in a non-destructive manner, ensuring no changes are made to actual data."
      content={(
        <>
          <p>
            Are you sure you want to replay the billing process
            for this date and this organization?
          </p>
          <p className="hint">
            This operation simulates a past billing process and is not destructive.
            Any changes made during the operation are rolled back on completion.
          </p>
        </>
      )}
      icon={<Icon>refresh</Icon>}
      ids={[orgBillingId]}
      resource="organization_billings"
      action="replay-org-billings"
    />
  );
};

export default OrgBillingTransactionsButton;
