import React, { Fragment, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, useRefresh, useNotify } from 'react-admin';

import { BILLING_PROCESS_INVOICE_SEGMENTS } from 'finance/assets/js/constants';
import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogContentText, DialogTitle, DialogContent, FormControlLabel, Checkbox } from '@material-ui/core';
import { adminCommandApiUrl } from 'admin/urls';

const GeneratePublishedInvoicesButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [createTransactions, setCreateTransactions] = React.useState(false);
  const [sendNotifications, setSendNotifications] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async (values) => {
    const command = 'invoices-generate-published';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify({
        ...values,
        createTransactions,
        sendNotifications,
      }) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>GENERATE INVOICES</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Generate Invoices"
      >
        <DialogTitle>
          <div className="d-flex align-items-center justify-content-between">
            <div>Generate Invoices</div>
            <FormControlLabel
              checked={createTransactions}
              onChange={e => setCreateTransactions(e.target.checked)}
              value="createTransactions"
              control={<Checkbox color="primary" />}
              label="Create transactions"
              labelPlacement="start"
            />
            <FormControlLabel
              checked={sendNotifications}
              onChange={e => setSendNotifications(e.target.checked)}
              value="sendNotifications"
              control={<Checkbox color="primary" />}
              label="Send notifications to all related parties"
              labelPlacement="start"
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Publishing invoices will create finalized invoices for our users and will
            also send notifications to them about their new invoices.
            <br />
            By selecting an organization which should not be billed in the selected billing date
            e.g An org which has &apos;monthly&apos; invoicing frequency and the
            selected date is not the end of the month, will result in NO invoices generated at all.
            <br />
            <strong>Proceed with caution.</strong>
          </DialogContentText>
          <Form onSubmit={handleGenerateClick}>
            {({ handleSubmit, values }) => {
              const { orgId } = values;
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="orgId">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Organization Ids</label>
                          { !orgId && (
                            <div className="hint" style={{ marginBottom: '10px' }}>
                              Νot selecting specific organization ids means generating invoices
                              for ALL organizations.
                            </div>
                          )}
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                              display: 'block',
                            }}
                            {...input}
                            type="text"
                            placeholder="10,22,33"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="billingDeadline">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label style={{ margin: '0' }}>Billing date</label>
                          <div className="hint" style={{ marginBottom: '10px' }}>
                            Select a billing date only in cases where today
                            is NOT the billing date you want to generate invoices for.
                            If no billing date is selected,
                            the system will select today&apos;s date
                            by default.
                          </div>
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="invoiceSegment" component="select">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Invoice segment</label>
                          <div className="hint" style={{ marginBottom: '10px' }}>
                            Select whether you want all invoices to be generated or
                            just a part of them.
                          </div>
                          <select
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                            required
                          >
                            <option value={undefined}>All</option>
                            <option value={BILLING_PROCESS_INVOICE_SEGMENTS.LICENCE}>
                              Just licence invoices
                            </option>
                            <option value={BILLING_PROCESS_INVOICE_SEGMENTS.PROCESSING}>
                              Just processing invoices
                            </option>
                          </select>
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Button variant="contained" disabled={loading} label="Generate" type="submit" />
                    <Button label="cancel" type="button" onClick={handleCloseClick} />
                  </div>
                </form>
              );
            }}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default GeneratePublishedInvoicesButton;
