import React from 'react';
import { Card, CardHeader, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';

import { formatDate } from 'admin/assets/js/lib/utils';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
    fontWeight: 600,
  },
}));
const GeneralInfoCard = ({ organization }) => {
  const classes = useStyles();
  if (!organization) {
    return null;
  }
  const {
    serialized_address_components: { country },
    unique_alias: alias,
    invoicing_parent_org_id: invoicingParentOrgId,
  } = organization;
  const parentOrgName = organization.invoicingParentOrg?.name;
  const ownerUc = organization.ownerUserCard;
  const ownerUserId = ownerUc.user.id;
  const profile = ownerUc.profile;
  const formattedCreatedAt = organization.created_at ? formatDate(organization.created_at) : '-';
  const ownerName = `${profile?.first_name ?? ''}${profile?.middle_name ?? ''} ${profile?.last_name ?? ''}`;
  const contactPhone = profile?.phone ?? '-';
  return (
    <Card variant="outlined">
      <CardHeader style={{ fontWeight: 600 }} title="General Info" />
      <CardContent sx={{ m: 0, p: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.column}>
            <Typography>Country</Typography>
            <Typography className={classes.fieldValue}>{country}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>Client Since</Typography>
            <Typography className={classes.fieldValue}>{formattedCreatedAt}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>Alias</Typography>
            <Typography className={classes.fieldValue}>{alias}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Owner
            </Typography>
            <Typography className={classes.fieldValue}>
              <Link to={`/full_users/${ownerUserId}/show`}>{ownerName}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Contact
            </Typography>
            <Typography className={classes.fieldValue}>
              <Link to={`/full_users/${ownerUserId}/show`}>{ownerName}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Contact Phone
            </Typography>
            <Typography
              className={classes.fieldValue}
            >
              {contactPhone}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>Parent Organization</Typography>
            <Typography className={classes.fieldValue}>
              { invoicingParentOrgId
                ? <Link to={`/organizations/${invoicingParentOrgId}/show`}>{parentOrgName}</Link>
                : '-'
              }
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

GeneralInfoCard.propTypes = {
  organization: PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    invoicingParentOrg: PropTypes.shape({
      name: PropTypes.string,
    }),
    ownerUserCard: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.shape({
        id: PropTypes.number,
      }),
      profile: PropTypes.shape({
        first_name: PropTypes.string,
        id: PropTypes.number,
        last_name: PropTypes.string,
        middle_name: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
    serialized_address_components: PropTypes.shape({
      country: PropTypes.string,
    }),
    unique_alias: PropTypes.string,
    user_id: PropTypes.number.isRequired,
    invoicing_parent_org_id: PropTypes.number,
  }),
};

GeneralInfoCard.defaultProps = {
  organization: PropTypes.shape({
    invoicing_parent_org_id: null,
  }),
};

export default GeneralInfoCard;
