import { ValidationError, ValidationErrorItem } from 'core/assets/js/errors';
import { TRANSACTION_METHOD } from 'finance/assets/js/constants';
import { isValidUUID } from 'finance/assets/js/lib/utils';

/**
 * Revolut transaction method pojo.
 * Records all details specific to a Revolut transaction.
 */
class TransactionMethodRevolut {
  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  /**
   * Initialise instance with initial values.
   * @param {object} param0 - instance values.
   */
  init({
    remoteTransactionId,
    localTransactionId,
    accountId,
  }) {
    const sanitizedRemoteTransactionId = typeof remoteTransactionId === 'string'
      ? remoteTransactionId.trim() : remoteTransactionId;

    const sanitizedLocalTransactionId = typeof localTransactionId === 'string'
      ? localTransactionId.trim() : localTransactionId;


    if (!remoteTransactionId && !localTransactionId) {
      throw new ValidationError('Transaction id is required', [
        new ValidationErrorItem('transactionId', 'Transaction id is required'),
      ]);
    }

    if (!isValidUUID(sanitizedRemoteTransactionId) && !isValidUUID(sanitizedLocalTransactionId)) {
      throw new ValidationError('Transaction id is not valid', [
        new ValidationErrorItem('transactionId', 'Transaction id is not valid'),
      ]);
    }

    this.details = {
      method: TRANSACTION_METHOD.REVOLUT,
      localTransactionId: sanitizedLocalTransactionId,
      remoteTransactionId: sanitizedRemoteTransactionId,
      accountId,
    };
  }

  /**
   * Serialize instance.
   * @return {object} serialized instance.
   */
  serialize() {
    const res = { ...this.details };
    return res;
  }

  /**
   * Get a transfer descriptor for this transaction method.
   * @return {string} the transfer descriptor.
   */
  getTransactionDescriptor() {
    return `#${this.details.remoteTransactionId || this.details.localTransactionId}`;
  }

  /**
   * Get the transaction id.
   * @return {string} the transaction id.
   */
  getTransactionId() {
    return this.details.remoteTransactionId || this.details.localTransactionId;
  }

  /**
   * Get the remote transaction id.
   * @return {string} the remote transaction id.
   */
  getRemoteTransactionId() {
    return this.details.remoteTransactionId;
  }

  /**
   * Get the local transaction id.
   * @return {string} the local transaction id.
   */
  getLocalTransactionId() {
    return this.details.localTransactionId;
  }

  /**
   * Get the account id of the transaction.
   * This will identify which balance the transaction funds came from.
   * @return {string} revolut account uuid.
   */
  getAccountId() {
    return this.details.accountId;
  }
}

export default TransactionMethodRevolut;
