import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, useNotify, useRefresh, useUpdate } from 'react-admin';
import { get } from 'lodash';

import { BANK_ACCOUNT_TYPE } from 'settings/assets/js/constants';

const ToggleWiseValidationButton = ({ record }) => {
  const [update] = useUpdate('bank_accounts', get(record, 'id'));
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = useCallback(() => {
    // toggle bank account type between validation and no validation
    const data = {
      ...record,
      bank_account_type: record.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE
        ? BANK_ACCOUNT_TYPE.TRANSFERWISE_NO_VALIDATION : BANK_ACCOUNT_TYPE.TRANSFERWISE,
    };

    update({ payload: { data } }, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        refresh();
      },
    });
  }, [update]);

  // don't bother to show UI if not a Wise account
  if (![BANK_ACCOUNT_TYPE.TRANSFERWISE,
    BANK_ACCOUNT_TYPE.TRANSFERWISE_NO_VALIDATION].includes(record.bank_account_type)) {
    return null;
  }

  return (
    <>
      <label>Toggle Wise validation</label>
      <div>
        <Button
          label={record.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE
            ? 'Disable Wise validation'
            : 'Enable Wise validation'
          }
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

ToggleWiseValidationButton.propTypes = {
  record: PropTypes.object.isRequired,
};

ToggleWiseValidationButton.defaultProps = {};

export default ToggleWiseValidationButton;
