import React from 'react';
import { ReferenceField, Datagrid, List, TextField, FunctionField } from 'react-admin';
import PropTypes from 'prop-types';
import InvoiceBankDetails from 'finance/assets/js/components/invoicing/InvoiceBankDetails.jsx';
import ListFilter from 'admin/assets/js/resources/system_bank_account_overrides/ListFilter.jsx';
import ListActions from 'admin/assets/js/resources/system_bank_account_overrides/ListActions.jsx';
import { INVOICE_SEGMENT_LABEL } from 'finance/assets/js/constants';

const SystemBankAccountOverridesList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<ListFilter />}
    hasCreate
    actions={<ListActions {...props} />}
  >
    <Datagrid>
      <ReferenceField label="Organization" source="organization_id" reference="organizations_lite" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Invoices"
        source="invoice_segment"
        render={bankOverride => (INVOICE_SEGMENT_LABEL[bankOverride.invoice_segment])}
      />
      <ReferenceField label="Invoicing Entity" source="system_bank_account_id" reference="system_bank_accounts" link={false}>
        <ReferenceField label="Invoicing Entity" source="invoicing_entity_id" reference="invoicing_entities" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField label="Bank" source="system_bank_account_id" reference="system_bank_accounts" link="show">
        <TextField source="alias" />
      </ReferenceField>
      <ReferenceField label="Bank Details" source="system_bank_account_id" reference="system_bank_accounts" link={false}>
        <FunctionField
          label="Details"
          render={systemBankAccount => (
            <InvoiceBankDetails bankAccount={systemBankAccount.details} />
          )}
        />
      </ReferenceField>
    </Datagrid>
  </List>
);

SystemBankAccountOverridesList.propTypes = {
  basePath: PropTypes.string,
};

SystemBankAccountOverridesList.defaultProps = {
  basePath: null,
};

export default SystemBankAccountOverridesList;
