import React from 'react';
import PropTypes from 'prop-types';

import { useGetMany } from 'react-admin';

import Audits from 'admin/assets/js/resources/fact_audit_log_groups/Audits';


const AuditLoader = ({ groupIds }) => {
  const { data, loaded } = useGetMany('fact_audit_logs', groupIds);
  // note - don't render until we have data,
  //        so wait for loaded before first render
  return loaded ? (<Audits data={data} />) : null;
};

AuditLoader.propTypes = {
  groupIds: PropTypes.array.isRequired,
};

export default AuditLoader;
