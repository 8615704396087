
import CustomCashoutRequestsView from 'admin/assets/js/resources/cashout_requests/CustomCashoutRequestsView.jsx';
import { CASHOUT_REQUESTS_TABS } from 'admin/assets/js/constants';

const CustomDeferredInvoicesView = ({ ...props }) => {
  return CustomCashoutRequestsView({
    ...props,
    selectedTab: CASHOUT_REQUESTS_TABS.DEFERRED_INVOICES,
  });
};

export default CustomDeferredInvoicesView;
