import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { Button } from '@material-ui/core';
import { get } from 'lodash';

import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';


const ToggleWithConfirmationButton = ({
  className,
  confirmationModalContent,
  confirmationModalTitle,
  color,
  label,
  newValues,
  onSuccess,
  record,
  resource,
  variant,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update] = useUpdate(resource, get(record, 'id'));
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = useCallback(() => {
    setLoading(true);
    update({ payload: { data: newValues } }, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        setLoading(false);
        setModalOpen(false);
        refresh();
        onSuccess();
      },
      onFailure: (error) => {
        setLoading(false);
        if (error?.body?._error) {
          notify(error.body._error);
        } else {
          notify(error.message);
        }
      },
    });
  }, [update]);

  return (
    <>
      <Button
        className={className}
        onClick={() => setModalOpen(true)}
        size="small"
        color={color}
        variant={variant}
      >
        {label?.toUpperCase()}
      </Button>

      <ConfirmModal
        confirm="Confirm"
        cancel="No, Don't"
        content={confirmationModalContent}
        disableCtas={loading}
        onClose={() => setModalOpen(false)}
        onConfirm={handleSubmit}
        open={modalOpen}
        title={confirmationModalTitle}
      />
    </>
  );
};

ToggleWithConfirmationButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  confirmationModalContent: PropTypes.any.isRequired,
  confirmationModalTitle: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  newValues: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

ToggleWithConfirmationButton.defaultProps = {
  className: '',
  color: undefined,
  onSuccess: () => {},
  variant: 'outlined',
};

export default ToggleWithConfirmationButton;
