/* eslint react/prop-types: "warn", import/prefer-default-export: "warn", react/no-multi-comp: 0 */
import React, { useCallback, useState } from 'react';
import { fromPairs, get, isEmpty, isFinite, isNil, sortBy, toPairs, isEqual } from 'lodash';
import {
  ArrayInput, NumberInput, SaveButton, SimpleForm, SimpleFormIterator,
  Toolbar, useNotify, useRefresh, useUpdate, SelectInput, Button,
} from 'react-admin';
import { Icon, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import ProcessingFeeScheme from 'finance/assets/js/lib/ProcessingFeeScheme';
import FeeScale from 'finance/assets/js/lib/FeeScale';
import useStyles from './useStyles';
import { CURRENCY, CURRENCY_LABEL, ICON } from 'core/assets/js/constants';
import { PROCESSING_FEES_MODE } from 'finance/assets/js/constants';

const currencies = Object.values(CURRENCY).map(c => ({
  id: c, name: CURRENCY_LABEL[c],
}));

export const parseFeeScale = (scaleValues) => {
  let spec;
  if (scaleValues) {
    spec = sortBy(
      scaleValues.filter(s => !!s), s => parseFloat(s.amount),
    ).map(s => [s.amount, s.percent]);
    const scale = new FeeScale(spec);
    return scale;
  }
  return null;
};

const SaveWithoutRedirectButton = ({ handleSubmitWithRedirect, ...props }) => {
  const formState = useFormState();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate('pricings', get(formState.values, 'id'));

  const handleClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const scale = parseFeeScale(formState.values.scale);

    const processingScheme = new ProcessingFeeScheme({
      ...formState.values, scale: scale.serialize(),
    });

    const data = {
      invoicing_fee_scheme: {
        processing: processingScheme.serialize(),
        licence: formState.values.scheme.getLicenceFeeSpec(),
      },
    };

    const currentPricingScaleSelection = scale.serialize();
    const initialPricingScaleSelection = parseFeeScale(formState.initialValues.scale).serialize();
    const hasPricingScaleChanged = !isEqual(
      currentPricingScaleSelection, initialPricingScaleSelection,
    );
    if (hasPricingScaleChanged) {
      data.pricing_scale_id = null;
    }

    update({ payload: { data } }, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        refresh();
      },
    });
  }, [
    formState.valid,
    formState.values,
    update,
    notify,
  ]);

  return <SaveButton {...props} redirect={false} handleSubmitWithRedirect={handleClick} />;
};

const ToolbarWithoutRedirect = props => {
  return (
    <Toolbar {...props}>
      <SaveWithoutRedirectButton {...props} />
    </Toolbar>
  );
};

const validateNumber = (value) => {
  if (value && !isFinite(parseFloat(value))) {
    return 'Invalid number';
  }
  return undefined;
};

export const validateScale = (items) => {
  const errorArray = [];

  if (isEmpty(items)) {
    return undefined;
  }

  items.forEach((value) => {
    const errors = {};

    if (!value || isNil(value.percent)) {
      errors.percent = 'Required';
    } else {
      errors.percent = validateNumber(value.percent);
    }
    if (!value || isNil(value.amount)) {
      errors.amount = 'Required';
    } else {
      errors.amount = validateNumber(value.amount);
    }
    errorArray.push(errors);
  });

  if (errorArray.some(object => !isEmpty(object))) {
    return errorArray;
  }
  return undefined;
};

const validateFees = (values) => {
  const errors = {};

  errors.floor = validateNumber(values.floor);

  errors.scale = validateScale(values.scale);

  try {
    parseFeeScale(values.scale);
  } catch (e) {
    if (!errors.scale[0].amount) {
      errors.scale[0].amount = e.message;
    }
  }

  return fromPairs(toPairs(errors).filter(([, e]) => !!e));
};

const ProcessingFeesForm = (props) => {
  const { record } = props;
  const { id, invoicing_fee_scheme, currency: orgCurrency } = record;

  const scheme = new InvoicingFeeScheme(invoicing_fee_scheme);
  const processingFeeScheme = scheme.getProcessingFeeSpec();
  const parsedProcFeeScheme = new ProcessingFeeScheme(processingFeeScheme);
  const classes = useStyles();
  const hasPerApprovedWorksheetFee = parsedProcFeeScheme.hasPerApprovedWorksheet();
  const hasScaleFee = parsedProcFeeScheme.hasScale();
  let initialTab = PROCESSING_FEES_MODE.SCALE;
  if (hasPerApprovedWorksheetFee) {
    initialTab = PROCESSING_FEES_MODE.PER_APPROVED_WORKSHEET;
  }
  const [selectedFeeCategory, setSelectedFeeCategory] = useState(initialTab);
  const initialValues = {
    id,
    ...processingFeeScheme,
    // this is an override of processing currency so that we can update it based on the
    // org currency value.Right now we have no other way to update it.
    // Processing currency default currency is always GBP as a result of that.
    currency: orgCurrency,
    scale: scheme.getProcessingScheme().getScaleSteps().map(({ range, feePercent }) => ({
      percent: feePercent, amount: range[0],
    })),
    scheme,
  };
  const isScaleFeeCategorySelected = selectedFeeCategory === PROCESSING_FEES_MODE.SCALE
    || !selectedFeeCategory;
  const isPreApprovedSoCategorySelected = selectedFeeCategory === PROCESSING_FEES_MODE
    .PER_APPROVED_WORKSHEET;
  const [adminOptedForScaleFeeChange, setScaleFeeChangeSelection] = useState(false);
  const isScaleFeeFormDisabled = record.pricing_scale_id && !adminOptedForScaleFeeChange;
  return (
    <React.Fragment>
      <div>
        <div>Select one of the following processing fee categories, for this organisation:</div>
        <List className="d-flex" component="nav" aria-label="main mailbox folders">
          <ListItem
            button
            onClick={() => setSelectedFeeCategory(PROCESSING_FEES_MODE.SCALE)}
          >
            <ListItemAvatar>
              <Avatar variant="square" className={`${classes.large} ${hasScaleFee ? classes.withValue : ''}`}>
                <Icon className={ICON.PERCENTAGE_ALT} fontSize="small" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Scale fees"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => setSelectedFeeCategory(PROCESSING_FEES_MODE.PER_APPROVED_WORKSHEET)}
          >
            <ListItemAvatar>
              <Avatar variant="square" className={`${classes.large} ${hasPerApprovedWorksheetFee ? classes.withValue : ''}`}>
                <Icon className={ICON.COPY} fontSize="small" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Per approved worksheet"
            />
          </ListItem>
        </List>
      </div>
      {isPreApprovedSoCategorySelected && (
        <SimpleForm
          toolbar={<ToolbarWithoutRedirect />}
          redirect={false}
          validate={validateFees}
          initialValues={initialValues}
        >
          <div className={classes.containerClasses}>
            <SelectInput
              helperText="Replacing default processing currency with org currency"
              label="currency"
              source="currency"
              choices={currencies}
              disabled
              className={classes.itemClasses}
              style={{ maxWidth: '25%', display: 'none' }}
            />
            <NumberInput
              step={0.01}
              helperText="How much is the minimum fee this org will be charged?"
              source="floor"
              className={classes.itemClasses}
              style={{ maxWidth: '50%' }}
            />
            {parsedProcFeeScheme && (
              <div className={classes.infoBar}>
                <p className={classes.infoBarLabel}>Currency</p>
                <p className={classes.infoBarValue}>{`${parsedProcFeeScheme.getCurrency()} (${parsedProcFeeScheme.getCurrencySymbol()})`}</p>
              </div>
            )}
            <div className={classes.containerClasses}>
              <NumberInput step={0.01} helperText="How much will be charged per approved worksheet?" source="perApprovedWorksheet" className={classes.itemClasses} style={{ maxWidth: '50%' }} />
            </div>
          </div>
        </SimpleForm>
      )}
      {isScaleFeeCategorySelected && (
        <div className="py-5 d-flex flex-column align-items-center justify-content-center">
          {isScaleFeeFormDisabled && (
            <>
              <p className="text-danger">
                <strong>
                  This organization does not have a Custom Pricing Scale.
                </strong>
              </p>
              <p>
                If you are 100% sure you want to modify the Pricing Scale of this organization
                click on
                {' '}
                <strong>Override settings</strong>
                .
                By doing so, the organization&apos;s pricing scale will be set to that of
                {' '}
                <strong>Custom</strong>
                {' '}
                type.
              </p>
            </>
          )}
          <SimpleForm
            toolbar={<ToolbarWithoutRedirect disabled={isScaleFeeFormDisabled} />}
            redirect={false}
            validate={validateFees}
            initialValues={initialValues}
            disabled={isScaleFeeFormDisabled}
          >
            <div className={classes.containerClasses}>
              <SelectInput
                helperText="Replacing default processing currency with org currency"
                label="currency"
                source="currency"
                choices={currencies}
                disabled={isScaleFeeFormDisabled}
                className={classes.itemClasses}
                style={{ maxWidth: '25%', display: 'none' }}
              />
              <NumberInput
                step={0.01}
                helperText="How much is the minimum fee this org will be charged?"
                source="floor"
                disabled={isScaleFeeFormDisabled}
                className={classes.itemClasses}
                style={{ maxWidth: '50%' }}
              />
              {parsedProcFeeScheme && (
                <div className={classes.infoBar}>
                  <p className={classes.infoBarLabel}>Currency</p>
                  <p className={classes.infoBarValue}>{`${parsedProcFeeScheme.getCurrency()} (${parsedProcFeeScheme.getCurrencySymbol()})`}</p>
                </div>
              )}
              <ArrayInput source="scale" className={classes.iterator}>
                <SimpleFormIterator
                  className={classes.formIterator}
                  disableSubmit={isScaleFeeFormDisabled}
                  disableRemove={isScaleFeeFormDisabled}
                  disableAdd={isScaleFeeFormDisabled}
                >
                  <NumberInput
                    step={1}
                    disabled={isScaleFeeFormDisabled}
                    label="Over amount"
                    helperText="Over which amount of provider charges we apply this percentage fee?"
                    source="amount"
                    className={classes.inputFields}
                    style={{ maxWidth: '97%' }}
                  />
                  <NumberInput
                    step={0.5}
                    disabled={isScaleFeeFormDisabled}
                    label="Fee percent"
                    helperText="What is the percentage we charge for this charges tier?"
                    source="percent"
                    className={classes.inputFields}
                    style={{ maxWidth: '97%', paddingRight: 0 }}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          </SimpleForm>
          {isScaleFeeFormDisabled && (
            <Button
              variant="contained"
              className="mt-3"
              onClick={() => setScaleFeeChangeSelection(true)}
              label="Override settings"
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ProcessingFeesForm;
