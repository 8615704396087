import moment from 'moment';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Create, DateInput, FormDataConsumer,
  NumberInput, SelectInput, SimpleForm,
  TextInput, Toolbar,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import RecordSubscriptionTransactionButton from 'admin/assets/js/resources/subscriptions/RecordSubscriptionTransactionButton.jsx';
import { TRANSACTION_METHOD_LABEL } from 'finance/assets/js/constants';
import TransactionFormRelatedInfoComponent from 'admin/assets/js/components/transactions/TransactionFormRelatedInfoComponent.jsx';
import { CURRENCY, CURRENCY_LABEL } from 'core/assets/js/constants';

const SubscriptionTransactionAddDialog = ({ onClose, subscription, basePath }) => {
  const methodOptions = useMemo(() => Object.keys(TRANSACTION_METHOD_LABEL).map(k => ({
    id: parseInt(k, 10), name: TRANSACTION_METHOD_LABEL[k],
  })), [TRANSACTION_METHOD_LABEL]);
  // note - UI should match the list of currencies enforced by db schema ( invoices.currency )
  const currencyOptions = useMemo(() => Object.values(CURRENCY).map(k => ({
    id: k, name: CURRENCY_LABEL[k],
  })), [CURRENCY, CURRENCY_LABEL]);

  return (
    <Dialog onClose={onClose} open={!!subscription}>
      {subscription && (
        <>
          <DialogTitle>{`Add transaction for subscription #${subscription.id}`}</DialogTitle>
          <DialogContent>
            <Create
              resource="subscription_transaction"
              basePath={basePath}
              id={subscription.id}
              onSuccess={onClose}
            >
              <SimpleForm
                initialValues={{
                  subscriptionId: subscription.id,
                  orgId: subscription.organization_id,
                  date: moment().format('YYYY-MM-DD'),
                  periodStart: moment().format('YYYY-MM-DD'),
                  periodEnd: moment().format('YYYY-MM-DD'),
                  quantity: 1,
                  plan: subscription.plan,
                }}
                redirect={false}
                toolbar={(
                  <Toolbar>
                    <RecordSubscriptionTransactionButton subscription={subscription} />
                  </Toolbar>
                )}
              >
                <TextInput
                  label="Subscription id"
                  fullWidth
                  source="subscriptionId"
                  disabled
                />

                <SelectInput
                  label="Transaction method"
                  source="method"
                  choices={methodOptions}
                  fullWidth
                />

                <DateInput source="date" label="Date of transfer" fullWidth />
                <div className="d-flex align-items-center w-100">
                  <DateInput style={{ flex: 1 }} source="periodStart" label="Subsription period start" />
                  <DateInput style={{ flex: 1 }} source="periodEnd" label="Subscription period end" />
                </div>
                <NumberInput source="quantity" label="Quantity" fullWidth />

                <div className="w-100">
                  <FormDataConsumer>
                    {({ formData }) => (
                      <TransactionFormRelatedInfoComponent formData={formData} />
                    )}
                  </FormDataConsumer>

                  <div className="d-flex align-items-center mb-2">
                    <SelectInput
                      style={{ flex: 1 }}
                      label="Currency"
                      source="currency"
                      choices={currencyOptions}
                      helperText="Please select the currency of the amount, VAT and fee."
                    />
                    <NumberInput
                      style={{ flex: 1 }}
                      source="amount"
                      label="Amount (Incl. VAT)"
                      step={0.01}
                      helperText="Please include any VAT and fee in the amount."
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <NumberInput
                      style={{ flex: 1 }}
                      source="vat"
                      label="VAT (amount)"
                      step={0.01}
                      helperText="Please enter the VAT as a monetary amount, not a percentage."
                    />
                    <NumberInput
                      style={{ flex: 1 }}
                      source="fee"
                      label="Fee"
                      step={0.01}
                      helperText="Please enter how much of the amount was a fee as a monetary amount."
                    />
                  </div>
                </div>
              </SimpleForm>
            </Create>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

SubscriptionTransactionAddDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.number.isRequired,
    started_at: PropTypes.string.isRequired,
    expires_at: PropTypes.string.isRequired,
    organization_id: PropTypes.number.isRequired,
    plan: PropTypes.number.isRequired,
  }).isRequired,
  basePath: PropTypes.string.isRequired,
};

export default SubscriptionTransactionAddDialog;
