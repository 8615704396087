import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { Grid, Paper } from '@material-ui/core';

import { formatDate } from 'admin/assets/js/lib/utils';
import UserAmlInfo from 'admin/assets/js/resources/full_users/UserAmlInfo.jsx';
import { USER_STATUS_LABEL } from 'core/assets/js/constants';
import { USER_STATUS_COLORS } from 'admin/assets/js/resources/full_users/constants';
import {
  COMPANY_TAX_SYSTEM_INFO,
} from 'settings/assets/js/constants';

const useStyles = makeStyles({
  labelStyle: {
    color: '#0000008A',
    marginBottom: '5px',
  },
});

const UserInfo = ({ record: user }) => {
  const classes = useStyles();
  const userTaxSystemType = user?.company?.tax_system_type;
  const userIsOrgMember = !isEmpty(user.userCards);
  const userCountry = user.profile?.address_components?.country;
  const userHasProfile = user.profile;

  if (!userHasProfile) {
    return (
      <Paper style={{ padding: '15px' }}>
        User has no profile setup
      </Paper>
    );
  }
  return (
    <Paper style={{ padding: '15px' }}>
      <div className={classes.root}>
        <h2 style={{ marginTop: '0', fontWeight: '600' }}>General info</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Name</div>
            <div>
              {`${user.profile.firstName} ${user.profile.lastName}`}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Status</div>
            <div style={{ color: USER_STATUS_COLORS[user.status] }}>
              {USER_STATUS_LABEL[user.status]}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Tax registered</div>
            <div>
              {COMPANY_TAX_SYSTEM_INFO[userTaxSystemType]?.label || 'None'}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Created</div>
            <div>
              {formatDate(user.created_at)}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Organizations</div>
            { userIsOrgMember ? (
              <div>
                {user.userCards.length || ''}
                {' ('}
                { user.userCards.map((uc, index) => (
                  <span key={uc.id}>
                    {' '}
                    <Link to={`/organizations/${uc.organization.id}/show`}>{uc.organization.name}</Link>
                    {index !== user.userCards.length - 1 ? ', ' : ''}
                  </span>
                ))}
                {' )'}
              </div>
            ) : '-'}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Tax number</div>
            <div>
              {userTaxSystemType ? user?.company[COMPANY_TAX_SYSTEM_INFO[userTaxSystemType].systemLabel] : '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Country</div>
            <div>
              {userCountry || '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.labelStyle}>Full legal name</div>
            <div>
              {`${user.profile.firstName} ${user.profile.middleName || ''} ${user.profile.lastName}`}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Email</div>
            <div>
              {user.email}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>US citizen</div>
            {
              userCountry ? (
                `${user.profile?.address_components?.country_code === 'US' ? 'Yes' : 'No'}`
              ) : '-'
            }
          </Grid>
        </Grid>
        <h2 className="mt-5" style={{ fontWeight: '600' }}>AML</h2>
        <UserAmlInfo user={user} />
      </div>
    </Paper>
  );
};

UserInfo.propTypes = {
  record: PropTypes.object,
};

UserInfo.defaultProps = {
  record: {},
};

export default UserInfo;
