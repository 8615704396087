import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';
import { CASHOUT_REQUEST_STATUS_LABEL } from 'finance/assets/js/constants';

const statusOptions = Object.keys(CASHOUT_REQUEST_STATUS_LABEL).map(k => ({
  id: k, name: CASHOUT_REQUEST_STATUS_LABEL[k],
}));

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statusOptions}
    />
  </Filter>
);

export default ListFilter;
