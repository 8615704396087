/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
/* eslint-disable react/no-multi-comp */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import jsonExport from 'jsonexport/dist';
import {
  List,
  Datagrid, Filter,
  TextField, DateField, BooleanField,
  DateInput, TextInput, SelectInput, BooleanInput,
  FunctionField, downloadCSV,
} from 'react-admin';

import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { USER_STATUS_LABEL, UTM_KEYS, USER_TYPE_LABEL } from 'core/assets/js/constants';

const statusOptions = Object.keys(USER_STATUS_LABEL).map(k => ({
  id: k, name: USER_STATUS_LABEL[k],
}));

const userTypesAvailable = Object.keys(USER_TYPE_LABEL).map(k => ({
  id: k, name: USER_TYPE_LABEL[k],
}));

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />

    <SelectInput label="Status" alwaysOn allowEmpty source="status" choices={statusOptions} />
    <SelectInput
      label="Country"
      allowEmpty
      source="countryCode"
      alwaysOn
      choices={countryOptions.map(option => ({ id: option.value, name: option.text }))}
    />
    <TextInput label={UTM_KEYS.CAMPAIGN} source={UTM_KEYS.CAMPAIGN} alwaysOn />
    <TextInput label={UTM_KEYS.SOURCE} source={UTM_KEYS.SOURCE} />
    <TextInput label={UTM_KEYS.MEDIUM} source={UTM_KEYS.MEDIUM} />
    <TextInput label={UTM_KEYS.CONTENT} source={UTM_KEYS.CONTENT} />
    <TextInput label={UTM_KEYS.TERM} source={UTM_KEYS.TERM} />
    <TextInput label={UTM_KEYS.REFERER} source={UTM_KEYS.REFERER} />
    <TextInput label={UTM_KEYS.HSPAGEID} source={UTM_KEYS.HSPAGEID} />
    <TextInput label={UTM_KEYS.HSTK} source={UTM_KEYS.HSTK} />
    <TextInput label="City / Address" source="address" />
    <SelectInput
      label="Email verified"
      allowEmpty
      source="email_verified"
      choices={[
        { id: 1, name: 'Yes' },
        { id: 0, name: 'No' },
      ]}
    />
    <SelectInput
      label="User Type"
      allowEmpty
      source="user_type"
      choices={userTypesAvailable}
    />
    <DateInput source="created_at_gte" label="Created on or after" />
    <DateInput source="created_at_lte" label="Created on or before" />
    <BooleanInput label="Problematic bank account" source="problematic" alwaysOn />
  </Filter>
);

const StatusField = ({ record, source }) => (record
  ? (<span>{USER_STATUS_LABEL[record[source]]}</span>)
  : null
);

StatusField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

StatusField.defaultProps = {
  record: null,
  source: null,
};

const UserList = (props) => {
  // custom export
  const exporter = useCallback((orgs) => {
    const rows = orgs.map(o => ({
      Id: o.id,
      'First Name': o.profile.first_name,
      'Last Name': o.profile.last_name,
      Email: o.email,
      Created: o.created_at,
      Organization: o.profile.default_org_id,
    }));

    jsonExport(rows, {
      headers: [
        'Id',
        'First Name',
        'Last Name',
        'Email',
        'Created',
        'Organization',
      ],
    }, (err, csv) => {
      downloadCSV(csv, 'users');
    });
  }, []);

  return (
    <List
      {...props}
      exporter={exporter}
      filters={<ListFilter />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick={id => `/full_users/${id}/show`}>
        <TextField label="Email" source="email" />
        <FunctionField
          source="userRoles"
          label="Role"
          render={record => (
            record && record.userRoles
            && record.userRoles.map(userRole => (
              <div key={userRole.userCard.id}>
                <span key={userRole.userCard.id}>{USER_TYPE_LABEL[userRole.of_type]}</span>
                <br />
              </div>
            )))
          }
        />
        <TextField label="Country" source="profile.country" />
        <TextField label="City" source="profile.city" />
        <BooleanField label="Verified" source="email_verified" />
        <StatusField {...props} label="Status" source="status" />
        <DateField label="Created at" options={{ timeZone: 'UTC' }} source="created_at" />
      </Datagrid>
    </List>
  );
};

export {
  UserList as List,
};
