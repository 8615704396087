/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import {
  List,
  Datagrid, Filter,
  TextField, FunctionField, ReferenceField,
  SelectInput, ReferenceInput, Link,
} from 'react-admin';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { orderBy } from 'lodash';

import {
  INVOICE_TYPE, TRANSACTION_METHOD_LABEL, TRANSACTION_STATUS,
  TRANSACTION_STATUS_LABEL,
  TRANSACTION_TYPE_LABEL,
} from 'finance/assets/js/constants';
import TransactionActions from 'admin/assets/js/resources/transactions/TransactionActions.jsx';
import TextInput from 'admin/assets/js/components/inputs/TextInput.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { formatDate } from 'admin/assets/js/lib/utils';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import CustomTransactionsView from 'admin/assets/js/CustomTransactionsView.jsx';

const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const typeOptions = Object.keys(TRANSACTION_TYPE_LABEL).map(k => ({
  id: k, name: TRANSACTION_TYPE_LABEL[k],
}));

const statusOptions = Object.keys(TRANSACTION_STATUS_LABEL).map(k => ({
  id: parseInt(k, 10), name: TRANSACTION_STATUS_LABEL[k],
}));

const methodOptions = orderBy(
  Object
    .keys(TRANSACTION_METHOD_LABEL)
    .map(key => ({ id: parseInt(key, 10), name: TRANSACTION_METHOD_LABEL[key] })),
  'name',
);

const StatusIcon = ({ status }) => {
  let IconWithTooltip = null;
  switch (status) {
    case TRANSACTION_STATUS.PENDING:
      IconWithTooltip = (
        <HourglassEmptyIcon
          style={{ color: '#3f51b5' }}
        />
      );
      break;
    case TRANSACTION_STATUS.SUCCEEDED:
      IconWithTooltip = (
        <CheckCircleIcon
          style={{ color: 'green' }}
        />
      );
      break;
    case TRANSACTION_STATUS.FAILED:
      IconWithTooltip = (
        <CancelIcon
          style={{ color: 'red' }}
        />
      );
      break;
    case TRANSACTION_STATUS.CANCELLED:
      IconWithTooltip = (
        <BlockIcon
          style={{ color: 'gray' }}
        />
      );
      break;
    case TRANSACTION_STATUS.EXECUTED:
      IconWithTooltip = (
        <DoneAllRoundedIcon
          style={{ color: 'black' }}
        />
      );
      break;
    case TRANSACTION_STATUS.REFUNDED:
      IconWithTooltip = (
        <Rotate90DegreesCcwIcon />
      );
      break;
    case TRANSACTION_STATUS.REMOVED:
      IconWithTooltip = (
        <BlockIcon
          style={{ color: 'gray' }}
        />
      );
      break;
    default:
      break;
  }
  return (
    <Tooltip title={TRANSACTION_STATUS_LABEL[status]} placement="right">
      {IconWithTooltip}
    </Tooltip>
  );
};

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput alwaysOn allowEmpty source="status" choices={statusOptions} />
    <SelectInput alwaysOn allowEmpty source="type" choices={typeOptions} />
    <SelectInput alwaysOn allowEmpty source="method" choices={methodOptions} />
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={MAX_ORGANIZATIONS_FETCHED}
      filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText={org => `${org.name} (${org.unique_alias})`} />
    </ReferenceInput>
  </Filter>
);


const TransactionList = props => (
  <>
    <div className="mt-2">
      <div className="ml-2 d-inline">
        <HourglassEmptyIcon style={{ color: '#3f51b5' }} />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.PENDING]}</span>
      </div>
      <div className="ml-2 d-inline">
        <Rotate90DegreesCcwIcon />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.REFUNDED]}</span>
      </div>
      <div className="ml-2 d-inline">
        <CheckCircleIcon style={{ color: 'green' }} />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.SUCCEEDED]}</span>
      </div>
      <div className="ml-2 d-inline">
        <CancelIcon style={{ color: 'red' }} />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.FAILED]}</span>
      </div>
      <div className="ml-2 d-inline">
        <BlockIcon style={{ color: 'gray', marginLeft: '5px' }} />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.REMOVED]}</span>
      </div>
      <div className="ml-2 d-inline">
        <BlockIcon style={{ color: 'gray', marginLeft: '5px' }} />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.CANCELLED]}</span>
      </div>
      <div className="ml-2 d-inline">
        <DoneAllRoundedIcon style={{ color: 'black', marginLeft: '5px' }} />
        <span className="ml-2">{TRANSACTION_STATUS_LABEL[TRANSACTION_STATUS.EXECUTED]}</span>
      </div>
      <div className="ml-2 d-inline">
        <ArrowBackIcon style={{ color: '#3f51b5' }} />
        <span className="ml-2">Outbound</span>
      </div>
      <div className="ml-2 d-inline">
        <ArrowForwardIcon style={{ color: '#3f51b5' }} />
        <span className="ml-2">Inbound</span>
      </div>
    </div>
    <List
      {...props}
      filters={<ListFilter />}
      bulkActionButtons={false}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid>
        <ReferenceField
          {...props}
          source="id"
          label="ID"
          reference="transactions"
          link="show"
        >
          <FunctionField
            label=""
            render={record => (
              <Link to={`/transactions/${record.id}/details`}>{record.id}</Link>
            )}
          />
        </ReferenceField>
        <ReferenceField label="Reference ID" source="id" reference="transactions" link="show">
          <TextField source="tw_transfer_id" />
        </ReferenceField>
        <ReferenceField label="Invoice No" source="invoice_id" reference="invoices">
          <FunctionField
            render={record => {
              const isOutbound = record.type === INVOICE_TYPE.OUTBOUND;
              const invoiceIcon = isOutbound ? <ArrowBackIcon /> : <ArrowForwardIcon />;
              return (
                (record.uniqueNumber !== record.number)
                  ? (
                    <>
                      {invoiceIcon}
                      {' '}
                      {record.number}
                      (
                      {record.uniqueNumber}
                      )
                    </>
                  ) : (
                    <>
                      {invoiceIcon}
                      {' '}
                      {record.number}
                    </>
                  )
              );
            }}
          />
        </ReferenceField>
        <ReferenceField label="Organization" source="organization_id" reference="organizations">
          <TextFieldInForm source="name" />
        </ReferenceField>
        <TextField source="method_label" label="Method" />
        <FunctionField
          source="type"
          label="Type"
          render={record => TRANSACTION_TYPE_LABEL[record.type]}
          sortable={false}
        />
        <FunctionField
          source="amount"
          label="Outgoing amount"
          render={record => {
            return (
              <NumberTpl
                prefix={CURRENCY_SYMBOL[record.amounts.outgoingCurrency.toLowerCase()]}
                value={record.amounts.outgoingAmount}
              />
            );
          }}
          sortable={false}
        />
        <FunctionField
          source="amount"
          label="Target amount"
          render={record => (
            <NumberTpl
              prefix={CURRENCY_SYMBOL[record.amounts.targetCurrency.toLowerCase()]}
              value={record.amounts.targetAmount}
            />
          )}
          sortable={false}
        />
        <FunctionField
          source="date"
          label="Created at"
          render={record => formatDate(record.date)}
        />
        <FunctionField
          source="status"
          label="Status"
          render={record => <StatusIcon status={record.status} />}
          sortable={false}
        />
        <FunctionField
          render={record => (
            <TransactionActions
              id={record.id}
              status={record.status}
            />
          )}
        />
      </Datagrid>
    </List>
  </>
);

export {
  TransactionList as List,
  CustomTransactionsView as Show,
};
