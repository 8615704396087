import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, useRefresh, useNotify } from 'react-admin';

import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { adminCommandApiUrl } from 'admin/urls';

const RebuildInvoicesButton = ({ onlyStaticData }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleConfirmClick = async (values) => {
    const command = onlyStaticData ? 'invoices-update-static-data' : 'rebuild-invoices';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(values) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  const label = onlyStaticData ? 'UPDATE STATIC DATA OF INVOICES' : 'REBUILD INVOICES';
  const title = onlyStaticData ? 'Update static data of invoices' : 'Rebuild invoices';
  const confirmLabel = onlyStaticData ? 'Update' : 'Rebuild';

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>{label}</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={title}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleConfirmClick}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="invoiceIds">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Invoice Ids</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="text"
                          placeholder="10,22,33"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label={confirmLabel} type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

RebuildInvoicesButton.propTypes = {
  onlyStaticData: PropTypes.bool,
};

RebuildInvoicesButton.defaultProps = {
  onlyStaticData: false,
};

export default RebuildInvoicesButton;
