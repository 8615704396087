import React, { useEffect, useState } from 'react';
import { SelectInput, SimpleForm, Title, useNotify } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { getIsSuperAdmin } from 'admin/assets/js/lib/utils';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';
import {
  INTEGRATION_AVAILABILITY_STATUS_LABEL,
  INTEGRATION_AVAILABILITY_STATUS_VALUES,
  INTEGRATION_TYPE_CONFIG,
  INTEGRATION_TYPE_VALUES,
} from 'integrations/assets/js/constants';

const choices = INTEGRATION_AVAILABILITY_STATUS_VALUES.map(id => ({
  id, name: INTEGRATION_AVAILABILITY_STATUS_LABEL[id],
}));

const IntegrationsAvailability = () => {
  const [integrations, setIntegrations] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  const isSuperAdmin = getIsSuperAdmin();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await httpClient(
          adminCommandApiUrl('integrations-availability'), { method: 'GET' },
        );
        setIntegrations(response.json);
      } catch (e) {
        notify('Error: Loading settings failed', 'warning');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <Title title="Integrations availability" />
      {isSuperAdmin && (
        <SimpleForm
          initialValues={integrations}
          redirect={false}
          save={async newValues => {
            setSaving(true);
            const changes = {};
            Object.entries(newValues).forEach(([key, value]) => {
              if (value !== integrations[key]) {
                changes[key] = value;
              }
            });
            if (Object.keys(changes).length === 0) {
              return;
            }
            try {
              const response = await httpClient(
                adminCommandApiUrl('integrations-availability'),
                { method: 'PUT', body: JSON.stringify(changes) },
              );
              setIntegrations(response.json);
              notify('Integrations availability updated');
            } catch (e) {
              notify(e.message || 'Error: Updating integrations availability failed', 'warning');
            } finally {
              setSaving(false);
            }
          }}
          saving={saving}
        >
          {Object.keys(integrations).map(type => (
            <SelectInput
              key={type}
              label={INTEGRATION_TYPE_CONFIG[type].name}
              source={type}
              choices={choices}
              fullWidth
            />
          ))}
        </SimpleForm>
      )}
      {!isSuperAdmin && (
        <>
          <p className="mt-4">
            You do not have permission to change integrations availability.
            Please contact a super admin.
          </p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Value</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {INTEGRATION_TYPE_VALUES.map(type => (
                  <TableRow key={type}>
                    <TableCell><strong>{INTEGRATION_TYPE_CONFIG[type].name}</strong></TableCell>
                    <TableCell>{integrations[type]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default IntegrationsAvailability;
