import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, useRefresh, useNotify } from 'react-admin';
import CommentIcon from '@material-ui/icons/Comment';

import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { adminCommandApiUrl } from 'admin/urls';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    '& .MuiButton-root': {
      marginBottom: '10px',
    },
  },
});

const CommentTransactionButton = ({ transactionId, comment }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async (values) => {
    const valuesToSend = {
      ...values,
      id: transactionId,
      // note, without this we can't blank an existing comment
      comment: values.comment ? values.comment : '',
    };

    const command = 'transaction-comment';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(valuesToSend) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  const initialValues = {
    comment,
  };

  return (
    <div className={classes.container}>
      <Button
        onClick={handleClick}
        label={comment ? 'Edit Comment' : 'Add Comment'}
        variant="outlined"
        startIcon={<CommentIcon />}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Comment Transaction"
      >
        <DialogTitle>Comment transaction</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleGenerateClick} initialValues={initialValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="comment">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Comment</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '100%',
                          }}
                          {...input}
                          type="text"
                          placeholder="ACH routing number doesn't match what Wise expects"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Comment" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CommentTransactionButton.propTypes = {
  comment: PropTypes.string,
  transactionId: PropTypes.number.isRequired,
};

CommentTransactionButton.defaultProps = {
  comment: '',
};

export default CommentTransactionButton;
