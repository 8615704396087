/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { NODE_ENV, ENV_DEVELOPMENT, ENV_STAGING, ENV_PRODUCTION } from 'core/assets/js/config/settings';
import DeploymentsStatusBanner from 'admin/assets/js/layout/DeploymentsStatusBanner.jsx';

const envColors = {
  [ENV_DEVELOPMENT]: '#22c55e',
  [ENV_STAGING]: '#eab308',
  [ENV_PRODUCTION]: '#ef4444',
};

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  appBar: {
    backgroundColor: envColors[NODE_ENV] || '#232e76',
  },
});

const CustomUserMenu = props => (
  <UserMenu {...props} />
);

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <>
      <DeploymentsStatusBanner />
      <AppBar {...props} userMenu={<CustomUserMenu />} className={classes.appBar}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <img height="24" src="/img/talentdesk_logo-white.svg" alt="TalentDesk.io" />
        <span className={classes.spacer} />
      </AppBar>
    </>
  );
};

export default CustomAppBar;
