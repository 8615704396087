import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import { List as BankAccountList } from 'admin/assets/js/resources/bank_accounts.jsx';
import SystemBankAccountList from 'admin/assets/js/resources/system_bank_accounts/list.jsx';
import { BANK_ACCOUNT_TABS, BANK_ACCOUNT_TABS_LABEL } from 'admin/assets/js/constants';

const CustomBankAccountsView = (props) => {
  const { selectedTab } = props;
  const history = useHistory();

  const handleChange = useCallback((event, newValue) => {
    history.push(newValue);
  }, [history]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
      >
        { Object.keys(BANK_ACCOUNT_TABS).map(key => (
          <Tab
            key={BANK_ACCOUNT_TABS[key]}
            value={BANK_ACCOUNT_TABS[key]}
            label={BANK_ACCOUNT_TABS_LABEL[BANK_ACCOUNT_TABS[key]]}
          />
        ))}
      </Tabs>
      { selectedTab === BANK_ACCOUNT_TABS.ALL && (
        <BankAccountList
          resource="bank_accounts"
          basePath="/bank_accounts"
        />
      )}

      { selectedTab === BANK_ACCOUNT_TABS.SYSTEM && (
        <SystemBankAccountList
          resource="system_bank_accounts"
          basePath="/system_bank_accounts"

        />
      )}
    </>
  );
};

CustomBankAccountsView.propTypes = {
  selectedTab: PropTypes.string,
};

CustomBankAccountsView.defaultProps = {
  selectedTab: BANK_ACCOUNT_TABS.ALL,
};

export default CustomBankAccountsView;
