import React from 'react';
import { Layout as AdminLayout, Sidebar } from 'react-admin';
import AppBar from './AppBar.jsx';
import Menu from './Menu.jsx';
import { lightTheme } from './themes';

const CustomSidebar = props => <Sidebar {...props} size={200} />;

const Layout = (props) => {
  return (
    <AdminLayout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      theme={lightTheme}
      menu={Menu}
    />
  );
};

export default Layout;
