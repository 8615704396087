import React from 'react';
import {
  Datagrid, FunctionField,
  List,
  TextField,
} from 'react-admin';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import PricingScaleStatusField from 'admin/assets/js/resources/pricing_scales/PricingScaleStatusField.jsx';
import PricingScaleSchemeField from 'admin/assets/js/resources/pricing_scales/PricingScaleSchemeField.jsx';
import { formatDate } from 'admin/assets/js/lib/utils';
import PricingScaleListFilter from 'admin/assets/js/resources/pricing_scales/PricingScaleListFilter.jsx';
import PricingScaleActions from 'admin/assets/js/resources/pricing_scales/PricingScaleActions.jsx';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { PRICING_SCALES_ALLOWED_FOR_DEFAULT } from 'finance/assets/js/constants';

const PricingScaleList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<PricingScaleActions />}
      filters={<PricingScaleListFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid>
        <TextField
          label="Version"
          source="version"
        />
        <PricingScaleStatusField label="Status" />
        <PricingScaleSchemeField label="Scale" />
        <FunctionField
          source="created_at"
          label="Created at"
          render={record => formatDate(record.created_at)}
        />
        <FunctionField
          source="activated_at"
          label="Activated at"
          render={record => formatDate(record.activated_at)}
        />
        <FunctionField
          source="deactivated_at"
          label="Deactivated at"
          render={record => formatDate(record.dectivated_at)}
        />
        <FunctionField
          render={({ id, version, status }) => {
            if (!PRICING_SCALES_ALLOWED_FOR_DEFAULT.includes(status)) {
              return null;
            }
            return (
              <BulkApiActionButton
                label="Set as default"
                title="Set as default"
                confirmLabel="set as default"
                content={(
                  <div>
                    <div><strong>Proceed with caution!</strong></div>
                    <div>
                      Setting a pricing scale as the Default one means that
                      every new organization created will use this
                      pricing scale as their pricing scale.
                    </div>
                    <div>
                      The current Default pricing scale will be set as Activated and
                      will no longer be used when creating an organization.
                    </div>
                    <br />
                    <div>{`Are you sure you want to set pricing scale version ${version} as the default one?`}</div>
                  </div>
                )}
                ids={id}
                icon={<TouchAppIcon />}
                resource="pricing_scales"
                action="set-as-default"
              />
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default PricingScaleList;
