import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField, ReferenceField, Link } from 'react-admin';

const TransactionAttemptTitle = ({ record, label }) => {
  if (!record) {
    return null;
  }
  return (
    <ReferenceField
      source="invoice_id"
      reference="invoices"
      link={false}
      label={label}
    >
      <FunctionField
        render={inv => (
          <>
            <Link style={{ whiteSpace: 'nowrap' }} to={`/invoices/${inv.id}`}>{inv.uniqueNumber}</Link>
            <br />
            <small>
              Tx#
              {record.id}
            </small>
          </>
        )}
      />
    </ReferenceField>
  );
};

TransactionAttemptTitle.propTypes = {
  label: PropTypes.node.isRequired,
  record: PropTypes.object,
};

TransactionAttemptTitle.defaultProps = {
  record: null,
};

export default TransactionAttemptTitle;
