import React from 'react';
import {
  List, Datagrid,
  TextField,
  ChipField,
  SimpleShowLayout,
  Show,
  ReferenceManyField,
  SingleFieldList,
} from 'react-admin';
import PreformattedField from 'admin/assets/js/components/fields/PreformattedField.jsx';


const TransferwiseRecipientDetails = props => (
  <SimpleShowLayout {...props}>
    <PreformattedField source="details_dump" />
  </SimpleShowLayout>
);

const TransferwiseRecipientShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceManyField label="Bank Account" target="dim_transferwise_recipient_id" reference="bank_accounts" link="show">
        <SingleFieldList linkType="show">
          <ChipField source="beneficiary" />
        </SingleFieldList>
      </ReferenceManyField>
      <PreformattedField source="details_dump" />
    </SimpleShowLayout>
  </Show>
);

const TransferwiseRecipientList = props => (
  <List
    {...props}
    bulkActionButtons={false}
  >
    <Datagrid
      expand={<TransferwiseRecipientDetails />}
    >
      <TextField source="id" />
      <ReferenceManyField label="Bank Account" target="dim_transferwise_recipient_id" reference="bank_accounts" link="show">
        <SingleFieldList linkType="show">
          <ChipField source="beneficiary" />
        </SingleFieldList>
      </ReferenceManyField>
    </Datagrid>
  </List>
);

export {
  TransferwiseRecipientList as List,
  TransferwiseRecipientShow as Show,
};
