import React from 'react';

import CreateInvoicingEntityButton from 'admin/assets/js/resources/invoicing_entities/CreateInvoicingEntityButton.jsx';

const InvoicingEntityActions = props => (
  <div>
    <CreateInvoicingEntityButton {...props} />
  </div>
);

export default InvoicingEntityActions;
