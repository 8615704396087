import React, { useCallback } from 'react';
import {
  Datagrid,
  downloadCSV,
  FunctionField,
  List,
  TextField,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { ORGANIZATION_STATUS, ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';
import LicenceListFilter from 'admin/assets/js/resources/licences/LicenceListFilter.jsx';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';
import LicenceFeesCalculator from 'admin/assets/js/components/LicenceFeesCalculator.jsx';

const LicenceList = (props) => {
  // estimate current charge
  const estimateLicenceFee = useCallback((org) => {
    const fee = new InvoicingFeeScheme(org.invoicing_fee_scheme)
      .getLicenceScheme()
      .getFee({ numManagers: org.numManagers, numProviders: org.numProviders });
    return fee;
  }, []);

  // custom export
  const exporter = useCallback((orgs) => {
    const rows = orgs.map((o) => {
      const licenceFee = estimateLicenceFee(o);
      // note, we need to convert numbers to strings to allow 0s to be visible in the csv
      return {
        Id: o.id,
        Name: o.name,
        Currency: o.currency,
        Active: ORGANIZATION_STATUS_LABEL[o.status],
        'Base manager seats': `${o.invoicing_fee_scheme.licence.baseManagerSeats}`,
        'Base manager price': `${o.invoicing_fee_scheme.licence.perManagerSeat}`,
        'Active manager seats': `${o.numManagers}`,
        'Base provider seats': `${o.invoicing_fee_scheme.licence.baseProviderSeats}`,
        'Base provider price': `${o.invoicing_fee_scheme.licence.perProviderSeat}`,
        'Active provider seats': `${o.numProviders}`,
        'Base licence fee': `${o.invoicing_fee_scheme.licence.baseLicenceFee}`,
        'Estimated charges': `${licenceFee}`,
      };
    });

    jsonExport(rows, {
      headers: [
        'Id', 'Name', 'Currency', 'Active', 'Base manager seats', 'Base manager price',
        'Active manager seats', 'Base provider seats', 'Base provider price', 'Active provider seats',
        'Base licence fee', 'Estimated charges',
      ],
    }, (err, csv) => {
      downloadCSV(csv, 'licence-fees');
    });
  }, []);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'ASC' }}
      filters={<LicenceListFilter />}
      filterDefaultValues={{ status: ORGANIZATION_STATUS.ACTIVE }}
      exporter={exporter}
    >
      <Datagrid>
        <TextField label="Id" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Currency" source="currency" />
        <FunctionField
          label="Active"
          render={org => ORGANIZATION_STATUS_LABEL[org.status]}
        />
        <TextField
          label="Base manager seats"
          source="invoicing_fee_scheme.licence.baseManagerSeats"
          sortable={false}
        />
        <TextField
          label="Base manager price"
          source="invoicing_fee_scheme.licence.perManagerSeat"
          sortable={false}
        />
        <TextField
          label="Active manager seats"
          source="numManagers"
          sortable={false}
        />
        <TextField
          label="Base provider seats"
          source="invoicing_fee_scheme.licence.baseProviderSeats"
          sortable={false}
        />
        <TextField
          label="Base provider price"
          source="invoicing_fee_scheme.licence.perProviderSeat"
          sortable={false}
        />
        <TextField
          label="Active provider seats"
          source="numProviders"
          sortable={false}
        />
        <TextField
          label="Base licence fee"
          source="invoicing_fee_scheme.licence.baseLicenceFee"
          sortable={false}
        />
        <FunctionField
          label="Estimated charge"
          render={({ ...org }) => {
            const amount = estimateLicenceFee(org);
            return (<>{amount}</>);
          }}
        />
        <FunctionField
          label="Fee calculator"
          render={({ ...org }) => {
            const scheme = new InvoicingFeeScheme(org.invoicing_fee_scheme);
            const licenceFeeScheme = scheme.getLicenceFeeSpec();
            const parsedLicenceFeeScheme = new LicenceFeeScheme(licenceFeeScheme);
            const showLicenceCalculator = parsedLicenceFeeScheme.hasFee();
            const numManagers = org.numManagers;
            const numProviders = org.numProviders;

            return (
              <LicenceFeesCalculator
                showCalculatorIcon={showLicenceCalculator}
                feeScheme={licenceFeeScheme}
                numManagers={numManagers}
                numProviders={numProviders}
              />
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default LicenceList;
