import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-admin';

import BulkApiActionButton from './BulkApiActionButton.jsx';
import { USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS, USER_STATUS } from 'core/assets/js/constants';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';

const DeleteUserDataButton = ({ resource, record }) => {
  const [modalOpen, setModalOpen] = useState(false);

  if (!record) {
    return null;
  }
  if (record.outstandingCounts && Object.keys(record.outstandingCounts).length) {
    return (
      <>
        <ConfirmModal
          confirm="Ok"
          content={(
            <>
              <p>
                You cannot Delete data for this user, because they have outstanding items in the
                following organizations:
              </p>
              <ul>
                {Object.keys(record.outstandingCounts).map(orgName => (
                  <li key={orgName}>
                    {orgName}
                    <ul>
                      {Object.keys(record.outstandingCounts[orgName]).map(key => {
                        const count = record.outstandingCounts[orgName][key];
                        if (count === 0) {
                          return null;
                        }
                        const name = USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS[key];
                        return <li key={key}>{`${name} (${count})`}</li>;
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          )}
          onClose={() => setModalOpen(false)}
          onConfirm={() => setModalOpen(false)}
          open={modalOpen}
          title="Cannot delete user data"
        />
        <Button label="Delete User Data" onClick={() => setModalOpen(true)} />
      </>
    );
  }
  const isDeleted = record.status === USER_STATUS.DELETED;
  return (
    <BulkApiActionButton
      disabled={isDeleted}
      ids={[record.id]}
      resource={resource}
      action="delete-user-data"
      label="Delete User Data"
      title="Delete User Data"
      content="Are you sure you want to delete the data of this user?"
    />
  );
};

DeleteUserDataButton.propTypes = {
  resource: PropTypes.string,
  record: PropTypes.object,
};

DeleteUserDataButton.defaultProps = {
  resource: null,
  record: null,
};

export default DeleteUserDataButton;
