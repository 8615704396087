import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';

import { RUNNING_INVOICING_STATUS_LABEL, RUNNING_INVOICING_STATUS_COLOR } from 'admin/assets/js/constants';

const useStyles = makeStyles({
  chip: {
    color: 'white',
  },
});

const InvoicingStatus = ({ status }) => {
  const classes = useStyles();
  return (
    <Chip
      label={RUNNING_INVOICING_STATUS_LABEL[status]}
      size="small"
      className={classes.chip}
      style={{ backgroundColor: RUNNING_INVOICING_STATUS_COLOR[status]  }}
    />
  );
};

InvoicingStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default InvoicingStatus;
