import React, { Fragment, useState } from 'react';

import { Button, Create } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PricingScaleForm from 'admin/assets/js/resources/pricing_scales/PricingScaleForm.jsx';
import { PRICING_SCALE_STATUS, PRICING_SCALE_STATUS_LABEL } from 'finance/assets/js/constants';
import PropTypes from 'prop-types';

const CreatePricingScaleButton = ({ basePath }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const title = 'Create a new pricing scale';

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        title="Create pricing scale"
        label="Create pricing scale"
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`
            The new pricing scale that will be created will have a status of
            ${PRICING_SCALE_STATUS_LABEL[PRICING_SCALE_STATUS.PREACTIVATED]}.
            ${PRICING_SCALE_STATUS_LABEL[PRICING_SCALE_STATUS.PREACTIVATED]}
            pricing scales are not used when creating a new organization.
            To use a pricing scale as the ${PRICING_SCALE_STATUS_LABEL[PRICING_SCALE_STATUS.DEFAULT]} for all new organizations
            you'll need to set it as ${PRICING_SCALE_STATUS_LABEL[PRICING_SCALE_STATUS.DEFAULT]}
            through the respective button in the pricing scale listing. 
            `}
          </DialogContentText>
          <Create
            resource="pricing_scales"
            basePath={basePath}
          >
            <PricingScaleForm
              onDialogClose={handleDialogClose}
            />
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

CreatePricingScaleButton.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default CreatePricingScaleButton;
