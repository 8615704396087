import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  downloadCSV,
  DateField,
  Datagrid,
  FunctionField,
  Link,
  List,
  TextField,
  NumberField,
} from 'react-admin';
import { Icon, Typography } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

import CurrencyAmountField from 'admin/assets/js/resources/org_billing_analytics/CurrencyAmountField.jsx';
import { INVOICE_STATUS_STYLES, ORG_BILLING_ANALYTICS_CURRENCIES } from 'admin/assets/js/constants';
import { INVOICE_STATUS_LABEL, INVOICE_TYPE_LABEL, TRANSACTION_METHOD_LABEL } from 'finance/assets/js/constants';
import ListFilters from 'admin/assets/js/resources/org_billing_invoices_analytics/components/ListFilters.jsx';

/**
 * List view of invoices in the billing process for the given org.
 *
 * Each row represents a single invoice from the billing process.
 *
 * Links are provided to the HTML invoice, the invoice details page in RC and the transction
 * page in RC ( when the invoice is paid ).
 *
 * This contains both static and dynamic filters.  The dynamic filters are rendered as UI
 * and allow for filtering by either invoice status or invoice type.
 * The static filters are hidden and implied by the URL, these lock in the org and billing
 * process to which the invoices belong.
 *
 * @param {String} analyticsId - compound key containing both billing process and org.
 * @return {JSX.Element} rendered component.
 */
// eslint-disable-next-line arrow-body-style
const OrgBillingInvoicesAnalyticsList = ({ analyticsId, ...props }) => {
  // note - here's what looks like another bug in react-admin
  //        although it passes 'filters' values it doesn't pass 'filter' values
  //        which we need to identify the org and billing process which
  //        the exported invoices belong to :/  therefore export doesn't
  //        know which invoices to export :/
  // custom export
  const exporter = useCallback((list) => {
    const rows = list.map(li => ({
      Number: li.number,
      Amount: li.amount,
      Tax: li.tax,
      Type: INVOICE_TYPE_LABEL[li.type],
      'Raised By': li.raised_by,
      Recipient: li.recipient,
      Status: INVOICE_STATUS_LABEL[li.status],
      Payment: li.transaction_id,
      'Paid On': li.paid_on,
      Fee: li.fee,
    }));

    jsonExport(rows, {
      headers: [
        'Number', 'Amount', 'Tax', 'Type', 'Raised By',
        'Recipient', 'Status', 'Payment', 'Paid On', 'Fee',
      ],
    }, (err, csv) => {
      downloadCSV(csv, 'invoices');
    });
  }, []);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      resource="org_billing_invoices_analytics"
      perPage={10}
      filters={<ListFilters />}
      filter={{
        key: analyticsId,
        currency: ORG_BILLING_ANALYTICS_CURRENCIES.ORG_CURRENCY,
      }}
      exporter={exporter}
    >
      <Datagrid>
        <FunctionField
          label="Number"
          sortBy="number"
          render={record => (
            <Link to={`/invoices/${record.id}`}>
              {record.number}
            </Link>
          )}
        />
        <FunctionField
          label="Type"
          sortBy="calced_type"
          render={record => (
            <Typography>
              {INVOICE_TYPE_LABEL[record.type]}
            </Typography>
          )}
        />
        <TextField label="Raised By" source="raised_by" />
        <TextField label="Recipient" source="recipient" />
        <FunctionField
          label="Status"
          sortBy="status"
          render={record => (
            <Typography
              className="rounded"
              align="center"
              style={{ backgroundColor: INVOICE_STATUS_STYLES[record.status], color: 'white' }}
            >
              {INVOICE_STATUS_LABEL[record.status]}
            </Typography>
          )}
        />
        <DateField label="Paid On" source="paid_on" />

        <FunctionField
          label="Method"
          sortBy="method"
          render={record => TRANSACTION_METHOD_LABEL[record.method]}
        />

        <CurrencyAmountField label="Invoice Total" source="amount" />
        <CurrencyAmountField label="Payment Amount" source="outgoing_amount" />
        <CurrencyAmountField label="Bank Fees" source="fee" />
        <NumberField label="Fee %" source="fee_percent" />

        <FunctionField
          addLabel={false}
          render={record => (
            <Link to={`/invoices/${record.id}/show`}>
              <Icon>visibility</Icon>
            </Link>
          )}
        />
        <FunctionField
          addLabel={false}
          label="Tx"
          render={record => (
            <Link to={`/transactions/${record.transaction_id}/show`}>
              <Icon>attach_money</Icon>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

OrgBillingInvoicesAnalyticsList.propTypes = {
  analyticsId: PropTypes.string.isRequired,
};

export default OrgBillingInvoicesAnalyticsList;
