import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SubscriptionTransactionAddDialog from 'admin/assets/js/resources/subscriptions/SubscriptionTransactionAddDialog.jsx';

const SubscriptionTransactionAddButton = ({ subscription, basePath }) => {
  if (!subscription) {
    return null;
  }
  const [showModal, setShowModal] = useState(false);

  const onOpen = useCallback(() => setShowModal(true), [setShowModal]);
  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <>
      <Button
        color="primary"
        onClick={onOpen}
        startIcon={<AddIcon />}
      >
        Transaction
      </Button>

      {showModal && (
        <SubscriptionTransactionAddDialog
          basePath={basePath}
          onClose={onClose}
          subscription={subscription}
        />
      )}
    </>
  );
};

SubscriptionTransactionAddButton.propTypes = {
  subscription: PropTypes.object,
  basePath: PropTypes.string,
};

SubscriptionTransactionAddButton.defaultProps = {
  subscription: null,
  basePath: null,
};

export default SubscriptionTransactionAddButton;
