import PropTypes from 'prop-types';
import React from 'react';
import { Edit } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import InvoicingEntityForm from 'admin/assets/js/resources/invoicing_entities/InvoicingEntityForm.jsx';

const InvoicingEntityEditDialog = ({ onClose, invoicingEntity, basePath }) => (
  <Dialog onClose={onClose} open={!!invoicingEntity}>
    {invoicingEntity && (
      <>
        <DialogTitle>{`Edit invoicing entity: #${invoicingEntity.id} "${invoicingEntity.name}"`}</DialogTitle>
        <DialogContent>
          <Edit
            basePath={basePath}
            resource="invoicing_entities"
            id={invoicingEntity.id}
            onSuccess={onClose}
            undoable={false}
          >
            <InvoicingEntityForm onDialogClose={onClose} />
          </Edit>
        </DialogContent>
      </>
    )}
  </Dialog>
);

InvoicingEntityEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  invoicingEntity: PropTypes.object.isRequired,
  basePath: PropTypes.string,
};

InvoicingEntityEditDialog.defaultProps = {
  basePath: null,
};

export default InvoicingEntityEditDialog;
