import { ValidationError, ValidationErrorItem } from 'core/assets/js/errors';
import { TRANSACTION_METHOD } from 'finance/assets/js/constants';
import { isValidUUID } from 'finance/assets/js/lib/utils';

/**
 * Worldpay transaction method pojo.
 * Records all details specific to a Worldpay transaction.
 */
class TransactionMethodWorldpay {
  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  /**
   * Initialise instance with initial values.
   * @param {object} param0 - instance values.
   */
  init({
    transferId,
    secondaryTransferId,
  }) {
    const sanitizedTransferId = typeof transferId === 'string'
      ? transferId.trim() : transferId;
    const sanitizedSecondaryTransferId = typeof secondaryTransferId === 'string'
      ? secondaryTransferId.trim() : secondaryTransferId;

    if (!transferId) {
      throw new ValidationError('Transfer id is required', [
        new ValidationErrorItem('transferId', 'Transfer id is required'),
      ]);
    }
    if (!secondaryTransferId) {
      throw new ValidationError('Statement id is required', [
        new ValidationErrorItem('secondaryTransferId', 'Secondary transfer id is required'),
      ]);
    }

    if (!isValidUUID(sanitizedTransferId)) {
      throw new ValidationError('Transfer id is not valid', [
        new ValidationErrorItem('transferId', 'Transfer id is not valid'),
      ]);
    }

    if (!isValidUUID(sanitizedSecondaryTransferId)) {
      throw new ValidationError('Statement id is not valid', [
        new ValidationErrorItem('secondaryTransferId', 'Secondary transfer id is not valid'),
      ]);
    }

    this.details = {
      method: TRANSACTION_METHOD.WORLDPAY,
      transferId: sanitizedTransferId,
      secondaryTransferId: sanitizedSecondaryTransferId,
    };
  }

  /**
   * Serialize instance.
   * @return {object} serialized instance.
   */
  serialize() {
    const res = { ...this.details };
    return res;
  }

  /**
   * Get a transfer descriptor for this transaction method.
   * @return {string} the transfer descriptor.
   */
  getTransactionDescriptor() {
    return `#${this.details.transferId}`;
  }

  /**
   * Get the transaction id.
   * @return {string} the transaction id.
   */
  getTransactionId() {
    return this.details.transferId;
  }
}

export default TransactionMethodWorldpay;
