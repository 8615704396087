import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormLabel } from '@material-ui/core';


export const FileInput = ({
  containerClassName, name, disabled, label, selectedFiles, required, value,
}) => {
  return (
    <FormControl className={containerClassName} component="fieldset" required={required}>
      <FormLabel component="legend">{label}</FormLabel>

      { selectedFiles && (
        <span className="discreet">
          {selectedFiles?.[0]?.name}
        </span>
      )}

      { !selectedFiles && (
        <input
          type="file"
          disabled={disabled}
          value={value}
          name={name}
          required={required}
        />
      )}
    </FormControl>
  );
};

FileInput.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  selectedFiles: PropTypes.array,
  value: PropTypes.any.isRequired,
};

FileInput.defaultProps = {
  containerClassName: null,
  disabled: false,
  required: false,
  selectedFiles: null,
};

export default FileInput;
