import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import DialogCloseButton from 'admin/assets/js/components/buttons/DialogCloseButton.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { adminApiAMLExpiredUpdateUrl } from 'admin/urls';
import { WINDOW_RELOAD } from 'core/assets/js/config/settings';

const AMLRecordActions = ({ amlCheck }) => {
  const [showSentData, setShowData] = useState(false);
  const [showResultData, setShowResultData] = useState(false);

  const notifications = getNotifications();

  const { sent_payload: sentPayload, smart_search_complete_payload: completePayload } = amlCheck;

  return (
    <>
      <div className="d-flex text-nowrap">
        {!!sentPayload && (
          <Button
            onClick={() => {
              setShowData(true);
            }}
          >
            VIEW SENT DATA
          </Button>
        )}
        {!!completePayload && (
          <Button
            className="ml-4"
            onClick={() => {
              setShowResultData(true);
            }}
          >
            VIEW REPORT
          </Button>
        )}
        {!!amlCheck.expired_at && (
          <Button
            onClick={async () => {
              try {
                await apiClient({
                  method: 'POST', url: adminApiAMLExpiredUpdateUrl(amlCheck.id),
                });
                notifications.success('AML check updated');
                WINDOW_RELOAD();
              } catch (e) {
                notifications.error(e.response?.data?._error || e.message);
              }
            }}
          >
            UPDATE
          </Button>
        )}
      </div>
      <Dialog onClose={() => setShowData(false)} open={showSentData}>
        <DialogTitle className="d-flex">
          Identity check sent data
          <DialogCloseButton onClose={() => setShowData(false)} />
        </DialogTitle>
        <DialogContent><pre>{JSON.stringify(sentPayload, null, 2)}</pre></DialogContent>
      </Dialog>
      <Dialog onClose={() => setShowResultData(null)} open={showResultData}>
        <DialogTitle className="d-flex">
          AML report
          <DialogCloseButton onClose={() => setShowResultData(null)} />
        </DialogTitle>
        <DialogContent>
          {completePayload && (
            <>
              <h3>Scan result</h3>
              <Table className="mb-5">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>First name</TableCell>
                    <TableCell>{completePayload.data?.attributes?.extracted?.name_first}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last name</TableCell>
                    <TableCell>{completePayload.data?.attributes?.extracted?.name_last}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date of birth</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.extracted?.date_of_birth}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gender</TableCell>
                    <TableCell>{completePayload.data?.attributes?.extracted?.gender}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>{completePayload.data?.attributes?.extracted?.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Document type</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.extracted?.document_type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Document number</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.extracted?.document_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Document expiry</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.extracted?.document_expiry}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <h3>Document checks</h3>
              <Table className="mb-5">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Document expiry</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.document_expiry}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Document number</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.document_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Document country</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.document_country}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date of birth</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.comparison_dob}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Surname</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.comparison_surname}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Given names</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.comparison_givennames}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gender</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.gender}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <h3>Warnings</h3>
              <Table className="mb-5">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Amberhill check</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.checks?.amberhill}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Worldwide sanctions</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.due_diligence?.worldwide_sanctions}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Politically exposed person</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.due_diligence?.politically_exposed_person}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Special interest person</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.due_diligence?.special_interest_person}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Relative/close associate</TableCell>
                    <TableCell>
                      {completePayload.data?.attributes?.due_diligence?.relative_close_associate}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <h3>Full result</h3>
              <pre>{JSON.stringify(completePayload, null, 2)}</pre>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

AMLRecordActions.propTypes = {
  amlCheck: PropTypes.object.isRequired,
};

export default AMLRecordActions;
