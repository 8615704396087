import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput, { getCountries } from 'react-phone-number-input';
import { FormControl, FormLabel } from '@material-ui/core';

export const COUNTRIES = getCountries();

export const PhoneNumberInput = ({
  containerClassName, defaultCountry, disabled, label, onChange, required, value,
}) => (
  <FormControl className={containerClassName} component="fieldset" required={required}>
    <FormLabel component="legend">{label}</FormLabel>
    <PhoneInput
      addInternationalOption={false}
      defaultCountry={defaultCountry}
      disabled={disabled}
      international
      onChange={newValue => {
        onChange({ target: { value: newValue } });
      }}
      value={value}
      withCountryCallingCode
    />
  </FormControl>
);

PhoneNumberInput.propTypes = {
  containerClassName: PropTypes.string,
  defaultCountry: PropTypes.oneOf(COUNTRIES),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

PhoneNumberInput.defaultProps = {
  containerClassName: null,
  defaultCountry: 'US',
  disabled: false,
  error: null,
  helperText: null,
  required: false,
};

export default PhoneNumberInput;
