import PropTypes from 'prop-types';
import React from 'react';

import RadioGroupInput from 'admin/assets/js/components/inputs/RadioGroupInput.jsx';

export const YesNoInput = ({ onChange, ...rest }) => (
  <RadioGroupInput
    onChange={event => {
      const value = event.target.value === 'true';
      onChange({ target: { value } });
    }}
    options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
    {...rest}
  />
);

YesNoInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default YesNoInput;
