import { SETTINGS } from 'admin/assets/js/constants';

export const RULES = {
  [SETTINGS.SUBSCRIPTION_EXPIRATION_REMINDER]: ['isString', 'isInteger', 'isPositive'],
  [SETTINGS.TRIAL_PERIOD_EXPIRATION_REMINDER]: ['isString', 'isInteger', 'isPositive'],
  [SETTINGS.TRIAL_PERIOD_LENGTH]: ['isString', 'isInteger', 'isPositive'],
  [SETTINGS.AUDIT_LICENCE_TOLERANCE]: ['isString', 'isInteger', 'isPositive', 'isPercentage'],
  [SETTINGS.INVOICING_TAX_METHOD]: ['isInteger'],
  [SETTINGS.USE_WISE_OAUTH]: ['isInteger'],
  [SETTINGS.DB_PROFILING]: ['isInteger'],
  [SETTINGS.ENABLE_DEFERRED_PAYMENTS_FEATURE]: ['isInteger'],
};

const VALIDATORS = {
  // All settings are submitted and stored as strings
  isString: {
    action: value => typeof value === 'string',
    error: 'The value is not a string',
  },
  isInteger: {
    action: value => /^\d+$/.test(value),
    error: 'The value is not an integer',
  },
  isPositive: {
    action: value => value > 0,
    error: 'The value is not a positive number',
  },
  isPercentage: {
    action: value => value >= 1 && value <= 100,
    error: 'This value is a percentage, it must be between 1 and 100',
  },
};

/**
 * Validates a settings value
 *
 * @param {String} name
 * @param {String} value
 * @returns {true}
 */
export const validate = (name, value) => {
  const rules = RULES[name];
  if (!rules) {
    throw new Error(`No validation rules specified for ${name}`);
  }
  rules.forEach((ruleName) => {
    const validator = VALIDATORS[ruleName];
    if (!validator) {
      throw new Error(`No validator specified for ${ruleName}`);
    }
    if (!validator.action(value)) {
      throw new Error(validator.error);
    }
  });
  return true;
};
