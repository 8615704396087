import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Button } from '@material-ui/core';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import { ORGANIZATION_FEATURE_USAGE_TABS } from 'admin/assets/js/constants';
import { ICON } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';

const tab = ORGANIZATION_FEATURE_USAGE_TABS['1099_FILINGS'];

const succeededValueToLabel = value => (value ? 'Yes' : 'No');

const US1099FilingsTab = ({ organization }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { year: selectedYear } = qs.parse(search);

  if (selectedYear) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-left">
          <BackButton />
          <h2 className="m-0">{selectedYear}</h2>
        </div>
        <ApiTable
          columns={[
            {
              label: 'Contractor name',
              name: 'contractor_name',
              options: {
                cellRender: (contractorName, { user_id: userId }) => {
                  if (!userId) {
                    return contractorName;
                  }
                  return (
                    <Link to={`/full_users/${userId}/show`}>
                      {contractorName}
                    </Link>
                  );
                },
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Created',
              name: 'created_at',
              options: {
                cellRender: createdAt => formatDate(createdAt),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Succeeded',
              name: 'succeeded',
              options: {
                cellRender: succeeded => <i class={succeeded ? ICON.CHECKMARK : ICON.CROSS} />,
                customFilterListOptions: {
                  render: value => `Succeeded: ${succeededValueToLabel(value)}`,
                },
                filter: true,
                filterOptions: { names: [0, 1], renderValue: succeededValueToLabel },
                sort: true,
              },
            },
          ]}
          defaultSortBy="created_at"
          defaultSortDirection="DESC"
          extraResourceQueryParams={{ year: selectedYear, organization_id: organization.id, tab }}
          resource="tax_1099_filing_requests"
        />
      </>
    );
  }

  return (
    <ApiTable
      columns={[
        {
          label: 'Year',
          name: 'year',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Submitted',
          name: 'submitted',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Succeeded',
          name: 'succeeded',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Details',
          name: 'year',
          options: {
            cellRender: year => (
              <Button
                color="primary"
                onClick={() => {
                  history.push(`${pathname}?year=${year}&tab=${tab}`);
                }}
              >
                View
              </Button>
            ),
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="year"
      defaultSortDirection="DESC"
      excludeColumnsFromDownload={['Details']}
      extraResourceQueryParams={{ organization_id: organization.id, tab }}
      resource="tax_1099_filing_requests"
      searchEnabled={false}
    />
  );
};

US1099FilingsTab.propTypes = {
  organization: PropTypes.object,
};

US1099FilingsTab.defaultProps = {
  organization: {},
};

export default withRouter(US1099FilingsTab);
