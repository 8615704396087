import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const PayerList = props => (
  <List {...props}>
    <Datagrid>
      <TextField label="id" source="id" />
      <TextField label="name" source="name" />
      <TextField label="account" source="account" />
    </Datagrid>
  </List>
);

export {
  PayerList as List, //eslint-disable-line
};
