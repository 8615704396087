import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import {
  List, Datagrid, Filter,
  ReferenceField, TextField, FunctionField,
  ReferenceInput, SelectInput,
  TextInput,
} from 'react-admin';
import { formatDate } from 'admin/assets/js/lib/utils';
import Icon from '@material-ui/core/Icon';
import { STATUS, TYPE } from 'invitations/assets/js/constants';

const typeOptions = Object.entries(TYPE).map(([k, v]) => ({
  id: v, name: k.split('_').map(l => startCase(l.toLowerCase())).join(' '),
}));

const statusOptions = Object.entries(STATUS).map(([k, v]) => ({
  id: v, name: k.split('_').map(l => startCase(l.toLowerCase())).join(' '),
}));

const toLabel = type => typeOptions.find(o => o.id === type).name;

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput alwaysOn allowEmpty label="Status" source="status" choices={statusOptions} />
    <SelectInput alwaysOn allowEmpty label="Type" source="type" choices={typeOptions} />
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText={org => `${org.name} (${org.unique_alias})`} />
    </ReferenceInput>

  </Filter>
);


const TypeField = ({ record }) => (
  <span>
    {record.type && (
      <span>{toLabel(record.type)}</span>
    )}
  </span>
);

TypeField.propTypes = {
  record: PropTypes.object,
};

TypeField.defaultProps = {
  record: {},
};


const StatusField = ({ record }) => {
  switch (record.status) {
    case STATUS.PENDING:
      return <Icon>notification_important</Icon>;
    case STATUS.ACCEPTED:
      return <Icon>check</Icon>;
    case STATUS.REJECTED:
      return <Icon>thumb_down</Icon>;
    case STATUS.USED:
      return <Icon>check</Icon>;
    case STATUS.CANCELLED:
      return <Icon>cancel_presentation</Icon>;
    default:
      break;
  }
  return null;
};

StatusField.propTypes = {
  record: PropTypes.object,
};

StatusField.defaultProps = {
  record: {},
};

const InvitationList = props => (
  <List
    {...props}
    filters={<ListFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid>
      <StatusField />
      <TextField label="Sent to" source="sent_to" />
      <TypeField label="Type" />
      <ReferenceField
        allowEmpty
        label="Organization"
        source="organization_id"
        reference="organizations_lite"
        link={false}
      >
        <TextField className="text-muted" source="name" />
      </ReferenceField>
      <FunctionField
        label="Created at"
        render={record => formatDate(record.created_at)}
        source="created_at"
      />
    </Datagrid>
  </List>
);

export {
  InvitationList as List, //eslint-disable-line
};
