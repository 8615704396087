import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core';

import BackButton from 'admin/assets/js/components/BackButton.jsx';

const useStyles = makeStyles({
  heading: {
    fontWeight: 400,
    paddingLeft: 0,
  },
});

const CashoutRequestViewHeader = ({ cashoutRequestId }) => {
  const classes = useStyles();
  return (
    <div className="d-flex align-items-center">
      <BackButton />
      <h1 className={classes.heading}>
        {cashoutRequestId}
      </h1>
    </div>
  );
};

CashoutRequestViewHeader.propTypes = {
  cashoutRequestId: PropTypes.number,
};

CashoutRequestViewHeader.defaultProps = {
  cashoutRequestId: null,
};

export default CashoutRequestViewHeader;
