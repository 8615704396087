import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';

import { formatDate } from 'admin/assets/js/lib/utils';
import { USER_ROLE_ICONS } from 'admin/assets/js/constants';
import { USER_CARD_STATUS_LABEL } from 'core/assets/js/constants';
import { SUBMISSION_STATUS_LABEL } from 'interviews/assets/js/constants';
import { useStyles } from 'admin/assets/js/resources/full_users/UserOrgInfo.jsx';

const UserOrgInfo = ({ userCard }) => {
  const classes = useStyles();

  return (
    <Paper style={{ padding: '15px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Name</div>
          <Link to={`/organizations/${userCard.organization.id}/show`}>{userCard.organization.name}</Link>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Status</div>
          {USER_CARD_STATUS_LABEL[userCard.status]}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Onboarding submission status</div>
          {userCard.interviewSubmissionStatus === null && '-'}
          {userCard.interviewSubmissionStatus !== null && (
            SUBMISSION_STATUS_LABEL[userCard.interviewSubmissionStatus]
          )}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Joined on</div>
          {formatDate(userCard.joinedAt)}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Role</div>
          <i style={{ color: '#3f51b5', display: 'inline', marginRight: '10px' }} className={USER_ROLE_ICONS[userCard.userRole.ofType]} />
          {userCard.userRole.title}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Employment type</div>
          {userCard.isEmployee ? 'Employee' : 'Contractor'}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelStyle}>Organization email</div>
          {userCard.email}
        </Grid>
      </Grid>
    </Paper>
  );
};

UserOrgInfo.propTypes = {
  userCard: PropTypes.object,
};

UserOrgInfo.defaultProps = {
  userCard: {},
};

export default UserOrgInfo;
