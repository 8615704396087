import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { TopToolbar, useNotify, useRefresh, useUpdate } from 'react-admin';

import BackButton from 'admin/assets/js/components/BackButton.jsx';
import SplitButton from 'admin/assets/js/components/buttons/SplitButton.jsx';
import { SYSTEM_BANK_ACCOUNT_STATUS } from 'finance/assets/js/constants';

const SystemBankAccountShowActions = ({ resource, record }) => {
  const { status, id } = record;
  const [update] = useUpdate(resource, id);
  const refresh = useRefresh();
  const notify = useNotify();

  const options = useMemo(() => ([
    { label: 'Archive',
      main: true,
      key: 'archive',
      disabled: status === SYSTEM_BANK_ACCOUNT_STATUS.ARCHIVED,
      onHandleClick: () => {
        update({ payload: { data: { status: SYSTEM_BANK_ACCOUNT_STATUS.ARCHIVED } } }, {
          onSuccess: () => {
            notify('System bank account updated');
            refresh();
          },
        });
      },
    },
  ]), [status, notify, refresh]);
  return (
    <TopToolbar
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 0,
      }}
    >
      <div className="d-flex align-items-center">
        <BackButton />
        <h1 className="my-0">
          {record.id}
        </h1>
      </div>
      <SplitButton options={options} />
    </TopToolbar>
  );
};

SystemBankAccountShowActions.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
};

SystemBankAccountShowActions.defaultProps = {
  resource: null,
  record: {},
};

export default SystemBankAccountShowActions;
