import { ValidationError, ValidationErrorItem } from 'core/assets/js/errors';
import { TRANSACTION_METHOD } from 'finance/assets/js/constants';

/**
 * Transferwise transaction method pojo.
 * Records all details specific to a Transferwise transaction.
 */
class TransactionMethodTransferwise {
  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  /**
   * Initialise instance with initial values.
   * @param {object} param0 - instance values.
   */
  init({
    transferId,
  }) {
    const sanitizedTransferId = typeof transferId === 'string'
      ? transferId.trim() : transferId;

    if (!transferId) {
      throw new ValidationError('Transfer id is required', [
        new ValidationErrorItem('transferId', 'Transfer id is required'),
      ]);
    }

    if (!/^\d{8,10}$/.test(sanitizedTransferId)) {
      throw new ValidationError('Transfer id is not valid', [
        new ValidationErrorItem('transferId', 'Transfer id is not valid'),
      ]);
    }

    this.details = {
      method: TRANSACTION_METHOD.TRANSFERWISE,
      transferId: sanitizedTransferId,
    };
  }

  /**
   * Serialize instance.
   * @return {object} serialized instance.
   */
  serialize() {
    const res = { ...this.details };
    return res;
  }

  /**
   * Get a transfer descriptor for this transaction method.
   * @return {string} the transaction descriptor.
   */
  getTransactionDescriptor() {
    return `#${this.details.transferId}`;
  }

  /**
   * Get the transaction id.
   * @return {string} the transaction id.
   */
  getTransactionId() {
    return this.details.transferId;
  }
}

export default TransactionMethodTransferwise;
