import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';

import { SUBSCRIPTION_PLAN_LABELS, TRANSACTION_MODE } from 'finance/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';

const TRANSACTION_MODE_LABEL = {
  [TRANSACTION_MODE.FIXED_BALANCE]: 'Exact Service Order Amount',
  [TRANSACTION_MODE.FIXED_INVOICE]: 'Exact Invoice Amount',
  [TRANSACTION_MODE.FIXED_RECIPIENT_BANK]: 'Exact Invoice Amount (in Bank currency)',
  [TRANSACTION_MODE.FIXED_ORG_PAYMENT]: 'Exact Service Order Amount (in Org currency)',
  [TRANSACTION_MODE.FIXED_SO_AMOUNT]: 'Exact Service Order Amount (in Org currency)',
};
const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
    fontWeight: 600,
  },
}));
const InvoicingCard = ({ organization }) => {
  const classes = useStyles();
  if (!organization) {
    return null;
  }
  const {
    currency,
    invoicing_system_currency,
    invoicing_target_currency,
    invoicing_balance_currency,
    invoicing_mode,
    invoicing_frequency_dump,
    invoicing_fx_markup,
    invoicing_transaction_mode,
  } = organization;

  const plan = organization.subscription?.plan;

  const invoicingFrequencyPOJO = InvoicingFrequency.fromDump(invoicing_frequency_dump);


  return (
    <Card variant="outlined">
      <CardHeader title="Invoicing" />
      <CardContent sx={{ m: 0, p: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Subscription
            </Typography>
            {plan && (
              <Typography className={classes.fieldValue}>
                {SUBSCRIPTION_PLAN_LABELS[plan]}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Invoicing Mode
            </Typography>
            <Typography
              className={classes.fieldValue}
              style={{ textTransform: 'capitalize' }}
            >
              {invoicing_mode}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Frequency
            </Typography>
            <Typography
              className={classes.fieldValue}
              style={{ textTransform: 'capitalize' }}
            >
              {invoicingFrequencyPOJO.toHumanizedString()}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Organization Currency
            </Typography>
            <Typography
              style={{ textTransform: 'uppercase' }}
              className={classes.fieldValue}
            >
              {currency}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Invoicing Currency
            </Typography>
            <Typography
              style={{ textTransform: 'uppercase' }}
              className={classes.fieldValue}
            >
              {invoicing_target_currency}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Bank Account Currency
            </Typography>
            <Typography
              style={{ textTransform: 'uppercase' }}
              className={classes.fieldValue}
            >
              {invoicing_system_currency}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Balance Currency
            </Typography>
            <Typography
              style={{ textTransform: 'uppercase' }}
              className={classes.fieldValue}
            >
              {invoicing_balance_currency}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              Contractor Payment Mode
            </Typography>
            <Typography
              className={classes.fieldValue}
            >
              {TRANSACTION_MODE_LABEL[invoicing_transaction_mode]}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography>
              FX Margin
            </Typography>
            <Typography
              className={classes.fieldValue}
            >
              <NumberTpl
                value={invoicing_fx_markup}
                suffix="%"
                decimals={2}
              />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

InvoicingCard.propTypes = {
  organization: PropTypes.shape({
    invoicing_mode: PropTypes.string.isRequired,
    invoicing_frequency_dump: PropTypes.string.isRequired,
    invoicing_transaction_mode: PropTypes.number.isRequired,
    invoicing_fx_markup: PropTypes.string.isRequired,
    invoicing_system_currency: PropTypes.string.isRequired,
    invoicing_target_currency: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    invoicing_balance_currency: PropTypes.string.isRequired,
    subscription: PropTypes.shape({
      plan: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default InvoicingCard;
