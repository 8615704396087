import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogTitle, DialogContent,
} from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';

import AorContractUploadForm from 'admin/assets/js/components/AorContractUploadForm.jsx';

const ContractUploadButton = ({ userCard, onSuccess, disabled }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id: userCardId } = userCard;

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleHideDialog}
      >
        <DialogTitle>
          <span>
            Upload contract
          </span>
        </DialogTitle>

        <DialogContent>
          { loading && (
            <div style={{ width: '100%', textAlign: 'center', margin: '40px 0' }}>
              Uploading...
            </div>
          )}
          { !loading && (
            <AorContractUploadForm
              userCardId={userCardId}
              loading={loading}
              onCancel={handleHideDialog}
              setLoading={setLoading}
              onSuccess={() => {
                handleHideDialog();
                onSuccess();
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Button
        className="ml-3 text-primary mt-n1"
        color="primary"
        component="label"
        onClick={handleShowDialog}
        disabled={loading || disabled}
        startIcon={<CloudUpload />}
        variant=""
      >
        Upload contract
      </Button>
    </>
  );
};

ContractUploadButton.propTypes = {
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  userCard: PropTypes.object,
};

ContractUploadButton.defaultProps = {
  disabled: false,
  onSuccess: () => {},
  userCard: {},
};


export default ContractUploadButton;
