import { omit } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, Card, CardContent, CardHeader, Icon, IconButton, Slider, TextField,
} from '@material-ui/core';

import BackButton from 'admin/assets/js/components/BackButton.jsx';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import ClassificationQuestionnairePreview from 'admin/assets/js/components/ClassificationQuestionnairePreview.jsx';
import { CLASSIFICATION_QUESTIONNAIRE_INTRO, CLASSIFICATION_TABS } from 'admin/assets/js/constants';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import {
  calculateClassificationQuestionnaireScoreBinding,
} from 'admin/assets/js/lib/utils-non-react';
import { adminApiCreateNewVersionClassificationQuestionnaireUrl } from 'admin/urls';

const ClassificationForm = ({ initialValues, version }) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [scoreBindingModalIsOpen, setScoreBindingModalIsOpen] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const notifications = getNotifications();
  const history = useHistory();

  const questionsLength = values.questions.length;
  const {
    min: scoreMinimum, max: scoreMaximum,
  } = calculateClassificationQuestionnaireScoreBinding(values.questions);
  const showScoreBinding = scoreMinimum !== null && scoreMaximum !== null;

  const onChangeQuestion = (questionIndex, propertyName) => event => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues.questions[questionIndex][propertyName] = event.target.value;
    setValues(newValues);
    setErrors({});
  };
  const moveQuestion = (currentIndex, newIndex) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const { questions } = newValues;
    questions.splice(newIndex, 0, questions.splice(currentIndex, 1)[0]);
    setValues(newValues);
  };
  const calculateDefaultThresholds = questions => {
    const binding = calculateClassificationQuestionnaireScoreBinding(questions);
    if (binding.min === null || binding.max === null) {
      return {};
    }
    const diff = binding.max - binding.min;
    const diffThird = Math.floor(diff / 3);
    const riskModerateThreshold = binding.max - diffThird;
    const riskHighThreshold = binding.min + diffThird;
    return { riskHighThreshold, riskModerateThreshold };
  };
  const onChangeChoice = (questionIndex, choiceIndex, propertyName) => ({ target: { value } }) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues.questions[questionIndex].choices[choiceIndex][propertyName] = value;
    if (propertyName === 'score') {
      Object.assign(newValues, calculateDefaultThresholds(newValues.questions));
    }
    setValues(newValues);
    setErrors({});
  };
  const moveChoice = (questionIndex, currentIndex, newIndex) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues.questions[questionIndex].choices.splice(
      newIndex,
      0,
      newValues.questions[questionIndex].choices.splice(currentIndex, 1)[0],
    );
    setValues(newValues);
  };

  return (
    <form
      className="d-flex flex-column"
      onSubmit={async event => {
        event.preventDefault();
        const { riskHighThreshold, riskModerateThreshold, questions } = values;
        if (!Array.isArray(questions) || questions.length === 0) {
          notifications.error('Please add at least one question');
        }
        const newErrors = {};
        for (let i = 0; i < questionsLength; i += 1) {
          const question = questions[i];
          if (typeof question.label !== 'string' || question.label.length === 0) {
            newErrors[`questions[${i}].label`] = 'Label is required';
          }
          if (!Array.isArray(question.choices) || question.choices.length < 2) {
            notifications.error(`Please add at least two choices to question ${i + 1}`);
            return;
          }
          question.choices.forEach((choice, choiceIndex) => {
            const prefix = `questions[${i}].choices[${choiceIndex}].`;
            if (typeof choice.label !== 'string' || choice.label.length === 0) {
              newErrors[`${prefix}label`] = 'Label is required';
            }
            if (!/^(-)?\d+$/.test(choice.score)) {
              newErrors[`${prefix}score`] = 'Score must be an integer';
            }
          });
        }
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }
        const data = { riskHighThreshold, riskModerateThreshold, questions: [...questions] };
        data.questions.forEach((question, questionIndex) => {
          question.order = questionIndex + 1; // eslint-disable-line no-param-reassign
          questions[questionIndex].choices.forEach((choice, choiceIndex) => {
            choice.order = choiceIndex + 1; // eslint-disable-line no-param-reassign
            choice.score = parseInt(choice.score, 10); // eslint-disable-line no-param-reassign
          });
        });
        try {
          await apiClient({
            data, method: 'POST', url: adminApiCreateNewVersionClassificationQuestionnaireUrl(),
          });
          history.push(`/classification-questionnaire?tab=${CLASSIFICATION_TABS.QUESTIONNAIRE}`);
        } catch (e) {
          let error = e.response?.data?._error || e.message;
          const errorProperties = omit(e.response?.data, '_error', '_meta');
          const keys = Object.keys(errorProperties);
          if (keys.length > 0) {
            error += ` (${keys.map(key => `${key}: ${errorProperties[key]}`).join('; ')}`;
          }
          notifications.error(error);
        }
      }}
    >
      <ConfirmModal
        cancel="Close"
        content={(
          <>
            <Slider
              className="my-5"
              disableSwap
              marks={[
                { value: scoreMinimum, label: scoreMinimum },
                {
                  value: values.riskHighThreshold,
                  label: `${values.riskHighThreshold} | ${values.riskHighThreshold + 1}`,
                },
                {
                  value: values.riskModerateThreshold,
                  label: `${values.riskModerateThreshold} | ${values.riskModerateThreshold + 1}`,
                },
                { value: scoreMaximum, label: scoreMaximum },
              ]}
              max={scoreMaximum}
              min={scoreMinimum}
              onChange={(event, [newRiskHighThreshold, newRiskModerateThreshold]) => {
                if (
                  newRiskHighThreshold === scoreMinimum
                  || newRiskModerateThreshold >= scoreMaximum - 1
                  || (newRiskModerateThreshold - newRiskHighThreshold) === 0
                ) {
                  return;
                }
                const newValues = JSON.parse(JSON.stringify(values));
                newValues.riskHighThreshold = newRiskHighThreshold;
                newValues.riskModerateThreshold = newRiskModerateThreshold;
                setValues(newValues);
              }}
              step={1}
              value={[values.riskHighThreshold, values.riskModerateThreshold]}
            />
            <div className="classification-questionnaire-score-binding">
              {[
                {
                  from: values.riskModerateThreshold + 1,
                  label: 'Low',
                  subtext: 'i.e. a person who provides services as an independent contractor',
                  text: 'You are most likely a contractor',
                  to: scoreMaximum,
                },
                {
                  from: values.riskHighThreshold + 1,
                  label: 'Moderate',
                  text: 'We think your classification may need extra guidance',
                  to: values.riskModerateThreshold,
                },
                {
                  from: scoreMinimum,
                  label: 'High',
                  subtext: [
                    'i.e. an individual who works under a contract of employment to provide ',
                    'services to the employer.',
                  ].join(''),
                  text: 'You are most likely an employee',
                  to: values.riskHighThreshold,
                },
              ].map(({ from, label, subtext, text, to }) => (
                <Fragment key={label}>
                  <div className={`px-3 text-center label ${label.toLowerCase()}`}>{label}</div>
                  <div className="d-flex flex-column justify-content-center px-3">
                    <span className="font-weight-bold">{text}</span>
                    {subtext && <span>{subtext}</span>}
                  </div>
                  <div className="score text-center">{`${from} to ${to}`}</div>
                </Fragment>
              ))}
            </div>
          </>
        )}
        onClose={() => setScoreBindingModalIsOpen(false)}
        open={scoreBindingModalIsOpen}
        showConfirm={false}
        title="Score binding"
      />
      <ConfirmModal
        cancel="Close"
        content={<ClassificationQuestionnairePreview questions={values.questions} />}
        onClose={() => setPreviewModalIsOpen(false)}
        open={previewModalIsOpen}
        showConfirm={false}
        title="Classification questionnaire"
      />
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <BackButton />
          <h1 className="my-5">{`Create questionnaire v${version}`}</h1>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column text-right mr-3" style={{ maxWidth: 160 }}>
            <div>Score binding:</div>
            {showScoreBinding && (
              <div>{`${scoreMinimum} to ${scoreMaximum}`}</div>
            )}
            {!showScoreBinding && (
              <div style={{ color: 'red' }}>Questionnaire does not have scores yet</div>
            )}
          </div>
          <Button
            disabled={!showScoreBinding}
            onClick={() => setScoreBindingModalIsOpen(true)}
            startIcon={<Icon>commit</Icon>}
            variant="outlined"
          >
            Score binding
          </Button>
          <Button
            className="mx-3"
            onClick={() => setPreviewModalIsOpen(true)}
            startIcon={<Icon>visibility</Icon>}
            variant="outlined"
          >
            Preview
          </Button>
          <Button color="primary" type="submit" variant="contained">
            Save
          </Button>
        </div>
      </div>
      <Card>
        <CardHeader title="Intro" />
        <CardContent>{CLASSIFICATION_QUESTIONNAIRE_INTRO}</CardContent>
      </Card>
      {(values.questions || []).map((question, questionIndex) => {
        const { choices = [] } = question;
        const choicesLength = choices.length;
        const questionPrefix = `questions[${questionIndex}]`;
        return (
          <Card className="d-flex flex-column mt-5 p-3" key={questionIndex}>
            <div className="d-flex align-items-center justify-content-end w-100">
              <IconButton
                onClick={() => {
                  const newValues = JSON.parse(JSON.stringify(values));
                  newValues.questions.splice(questionIndex + 1, 0, { ...question });
                  Object.assign(newValues, calculateDefaultThresholds(newValues.questions));
                  setValues(newValues);
                }}
              >
                <Icon>content_copy</Icon>
              </IconButton>
              <IconButton
                disabled={questionsLength === 1}
                onClick={() => {
                  const newValues = JSON.parse(JSON.stringify(values));
                  newValues.questions.splice(questionIndex);
                  Object.assign(newValues, calculateDefaultThresholds(newValues.questions));
                  setValues(newValues);
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
              <IconButton
                disabled={questionIndex === 0}
                onClick={() => moveQuestion(questionIndex, questionIndex - 1)}
              >
                <Icon>north</Icon>
              </IconButton>
              <IconButton
                disabled={questionIndex === questionsLength - 1}
                onClick={() => moveQuestion(questionIndex, questionIndex + 1)}
              >
                <Icon>south</Icon>
              </IconButton>
            </div>
            <div className="d-flex align-items-center w-100">
              <h2 className="mr-3">{`${questionIndex + 1}.`}</h2>
              <TextField
                error={!!errors[`${questionPrefix}.label`]}
                helperText={errors[`${questionPrefix}.label`]}
                name={`${questionPrefix}.label`}
                onChange={onChangeQuestion(questionIndex, 'label')}
                placeholder="Question"
                style={{ width: 'calc(100% - 2rem)' }}
                value={values.questions[questionIndex].label}
                variant="filled"
              />
            </div>
            <div className="d-flex align-items-center w-100 mt-3">
              <TextField
                className="ml-5"
                error={!!errors[`${questionPrefix}.sublabel`]}
                helperText={errors[`${questionPrefix}.sublabel`]}
                name={`${questionPrefix}.sublabel`}
                onChange={onChangeQuestion(questionIndex, 'sublabel')}
                placeholder="Subtitle (leave empty if you don't need it)"
                style={{ width: 'calc(100% - 2rem)' }}
                value={values.questions[questionIndex].sublabel}
                variant="filled"
              />
            </div>
            {choices.map((choice, choiceIndex) => {
              const choicePrefix = `${questionPrefix}.choices[${choiceIndex}]`;
              return (
                <div
                  className="d-flex align-items-center w-100 mt-3 ml-5"
                  key={choiceIndex}
                >
                  <Icon>radio_button_unchecked</Icon>
                  <div
                    className="d-flex align-items-center"
                    style={{ width: 'calc(100% - 3.5rem)' }}
                  >
                    <TextField
                      className="mx-3"
                      error={!!errors[`${choicePrefix}.label`]}
                      helperText={errors[`${choicePrefix}.label`]}
                      name={`${choicePrefix}.label`}
                      onChange={onChangeChoice(questionIndex, choiceIndex, 'label')}
                      placeholder={`Option ${choiceIndex + 1}`}
                      style={{ width: 'calc(100% - 16rem)' }}
                      value={values.questions[questionIndex].choices[choiceIndex].label}
                      variant="filled"
                    />
                    <TextField
                      className="mr-3"
                      error={!!errors[`${choicePrefix}.score`]}
                      helperText={errors[`${choicePrefix}.score`]}
                      name={`${choicePrefix}.score`}
                      onChange={onChangeChoice(questionIndex, choiceIndex, 'score')}
                      placeholder="Score"
                      style={{ width: '6rem' }}
                      value={values.questions[questionIndex].choices[choiceIndex].score}
                      variant="filled"
                    />
                    <IconButton
                      disabled={choicesLength === 2}
                      onClick={() => {
                        const newValues = JSON.parse(JSON.stringify(values));
                        newValues.questions[questionIndex].choices.splice(choiceIndex);
                        Object.assign(newValues, calculateDefaultThresholds(newValues.questions));
                        setValues(newValues);
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                    <IconButton
                      disabled={choiceIndex === 0}
                      onClick={() => moveChoice(questionIndex, choiceIndex, choiceIndex - 1)}
                    >
                      <Icon>north</Icon>
                    </IconButton>
                    <IconButton
                      disabled={choiceIndex === choicesLength - 1}
                      onClick={() => moveChoice(questionIndex, choiceIndex, choiceIndex + 1)}
                    >
                      <Icon>south</Icon>
                    </IconButton>
                  </div>
                </div>
              );
            })}
            <Button
              className="mt-3 ml-5 align-self-start"
              color="primary"
              onClick={async () => {
                const newValues = JSON.parse(JSON.stringify(values));
                newValues.questions[questionIndex].choices.push({});
                setValues(newValues);
              }}
              startIcon={<Icon>add</Icon>}
            >
              Add option
            </Button>
          </Card>
        );
      })}
      <Button
        color="primary"
        className="mt-3 align-self-start"
        onClick={async () => {
          const newValues = JSON.parse(JSON.stringify(values));
          newValues.questions.push({ choices: [{}, {}] });
          setValues(newValues);
        }}
        startIcon={<Icon>add</Icon>}
        variant="contained"
      >
        Add question
      </Button>
    </form>
  );
};

ClassificationForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  version: PropTypes.number,
};

ClassificationForm.defaultProps = {
  version: 1,
};

export default ClassificationForm;
