import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import {
  CLASSIFICATION_SUBMISSION_RISK,
  CLASSIFICATION_SUBMISSION_RISK_LABELS,
  CLASSIFICATION_SUBMISSION_RISK_VALUES,
} from 'accounts/assets/js/constants';
import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import { formatDate, getOrganizationsList } from 'admin/assets/js/lib/utils';

const renderRisk = risk => (risk ? CLASSIFICATION_SUBMISSION_RISK_LABELS[risk] : '-');

const ClassificationSubmissions = () => {
  const { ids, keyedById } = getOrganizationsList();
  const history = useHistory();

  return (
    <ApiTable
      className="classification-submissions"
      columns={[
        {
          label: 'Name',
          name: 'contractor_name',
          options: {
            cellRender: (contractorName, { contractor_user_id: userId }) => (
              <Link to={`/full_users/${userId}/show`}>{contractorName}</Link>
            ),
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Organization',
          name: 'organization_id',
          options: {
            cellRender: (orgId, { organization_name: orgName }) => (
              <Link to={`/organizations/${orgId}/show`}>{orgName}</Link>
            ),
            customFilterListOptions: {
              render: orgId => `Organization: ${keyedById[orgId]}`,
            },
            filter: true,
            filterOptions: {
              fullWidth: true,
              names: ids,
              renderValue: orgId => keyedById[orgId],
            },
            sort: true,
          },
        },
        {
          label: 'Date completed',
          name: 'created_at',
          options: {
            cellRender: createdAt => formatDate(createdAt),
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Risk',
          name: 'risk',
          options: {
            cellRender: renderRisk,
            customFilterListOptions: { render: risk => `Risk: ${renderRisk(risk)}` },
            filter: true,
            filterOptions: {
              names: CLASSIFICATION_SUBMISSION_RISK_VALUES, renderValue: renderRisk,
            },
            setCellProps: record => ({ className: record.risk }),
            sort: true,
          },
        },
        {
          label: 'Action required',
          name: 'risk',
          options: {
            cellRender: risk => risk === CLASSIFICATION_SUBMISSION_RISK.HIGH && (
              <div className="action-required d-inline-block py-1 px-3">Action required</div>
            ),
            filter: false,
            sort: false,
          },
        },
        {
          label: 'Actions',
          name: 'id',
          options: {
            cellRender: id => (
              <Button
                className="text-nowrap"
                color="primary"
                onClick={() => history.push(`/classification-questionnaire/${id}`)}
                variant="outlined"
              >
                View response
              </Button>
            ),
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="created_at"
      defaultSortDirection="DESC"
      downloadEnabled={false}
      resource="classification_submissions"
      sortFilterList={false}
    />
  );
};

export default ClassificationSubmissions;
