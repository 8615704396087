import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import TransactionDetails from 'admin/assets/js/resources/transactions/TransactionDetails.jsx';
import TransactionInvoices from 'admin/assets/js/resources/transactions/TransactionInvoices.jsx';
import PreviousTransactionBanner from 'admin/assets/js/resources/transactions/PreviousTransactionBanner.jsx';
import NextTransactionBanner from 'admin/assets/js/resources/transactions/NextTransactionBanner.jsx';

const TransactionDetailsTab = ({ transaction }) => (
  <>
    <PreviousTransactionBanner transaction={transaction} />
    <NextTransactionBanner transaction={transaction} />
    <Grid container item spacing={2}>
      <TransactionDetails transaction={transaction} />
      <TransactionInvoices transaction={transaction} />
    </Grid>
  </>
);

TransactionDetailsTab.propTypes = {
  transaction: PropTypes.object,
};

TransactionDetailsTab.defaultProps = {
  transaction: {},
};

export default TransactionDetailsTab;
