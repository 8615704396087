import PropTypes from 'prop-types';
import React from 'react';
import Datetime from 'react-datetime';
import { FormControl, FormLabel } from '@material-ui/core';

import { API_DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { parseDate } from 'core/assets/js/lib/utils';

export const DateInput = ({
  containerClassName, disabled, error, isValidDate, label, onChange, required, value,
}) => {
  const parsedValue = parseDate(value, API_DATE_FORMAT, false);
  return (
    <FormControl className={containerClassName} component="fieldset" required={required}>
      <FormLabel component="legend">{label}</FormLabel>
      <Datetime
        closeOnSelect
        dateFormat={DATE_FORMAT_DEFAULT}
        inputProps={{
          autoComplete: 'off',
          className: 'form-control',
          disabled,
        }}
        isValidDate={isValidDate}
        onChange={selectedDate => {
          if (selectedDate?.format) {
            onChange({ target: { value: selectedDate.format(API_DATE_FORMAT) } });
          }
        }}
        timeFormat={false}
        value={parsedValue && parsedValue.isValid() ? parsedValue : value}
      />

      {error && <div style={{ color: '#DC3545' }} className="mt-2">{error}</div>}
    </FormControl>
  );
};

DateInput.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  isValidDate: PropTypes.func,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

DateInput.defaultProps = {
  containerClassName: null,
  disabled: false,
  error: null,
  helperText: null,
  required: false,
  isValidDate: () => true,
};

export default DateInput;
