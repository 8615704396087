import moment from 'moment';
import React, { Fragment, useState } from 'react';
import {
  Button,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Field } from 'react-final-form';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const RunBillingProcessesForYearETLButton = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(false);
  const notify = useNotify();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const command = 'etl/billing-processes-for-year';
  const title = 'Generate billing processes for a year';

  const handleConfirm = async (values) => {
    const { year, filterByOrgId } = values;
    const url = adminCommandApiUrl(command, {
      year,
      filterByOrgId,
    });
    setLoading(true);
    try {
      const res = await httpClient(url, { method: 'GET' });
      setDownloadId(res.json.downloadId);
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        title="Export data for billing processes for a year"
        label="Export data for billing processes for a year"
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {downloadId ? (
            <Link to={`/downloads/${downloadId}/show`}>See your download</Link>
          ) : (
            <Form
              initialValues={{ filterByOrgId: '', year: `${moment().year()}` }}
              onSubmit={handleConfirm}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="year">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Year to export</label>
                          <br />
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="text"
                            placeholder="2022"
                            required
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="filterByOrgId">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Filter by organization id ( optional )</label>
                          <br />
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="text"
                            placeholder="ie: 88"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <p>
                    Your data will be available from the downloads view,
                    {' '}
                    you may need to wait a short while for the data generation to complete.
                  </p>
                  <div>
                    <Button variant="contained" disabled={loading} label="confirm" type="submit" />
                    <Button label="cancel" type="button" onClick={handleDialogClose} />
                  </div>
                </form>
              )}
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

RunBillingProcessesForYearETLButton.propTypes = {};

RunBillingProcessesForYearETLButton.defaultProps = {};

export default RunBillingProcessesForYearETLButton;
