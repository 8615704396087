import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { TextField, ReferenceField } from 'react-admin';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';

import { RAISED_BY, RAISED_FOR } from 'finance/assets/js/constants';
import { useStyles } from 'admin/assets/js/layout/themes';

const InvoicePartiesField = ({ record, ...rest }) => {
  const [ownerPopAnchorEl, handleOwnerPopover] = useState(false);
  const [recipientPopAnchorEl, handleRecipientPopover] = useState(false);

  const handleOwnerPopOpen = (event) => {
    handleOwnerPopover(event.currentTarget);
  };

  const handleOwnerPopClose = () => {
    handleOwnerPopover(false);
  };

  const handleRecipientPopOpen = (event) => {
    handleRecipientPopover(event.currentTarget);
  };

  const handleRecipientPopClose = () => {
    handleRecipientPopover(false);
  };

  const ownerPopIsOpen = Boolean(ownerPopAnchorEl);
  const recipientPopIsOpen = Boolean(recipientPopAnchorEl);

  const classes = useStyles();
  const { ownerFE, recipientFE } = record;
  const ownerFEName = get(ownerFE, 'name');
  const recipientFEName = get(recipientFE, 'name');
  const entityInfo = entity => (
    <div className="p-2">
      {entity.user && (
        <div>
          <span className="mr-1"><strong>Email:</strong></span>
          <span>{entity.user.email}</span>
        </div>
      )}
      { entity.userId && (
        <div>
          <span className="mr-1"><strong>UserId:</strong></span>
          <span>{entity.userId}</span>
        </div>
      )}
      <div>
        <span className="mr-1"><strong>OrgId:</strong></span>
        <span>{entity.orgId}</span>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div>
        <ReferenceField record={record} {...rest} source="orgId" reference="organizations_lite" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </div>
      {record.raisedBy === RAISED_BY.PROVIDER && (
        <div>
          <div className={classes.discreet}>
            <div className="d-flex">
              <span className="mr-2">{`By ${ownerFEName}`}</span>
              <Icon
                onClick={handleOwnerPopOpen}
                color="action"
                fontSize="small"
              >
                help_outline
              </Icon>
              <Popover
                id="recipient-popover"
                open={ownerPopIsOpen}
                anchorEl={ownerPopAnchorEl}
                onClose={handleOwnerPopClose}
              >
                {entityInfo(ownerFE)}
              </Popover>
            </div>
          </div>
          <div className={classes.discreet}>
            <div className="d-flex">
              <span className="mr-2">{`For ${recipientFEName}`}</span>
              <Icon
                onClick={handleRecipientPopOpen}
                color="action"
                fontSize="small"
              >
                help_outline
              </Icon>
              <Popover
                id="recipient-popover"
                open={recipientPopIsOpen}
                anchorEl={recipientPopAnchorEl}
                onClose={handleRecipientPopClose}
              >
                {entityInfo(recipientFE)}
              </Popover>
            </div>
          </div>
        </div>
      )}
      {record.raisedBy === RAISED_BY.TALENTDESK && (
        <div className={classes.discreet}>
          By TalentDesk.io
        </div>
      )}
      {record.raisedFor === RAISED_FOR.TALENTDESK && (
        <div className={classes.discreet}>
          Through TalentDesk.io
        </div>
      )}
    </React.Fragment>
  );
};

InvoicePartiesField.propTypes = {
  record: PropTypes.object,
};
InvoicePartiesField.defaultProps = {
  record: {},
};
export default InvoicePartiesField;
