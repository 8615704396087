import React, { Fragment, useState } from 'react';
import {
  Button,
  useRefresh,
  useNotify,
  downloadCSV,
} from 'react-admin';
import {
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const ViaFundsFlowButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async (values) => {
    const command = 'reports/via-funds-flow';
    const url = adminCommandApiUrl(command, { ...values });
    setLoading(true);
    try {
      const data = await httpClient(url, { method: 'GET' });
      downloadCSV(data.body, 'via-funds-flow');
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>VIA FUNDS FLOW REPORT</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="VIA funds flow report"
      >
        <DialogTitle>VIA funds flow report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the id of the organization.
          </DialogContentText>
          <Form
            onSubmit={handleGenerateClick}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="targetMonth">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Month</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="text"
                          placeholder="ie: 1"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>

                  <Field name="targetYear">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Year</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="text"
                          placeholder="ie: 2022"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Download report" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

ViaFundsFlowButton.propTypes = {};

ViaFundsFlowButton.defaultProps = {};

export default ViaFundsFlowButton;
