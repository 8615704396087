import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';

import { INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';
import { useStyles } from 'admin/assets/js/layout/themes';
import { getInvoiceStatusReasonInfo } from 'admin/assets/js/resources/invoices/utils';

const InvoiceStatusField = ({ record }) => {
  const [statusAnchorEl, handleStatusPopover] = useState(false);
  const classes = useStyles();

  const handleStatusPopOpen = (event) => {
    handleStatusPopover(event.currentTarget);
  };

  const handleStatusPopClose = () => {
    handleStatusPopOpen(false);
  };

  const statusPopIsOpen = Boolean(statusAnchorEl);

  const { showReason, reason, statusStyling } = getInvoiceStatusReasonInfo(record);
  return (
    <div className="d-flex">
      <div style={{ ...statusStyling }}>
        {INVOICE_STATUS_LABEL[record.status]}
      </div>
      {showReason && (
        <div className="ml-2">
          <div className={classes.discreet}>
            <React.Fragment>
              <Icon
                onClick={handleStatusPopOpen}
                color="action"
                fontSize="small"
              >
                help_outline
              </Icon>
              <Popover
                id="recipient-popover"
                open={statusPopIsOpen}
                anchorEl={statusAnchorEl}
                onClose={handleStatusPopClose}
              >
                <div style={{ padding: 10, width: 300 }}>
                  <div><strong>Status info</strong></div>
                  {reason}
                </div>
              </Popover>
            </React.Fragment>
          </div>
        </div>
      )}
    </div>
  );
};

InvoiceStatusField.propTypes = {
  record: PropTypes.object,
};
InvoiceStatusField.defaultProps = {
  record: {},
};
export default InvoiceStatusField;
