import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Paper, Tabs, Tab, makeStyles } from '@material-ui/core';
import queryString from 'query-string';

import UserOrgInfoTab from 'admin/assets/js/resources/full_users/UserOrgInfoTab.jsx';
import UserOrgAorTab from 'admin/assets/js/resources/full_users/UserOrgAorTab.jsx';
import { USER_TABS, USER_INFO_SUB_TABS } from 'admin/assets/js/constants';

export const useStyles = makeStyles({
  labelStyle: {
    color: '#0000008A',
    marginBottom: '5px',
  },
  simpleForm: {
    marginLeft: '-10px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
  },
  button_disable: {
    color: '#DC3545',
    borderColor: '#DC3545',
  },
  aor_contract_approve: {
    color: '#fff',
    borderColor: '#60DC35',
    backgroundColor: '#60DC35',
    '&:hover': {
      borderColor: '#67e13c',
      backgroundColor: '#67e13c',
    },
    '&+ button': {
      marginLeft: '10px',
    },
  },
  aor_contract_reject: {
    color: '#fff',
    backgroundColor: '#DC3545',
    borderColor: '#DC3545',
    '&:hover': {
      borderColor: '#ec5765',
      backgroundColor: '#ec5765',
    },
  },
});

const UserOrgInfo = ({ userCard, profile, ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const parsedHistory = queryString.parse(location.search);
  const { subTab } = parsedHistory;
  const selectedSubTab = subTab || USER_INFO_SUB_TABS.INFO;

  const tabs = [
    { id: USER_INFO_SUB_TABS.INFO, name: USER_INFO_SUB_TABS.INFO },
    { id: USER_INFO_SUB_TABS.AOR, name: USER_INFO_SUB_TABS.AOR },
  ];

  return (
    <Paper>
      <Tabs
        style={{ marginBottom: '15px' }}
        value={selectedSubTab}
        indicatorColor="primary"
        onChange={(event, value) => {
          history.push({
            pathname: location.pathname,
            search: `?tab=${USER_TABS.INFO}&subTab=${value}`,
          });
        }}
      >
        {tabs.map(choice => (
          <Tab
            key={choice.id}
            label={choice.name}
            value={choice.id}
          />
        ))}
      </Tabs>

      <div>
        {selectedSubTab === USER_INFO_SUB_TABS.INFO && (
          <UserOrgInfoTab
            userCard={userCard}
          />
        )}
        {selectedSubTab === USER_INFO_SUB_TABS.AOR && (
          <UserOrgAorTab
            userCard={userCard}
            profile={profile}
            {...props}
          />
        )}
      </div>
    </Paper>
  );
};

UserOrgInfo.propTypes = {
  userCard: PropTypes.object,
  profile: PropTypes.object,
};

UserOrgInfo.defaultProps = {
  userCard: {},
  profile: {},
};

export default UserOrgInfo;
