import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

export const RadioGroupInput = ({
  containerClassName,
  disabled,
  horizontal,
  label: fieldLabel,
  onChange,
  options = [],
  required,
  value: formValue,
}) => {
  return (
    <FormControl className={containerClassName} component="fieldset" required={required}>
      <FormLabel component="legend">{fieldLabel}</FormLabel>
      <RadioGroup onChange={onChange} row={horizontal} value={formValue}>
        {options.map(({ label, value }) => (
          <FormControlLabel
            control={<Radio />}
            disabled={disabled}
            label={label}
            value={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupInput.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  horizontal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired, value: PropTypes.any.isRequired,
  })).isRequired,
  required: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

RadioGroupInput.defaultProps = {
  containerClassName: null,
  disabled: false,
  error: null,
  helperText: null,
  horizontal: false,
  required: false,
};

export default RadioGroupInput;
