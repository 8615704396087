import React from 'react';
import PropTypes from 'prop-types';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import { useUnselectAll } from 'react-admin';

const OnDemandPayoutsUnmarkAsReviewedButton = ({
  selectedIds, permissions,
}) => {
  const numReviewed = (selectedIds && selectedIds.length > 0)
    ? selectedIds.length
    : 0;
  const canReview = (
    permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
  );
  const label = canReview && numReviewed
    ? `Unmark ${numReviewed} transactions as reviewed`
    : 'Unmark transactions as reviewed';
  const ids = selectedIds || [];
  const unselectAll = useUnselectAll();
  return (
    <BulkApiActionButton
      successNotifMsg="Transactions unmarked as reviewed"
      disabled={!canReview || !numReviewed}
      label={label}
      title="Unmark as reviewed transactions"
      content={`Are you sure you want unmark ${numReviewed} selected transactions as reviewed?`}
      icon={null}
      ids={ids}
      resource="on_demand_payouts"
      action="unmark-review-transactions"
      onSuccess={() => {
        unselectAll('on_demand_payouts');
      }}
    />
  );
};

OnDemandPayoutsUnmarkAsReviewedButton.propTypes = {
  selectedIds: PropTypes.array,
  permissions: PropTypes.object,
};

OnDemandPayoutsUnmarkAsReviewedButton.defaultProps = {
  selectedIds: [],
  permissions: {},
};

export default OnDemandPayoutsUnmarkAsReviewedButton;
