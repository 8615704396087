import React from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';

const PreformattedField = ({ record, source }) => {
  let fullText = record[source];
  if (isObject(fullText)) {
    fullText = JSON.stringify(fullText, null, 2);
  }
  return (
    <pre>
      {fullText}
    </pre>
  );
};

PreformattedField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
};

PreformattedField.defaultProps = {
  record: {},
  addLabel: true, //eslint-disable-line
};

export default PreformattedField;
