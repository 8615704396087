/* eslint react/prop-types: "warn", import/prefer-default-export: "warn", react/no-multi-comp: "off", max-len: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { logger } from 'core/assets/js/lib/Logger';

import { formatAmountString, formatCurrency } from 'admin/assets/js/lib/utils';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import Money from 'finance/assets/js/lib/Money';


export const STYLE = {
  PLAIN: 'plain',
  STANDARD: 'standard',
  EXTENDED: 'extended',
  BREAKDOWN: 'breakdown',
  TOTALS: 'totals',
  TOTALS_BREAKDOWN: 'totals_breakdown',
  EMPTY: 'empty',
};

const _COMMON_ROW_CSS = {
  '& td': {
    padding: '0.7em',
    'font-size': '0.90em',
  },
  '& td:nth-of-type(1)': {
    'text-align': 'left',
  },
  '& td:nth-of-type(2)': {
    'text-align': 'left',
  },
  '& td:nth-of-type(3)': {
    'text-align': 'left',
  },
  '& td:nth-of-type(4)': {
    'text-align': 'right',
  },
};

const useStyles = makeStyles({
  [STYLE.PLAIN]: {
    ..._COMMON_ROW_CSS,
  },
  [STYLE.STANDARD]: {
    ..._COMMON_ROW_CSS,
    '& td:first-of-type': {
      backgroundColor: '#aab4ec',
    },
  },
  [STYLE.EXTENDED]: {
    ..._COMMON_ROW_CSS,
    '& td:first-of-type': {
      backgroundColor: '#e5e7fa',
    },
  },
  [STYLE.TOTALS]: {
    ..._COMMON_ROW_CSS,
    '& td': {
      ..._COMMON_ROW_CSS['& td'],
      'font-weight': 'bold',
      backgroundColor: '#e8bc7a',
    },
  },
  [STYLE.TOTALS_BREAKDOWN]: {
    ..._COMMON_ROW_CSS,
    '& td': {
      ..._COMMON_ROW_CSS['& td'],
      'font-weight': 'bold',
      backgroundColor: '#f3ddbc',
    },
  },
  [STYLE.BREAKDOWN]: {
    ..._COMMON_ROW_CSS,
    description: {
      backgroundColor: null,
    },
  },
});

const _DIRECTION = {
  IN: 'in',
  OUT: 'out',
};

const _formatAmount = (amount, currency, direction) => {
  assertAllKeysPresent({ amount, currency, direction });
  const money = new Money(amount, currency);
  if (money.isZero()) {
    return `${formatCurrency(currency)} - `;
  }
  switch (direction) {
    case _DIRECTION.OUT:
      return `(${formatAmountString(amount, currency)})`;
    case _DIRECTION.IN:
      return formatAmountString(amount, currency);
    default:
      throw new Error('unknown direction');
  }
};

const BalanceSheetRow = ({
  description, amount, currency, direction, style, strongDescription, strongAmount,
}) => {
  const classes = useStyles();
  if (style === STYLE.EMPTY) {
    return (
      <TableRow className={classes[style]}>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }
  try {
    assertAllKeysPresent({ amount, currency, direction, style });
  } catch (e) {
    logger.error(e);
    return null;
  }

  const descriptionCell = (
    <TableCell key="description">
      {strongDescription ? (<strong>{description}</strong>) : description}
    </TableCell>
  );

  const formattedAmount = _formatAmount(amount, currency, direction);
  const amountCell = (
    <TableCell key="amount">
      {strongAmount ? (<strong>{formattedAmount}</strong>) : formattedAmount}
    </TableCell>
  );

  const firstEmptyCell = (<TableCell key="first" />);
  const secondEmptyCell = (<TableCell key="second" />);

  let cells = [];
  switch (style) {
    case STYLE.BREAKDOWN:
      cells = [firstEmptyCell, descriptionCell, amountCell, secondEmptyCell];
      break;
    case STYLE.TOTALS_BREAKDOWN:
      cells = [descriptionCell, firstEmptyCell, amountCell, secondEmptyCell];
      break;
    default:
      cells = [descriptionCell, firstEmptyCell, secondEmptyCell, amountCell];
  }

  return (
    <TableRow className={classes[style]}>
      {cells}
    </TableRow>
  );
};

BalanceSheetRow.propTypes = {
  description: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  strongDescription: PropTypes.bool,
  strongAmount: PropTypes.bool,
};

BalanceSheetRow.defaultProps = {
  strongDescription: false,
  strongAmount: false,
};

export const EmptyRow = props => BalanceSheetRow({ style: STYLE.EMPTY, ...props });
export const CreditRow = props => BalanceSheetRow({ direction: _DIRECTION.IN, style: STYLE.STANDARD, ...props });
export const CostRow = props => BalanceSheetRow({ direction: _DIRECTION.OUT, style: STYLE.STANDARD, ...props });
export const CreditBreakdownRow = props => CreditRow({ style: STYLE.BREAKDOWN, ...props });
export const CostBreakdownRow = props => CostRow({ style: STYLE.BREAKDOWN, ...props });
export const TotalsRow = props => BalanceSheetRow({ direction: _DIRECTION.IN, style: STYLE.TOTALS, ...props });
export const TotalsBreakdownRow = props => TotalsRow({ style: STYLE.TOTALS_BREAKDOWN, ...props });

export default BalanceSheetRow;
