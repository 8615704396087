import { debounce, omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button, Dialog, DialogContent, DialogTitle, Icon, Paper, TextField,
} from '@material-ui/core';

import Form from 'admin/assets/js/components/Form.jsx';
import SelectInput from 'admin/assets/js/components/inputs/SelectInput.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { adminApiUserNoteUrl } from 'admin/urls';
import { DATETIME_FORMAT_HUMAN_FRIENDLY_2, DATETIME_FORMAT_ISO } from 'core/assets/js/constants';
import { formatDate, parseDate } from 'core/assets/js/lib/utils';

const UserNotes = ({ record: user }) => {
  const [addNoteIsOpen, setAddNoteIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);

  const notifications = getNotifications();
  const location = useLocation();
  const history = useHistory();

  const modalClose = () => setAddNoteIsOpen(false);

  const organizationOptions = [
    { label: 'No organization', value: -1 },
    ...(user.userCards || []).map(uc => ({
      label: uc.organization.name, value: uc.organization.id,
    })),
  ];

  const parsedQueryString = qs.parse(location.search);

  const loadNotes = async () => {
    const response = await apiClient({
      url: [
        adminApiUserNoteUrl(),
        qs.stringify({ ...pick(parsedQueryString, 'kw', 'organization_id'), user_id: user.id }),
      ].join('?'),
    });
    setNotes(response.data);
  };

  useEffect(() => {
    loadNotes().catch(e => {
      notifications.error(e.response?.data?._error || e.message || 'Loading notes failed');
    });
  }, [qs.stringify(parsedQueryString)]);

  const changeQS = newQS => {
    history.push(`${location.pathname}?${qs.stringify(newQS)}`);
  };

  const onSearchChange = debounce(
    newKW => {
      const newQS = omit({ ...parsedQueryString }, 'kw');
      if (newKW) {
        newQS.kw = newKW;
      }
      changeQS(newQS);
    },
    1000,
  );

  return (
    <>
      <div className="d-flex align-items-center justify-content-start my-3">
        <TextField
          className="mr-5"
          label="Search"
          onChange={e => onSearchChange(e.target.value)}
          variant="filled"
        />
        <SelectInput
          containerClassName="user-notes-organization-select"
          label="Filter by organization"
          onChange={e => {
            const newQS = omit({ ...parsedQueryString }, 'organization_id');
            if (e.target.value && e.target.value.toString() !== '-1') {
              newQS.organization_id = e.target.value;
            }
            changeQS(newQS);
          }}
          options={organizationOptions}
          value={parsedQueryString.organization_id || -1}
          variant="filled"
        />
      </div>
      <Paper style={{ padding: '15px' }}>
        <Button
          className="mb-5"
          color="primary"
          onClick={() => setAddNoteIsOpen(true)}
          variant="outlined"
          startIcon={<Icon>chat</Icon>}
        >
          Add note
        </Button>
        <Dialog onClose={modalClose} open={addNoteIsOpen}>
          <DialogTitle>{`Add a note for ${user.profile.name}`}</DialogTitle>
          <DialogContent>
            <Form
              fields={[
                {
                  Component: SelectInput,
                  disabled: loading,
                  label: 'Organization',
                  name: 'organization_id',
                  options: organizationOptions,
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Note',
                  multiline: true,
                  name: 'note',
                  required: true,
                  rows: 4,
                  validate: value => (!value ? 'Enter a valid note' : undefined),
                },
              ]}
              initialValue={{ organization_id: -1 }}
              onCancel={modalClose}
              onSubmit={async data => {
                setLoading(true);
                try {
                  const finalData = { note: data.note, user_id: user.id };
                  if (data.organization_id && data.organization_id.toString() !== '-1') {
                    finalData.organization_id = data.organization_id;
                  }
                  await apiClient({ data: finalData, method: 'POST', url: adminApiUserNoteUrl() });
                  modalClose();
                  notifications.success('Note added successfully');
                  await loadNotes();
                } catch (e) {
                  notifications.error(
                    e.response?.data?._error || e.message || 'Creating note failed',
                  );
                } finally {
                  setLoading(false);
                }
              }}
            />
          </DialogContent>
        </Dialog>
        {notes.map(note => (
          <div className="d-flex flex-column user-note mb-3 p-3" key={note.id}>
            <div className="d-flex flex-row align-items-center justify-content-start">
              <span className="font-weight-bold mr-1">{note.createdByName}</span>
              {note.organizationId && (
                <div className="discreet">
                  &#x2022;
                  Organization:
                  <Link className="ml-1" to={`/organizations/${note.organizationId}/show`}>
                    {note.organizationName}
                  </Link>
                </div>
              )}
            </div>
            <div className="discreet my-1">
              {formatDate(parseDate(
                note.createdAt, DATETIME_FORMAT_ISO), DATETIME_FORMAT_HUMAN_FRIENDLY_2,
              )}
            </div>
            <div>{note.note}</div>
          </div>
        ))}
      </Paper>
    </>
  );
};

UserNotes.propTypes = {
  record: PropTypes.object.isRequired,
};

export default UserNotes;
