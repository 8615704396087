import PropTypes from 'prop-types';
import React from 'react';
import ReactMde from 'react-mde';
import { FormControl, FormLabel } from '@material-ui/core';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

const MarkdownInput = ({ containerClassName, disabled, label, onChange, placeholder, value }) => {
  const [selectedTab, setSelectedTab] = React.useState('write');
  return (
    <FormControl className={containerClassName} component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <ReactMde
        generateMarkdownPreview={markdown => Promise.resolve((
          <MarkdownText withBreaksPlugin text={markdown || ''} />
        ))}
        onChange={newValue => {
          onChange({ target: { value: newValue } });
        }}
        onTabChange={setSelectedTab}
        readOnly={disabled}
        selectedTab={selectedTab}
        textAreaProps={{ placeholder }}
        value={value}
      />
    </FormControl>
  );
};

MarkdownInput.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any.isRequired,
};

MarkdownInput.defaultProps = {
  containerClassName: null,
  disabled: false,
  error: null,
  helperText: null,
  label: null,
  placeholder: null,
};

export default MarkdownInput;
