import React, { useState } from 'react';
import {
  Datagrid, DateField, Filter, FunctionField, List, ReferenceInput, SelectInput, TextField,
} from 'react-admin';
import { Icon } from '@material-ui/core';

import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import BooleanField from 'admin/assets/js/components/fields/BooleanField.jsx';
import { CODAT_WEBHOOK_TYPES, INTEGRATION_TYPE_CONFIG } from 'integrations/assets/js/constants';

const INTEGRATION_TYPE_OPTIONS = Object
  .entries(INTEGRATION_TYPE_CONFIG)
  .map(([id, config]) => ({ id, name: config.name }));

const WEBHOOK_TYPE_OPTIONS = Object.values(CODAT_WEBHOOK_TYPES).map(id => ({ id, name: id }));

const ListFilter = props => (
  <Filter
    {...props}
  >
    <ReferenceInput
      allowEmpty
      alwaysOn
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText={org => `${org.name} (${org.unique_alias})`} />
    </ReferenceInput>
    <SelectInput
      allowEmpty
      alwaysOn
      choices={INTEGRATION_TYPE_OPTIONS}
      label="Integration type"
      source="integration_type"
    />
    <SelectInput
      allowEmpty
      alwaysOn
      choices={WEBHOOK_TYPE_OPTIONS}
      label="Webhook type"
      source="rule_type"
    />
    <SelectInput
      allowEmpty
      alwaysOn
      source="handled"
      choices={[
        { id: 1, name: 'Handled' },
        { id: 0, name: 'Not handled' },
      ]}
    />
  </Filter>
);

const CodatWebhookCallsList = props => {
  const [modalContent, setModalContent] = useState(null);
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<ListFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <Datagrid>
          <TextField
            label="Organization"
            source="codatCompanyConnection.integration.organization.name"
          />
          <FunctionField
            label="Integration"
            render={record => (
              INTEGRATION_TYPE_CONFIG[
                record.codatCompanyConnection.integration.integration_type
              ]?.name
            )}
          />
          <TextField label="Webhook type" source="rule_type" />
          <FunctionField
            label="Body"
            render={record => (
              <Icon
                onClick={() => setModalContent(record.body_dump)}
              >
                visibility
              </Icon>
            )}
          />
          <FunctionField
            label="Data"
            render={record => (
              <Icon
                onClick={() => setModalContent(record.data_dump)}
              >
                visibility
              </Icon>
            )}
          />
          <BooleanField label="Handled" source="handled" />
          <DateField label="Created at" showTime source="created_at" />
        </Datagrid>
      </List>
      <ConfirmModal
        cancel="Close"
        content={modalContent && <pre>{JSON.stringify(JSON.parse(modalContent), null, 2)}</pre>}
        onClose={() => setModalContent(null)}
        open={!!modalContent}
        showConfirm={false}
      />
    </>
  );
};

export default CodatWebhookCallsList;
