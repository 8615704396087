import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar } from 'react-admin';
import SystemBankOverrideAddButton from 'admin/assets/js/resources/system_bank_account_overrides/SystemBankOverrideAddButton.jsx';

const ListActions = ({ basePath }) => (
  <TopToolbar>
    <SystemBankOverrideAddButton
      basePath={basePath}
    />
  </TopToolbar>
);

ListActions.propTypes = {
  basePath: PropTypes.string,
};

ListActions.defaultProps = {
  basePath: null,
};

export default ListActions;
