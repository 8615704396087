/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import {
  Show, List, Datagrid, Filter,
  ReferenceField, TextField,
  ReferenceInput, SelectInput,
  TextInput, FunctionField,
} from 'react-admin';
import { Link } from 'react-router-dom';

import NotificationCard from 'notifier/assets/js/components/NotificationCard.jsx';
import { formatDate } from 'admin/assets/js/lib/utils';

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText={org => `${org.name} (${org.unique_alias})`} />
    </ReferenceInput>
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Type"
      perPage={1000}
      sort={{ field: 'id', order: 'ASC' }}
      source="type"
      reference="notification_types"
    >
      <SelectInput optionText="id" />
    </ReferenceInput>
  </Filter>
);

const NotificationList = props => (
  <>
    <p>
      A log of the following notifications is only saved if the recipient is an existing TalentDesk
      user:
      <br />
      OnboardingInvitationCancelled, OnboardingInviteManager, OnboardingInviteProvider,
      OnboardingInviteProviderPPH, ProjectInvitationCancelled
    </p>
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ListFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <ReferenceField
          label="Organization"
          link={false}
          reference="organizations"
          source="organization_id"
        >
          <TextField className="text-muted" source="name" />
        </ReferenceField>
        <TextField label="Sent to" source="sent_to" sortable={false} />
        <TextField label="Type" source="type" sortable={false} />
        <FunctionField
          label="Created at"
          render={record => formatDate(record.created_at)}
          source="created_at"
        />
        <TextField label="Subject" source="subject" sortable={false} />
      </Datagrid>
    </List>
  </>
);

const NotificationPreviewView = ({ record }) => (
  <React.Fragment>
    <div className="page page--notifier">
      <div className="container">
        <h2>{record.type}</h2>
        <h3>Notification summary</h3>
        <div className="row">
          <div className="container">
            <div className="col-4">
              <NotificationCard
                item={record}
              />
            </div>
          </div>
        </div>
        <h3>Full notification</h3>
        <div className="container">
          <NotificationCard
            item={record}
          />
        </div>
        <h3>Email</h3>
        <div className="container">
          {'Sent to: '}
          <Link to={`/full_users/${record.user_id}/show`}>{record.sent_to}</Link>
        </div>
        <div className="container">
          <h4>Subject</h4>
          <iframe title="subject" height="40px" width="100%" srcDoc={record.subject} />
        </div>
        <div className="container">
          <h4>Text</h4>
          <iframe title="text" width="100%" srcDoc={`<pre>${record.text}</pre>`} />
        </div>
        <div className="container">
          <h4>Html</h4>
          <iframe title="html" height="800px" width="100%" srcDoc={record.html} />
        </div>
      </div>
    </div>
  </React.Fragment>
);

const NotificationShow = props => (
  <Show
    {...props}
    hasList={false}
    hasEdit={false}
  >
    <NotificationPreviewView />
  </Show>
);

export {
  NotificationList as List,
  NotificationShow as Show,
};
