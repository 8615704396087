import React, { useCallback } from 'react';
import {
  Button,
  Datagrid,
  DateField,
  ExportButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  TopToolbar,
} from 'react-admin';
import { Link, withRouter } from 'react-router-dom';

import { formatDate, getIsSuperAdmin } from 'admin/assets/js/lib/utils';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import { ORGANIZATION_STATUS, ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';
import OrgNameField from 'admin/assets/js/components/OrgNameField.jsx';
import ListFilter from 'admin/assets/js/resources/organizations/ListFilter.jsx';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { adminApiActiveOrganizationsManagersList } from 'admin/urls';


const OrganizationList = ({ history, ...props }) => {
  const isSuperAdmin = getIsSuperAdmin();
  const notifications = getNotifications();

  const handleManagersExport = useCallback(async () => {
    try {
      WINDOW_OPEN(adminApiActiveOrganizationsManagersList());
    } catch (e) {
      notifications.error(e._error || e.message);
    }
  }, []);

  return (
    <List
      {...props}
      actions={(
        <TopToolbar>
          {isSuperAdmin && (
            <Button
              label="To be deactivated"
              onClick={() => history.push('/organizations-to-be-deactivated')}
            />
          )}
          <Button
            label="Export active managers"
            onClick={() => handleManagersExport()}
          />
          <ExportButton />
        </TopToolbar>
      )}
      bulkActionButtons={false}
      filterDefaultValues={{ status: ORGANIZATION_STATUS.ACTIVE }}
      filters={<ListFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      data-testid="organizations-list"
    >
      <Datagrid>
        <FunctionField
          label="Name"
          render={record => <OrgNameField record={record} />}
          source="name"
        />
        <ReferenceField
          label="Parent org"
          source="invoicing_parent_org_id"
          reference="organizations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField label="Created at" source="created_at" />
        <FunctionField
          label="Subscription"
          render={({ subscription_plan }) => SUBSCRIPTION_PLAN_LABELS[subscription_plan]}
          source="subscription_plan"
        />
        <FunctionField
          label="Last invoice MRR"
          render={({ lastParentInvoice }) => {
            if (!lastParentInvoice) {
              return '';
            }
            return (
              <div className="d-flex flex-column">
                <span>{formatDate(lastParentInvoice.created_at)}</span>
                <span>
                  <NumberTpl
                    prefix={CURRENCY_SYMBOL[lastParentInvoice.currency]}
                    value={lastParentInvoice.total}
                  />
                </span>
                <span>
                  <Link to={`/children_invoices?filter=%7B"parent_id"%3A${lastParentInvoice.id}%7D`}>
                    View invoice
                  </Link>
                </span>
              </div>
            );
          }}
          source="last_invoice_date"
        />
        <FunctionField
          label="Last invoice"
          render={({ lastUsageFeesInvoice }) => {
            if (!lastUsageFeesInvoice) {
              return '';
            }
            return (
              <div className="d-flex flex-column">
                <span>{formatDate(lastUsageFeesInvoice.created_at)}</span>
                <span>
                  <NumberTpl
                    prefix={CURRENCY_SYMBOL[lastUsageFeesInvoice.currency]}
                    value={lastUsageFeesInvoice.total}
                  />
                </span>
                <span>
                  <Link to={`/children_invoices?filter=%7B"parent_id"%3A${lastUsageFeesInvoice.id}%7D`}>
                    View invoice
                  </Link>
                </span>
              </div>
            );
          }}
          source="last_invoice_date"
        />
        <FunctionField
          label="Country"
          render={({ country_code: countryCode }) => countryNames[countryCode]}
          source="country_code"
        />
        <FunctionField
          label="Status"
          render={({ status }) => ORGANIZATION_STATUS_LABEL[status]}
          source="status"
        />
      </Datagrid>
    </List>
  );
};

OrganizationList.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default withRouter(OrganizationList);
