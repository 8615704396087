import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useListContext } from 'react-admin';
import TransactionDetailsField from './TransactionDetailsField.jsx';
import { TRANSACTION_METHOD, TRANSACTION_STATUS } from 'finance/assets/js/constants';

const TransactionDetailsList = ({ expectedAmount, transactionMode, isDeferred }) => {
  const { ids, data, basePath } = useListContext();

  // build a map of transaction ids to a boolean to show a fallback CTA
  // for that transaction
  const showRevolutFallbackCTALookup = useMemo(() => {
    // determine if a good transaction is present
    const goodTransactionPresent = (ids || []).some(id => [
      TRANSACTION_STATUS.PENDING,
      TRANSACTION_STATUS.SUCCEEDED,
      TRANSACTION_STATUS.EXECUTED,
    ].includes(data[id].status));
    // map each transaction id to a flag to show Revolut CTA or not
    // ( show if this is a Revolut transaction and no 'good' transaction is present )
    return (ids || []).reduce((mapper, id) => {
      /* eslint-disable-next-line no-param-reassign */
      mapper[id] = data[id].method === TRANSACTION_METHOD.REVOLUT && !goodTransactionPresent;
      return mapper;
    }, {});
  }, [ids, data]);

  if (Array.isArray(ids) && ids?.length === 0) {
    return <p>No transactions found</p>;
  }

  return (
    <>
      {ids.map(id => (
        <TransactionDetailsField
          key={id}
          transactionMode={transactionMode}
          expectedAmount={expectedAmount}
          record={data[id]}
          basePath={basePath}
          showCreateFallbackCTA={showRevolutFallbackCTALookup[id]}
          isDeferred={isDeferred}
        />
      ))}
    </>
  );
};

TransactionDetailsList.propTypes = {
  expectedAmount: PropTypes.string,
  transactionMode: PropTypes.number.isRequired,
  isDeferred: PropTypes.bool,
};

TransactionDetailsList.defaultProps = {
  expectedAmount: '0.00',
  isDeferred: false,
};

export default TransactionDetailsList;
