import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, FormControl } from '@material-ui/core';

const FormFieldsRenderer = ({ errors, fields, setError, updateValue, values }) => (
  fields.map(({ Component, name, validate, ...otherProps }) => (
    <FormControl className="mb-4" key={name}>
      <Component
        error={!!errors[name]}
        helperText={errors[name]}
        name={name}
        onChange={({ target: { value } }) => {
          setError(name, null);
          updateValue(name, value);
          if (validate) {
            const error = validate(value);
            if (error) {
              setError(name, error);
            }
          }
        }}
        value={values[name]}
        {...otherProps}
      />
    </FormControl>
  ))
);

FormFieldsRenderer.propTypes = {
  errors: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  setError: PropTypes.func.isRequired,
  updateValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

FormFieldsRenderer.defaultProps = {
  fields: [],
};

const Form = ({
  cancelButtonText,
  fields,
  FormFieldsRendererComponent,
  initialValues,
  onCancel,
  onSubmit,
  submitButtonText,
}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const updateValue = (name, value) => setValues({ ...values, [name]: value });
  const setError = (name, error) => setErrors({ ...errors, [name]: error });
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        onSubmit(values);
      }}
    >
      <div className="d-flex flex-column">
        <FormFieldsRendererComponent
          errors={errors}
          fields={fields}
          setError={setError}
          updateValue={updateValue}
          values={values}
        />
        <div className="d-flex flex-row justify-content-end">
          {onCancel && (
            <Button className="mr-5" color="secondary" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
          <Button color="primary" type="submit" variant="contained">
            {submitButtonText}
          </Button>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  cancelButtonText: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  FormFieldsRendererComponent: PropTypes.func,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
};

Form.defaultProps = {
  cancelButtonText: 'Cancel',
  FormFieldsRendererComponent: FormFieldsRenderer,
  initialValues: {},
  onCancel: null,
  submitButtonText: 'Save',
};

export default Form;
