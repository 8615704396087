import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle } from '@material-ui/core';
import Payer from 'finance/assets/js/lib/Payer';
import Icon from '@material-ui/core/Icon';
import PayerMappingCreate from 'admin/assets/js/resources/payer_mappings/create.jsx';

const MapPayerDialog = ({
  isOpen, handleClose, dimPayer, onSuccess,
}) => {
  const payer = new Payer(dimPayer.details);
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          {`Map payer ${payer.getName()} to organization`}
          <Button
            style={{ padding: 0, minWidth: 'initial' }}
            onClick={handleClose}
          >
            <Icon>close</Icon>
          </Button>
        </div>
      </DialogTitle>
      <PayerMappingCreate
        record={{ dim_payer_id: dimPayer.id }}
        onSuccess={onSuccess}
      />
    </Dialog>
  );
};

MapPayerDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  dimPayer: PropTypes.object,
  onSuccess: PropTypes.func,
};

MapPayerDialog.defaultProps = {
  isOpen: false,
  dimPayer: null,
  onSuccess: () => {},
};

export default MapPayerDialog;
