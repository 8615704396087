import React from 'react';
import { ReferenceField, Datagrid, List, TextField, FunctionField } from 'react-admin';
import PropTypes from 'prop-types';

import ListFilter from 'admin/assets/js/resources/system_bank_accounts/ListFilter.jsx';
import SystemBankAccountsMenu from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountsMenu.jsx';
import SystemBankAccountStatus from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountStatus.jsx';
import SystemBankAccountsListActions from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountsListActions.jsx';
import { SYSTEM_BANK_ACCOUNT_STATUS } from 'finance/assets/js/constants';

const SystemBankAccountsList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<SystemBankAccountsListActions />}
    sort={{ field: 'id', order: 'ASC' }}
    filters={<ListFilter />}
    filterDefaultValues={{
      status: SYSTEM_BANK_ACCOUNT_STATUS.ACTIVE,
    }}
  >
    <Datagrid>
      <ReferenceField
        label="ID"
        source="id"
        reference="system_bank_accounts"
        link="show"
      >
        <TextField source="id" />
      </ReferenceField>
      <FunctionField
        sortBy="bankName"
        label="Institution"
        render={systemBankAccount => (
          <span>{systemBankAccount.details.bankName}</span>
        )}
      />
      <ReferenceField label="Name" source="invoicing_entity_id" reference="invoicing_entities" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Currency" source="currency" style={{ textTransform: 'uppercase' }} />
      <FunctionField
        sortBy="countryCode"
        label="Country"
        render={systemBankAccount => (
          <span>{systemBankAccount.details.countryCode?.toUpperCase() || '-'}</span>
        )}
      />
      <FunctionField
        sortBy="iban"
        label="IBAN"
        render={systemBankAccount => (
          <span>{systemBankAccount.details.bankFields?.iban || '-'}</span>
        )}
      />
      <FunctionField
        sortBy="bic"
        label="BIC"
        render={systemBankAccount => (
          <span>{systemBankAccount.details.bankFields?.bic || '-'}</span>
        )}
      />
      <FunctionField
        sortBy="accountNumber"
        label="Account number"
        render={systemBankAccount => (
          <span>{systemBankAccount.details.bankFields?.accountNumber || '-'}</span>
        )}
      />
      <FunctionField
        sortBy="bankCode"
        label="Bank code"
        render={systemBankAccount => (
          <span>{systemBankAccount.details.bankFields?.bankCode || '-'}</span>
        )}
      />
      <FunctionField
        sortBy="status"
        label="Status"
        render={systemBankAccount => (
          <SystemBankAccountStatus systemBankAccount={systemBankAccount} />
        )}
      />
      <SystemBankAccountsMenu />
    </Datagrid>
  </List>
);

SystemBankAccountsList.propTypes = {
  basePath: PropTypes.string,
};

SystemBankAccountsList.defaultProps = {
  basePath: null,
};

export default SystemBankAccountsList;
