import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';

import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import { financeInvoicePreviewUpcomingOutbound } from 'finance/urls';
import httpClient from 'admin/assets/js/lib/httpClient';

const ViewNextInvoicesLogsButton = ({ record: organization, billingDeadline }) => {
  const notify = useNotify();
  const [logs, setLogs] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setLoading(true);
        const url = financeInvoicePreviewUpcomingOutbound(
          organization.unique_alias, billingDeadline,
        );
        const res = await httpClient(url, { method: 'GET' });
        const { invoicingLogs } = res.json;
        setLogs(invoicingLogs);
        setLoading(false);
      } catch (err) {
        notify(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [organization, billingDeadline, httpClient, setLoading, setLogs]);

  return (
    <>
      {isLoading && 'Fetching logs...'}
      {logs && <pre>{lineBreakToBr(logs)}</pre>}
    </>
  );
};

ViewNextInvoicesLogsButton.propTypes = {
  record: PropTypes.object,
  billingDeadline: PropTypes.string,
};

ViewNextInvoicesLogsButton.defaultProps = {
  record: {},
  billingDeadline: null,
};

export default ViewNextInvoicesLogsButton;
