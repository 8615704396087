import { pick } from 'lodash';
import React, { useState } from 'react';

import BannerForm from 'admin/assets/js/components/BannerForm.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { adminApiBannerCreateUrl } from 'admin/urls';
import { API_DATE_FORMAT, USER_TYPE } from 'core/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate, getDatetime } from 'core/assets/js/lib/utils';
import { INVOICING_MODE } from 'finance/assets/js/constants';

export const BannerCreateView = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const notifications = getNotifications();

  const goBack = () => history.push('/banners');
  const onAdd = async values => {
    setLoading(true);
    try {
      const finalValues = {
        ...pick(
          values,
          'title',
          'button_label',
          'button_url',
          'content',
          'invoicing_modes',
          'dismissable',
          'organizations',
          'start_date',
          'type',
        ),
        show_after_dismissed: values.dismissable && values.show_after_dismissed,
        end_date: values.definite === 'yes' ? values.end_date : null,
        user_roles: values.user_roles.map(id => parseInt(id, 10)),
      };
      await apiClient({ data: finalValues, method: 'POST', url: adminApiBannerCreateUrl() });
      goBack();
    } catch (e) {
      notifications.error(e._error || e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BannerForm
      initialValues={{
        definite: 'yes',
        dismissable: true,
        invoicing_modes: Object.values(INVOICING_MODE),
        organizations: 0,
        show_after_dismissed: true,
        start_date: formatDate(getDatetime(), API_DATE_FORMAT),
        user_roles: Object.values(USER_TYPE).map(type => type.toString()),
      }}
      loading={loading}
      onCancel={goBack}
      onSubmit={onAdd}
    />
  );
};

BannerCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default BannerCreateView;
