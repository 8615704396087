import React from 'react';
import { Filter, SelectInput } from 'react-admin';

import { INVOICE_STATUS_LABEL, INVOICE_TYPE_LABEL } from 'finance/assets/js/constants';

const statusOptions = Object.keys(INVOICE_STATUS_LABEL).map(k => ({
  id: k, name: INVOICE_STATUS_LABEL[k],
}));

const typeOptions = Object.keys(INVOICE_TYPE_LABEL).map(k => ({
  id: k, name: INVOICE_TYPE_LABEL[k],
}));

/**
 * List filters UI for biling process invoices.
 *
 * Allow filtering by either type ( outbound, inbound etc. )
 * or status ( paid, raised, etc. ).
 *
 * @param {Object} props - component properties.
 * @return {JSX.Element} rendered filter components.
 */
const ListFilters = props => (
  <Filter {...props}>
    <SelectInput label="Type" alwaysOn allowEmpty source="type" choices={typeOptions} />
    <SelectInput label="Status" alwaysOn allowEmpty source="status" choices={statusOptions} />
  </Filter>
);

export default ListFilters;
