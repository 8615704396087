import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { useGetOne } from 'react-admin';

import PricingScaleStatusField from 'admin/assets/js/resources/pricing_scales/PricingScaleStatusField.jsx';

const OrgPricingScaleField = ({ record }) => {
  if (!record) {
    return null;
  }
  const { pricing_scale_id } = record;
  if (!pricing_scale_id) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Chip
          onClick={() => {}}
          style={{ backgroundColor: '#FAB152', color: 'white', maxWidth: '100px' }}
          size="small"
          label="Custom plan"
        />
      </div>
    );
  }
  const { data, loading } = useGetOne('pricing_scales', pricing_scale_id);
  if (loading) {
    return <div>...</div>;
  }
  const { version } = data;
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-2">
        {`Version ${version}`}
      </div>
      <PricingScaleStatusField record={data} />
    </div>
  );
};

OrgPricingScaleField.propTypes = {
  record: PropTypes.object,
};

OrgPricingScaleField.defaultProps = {
  record: {},
};

export default OrgPricingScaleField;
