import React from 'react';
import PropTypes from 'prop-types';

import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core';

import { AUDIT_LEVEL } from 'finance/assets/js/constants';


const useStyles = makeStyles({
  container: {
    backgroundColor: '#ebf5fb',
  },
  icon: {
    color: '#35a0dc',
  },
  title: {
    color: '#35a0dc',
  },
  body: {
    color: '#525550',
    marginBottom: 0,
  },
});

const AuditBanner = ({ level }) => {
  const classes = useStyles();

  if (![AUDIT_LEVEL.SEVERE, AUDIT_LEVEL.CRITICAL].includes(level)) {
    return null;
  }

  const title = {
    [AUDIT_LEVEL.CRITICAL]: 'Create Jira tickets for "Critical" values!',
    [AUDIT_LEVEL.SEVERE]: 'Acknowledge "Severe" values!',
  };

  const body = {
    [AUDIT_LEVEL.CRITICAL]: 'In order to pass this audit, you need to view details of the item and create a Jira ticket.',
    [AUDIT_LEVEL.SEVERE]: 'In order to pass the audit, you need to view details of the item and accept them by clicking on "Accept & Continue" button.',
  };

  return (
    <div className={`d-flex flex-row m-4 p-3 ${classes.container}`}>
      <InfoIcon className={classes.icon} />
      <div className="d-flex flex-column pl-2">
        <p className={classes.title}>{title[level]}</p>
        <p className={classes.body}>{body[level]}</p>
      </div>
    </div>
  );
};

AuditBanner.propTypes = {
  level: PropTypes.number.isRequired,
};

export default AuditBanner;
