import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

const InvoicingEntityEditButton = ({ onClick, record }) => (
  record ? (
    <Button
      color="primary"
      onClick={() => onClick(record)}
      startIcon={<EditIcon />}
    >
      Edit
    </Button>
  ) : null
);

InvoicingEntityEditButton.propTypes = {
  onClick: PropTypes.func,
  record: PropTypes.object,
};

InvoicingEntityEditButton.defaultProps = {
  onClick: null,
  record: null,
};

export default InvoicingEntityEditButton;
