import { pick } from 'lodash';
import React, { useState } from 'react';
import { Confirm } from 'react-admin';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';

import AMLResultChip from 'admin/assets/js/components/AMLResultChip.jsx';
import AMLRecordActions from 'admin/assets/js/components/AMLRecordActions.jsx';
import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import DialogCloseButton from 'admin/assets/js/components/buttons/DialogCloseButton.jsx';
import DateInput from 'admin/assets/js/components/inputs/DateInput.jsx';
import PhoneNumberInput, { COUNTRIES } from 'admin/assets/js/components/inputs/PhoneNumberInput.jsx';
import SelectInput from 'admin/assets/js/components/inputs/SelectInput.jsx';
import Form from 'admin/assets/js/components/Form.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { DATETIME_FORMAT_DEFAULT, GENDERS, TITLES } from 'core/assets/js/constants';
import { WINDOW_RELOAD } from 'core/assets/js/config/settings';
import { alpha3ToAlpha2CountryCode, countryOptions } from 'core/assets/js/lib/isoCountries';
import { formatDate } from 'admin/assets/js/lib/utils';
import { useStyles } from 'admin/assets/js/resources/full_users/UserOrgInfo.jsx';
import { adminApiAMLCheckCreateUrl, adminApiAMLManualCheckCreateUrl } from 'admin/urls';
import {
  SMART_SEARCH_DOCUMENT_TYPE_LABEL,
  SMART_SEARCH_DOCUMENT_TYPE_VALUES,
  SMART_SEARCH_RESULT_COLOR,
  SMART_SEARCH_RESULT_LABEL,
} from 'organizations/assets/js/constants';

const TITLES_OPTIONS = TITLES.map(value => ({ label: value, value }));
const COUNTRY_OPTIONS = countryOptions.map(({ text, value }) => ({ label: text, value }));
const GENDER_OPTIONS = GENDERS.map(value => ({ label: value, value }));
const DOCUMENT_OPTIONS = SMART_SEARCH_DOCUMENT_TYPE_VALUES.map(value => ({
  label: SMART_SEARCH_DOCUMENT_TYPE_LABEL[value], value,
}));

const UserAmlInfo = ({ user, profile }) => {
  const classes = useStyles();
  const { latestAMLCheck } = user;

  const [amlModalOpen, setAMLModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manualAMLCheckOpen, setManualAMLCheckOpen] = useState(false);

  const onCreateAMLCheckClose = () => setAMLModalOpen(false);

  const notifications = getNotifications();

  if (!user) {
    return null;
  }

  let countryCode = profile?.address_components?.country_code;
  if (!countryCode && latestAMLCheck?.sent_payload?.address?.country) {
    countryCode = alpha3ToAlpha2CountryCode(latestAMLCheck.sent_payload.address.country);
  }
  let phoneDefaultCountry = null;
  if (countryCode && COUNTRIES.includes(countryCode)) {
    phoneDefaultCountry = countryCode;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.labelStyle}>AML status</div>
        <Chip
          style={{
            backgroundColor: latestAMLCheck?.result
              ? SMART_SEARCH_RESULT_COLOR[latestAMLCheck.result] : 'rgba(0, 0, 0, 0.26)',
            color: 'white',
          }}
          size="small"
          label={
            latestAMLCheck?.result
              ? SMART_SEARCH_RESULT_LABEL[latestAMLCheck.result]
              : 'Not done'
          }
        />
        <div className="mt-4">
          <Button
            onClick={() => setAMLModalOpen(true)}
            size="medium"
            variant="outlined"
          >
            RUN IDENTITY CHECK
          </Button>
        </div>
        <div className="my-4">
          <Button
            onClick={() => setManualAMLCheckOpen(true)}
            size="medium"
            variant="outlined"
          >
            MANUALLY CREATE PASSED IDENTITY CHECK
          </Button>
          <Confirm
            isOpen={manualAMLCheckOpen}
            loading={loading}
            title="Are you sure you want to manually create a passed identity check?"
            content={`This will manually create an anti-money laundering check that is passed,
              without going through SmartSearch. Are you sure you want to do this?`}
            onConfirm={async () => {
              setLoading(true);
              try {
                await apiClient({
                  method: 'POST', url: adminApiAMLManualCheckCreateUrl(user.id),
                });
                setManualAMLCheckOpen(false);
                notifications.success('Passed identity check manually created successfully');
                WINDOW_RELOAD();
              } catch (e) {
                notifications.error(
                  e.response?.data?._error || e.message || 'Creating identity check failed',
                );
              } finally {
                setLoading(false);
              }
            }}
            onClose={() => setManualAMLCheckOpen(false)}
          />
        </div>
        <ApiTable
          columns={[
            {
              label: 'Date',
              name: 'created_at',
              options: {
                cellRender: createdAt => formatDate(createdAt, DATETIME_FORMAT_DEFAULT),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Result',
              name: 'result',
              options: {
                cellRender: (_, amlCheck) => <AMLResultChip amlCheck={amlCheck} />,
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Manually created by',
              name: 'manually_created_by',
              options: {
                cellRender: (userId, amlCheck) => {
                  if (!userId) {
                    return null;
                  }
                  return (
                    <Link to={`/full_users/${userId}/show`}>
                      {amlCheck.manually_created_by_name}
                    </Link>
                  );
                },
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Actions',
              name: 'sent_payload',
              options: {
                cellRender: (_, amlCheck) => <AMLRecordActions amlCheck={amlCheck} />,
                filter: false,
                sort: false,
              },
            },
          ]}
          defaultSortBy="created_at"
          defaultSortDirection="DESC"
          downloadEnabled={false}
          draggableColumns={false}
          extraResourceQueryParams={{ user_id: user.id }}
          resource="user_aml_checks"
          searchEnabled={false}
          useQueryParams={false}
          viewColumnsEnabled={false}
        />
        <Dialog fullScreen onClose={onCreateAMLCheckClose} open={amlModalOpen}>
          <DialogTitle>
            Run identity check
            <DialogCloseButton onClose={onCreateAMLCheckClose} />
          </DialogTitle>
          <DialogContent>
            <Form
              fields={[
                {
                  Component: SelectInput,
                  disabled: loading,
                  label: 'Title',
                  name: 'title',
                  options: TITLES_OPTIONS,
                  required: true,
                  validate: value => (!value ? 'Select a valid title' : undefined),
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'First name',
                  name: 'firstName',
                  required: true,
                  validate: value => (!value ? 'Enter a valid first name' : undefined),
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Last name',
                  name: 'lastName',
                  required: true,
                  validate: value => (!value ? 'Enter a valid last name' : undefined),
                },
                {
                  Component: PhoneNumberInput,
                  defaultCountry: phoneDefaultCountry,
                  disabled: loading,
                  label: 'Phone number',
                  name: 'phone',
                  required: true,
                  validate: value => (!value ? 'Enter a valid phone' : undefined),
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Flat or apartment name/number',
                  name: 'flat',
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Building name or number',
                  name: 'building',
                  required: true,
                  validate: value => (!value ? 'Enter a valid building' : undefined),
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Street line 1',
                  name: 'street1',
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Street line 2',
                  name: 'street2',
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Town/city',
                  name: 'town',
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Region',
                  name: 'region',
                },
                {
                  Component: TextField,
                  disabled: loading,
                  label: 'Postcode/zipcode',
                  name: 'postCode',
                  required: true,
                  validate: value => (!value ? 'Enter a valid postcode' : undefined),
                },
                {
                  Component: SelectInput,
                  disabled: loading,
                  label: 'Country',
                  name: 'country',
                  options: COUNTRY_OPTIONS,
                  required: true,
                  validate: value => (!value ? 'Select a valid country' : undefined),
                },
                {
                  Component: SelectInput,
                  disabled: loading,
                  label: 'Document issuing country',
                  name: 'issuingCountry',
                  options: COUNTRY_OPTIONS,
                  required: true,
                  validate: value => (!value ? 'Select a valid country' : undefined),
                },
                {
                  Component: DateInput,
                  disabled: loading,
                  label: 'Date of birth',
                  name: 'dateOfBirth',
                  required: true,
                  validate: value => (
                    !/^\d{4}-\d{2}-\d{2}$/.test(value)
                      ? 'Select a valid date of birth'
                      : undefined
                  ),
                },
                {
                  Component: SelectInput,
                  disabled: loading,
                  label: 'Gender',
                  name: 'gender',
                  options: GENDER_OPTIONS,
                  required: true,
                  validate: value => (!value ? 'Select a valid gender' : undefined),
                },
                {
                  Component: SelectInput,
                  disabled: loading,
                  label: 'Document type',
                  name: 'documentType',
                  options: DOCUMENT_OPTIONS,
                  required: true,
                  validate: value => (!value ? 'Select a valid document type' : undefined),
                },
              ]}
              initialValues={{
                ...pick(profile, 'dateOfBirth', 'firstName', 'lastName', 'phone'),
                ...pick(
                  latestAMLCheck?.sent_payload?.address || {}, 'building', 'flat', 'region',
                ),
                country: countryCode,
                documentType: latestAMLCheck?.sent_payload?.document_type[0],
                gender: latestAMLCheck?.sent_payload?.gender,
                issuingCountry: countryCode,
                postCode: (
                  profile?.address_components?.postal_code
                  || latestAMLCheck?.sent_payload?.address?.postcode
                ),
                street1: latestAMLCheck?.sent_payload?.address?.street_1,
                street2: latestAMLCheck?.sent_payload?.address?.street_1,
                title: latestAMLCheck?.sent_payload?.name?.title,
                town: (
                  profile?.address_components?.city
                  || latestAMLCheck?.sent_payload?.address?.town
                ),
              }}
              onCancel={onCreateAMLCheckClose}
              onSubmit={async data => {
                setLoading(true);
                try {
                  data.userId = user.id; // eslint-disable-line no-param-reassign
                  await apiClient({ data, method: 'POST', url: adminApiAMLCheckCreateUrl() });
                  onCreateAMLCheckClose();
                  notifications.success('Identity check started successfully');
                  WINDOW_RELOAD();
                } catch (e) {
                  notifications.error(
                    e.response?.data?._error || e.message || 'Creating identity check failed',
                  );
                } finally {
                  setLoading(false);
                }
              }}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

UserAmlInfo.propTypes = {
  user: PropTypes.object,
  profile: PropTypes.object,
};

UserAmlInfo.defaultProps = {
  user: {},
  profile: {},
};

export default UserAmlInfo;
