import moment from 'moment';
import { fromPairs } from 'lodash';

// https://api-docs.transferwise.com/#quotes-create-response
class TransferwiseTransferChange {
  constructor({ id, ...details }) {
    this.id = id;
    Object.assign(this, details);
  }

  serialize() {
    return fromPairs(Object.entries(this));
  }

  getTransferId() {
    return this.data.resource.id;
  }

  getCurrentState() {
    return this.data.current_state;
  }

  getOccurredAt() {
    return moment(this.data.occurred_at);
  }

  getAccountId() {
    return this.data.resource.account_id;
  }
}

export default TransferwiseTransferChange;
