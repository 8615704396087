import PropTypes from 'prop-types';
import React from 'react';
import {
  SelectInput, DateInput, Edit, FormDataConsumer, SaveButton, SimpleForm, Toolbar,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { SUBSCRIPTION_PLAN_LABELS, SUBSCRIPTION_PLAN_VALUES } from 'finance/assets/js/constants';

const PLAN_CHOICES = Object.entries(SUBSCRIPTION_PLAN_LABELS).map(([id, name]) => ({ id, name }));

const EditButtons = props => <Toolbar {...props}><SaveButton /></Toolbar>;

const SubscriptionEditDialog = ({ onClose, subscription, basePath }) => (
  <Dialog onClose={onClose} open={!!subscription}>
    {subscription && (
      <>
        <DialogTitle>{`Edit subscription #${subscription.id}`}</DialogTitle>
        <DialogContent>
          <Edit
            basePath={basePath}
            resource="subscriptions"
            id={subscription.id}
            onSuccess={onClose}
            undoable={false}
          >
            <SimpleForm redirect={false} toolbar={<EditButtons />}>
              <SelectInput
                helperText="Change this subscription's plan"
                choices={PLAN_CHOICES}
                label="Plan"
                source="plan"
                validate={val => (
                  !SUBSCRIPTION_PLAN_VALUES.includes(parseInt(val, 10)) ? 'Field is required' : ''
                )}
              />
              <SelectInput
                helperText="Is this subscription a trial?"
                choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                label="Is trial"
                source="is_trial"
                validate={val => (![true, false].includes(val) ? 'Field is required' : '')}
              />
              <FormDataConsumer>
                {({ formData }) => formData.is_trial && (
                  <DateInput
                    helperText="Change the trial expiration date"
                    fullWidth
                    label="Trial expires at"
                    source="trial_expiration_date"
                  />
                )
                }
              </FormDataConsumer>
            </SimpleForm>
          </Edit>
        </DialogContent>
      </>
    )}
  </Dialog>
);

SubscriptionEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  basePath: PropTypes.string,
};

SubscriptionEditDialog.defaultProps = {
  basePath: null,
};

export default SubscriptionEditDialog;
