import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PreformattedField from 'admin/assets/js/components/fields/PreformattedField.jsx';


const useStyles = makeStyles(() => ({
  accordionSummary: {
    flexDirection: 'row-reverse',
  },
  accordionHeading: {
    fontWeight: 600,
    marginLeft: '10px',
  },
}));

const TransactionDataTab = ({ transaction }) => {
  const [isExpanded, setExpand] = useState(true);
  const classes = useStyles();

  const toggleAccordion = useCallback(() => {
    setExpand(prev => !prev);
  }, [setExpand]);

  return (
    <div>
      <Accordion
        expanded={isExpanded}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          onClick={toggleAccordion}
        >
          <Typography
            variant="h6"
            className={classes.accordionHeading}
          >
            Transfer data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreformattedField
            record={transaction}
            label="Amounts"
            source="amounts_dump"
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

TransactionDataTab.propTypes = {
  transaction: PropTypes.object,
};

TransactionDataTab.defaultProps = {
  transaction: {},
};

export default TransactionDataTab;
