import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';

const InboundTransferListAsideMessage = ({ muiIcon, title, body, classes }) => (
  <Container
    style={{ marginTop: '10rem' }}
    className={classes}
  >
    <Icon style={{ fontSize: '4rem' }}>
      {muiIcon}
    </Icon>
    <Typography variant="h6">
      {title}
    </Typography>
    <Typography variant="body1">
      {body}
    </Typography>
  </Container>
);

InboundTransferListAsideMessage.propTypes = {
  muiIcon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default InboundTransferListAsideMessage;
