import React from 'react';
import {
  DateField,
  Datagrid,
  List,
  ShowButton,
} from 'react-admin';

import ListFilter from 'admin/assets/js/resources/org_billing_analytics/ListFilter.jsx';
import CurrencyAmountField from 'admin/assets/js/resources/org_billing_analytics/CurrencyAmountField.jsx';
import { ORG_BILLING_ANALYTICS_CURRENCIES } from 'admin/assets/js/constants';

const TALENTDESK_LIMITED_ORG_ID = 2;

/**
 * Show a list of org_billing_analytics entities for a given org.
 * Each row represents a single billing process.
 * Use a custom field ( CurrencyAmountField ) to show monetary values.
 * @param {*} props - component properties.
 * @return {JSX.Element} rendered component.
 */
const OrgBillingAnalyticsList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ListFilter />}
    filterDefaultValues={{
      organization_id: TALENTDESK_LIMITED_ORG_ID,
      currency: ORG_BILLING_ANALYTICS_CURRENCIES.ORG_CURRENCY,
      showAllOrgs: false,
    }}
    sort={{ field: 'date', order: 'DESC' }}
  >
    <Datagrid>
      <DateField label="Date" source="date" />
      <CurrencyAmountField label="Client invoices" source="totalInvoiced" />
      <CurrencyAmountField label="Payments Received" source="paymentsReconciled" />
      <CurrencyAmountField label="Cash-In Diff" source="paymentsDue" />
      <CurrencyAmountField label="Contractor invoices" source="contractorInvoices" />
      <CurrencyAmountField label="Payments Made" source="amountPaid" />
      <CurrencyAmountField label="Cash-Out Diff" source="balance" />
      <CurrencyAmountField label="Licence Revenue" source="licenceFees" />
      <CurrencyAmountField label="Payments Revenue" source="processingFees" />
      <CurrencyAmountField label="Bank Fees" source="bankFees" />
      <CurrencyAmountField label="FX Costs" source="fxCosts" />
      <CurrencyAmountField label="VAT" source="vat" />
      <CurrencyAmountField label="Net Revenue" source="netRevenue" />
      <ShowButton label={null} />
    </Datagrid>
  </List>
);

export default OrgBillingAnalyticsList;
