import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  useRefresh,
  useNotify,
} from 'react-admin';
import {
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';
import { DOCUMENT_CREATE_ELEMENT, DOCUMENT_BODY_APPEND_CHILD, CREATE_BLOB } from 'core/assets/js/config/settings';

const downloadXLSX = (data, filename) => {
  const fakeLink = DOCUMENT_CREATE_ELEMENT('a');
  fakeLink.style.display = 'none';
  DOCUMENT_BODY_APPEND_CHILD(fakeLink);
  const buffer = Buffer.from(data, 'base64');
  const blob = CREATE_BLOB([buffer]);
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}.xlsx`);
  fakeLink.click();
};


const FeeMonthlyReportButton = ({ title, label, reportType, fileName }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async (values) => {
    const command = `reports/${reportType}`;
    const url = adminCommandApiUrl(command, { ...values });
    setLoading(true);
    try {
      const data = await httpClient(url, { method: 'GET' });
      downloadXLSX(data.body, fileName);
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>{label.toUpperCase()}</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={label}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the month and year of the report.
          </DialogContentText>
          <Form
            initialValues={{ targetMonth: moment().month() + 1, targetYear: moment().year() }}
            onSubmit={handleGenerateClick}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="targetMonth">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Month</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="text"
                          placeholder="ie: 1"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>

                  <Field name="targetYear">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Year</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="text"
                          placeholder="ie: 2022"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Download report" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

FeeMonthlyReportButton.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

FeeMonthlyReportButton.defaultProps = {};

export default FeeMonthlyReportButton;
