import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Datagrid, FunctionField,
} from 'react-admin';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { formatDate } from 'admin/assets/js/lib/utils';
import TransactionAmountsFieldPlain from 'admin/assets/js/resources/transactions/TransactionAmountsFieldPlain.jsx';
import TransactionStatusField from 'admin/assets/js/resources/transactions/TransactionStatusField.jsx';
import TransactionMethod from 'finance/assets/js/lib/TransactionMethod';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';

const Empty = () => (
  <p>No records</p>
);

const PerSubscriptionList = ({ basePath, subscriptionId }) => (
  subscriptionId ? (
    <List
      basePath={basePath}
      resource="transactions"
      hasShow={false}
      hasCreate={false}
      hasEdit={false}
      hasList
      bulkActionButtons={false}
      actions={false}
      sort={{ field: 'id', order: 'DESC' }}
      filter={{ subscription_id: subscriptionId }}
      pagination={false}
      empty={<Empty />}
    >
      <Datagrid
        optimized
      >
        <TransactionAmountsFieldPlain source="total" label="Amount" showFees={false} />
        <TransactionStatusField label="Status" />
        <FunctionField
          label="Remote payment id"
          render={(record) => {
            const trans = TransactionMethod.createTransactionMethod(record.vendor_details);

            return trans.getTransactionId();
          }}
        />
        <FunctionField
          label="Fee"
          render={(record) => {
            const amounts = new TransactionAmounts(record.amounts);
            const fee = amounts.getTotalFee();
            const currency = amounts.getOutgoingCurrency();
            const currencySymbol = CURRENCY_SYMBOL[currency];
            return (
              <span>
                <NumberTpl
                  value={fee}
                  prefix={currencySymbol}
                />
              </span>
            );
          }}
        />
        <FunctionField
          label="Net"
          render={(record) => {
            const amounts = new TransactionAmounts(record.amounts);
            const amount = amounts.getNetOutgoingAmount();
            const currency = amounts.getOutgoingCurrency();
            const currencySymbol = CURRENCY_SYMBOL[currency];
            return (
              <span>
                <NumberTpl
                  value={amount}
                  prefix={currencySymbol}
                />
              </span>
            );
          }}
        />
        <FunctionField
          label="Date"
          render={record => formatDate(record.paid_at || record.raised_at || record.date)}
        />
      </Datagrid>
    </List>
  ) : null
);

PerSubscriptionList.propTypes = {
  basePath: PropTypes.string.isRequired,
  subscriptionId: PropTypes.number.isRequired,
};

export default PerSubscriptionList;
