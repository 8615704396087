import React from 'react';
import { TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';

import {
  TRANSACTION_METHOD,
  TRANSACTION_METHOD_LABEL,
  TRANSACTION_STATUS,
} from 'finance/assets/js/constants';
import { ICON } from 'core/assets/js/constants';
import { PAYMENT_METHOD_DETAILS, PAYMENT_METHODS } from 'settings/assets/js/constants';

const TransactionFormRelatedInfoComponent = ({ formData, disabled }) => {
  const transactionIcon = {
    [TRANSACTION_METHOD.TRANSFERWISE]: (
      <img style={{ width: '80px' }} alt="Wise logo" src="/img/payment-options/wise-logo.png" />
    ),
    [TRANSACTION_METHOD.WORLDPAY]: (
      <img style={{ width: '130px' }} alt="Worldpay logo" src="/img/payment-options/worldpay-logo.png" />
    ),
    [TRANSACTION_METHOD.REALNET]: (
      <img style={{ width: '130px' }} alt="RealNet logo" src="/img/payment-options/realnet-logo.png" />
    ),
    [TRANSACTION_METHOD.WIRE_TRANSFER]: (
      <Icon className={ICON.BANK} />
    ),
    [TRANSACTION_METHOD.PAYPAL]: (
      <img style={{ width: '50px' }} alt="Paypal logo" src={PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYPAL].logo} />
    ),
    [TRANSACTION_METHOD.PAYONEER]: (
      <img style={{ width: '40px' }} alt="Payoneer logo" src={PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYONEER].logo} />
    ),
    [TRANSACTION_METHOD.STRIPE]: (
      <img style={{ width: '80px' }} alt="Stripe logo" src="/img/payment-options/stripe-logo.png" />
    ),
    [TRANSACTION_METHOD.REVOLUT]: (
      <img style={{ width: '100px' }} alt="Revolut logo" src="/img/payment-options/revolut-logo.png" />
    ),
  };
  return (
    <div className="mb-4 w-100">
      <div className="d-flex align-items-center justify-content-center mb-3">
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-2">{transactionIcon[formData.method]}</div>
          <div className="text-uppercase">
            {TRANSACTION_METHOD_LABEL[formData.method]}
            {' '}
            related info
          </div>
        </div>
      </div>
      {formData.method === TRANSACTION_METHOD.WIRE_TRANSFER && (
        <div>
          <div>
            <TextInput
              disabled={disabled}
              className="mr-4"
              source="vendor_details.transactionId"
              label="Transaction id"
            />
            <TextInput
              disabled={disabled}
              source="vendor_details.sortCode"
              label="Bank sort code"
            />
          </div>
          <TextInput
            disabled={disabled}
            source="vendor_details.accountNumber"
            label="Bank account number"
            fullWidth
          />
        </div>
      )}

      {formData.method === TRANSACTION_METHOD.PAYPAL && (
        <TextInput
          disabled={disabled}
          source="vendor_details.transactionId"
          label="Transaction id"
          fullWidth
        />
      )}

      {formData.method === TRANSACTION_METHOD.PAYONEER && (
        <TextInput
          disabled={disabled}
          source="vendor_details.clientReferenceId"
          label="Payout id"
          fullWidth
        />
      )}

      {formData.method === TRANSACTION_METHOD.TRANSFERWISE && (
        <>
          <TextInput
            disabled={disabled}
            source="vendor_details.transferId"
            label="Transfer id"
            fullWidth
          />
          {formData.status === TRANSACTION_STATUS.FAILED && (
            <p>
              Wise failed to create this transfer,
              {' '}
              please check the invoice details for more info about the transfer.
            </p>
          )}
        </>
      )}
      {formData.method === TRANSACTION_METHOD.REVOLUT && (
        <>
          <TextInput
            disabled={disabled}
            source="vendor_details.remoteTransactionId"
            label="Transaction id"
            fullWidth
            required
            helperText="Enter your input in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          />
          <TextInput
            disabled={disabled}
            source="vendor_details.accountId"
            label="Account id"
            fullWidth
            helperText="Enter your input in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          />
        </>
      )}
      {formData.method === TRANSACTION_METHOD.WORLDPAY && (
        <>
          <TextInput
            disabled={disabled}
            source="vendor_details.transferId"
            label="Transfer id"
            fullWidth
          />
          <TextInput
            disabled={disabled}
            source="vendor_details.secondaryTransferId"
            label="Statement id"
            fullWidth
          />
        </>
      )}

      {formData.method === TRANSACTION_METHOD.REALNET && (
        <>
          <TextInput
            disabled={disabled}
            source="vendor_details.transactionId"
            label="Transaction id"
            fullWidth
          />
        </>
      )}

      {(formData.method === TRANSACTION_METHOD.STRIPE && (
        <>
          <TextInput
            disabled={disabled}
            source="vendor_details.transactionId"
            label="Transaction id"
            fullWidth
          />
        </>
      ))}
    </div>
  );
};

TransactionFormRelatedInfoComponent.propTypes = {
  formData: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

TransactionFormRelatedInfoComponent.defaultProps = {
  disabled: false,
};

export default TransactionFormRelatedInfoComponent;
