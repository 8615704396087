import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { formatDate } from 'core/assets/js/lib/utils';
import { DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { ACTIVITY_RECORD_TYPE_FILTER } from 'finance/assets/js/constants';

const useStyles = makeStyles({
  lines: {
    marginBottom: 0,
  },
});

const PricingAudits = (props) => {
  const { record: { id: orgId }  } = props;
  const classes = useStyles();

  return (
    <>
      <h3>Audit trail</h3>
      <List
        filter={{ type: ACTIVITY_RECORD_TYPE_FILTER.PRICING, orgId }}
        sort={{ field: 'created_at', order: 'DESC' }}
        resource="activity_records"
        basePath="/activity_records"
        emptyWhileLoading={<h3>Loading ...</h3>}
        empty={<h3>No activity currently recorded ...</h3>}
        actions={false}
        bulkActionButtons={false}
        exporter={false}
      >
        <Datagrid>
          <FunctionField
            label="Date"
            sortBy="created_at"
            render={r => formatDate(new Date(r.createdAt), DATETIME_FORMAT_DEFAULT)}
          />
          <TextField sortBy="user_id" label="User" source="user.profile.name" />
          <FunctionField
            label="Changelog"
            sortBy="body"
            render={r => r.extendedBody
              .map((eb, k) => (
                <p key={k} className={classes.lines}>{eb}</p>
              ))
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

PricingAudits.propTypes = {
  record: PropTypes.object.isRequired,
};

export default PricingAudits;
