import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isNil } from 'lodash';
import Big from 'big.js';

import {
  Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton,
  InputAdornment, Table, TableBody, TableCell, TableContainer, TextField, Paper,
  TableRow, TableHead, makeStyles,
} from '@material-ui/core';

import { ICON } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ProcessingFeeScheme from 'finance/assets/js/lib/ProcessingFeeScheme';
import Money from 'finance/assets/js/lib/Money';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      width: 220,
      margin: 5,
    },
    '& .MuiFormHelperText-root': { marginLeft: 0 },
  },
  totalRow: { backgroundColor: '#ffe' },
  withValue: { borderColor: '#3F51B5 !important' },
}));

const ProcessingFeesCalculator = ({ showCalculatorIcon, feeScheme }) => {
  const classes = useStyles();
  const parsed = new ProcessingFeeScheme(feeScheme);
  const currencySymbol = parsed.getCurrencySymbol();
  const currency = parsed.getCurrency();

  const [open, showDialog] = useState(false);
  const [values, setValues] = React.useState({
    approvedWorksheetsCount: 0,
    amount: 0,
  });

  const scaleSteps = parsed.getScaleSteps();
  const showScaleFee = parsed.hasScale();
  const showPerApprovedWsFee = parsed.hasPerApprovedWorksheet();
  const scaleFloor = parsed.getFloor();
  const hasScaleFloor = !new Money(scaleFloor, currency).isZero();
  const firstScaleStepFee = get(scaleSteps, '[0].feeFactor', null);


  const { scale, perApprovedWorksheet } = parsed.apply(
    values.amount,
    values.approvedWorksheetsCount,
  );
  // fee totals
  const perApprovedWsTotalFee = perApprovedWorksheet.reduce(
    (acc, step) => Big(acc).plus(step.fee), new Big(0),
  ).toFixed(2);
  const scaleTotalFee = scale.reduce(
    (acc, step) => Big(acc).plus(step.fee), new Big(0),
  ).toFixed(2);

  const totalFee = parsed.getFee(
    values.amount,
    values.approvedWorksheetsCount,
  );

  const handleChange = prop => (event) => {
    const parsedNumber = parseInt(event.target.value, 10);
    if (!parsedNumber) {
      return;
    }
    setValues({ ...values, [prop]: event.target.value || 0 });
  };

  return (
    <React.Fragment>
      <IconButton
        className={`pl-0 mt-1 ${showCalculatorIcon ? 'd-block' : 'd-none'}`}
        size="small"
        onClick={() => showDialog(!open)}
      >
        <Icon className={ICON.CALCULATOR} />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => showDialog(false)}
        loading={null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <div className="d-flex align-items-center">
            <Icon className={`${ICON.CALCULATOR} mr-2`} />
            Processing fees calculator
          </div>
        </DialogTitle>
        <DialogContent>
          <form className={classes.root} noValidate autoComplete="off">
            <div className="mb-1"><strong>Applied fees</strong></div>
            {showPerApprovedWsFee && (
              <TextField
                id="outlined-basic"
                label="Per approved worksheet"
                variant="outlined"
                className="m-1"
                disabled
                defaultValue={parsed.getPerApprovedWorksheet()}
                InputProps={{
                  classes: {
                    ...showPerApprovedWsFee ? { notchedOutline: classes.withValue } : {},
                  },
                  startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                }}
              />
            )}
            {showScaleFee && (
              <React.Fragment>
                <TextField
                  id="outlined-basic"
                  label="Scale floor"
                  helperText={
                    (!isNil(firstScaleStepFee) && firstScaleStepFee && hasScaleFloor)
                      ? `Amount to pass floor: ${currencySymbol} ${Big(scaleFloor).div(firstScaleStepFee)}`
                      : ''
                  }
                  variant="outlined"
                  className="m-1"
                  disabled
                  defaultValue={scaleFloor}
                  InputProps={{
                    classes: {
                      ...showScaleFee ? { notchedOutline: classes.withValue } : {},
                    },
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Scale ceiling"
                  variant="outlined"
                  className="m-1"
                  disabled
                  defaultValue={parsed.getCeiling() || '0.00'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                />
                <div className="mb-1"><strong>Scale steps</strong></div>
                {scaleSteps.map((step, index) => (
                  <div className="d-flex align-items-center mb-1" key={`step-${index}`}>
                    <TextField
                      id="outlined-basic"
                      label="From"
                      variant="outlined"
                      className="m-1"
                      disabled
                      defaultValue={step.range[0]}
                      InputProps={{
                        classes: {
                          ...(step.feePercent !== 0) ? { notchedOutline: classes.withValue } : {},
                        },
                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                      }}
                    />
                    <div className="d-flex my-2 flex-column align-items-center justify-content-center">
                      <Chip
                        size="small"
                        className="mb-1"
                        color="primary"
                        label={`${step.feePercent}%`}
                      />
                      <Icon
                        className={ICON.ARROW_RIGHT}
                      />
                    </div>
                    <TextField
                      id="outlined-basic"
                      label="To"
                      variant="outlined"
                      className="m-1"
                      disabled
                      defaultValue={step.range[1] === Infinity ? '- any amount -' : step.range[1]}
                      InputProps={{
                        classes: {
                          ...(step.feePercent !== 0) ? { notchedOutline: classes.withValue } : {},
                        },
                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                      }}
                    />
                  </div>
                ))}
              </React.Fragment>
            )}
            <hr />
            <div className="mb-1"><strong>Amounts</strong></div>
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              defaultValue={values.amount}
              onChange={handleChange('amount')}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
            />
            {showPerApprovedWsFee && (
              <TextField
                id="outlined-basic"
                label="Approved worksheets (Qty)"
                variant="outlined"
                defaultValue={values.approvedWorksheetsCount}
                onChange={handleChange('approvedWorksheetsCount')}
              />
            )}
          </form>
          <hr />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Fee</strong></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="scaling-fees">
                  <TableCell component="th" scope="row">
                    Scaling fees
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={scaleTotalFee}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="per-approved-worksheets">
                  <TableCell component="th" scope="row">
                    Per approved worksheet
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={perApprovedWsTotalFee}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="total-fees" className={classes.totalRow}>
                  <TableCell component="th" scope="row">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={totalFee}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showDialog(false)} color="primary">
            <Icon>close</Icon>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};


ProcessingFeesCalculator.propTypes = {
  feeScheme: PropTypes.object.isRequired,
  showCalculatorIcon: PropTypes.bool,
};

ProcessingFeesCalculator.defaultProps = {
  showCalculatorIcon: true,
};

export default ProcessingFeesCalculator;
