/* eslint max-len: "warn", react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import PropTypes from 'prop-types';
import ClearFiltersButton from 'admin/assets/js/components/buttons/ClearFiltersButton.jsx';
import { FunctionField, TextField, List, Datagrid, Filter, TextInput, SelectInput, ReferenceInput, BooleanInput, ShowButton } from 'react-admin';
import { formatDate } from 'admin/assets/js/lib/utils';
import InvoiceAmountsFieldPlain from 'admin/assets/js/resources/invoices/InvoiceAmountsFieldPlain.jsx';
import InvoiceInfoField from 'admin/assets/js/resources/invoices/InvoiceInfoField.jsx';
import { PAYMENT_METHODS_LABEL } from 'settings/assets/js/constants';
import {
  INVOICE_STATUS_LABEL, INVOICE_TYPE,
} from 'finance/assets/js/constants';
import InvoiceStatus from 'admin/assets/js/components/InvoiceStatus';

const Empty = () => (
  <p>No records</p>
);

const paymentMethodOptions = Object.keys(PAYMENT_METHODS_LABEL).map(k => ({
  id: k, name: PAYMENT_METHODS_LABEL[k],
}));

const statusOptions = Object.keys(INVOICE_STATUS_LABEL).map(k => ({
  id: k, name: INVOICE_STATUS_LABEL[k],
}));

const ListFilter = (props) => {
  const { filterValues, setFilters } = props;
  const { type: invoiceType } = filterValues;

  return (
    <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput
          alwaysOn
          reference="dim_billing_processes"
          source="billing_process_id"
          sort={{ field: 'billing_date', order: 'DESC' }}
          label="Billing date"
        >
          <SelectInput
            optionText={process => formatDate(process.billing_date)}
          />
        </ReferenceInput>
        <SelectInput
          label="Status"
          alwaysOn
          allowEmpty
          source="status"
          choices={statusOptions}
        />
        <SelectInput
          label="Payment Method"
          alwaysOn
          allowEmpty
          source="paymentMethod"
          choices={paymentMethodOptions}
        />
        {invoiceType === INVOICE_TYPE.OUTBOUND && (
          <BooleanInput
            label="Licence"
            source="is_licence"
            alwaysOn
          />
        )}
        {invoiceType === INVOICE_TYPE.OUTBOUND && (
          <BooleanInput
            label="Subscription"
            source="is_subscription"
            alwaysOn
          />
        )}
        {invoiceType === INVOICE_TYPE.INBOUND && (
          <BooleanInput
            label="Without transaction"
            source="withoutTransaction"
            alwaysOn
          />
        )}
      </Filter>
      <ClearFiltersButton
        onClearFilters={setFilters}
      />
    </div>
  );
};

const InvoicesTab = ({ organization }) => {
  return (
    <List
      actions={null}
      basePath="/invoices"
      bulkActionButtons={false}
      empty={<Empty />}
      filters={<ListFilter />}
      filterDefaultValues={{ organization_id: organization.id }}
      hasCreate={false}
      hasEdit={false}
      hasList
      hasShow={false}
      resource="invoices"
      sort={{ field: 'created_at', order: 'DESC' }}
      title=" - Invoices"
    >
      <Datagrid>
        <InvoiceAmountsFieldPlain
          showFees={false}
          source="total"
          label="Amount"
          sortable={false}
        />
        <FunctionField
          label="Status"
          render={record => {
            return (
              <InvoiceStatus
                status={record.status}
                raisedStatus={record.raisedStatus}
                isOverdue={record.isOverdue}
              />
            );
          }}
        />
        <InvoiceInfoField label="Number" />
        <TextField label="Due Date" source="dueAt" sortable={false} />
        <TextField label="Creation Date" source="date" sortable={false} />
        <ShowButton label="" />
      </Datagrid>
    </List>
  );
};

InvoicesTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default InvoicesTab;

