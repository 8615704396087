import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import ProcessingFeeScheme from 'finance/assets/js/lib/ProcessingFeeScheme';
import { ProcessingFeeSpec } from 'finance/assets/js/components/invoicing/FeeScheme.jsx';
import ProcessingFeesCalculator from 'admin/assets/js/components/ProcessingFeesCalculator.jsx';

const ProcessingFeeField = ({ record }) => {
  if (!record) {
    return null;
  }
  const scheme = new InvoicingFeeScheme(record.invoicing_fee_scheme);
  const processingFeeScheme = scheme.getProcessingFeeSpec();
  const parsedProcFeeScheme = new ProcessingFeeScheme(processingFeeScheme);
  const hasProcessingFee = parsedProcFeeScheme.hasFee();

  const onTrial = moment(record.trial_expires_at).isAfter(moment());

  if (onTrial) {
    return (<span>On trial</span>);
  }
  return (
    <React.Fragment>
      <ProcessingFeeSpec scheme={processingFeeScheme} />
      <ProcessingFeesCalculator
        showCalculatorIcon={hasProcessingFee}
        feeScheme={processingFeeScheme}
      />
    </React.Fragment>
  );
};

ProcessingFeeField.propTypes = {
  record: PropTypes.object,
};

ProcessingFeeField.defaultProps = {
  record: {},
};

export default ProcessingFeeField;
