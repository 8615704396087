import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import { TRANSACTION_SEGMENT } from 'finance/assets/js/constants';
import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogContentText, DialogTitle, DialogContent } from '@material-ui/core';
import { adminCommandApiUrl } from 'admin/urls';
import Storage from 'core/assets/js/lib/Storage';

import {
  Button, useRefresh, useNotify,
  ReferenceManyField,
  useGetOne,
} from 'react-admin';

const ApproveTransactionsButton = (props) => {
  const { transactionIds, orgBillingId, total, disabled } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleApproveClick = async (values) => {
    const command = 'approve-reviewed-transactions';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    Storage.set('wiseToken', values.token);
    Storage.set('wisePrivateKey', values.privateKey);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify({
        orgBillingIds: [orgBillingId],
        transactionIds,
        ...values,
      }) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  const initialValues = {
    token: Storage.get('wiseToken'),
    privateKey: Storage.get('wisePrivateKey'),
  };

  const label = !disabled
    ? `Mark ${isEmpty(transactionIds) ? total : transactionIds.length} transactions as approved`
    : 'Mark transactions as approved';
  return (
    <>
      <Button
        onClick={handleClick}
        icon={<MonetizationOnIcon />}
        label={label}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={label}
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want mark all reviewed transactions as approved?
          </DialogContentText>
          <Form onSubmit={handleApproveClick} initialValues={initialValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="token">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Token</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '500px',
                          }}
                          {...input}
                          type="text"
                          placeholder="Your Wise token"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  <Field name="privateKey">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Private key</label>
                        <br />
                        <textarea
                          placeholder="Your Wise private key"
                          {...input}
                          style={{
                            padding: '10px',
                            width: '500px',
                          }}
                          rows="20"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Approve" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

ApproveTransactionsButton.propTypes = {
  orgBillingId: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  transactionIds: PropTypes.array,
};

ApproveTransactionsButton.defaultProps = {
  transactionIds: [],
};

const OrgBillingTransactionsApproveButton = (props) => {
  const { selectedIds, permissions, basePath, orgBillingId } = props;
  const canApprove = (
    permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
  );
  const transactionIds = selectedIds || [];
  const { data, loading } = useGetOne('organization_billings', orgBillingId);
  const orgId = data?.organization_id;
  const billingProcessId = data?.billing_process_id;
  const disabled = !canApprove || loading;
  if (loading) {
    return null;
  }
  return (
    <ReferenceManyField
      label="Approve transactions"
      reference="org_billing_transactions"
      target="org_billing_id"
      basePath={basePath}
      filter={{
        billing_process_id: billingProcessId,
        organization_id: orgId,
        segment: TRANSACTION_SEGMENT.PENDING_APPROVAL,
      }}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <ApproveTransactionsButton
        orgBillingId={orgBillingId}
        disabled={disabled}
        transactionIds={transactionIds}
      />
    </ReferenceManyField>
  );
};

OrgBillingTransactionsApproveButton.propTypes = {
  selectedIds: PropTypes.array,
  basePath: PropTypes.string.isRequired,
  orgBillingId: PropTypes.number.isRequired,
  permissions: PropTypes.object,
};

OrgBillingTransactionsApproveButton.defaultProps = {
  selectedIds: [],
  permissions: {},
};

export default OrgBillingTransactionsApproveButton;
