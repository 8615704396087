import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, useNotify } from 'react-admin';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const styles = () => ({
  dialogTitleStyle: {
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});

const ESStatsButton = ({ classes }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  const getIndicesStats = async () => {
    setLoading(true);
    try {
      await httpClient(
        adminCommandApiUrl('indices-stats'), { method: 'GET' },
      );
      setShowDialog(false);
    } catch (e) {
      notify(e.message, 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a
        className="btn btn-outline-dark m-1"
        onClick={handleShowDialog}
        rel="noopener noreferrer"
        target="_blank"
      >
        <strong>GET INDICES STATS</strong>
      </a>
      <Dialog
        fullWidth
        onClose={handleHideDialog}
        open={showDialog}
      >
        <DialogTitle className={classes.dialogTitleStyle}>
          <span>
            Get indices stats
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Get indices stats in the response body
          </DialogContentText>
          { !loading && (
            <div style={{ margin: '30px 0 15px' }}>
              <Button
                label="Cancel"
                onClick={handleHideDialog}
                style={{ marginRight: '20px' }}
                type="button"
                variant="outlined"
              />
              <Button
                onClick={getIndicesStats}
                label="Save"
                style={{ marginRight: '20px' }}
                type="button"
                variant="contained"
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

ESStatsButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ESStatsButton);
