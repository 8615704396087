import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from 'admin/assets/js/layout/themes';
import { RECIPIENT_FIELDS } from 'services/assets/js/constants';

const RecipientField = ({ label, value }) => {
  if (!value) {
    return null;
  }
  const classes = useStyles();
  return (
    <div className="d-flex">
      <span className={classes.discreet}>{label}</span>
      <span className="pl-4 ml-auto">{value}</span>
    </div>
  );
};

RecipientField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const RecipientDetailsField = ({ record }) => {
  if (!record) {
    return null;
  }
  const { id, accountHolderName, details } = record.details;
  const classes = useStyles();
  const validFields = RECIPIENT_FIELDS.filter(k => !!details[k]);
  return (
    <React.Fragment>
      <div className="d-flex">
        <span className={classes.discreet}>
          {accountHolderName}
          {' '}
          (#
          {id}
          )
        </span>
      </div>
      {validFields.filter(f => !['address', 'legalType', 'accountType'].includes(f)).map(field => (
        <RecipientField key={field} label={field} value={details[field]} />
      ))}
    </React.Fragment>
  );
};

RecipientDetailsField.propTypes = {
  record: PropTypes.object,
};

RecipientDetailsField.defaultProps = {
  record: null,
};

export default RecipientDetailsField;
