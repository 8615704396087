import React from 'react';
import PropTypes from 'prop-types';
import { adminInvoiceDownloadUrl } from 'admin/urls';
import Icon from '@material-ui/core/Icon';
import {
  Button,
} from 'react-admin';

const InvoiceDownloadButton = ({ record }) => {
  return (
    <Button label="Download" href={adminInvoiceDownloadUrl(record.id)}>
      <Icon>cloud_download</Icon>
    </Button>
  );
};

InvoiceDownloadButton.propTypes = {
  record: PropTypes.object,
};

InvoiceDownloadButton.defaultProps = {
  record: {},
};

export default InvoiceDownloadButton;
