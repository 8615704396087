import React from 'react';
import PropTypes from 'prop-types';
import Big from 'big.js';
import { useStyles } from 'admin/assets/js/layout/themes';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';

const QuoteDetailsField = ({ record, expectedAmount }) => {
  const { selectedPaymentOption, rate } = record.details;
  if (!selectedPaymentOption) {
    return null;
  }
  const classes = useStyles();
  const { sourceCurrency, targetCurrency } = selectedPaymentOption;
  const sourceSymbol = CURRENCY_SYMBOL[sourceCurrency.toLowerCase()];
  const targetSymbol = CURRENCY_SYMBOL[targetCurrency.toLowerCase()];
  const fee = Big(selectedPaymentOption.fee.total).toFixed(2);
  const paidAmount = Big(selectedPaymentOption.sourceAmount).minus(fee).toFixed(2);
  return (
    <React.Fragment>
      <div className="d-flex">
        <span className={classes.discreet}>we send</span>
        <strong className="pl-4 ml-auto">
          <NumberTpl
            value={Big(selectedPaymentOption.sourceAmount).toFixed(2)}
            prefix={sourceSymbol}
          />
        </strong>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>total fees (included)</span>
        <span className="pl-4 ml-auto">
          <NumberTpl value={fee} prefix={sourceSymbol} />
        </span>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>tranferwise will send</span>
        <strong className={`${paidAmount === expectedAmount ? classes.success : classes.danger} pl-4 ml-auto`}>
          <NumberTpl value={paidAmount} prefix={sourceSymbol} />
        </strong>
      </div>
      {rate !== 1 && (
        <div className="d-flex">
          <span className={classes.discreet}>
            exchange rate
            {' '}
            {sourceCurrency}
            {targetCurrency}
          </span>
          <span className="pl-4 ml-auto">
            {rate}
          </span>
        </div>
      )}
      <div className="d-flex">
        <span className={classes.discreet}>provider will receive</span>
        <strong className="pl-4 ml-auto">
          <NumberTpl
            value={Big(selectedPaymentOption.targetAmount).toFixed(2)}
            prefix={targetSymbol}
          />
        </strong>
      </div>
      <div className="d-flex">
        <span className={classes.discreet}>should arrive</span>
        <span className="pl-4 ml-auto">
          {selectedPaymentOption.formattedEstimatedDelivery}
        </span>
      </div>
    </React.Fragment>
  );
};

QuoteDetailsField.propTypes = {
  record: PropTypes.object,
  expectedAmount: PropTypes.string,
};

QuoteDetailsField.defaultProps = {
  expectedAmount: '0.00',
  record: null,
};

export default QuoteDetailsField;
