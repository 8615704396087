import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';

import SystemBankAccountStatus from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountStatus.jsx';
import { useGetOne } from 'react-admin';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    color: 'gray',
  },
  fieldValue: {
    textAlign: 'right',
    color: '#000',
  },
}));

const SystemBankAccountDetails = ({ record }) => {
  const {
    id,
    details,
    invoicing_entity_id: invoicingEntityId,
  } = record;
  const classes = useStyles();
  const {
    data,
    loading: invoicingEntityLoading,
  } = useGetOne('invoicing_entities', invoicingEntityId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <Card>
          <CardHeader title="Bank account details" />
          <CardContent sx={{ m: 0, p: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  ID
                </Typography>
                <Typography className={classes.fieldValue}>{id}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Institution
                </Typography>
                <Typography className={classes.fieldValue}>
                  { invoicingEntityLoading ? 'Loading...' : data?.name }
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Name
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.bankName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Currency
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.currency?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Country
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.countryCode?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  IBAN
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.bankFields?.iban || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  BIC
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.bankFields?.bic || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Account number
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.bankFields?.accountNumber || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Bank code
                </Typography>
                <Typography className={classes.fieldValue}>
                  {details.bankFields?.bankCode || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.column}>
                <Typography>
                  Status
                </Typography>
                <SystemBankAccountStatus systemBankAccount={record} size="small" />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

SystemBankAccountDetails.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    invoicing_entity_id: PropTypes.number,
    details: PropTypes.shape({
      bankName: PropTypes.string,
      bankFields: PropTypes.object,
      currency: PropTypes.string,
      countryCode: PropTypes.string,
    }),
  }),
};

SystemBankAccountDetails.defaultProps = {
  record: {},
};

export default SystemBankAccountDetails;
