import { isNaN, isNumber } from 'lodash';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormDataConsumer, DateInput, SimpleForm,
  SelectInput, NumberInput, ReferenceInput,
} from 'react-admin';

import { CURRENCY } from 'core/assets/js/constants';
import {
  SUBSCRIPTION_PLAN_LABELS, SUBSCRIPTION_PLAN_VALUES, SUBSCRIPTION_PLANS,
} from 'finance/assets/js/constants';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const PLAN_CHOICES = Object.entries(SUBSCRIPTION_PLAN_LABELS).map(([id, name]) => ({ id, name }));

const SubscriptionForm = (props) => {
  const currencyOptions = useMemo(() => Object.values(CURRENCY).map(value => ({
    id: value, name: value,
  })), [CURRENCY]);
  return (
    <SimpleForm
      {...props}
      redirect={false}
      variant="standard"
    >
      <FormDataConsumer>
        {({ formData: { plan } }) => {
          const unlimitedPlanSelected = !!(
            plan && parseInt(plan, 10) === SUBSCRIPTION_PLANS.UNLIMITED
          );
          return (
            <>
              <div>
                <ReferenceInput
                  alwaysOn
                  allowEmpty
                  label="Organization"
                  source="organization_id"
                  reference="organizations_lite"
                  perPage={MAX_ORGANIZATIONS_FETCHED}
                  filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <SelectInput
                    fullWidth
                    validate={val => (!val ? 'Field is required' : '')}
                    optionText={org => `${org.name} (${org.unique_alias})`}
                  />
                </ReferenceInput>
              </div>
              <div>
                <SelectInput
                  choices={PLAN_CHOICES}
                  label="Plan"
                  source="plan"
                  validate={val => (
                    !SUBSCRIPTION_PLAN_VALUES.includes(parseInt(val, 10)) ? 'Field is required' : ''
                  )}
                />
                <SelectInput
                  choices={[{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }]}
                  defaultValue={0}
                  label="Is trial"
                  source="is_trial"
                  validate={val => (![0, 1].includes(val) ? 'Field is required' : '')}
                />
                <DateInput
                  disabled={false}
                  source="started_at"
                  label="Started at"
                  fullWidth
                  required
                  validate={val => (!val ? 'Field is required' : '')}
                />
                <DateInput
                  disabled={false}
                  fullWidth
                  label="Expires at"
                  required={!unlimitedPlanSelected}
                  source="expires_at"
                  validate={val => {
                    if (!unlimitedPlanSelected && !val) {
                      return 'Field is required';
                    }
                    return '';
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <NumberInput
                  style={{ flex: 1.5 }}
                  source="manager_seats"
                  label="Manager seats"
                  step={1}
                  defaultValue={0}
                  required
                  validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
                />
                <NumberInput
                  style={{ flex: 1.5 }}
                  source="provider_seats"
                  label="Provider seats"
                  step={1}
                  defaultValue={0}
                  required
                  validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
                />
              </div>
              <div className="d-flex align-items-center">
                <NumberInput
                  label="Amount"
                  required={!unlimitedPlanSelected}
                  source="amount"
                  step={0.01}
                  style={{ flex: 2 }}
                  validate={val => {
                    if (
                      !unlimitedPlanSelected
                      && (!val || !isNumber(val) || isNaN(val) || parseFloat(val) < 0.01)
                    ) {
                      return 'Amount must be a positive number';
                    }
                    return '';
                  }}
                />
                <SelectInput
                  style={{ flex: 1 }}
                  label="Currency"
                  source="currency"
                  choices={currencyOptions}
                  initialValue={CURRENCY.GBP}
                />
              </div>
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

SubscriptionForm.propTypes = {
  record: PropTypes.object,
};

SubscriptionForm.defaultProps = {
  record: {},
};

export default SubscriptionForm;
