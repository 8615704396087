import React from 'react';
import PropTypes from 'prop-types';

import {
  ON_DEMAND_PAYOUT_SEGMENT,
} from 'finance/assets/js/constants';
import OnDemandPayoutsMarkAsReviewedButton from 'admin/assets/js/resources/on_demand_payouts/components/OnDemandPayoutsMarkAsReviewedButton';
import OnDemandPayoutsUnmarkAsReviewedButton from 'admin/assets/js/resources/on_demand_payouts/components/OnDemandPayoutsUnmarkAsReviewedButton';
import OnDemandPayoutsApproveButton from 'admin/assets/js/resources/on_demand_payouts/components/OnDemandPayoutsApproveButton';

const OnDemandPayoutsListBulkActionButtons = ({ segment, basePath, selectedIds, permissions }) => {
  if (segment === ON_DEMAND_PAYOUT_SEGMENT.BEING_PROCESSED) {
    return null;
  }

  if (segment === ON_DEMAND_PAYOUT_SEGMENT.PENDING_REVIEW) {
    return (
      <OnDemandPayoutsMarkAsReviewedButton
        permissions={permissions}
        selectedIds={selectedIds}
      />
    );
  }
  return (
    <>
      <OnDemandPayoutsUnmarkAsReviewedButton
        permissions={permissions}
        selectedIds={selectedIds}
      />
      <OnDemandPayoutsApproveButton
        permissions={permissions}
        selectedIds={selectedIds}
        basePath={basePath}
      />
    </>
  );
};

OnDemandPayoutsListBulkActionButtons.propTypes = {
  segment: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  selectedIds: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default OnDemandPayoutsListBulkActionButtons;
