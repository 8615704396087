import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import { LicenceFeeSpec } from 'finance/assets/js/components/invoicing/FeeScheme.jsx';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';
import LicenceFeesCalculator from 'admin/assets/js/components/LicenceFeesCalculator.jsx';


const LicenceFeeField = ({ record }) => {
  if (!record) {
    return null;
  }
  const scheme = new InvoicingFeeScheme(record.invoicing_fee_scheme);
  const licenceFeeScheme = scheme.getLicenceFeeSpec();
  const parsedLicenceFeeScheme = new LicenceFeeScheme(licenceFeeScheme);
  const showLicenceCalculator = parsedLicenceFeeScheme.hasFee();

  const onTrial = moment(record.trial_expires_at).isAfter(moment());

  if (onTrial) {
    return (<span>On trial</span>);
  }
  return (
    <React.Fragment>
      <LicenceFeeSpec scheme={scheme.getLicenceFeeSpec()} />
      <LicenceFeesCalculator
        showCalculatorIcon={showLicenceCalculator}
        feeScheme={licenceFeeScheme}
      />
    </React.Fragment>
  );
};

LicenceFeeField.propTypes = {
  record: PropTypes.object,
};

LicenceFeeField.defaultProps = {
  record: {},
};

export default LicenceFeeField;
