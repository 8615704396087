/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';

import InvoiceSingleAmountField from 'admin/assets/js/resources/invoices/InvoiceSingleAmountField.jsx';
import { COMPANY_TAX_SYSTEM, COMPANY_TAX_SYSTEM_INFO } from 'settings/assets/js/constants';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import Money from 'finance/assets/js/lib/Money';

const InvoiceInboundAmountsPreview = ({ record, classes }) => {
  if (!record || !record.amounts) {
    return null;
  }
  const invoiceAmounts = new InvoiceAmounts(record.amounts);

  const currency = invoiceAmounts.getCurrency();
  const targetCurrency = invoiceAmounts.getTargetCurrency();
  const exchangeRate = invoiceAmounts.getExchangeRate();
  const spotExchangeRate = invoiceAmounts.getSpotExchangeRate();
  const exchangeRateMarkup = invoiceAmounts.getExchangeRateMarkup();
  const bankFeeTotal = invoiceAmounts.getBankFee();
  const vatPercent = invoiceAmounts.getVatPercent();
  const contractorCharges = invoiceAmounts.getContractorCharges();
  const vat = invoiceAmounts.getVat();
  const total = invoiceAmounts.getTotal();
  const targetTotal = invoiceAmounts.getTargetTotal();

  const hasBankFeeTotal = !new Money(bankFeeTotal, currency).isZero();

  const ownerFE = new FinancialEntity(record.ownerFE);
  const taxSystemType = ownerFE.getTaxSystemType();
  const taxSystemLabel = taxSystemType
    ? COMPANY_TAX_SYSTEM_INFO[taxSystemType].label
    : COMPANY_TAX_SYSTEM_INFO[COMPANY_TAX_SYSTEM.VAT].label;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.column}>
        <Typography variant="subtitle2" color="textSecondary">
          Contractor charges
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={contractorCharges} currency={currency} />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.column}>
        <Typography variant="subtitle2" color="textSecondary">
          {`${taxSystemLabel} ${vatPercent}%`}
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={vat} currency={currency} />
        </Typography>
      </Grid>
      { hasBankFeeTotal && (
        <Grid item xs={12} className={classes.column}>
          <Typography variant="subtitle2" color="textSecondary">
            Bank fee
          </Typography>
          <Typography variant="subtitle2">
            <InvoiceSingleAmountField amount={bankFeeTotal} currency={currency} />
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={`${classes.column} ${classes.totalRow}`}>
        <Typography variant="subtitle2" color="textSecondary">
          Total
        </Typography>
        <Typography variant="subtitle2">
          <InvoiceSingleAmountField amount={total} currency={currency} />
        </Typography>
      </Grid>
      { currency && targetCurrency && (currency !== targetCurrency) && (
        <>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Total (local)
            </Typography>
            <Typography variant="subtitle2">
              <InvoiceSingleAmountField amount={targetTotal} currency={targetCurrency} />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Initial exchange rate
            </Typography>
            <Typography variant="subtitle2">
              {spotExchangeRate}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Exchange rate applied markup
            </Typography>
            <Typography variant="subtitle2">
              {`${exchangeRateMarkup * 100}%`}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Total exchange rate
            </Typography>
            <Typography variant="subtitle2">
              {exchangeRate}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Local currency
            </Typography>
            <Typography variant="subtitle2">
              {targetCurrency}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

InvoiceInboundAmountsPreview.propTypes = {
  record: PropTypes.object,
};

InvoiceInboundAmountsPreview.defaultProps = {
  record: {},
};

export default InvoiceInboundAmountsPreview;
