import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Icon } from '@material-ui/core';

const useStylesCustomTooltip = makeStyles(theme => ({
  arrow: {
    color: '#efefef',
  },
  tooltip: {
    padding: '10px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[2],
    fontSize: '14px',
  },
}));

function CustomTooltip(props) {
  const { children, placement } = props;
  const classes = useStylesCustomTooltip();
  return (
    <Tooltip
      arrow
      classes={classes}
      placement={placement}
      {...props}
    >
      {children || (
        <Icon
          style={{ color: 'gray', margin: '0 5px' }}
          fontSize="small"
        >
          help_outline
        </Icon>
      )}
    </Tooltip>
  );
}

CustomTooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
};

CustomTooltip.defaultProps = {
  children: null,
  placement: 'bottom',
};


export default CustomTooltip;
