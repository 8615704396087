import { orderBy } from 'lodash';
import React from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';

import { countryNames } from 'core/assets/js/lib/isoCountries';
import { ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';

const statusOptions = Object.keys(ORGANIZATION_STATUS_LABEL).map(k => ({
  id: k, name: ORGANIZATION_STATUS_LABEL[k],
}));

const countryOptions = orderBy(
  Object.keys(countryNames).map(id => ({ id, name: countryNames[id] })), 'name',
);


const ListFilter = props => (
  <Filter {...props}>
    <SelectInput label="Status" alwaysOn allowEmpty source="status" choices={statusOptions} />
    <SelectInput
      alwaysOn
      allowEmpty
      choices={countryOptions}
      label="Country"
      source="country_code"
    />
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export default ListFilter;
