import { ValidationError, ValidationErrorItem } from 'core/assets/js/errors';
import { TRANSACTION_METHOD } from 'finance/assets/js/constants';

/**
 * PayPal transaction method pojo.
 * Records all details specific to a PayPal transaction.
 */
class TransactionMethodPayPal {
  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  /**
   * Initialise instance with initial values.
   * @param {object} param0 - instance values.
   */
  init({
    transactionId,
  }) {
    const sanitizedTransactionId = typeof transactionId === 'string'
      ? transactionId.trim() : transactionId;

    if (!transactionId) {
      throw new ValidationError('Transaction id is required', [
        new ValidationErrorItem('transactionId', 'Transaction id is required'),
      ]);
    }

    if (!/^[\d|\w]{17}$/.test(sanitizedTransactionId)) {
      throw new ValidationError('Transaction id is not valid', [
        new ValidationErrorItem('transactionId', 'Transaction id is not valid'),
      ]);
    }

    this.details = {
      method: TRANSACTION_METHOD.PAYPAL,
      transactionId: sanitizedTransactionId,
    };
  }

  /**
   * Serialize instance.
   * @return {object} serialized instance.
   */
  serialize() {
    const res = { ...this.details };
    return res;
  }

  /**
   * Get a transfer descriptor for this transaction method.
   * @return {string} the transfer descriptor.
   */
  getTransactionDescriptor() {
    return `#${this.details.transactionId}`;
  }

  /**
   * Get the transaction id.
   * @return {string} the transaction id.
   */
  getTransactionId() {
    return this.details.transactionId;
  }
}

export default TransactionMethodPayPal;
