
import CustomSettingsGeneralView from 'admin/assets/js/CustomSettingsGeneralView.jsx';
import { SETTINGS_TABS } from 'admin/assets/js/constants';

const CustomSettingsMappingView = ({ ...props }) => {
  return CustomSettingsGeneralView({
    ...props,
    selectedTab: SETTINGS_TABS.MAPPING,
  });
};

export default CustomSettingsMappingView;
