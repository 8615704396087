import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Tabs, Tab } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useListContext } from 'react-admin';

const TabbedInvoiceDataGrid = ({ showTabs, Grid, setFilters, ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const list = useListContext();
  const searchParams = queryString.parse(location.search);
  const filterValues = searchParams.filter ? JSON.parse(searchParams.filter) : {};
  const selectedTab = list.filterValues?.selectedTab || 'outbound';
  const tab = showTabs ? selectedTab : 'all';
  const tabs = [
    { id: 'outbound', name: 'outbound' },
    { id: 'pph', name: 'direct pph' },
    { id: 'direct', name: 'direct' },
    { id: 'inbound', name: 'inbound' },
    { id: 'all', name: 'all' },
    { id: 'failing', name: 'failing' },
  ];

  if (!setFilters) {
    return null;
  }

  return (
    <React.Fragment>
      {showTabs && (
        <Tabs
          style={{ marginBottom: '15px' }}
          value={tab}
          indicatorColor="primary"
          onChange={(event, value) => {
            if (selectedTab !== value) {
              const newSearchParams = {
                ...searchParams,
                filter: JSON.stringify({
                  ...filterValues,
                  selectedTab: value,
                }),
              };

              history.push({
                pathname: location.pathname,
                search: queryString.stringify(newSearchParams),
              });
            }
          }}
        >
          {tabs.map(choice => (
            <Tab
              key={choice.id}
              label={choice.name}
              value={choice.id}
            />
          ))}
        </Tabs>
      )}
      <Divider />
      <Grid {...props} />
    </React.Fragment>
  );
};

TabbedInvoiceDataGrid.propTypes = {
  showTabs: PropTypes.bool,
  setFilters: PropTypes.func,
  Grid: PropTypes.elementType.isRequired,
};

TabbedInvoiceDataGrid.defaultProps = {
  showTabs: false,
  setFilters: null,
};

export default TabbedInvoiceDataGrid;
