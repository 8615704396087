import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
} from 'react-admin';

import { STRIPE_SUBSCRIPTION_STATUS_LABEL } from 'services/assets/js/constants';
import { getStripeStatusColor, formatStripeTimestamp } from 'services/assets/js/lib/utils';

const DimStripeSubscriptionList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid
      rowClick="show"
    >
      <TextField
        label="Id"
        source="id"
      />
      <TextField
        label="Stripe subscription id"
        source="details.id"
        sortable={false}
      />
      <FunctionField
        label="Status"
        source="details.status"
        sortable={false}
        render={record => (
          <span className={getStripeStatusColor(record.details.status)}>
            {STRIPE_SUBSCRIPTION_STATUS_LABEL[record.details.status]}
          </span>
        )}
      />
      <FunctionField
        label="Current period start"
        source="details.current_period_start"
        sortable={false}
        render={record => (
          <span>{formatStripeTimestamp(record.details.current_period_start)}</span>
        )}
      />
      <FunctionField
        label="Current period end"
        source="details.current_period_end"
        sortable={false}
        render={record => (
          <span>{formatStripeTimestamp(record.details.current_period_end)}</span>
        )}
      />
      <TextField
        label="Plan"
        source="details.plan.nickname"
        sortable={false}
      />
      <TextField
        label="Quantity"
        source="details.quantity"
        sortable={false}
      />
    </Datagrid>
  </List>
);

export default DimStripeSubscriptionList;
