import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from 'admin/assets/js/layout/themes';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import { SERVICE_KEY_NAME } from 'finance/assets/js/constants';


const InvoiceAmountsFieldPlain = ({ record, showFees }) => {
  const classes = useStyles();
  if (!record?.amounts) {
    return null;
  }
  const invoiceAmountsPOJO = new InvoiceAmounts(record.amounts);
  const amountsAmountsSerialized = invoiceAmountsPOJO.serialize();
  const fee = amountsAmountsSerialized ? amountsAmountsSerialized.fee : record.fee;
  const total = amountsAmountsSerialized ? amountsAmountsSerialized.total : record.total;
  const vat = amountsAmountsSerialized ? amountsAmountsSerialized.vat : record.vat;
  const childrenBankFees = amountsAmountsSerialized ? amountsAmountsSerialized.childrenBankFees : 0;
  const fees = (parseFloat(fee) + parseFloat(childrenBankFees)).toFixed(2);
  const currency = invoiceAmountsPOJO.getCurrency();
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const hasAORFee = invoiceAmountsPOJO.hasFeeForServiceKey(SERVICE_KEY_NAME.AOR);
  const hasLicenceFee = invoiceAmountsPOJO.hasLicenceFee();
  const hasSubscriptionFee = invoiceAmountsPOJO.hasSubscriptionFee();
  let feeLabel = 'Fee:';
  if (hasSubscriptionFee) {
    feeLabel = 'Subscription Fee:';
  } else if (hasAORFee) {
    feeLabel = 'Licence Fee (AOR):';
  } else if (hasLicenceFee) {
    feeLabel = 'Licence Fee:';
  }
  return (
    <span>
      <NumberTpl
        value={total}
        prefix={currencySymbol}
      />
      {showFees && (
        <span className={classes.discreet}>
          <br />
          {feeLabel}
          {' '}
          <NumberTpl
            value={fees}
            prefix={currencySymbol}
          />
          <br />
          Vat:
          {' '}
          <NumberTpl
            value={vat}
            prefix={currencySymbol}
          />
        </span>
      )}
    </span>
  );
};

InvoiceAmountsFieldPlain.propTypes = {
  record: PropTypes.object,
  showFees: PropTypes.bool,
};

InvoiceAmountsFieldPlain.defaultProps = {
  record: {},
  showFees: true,
};

export default InvoiceAmountsFieldPlain;
