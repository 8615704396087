/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import { isEmpty } from 'lodash';
import {
  Datagrid,
  DateInput,
  Filter,
  FunctionField,
  List,
  ListButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import ChevronRight from '@material-ui/icons/ChevronRight';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import Tooltip from '@material-ui/core/Tooltip';

import OrgNameField from 'admin/assets/js/components/OrgNameField.jsx';
import TransactionAnalysisField from 'admin/assets/js/resources/organization-billings/TransactionAnalysisField.jsx';
import OrganizationBillingExpansion from 'admin/assets/js/resources/organization-billings/OrganizationBillingExpansion.jsx';
import OrgBillingTransactionsButton from 'admin/assets/js/resources/organization-billings/OrgBillingTransactionsButton.jsx';
import OrgBillingReplayButton from 'admin/assets/js/resources/organization-billings/OrgBillingReplayButton.jsx';
import ListActions from 'admin/assets/js/resources/organization-billings/ListActions.jsx';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const ListFilter = props => (
  <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        allowEmpty
        label="Organization"
        source="organization_id"
        reference="organizations_lite"
        perPage={MAX_ORGANIZATIONS_FETCHED}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText={org => `${org.name}
           (${org.unique_alias})`}
        />
      </ReferenceInput>
      <DateInput
        source="billing_date"
        alwaysOn
        allowEmpty
      />
    </Filter>
  </div>
);

// eslint-disable-next-line react/no-multi-comp
const OrganizationBillingList = (props) => {
  const useStyles = makeStyles({
    dataGrid: {
      '& .column-analytics': {
        width: '350px',
      },
      '& .column-is_paid': {
        width: '150px',
      },
    },
  });
  const classes = useStyles();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid
        optimized
        expand={<OrganizationBillingExpansion />}
        className={classes.dataGrid}
      >
        <TextField source="id" label="#" />
        <ReferenceField
          label="Billing date"
          source="billing_process_id"
          reference="dim_billing_processes"
          link={false}
        >
          <TextField source="billing_date" />
        </ReferenceField>
        <ReferenceField
          label="Organization"
          source="organization_id"
          reference="organizations"
          link={false}
        >
          <OrgNameField  />
        </ReferenceField>
        <FunctionField
          render={(record) => {
            const {
              billing_process_id: billingProcessId, organization_id: orgId,
              analytics,
            } = record;
            const contractorInvoicesCount = analytics?.contractorInvoiceIds?.length || 0;
            const outboundInvoicesCount = analytics?.outboundInvoiceIds?.length || 0;
            const licenceInvoicesCount = analytics?.licenceInvoiceIds?.length || 0;
            const totalInvoices = contractorInvoicesCount
              + outboundInvoicesCount + licenceInvoicesCount;
            const queryOptions = queryString.stringify({
              order: 'DESC',
              perPage: 25,
              sort: 'id',
              filter: JSON.stringify({
                billing_process_id: billingProcessId,
                organization_id: orgId,
              }),
            });
            return (
              <ListButton
                basePath={`/invoices?${queryOptions}`}
                label={`See invoices ${totalInvoices ? `(${totalInvoices})` : ''}`}
                icon={<ChevronRight />}
              />
            );
          }}
        />
        <FunctionField
          source="analytics"
          label="Analytics"
          render={_props => (
            <TransactionAnalysisField label="Transactions" source="analytics" {..._props} />
          )}
        />
        <OrgBillingTransactionsButton label="Transactions" />
        <OrgBillingReplayButton label="Replay" />
        <FunctionField
          label="Paid"
          source="is_paid"
          render={(_record) => {
            if (!_record?.analytics || isEmpty(_record?.analytics)) {
              return null;
            }
            const unpaidContractorInvoicesCount = parseInt(
              _record?.analytics.unpaidContractorInvoicesCount, 10,
            ) || 0;
            const unpaidOutboundInvoicesCount = parseInt(
              _record?.analytics.unpaidOutboundInvoicesCount, 10,
            ) || 0;
            const unpaidLicenceInvoicesCount = parseInt(
              _record?.analytics.unpaidLicenceInvoicesCount, 10,
            ) || 0;
            const totalUnpaidInvoices = unpaidContractorInvoicesCount
              + unpaidOutboundInvoicesCount + unpaidLicenceInvoicesCount;
            const isPaid = totalUnpaidInvoices === 0;
            return (
              isPaid
                ? (
                  <Tooltip title="Invoice has been paid">
                    <BeenhereIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Invoice has not been paid yet">
                    <SettingsEthernetIcon />
                  </Tooltip>
                )

            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default OrganizationBillingList;
