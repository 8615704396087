import React from 'react';
import PropTypes from 'prop-types';
import {
  useRefresh,
  useNotify,
  downloadCSV,
} from 'react-admin';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const ReportButton = ({ label, reportType, fileName }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    const command = `reports/${reportType}`;
    const url = adminCommandApiUrl(command);
    try {
      const data = await httpClient(url, { method: 'GET' });
      downloadCSV(data.body, fileName);
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    }
  };

  return (
    <>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>{label.toUpperCase()}</strong>
      </a>
    </>
  );
};

ReportButton.propTypes = {
  label: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

ReportButton.defaultProps = {};

export default ReportButton;
