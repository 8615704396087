import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Form, Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { Button, useNotify } from 'react-admin';
import { adminCommandApiUrl } from 'admin/urls';
import httpClient from 'admin/assets/js/lib/httpClient';
import {
  Chip,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';

const styles = () => ({
  dialogTitleStyle: {
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});

const SyncIndexSetsButton = ({ classes }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [indicesTypes, setIndicesTypes] = useState([]);
  const [allIndices, setToggleAllIndices] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    const command = 'get-indices-types';
    const url = adminCommandApiUrl(command);
    // eslint-disable-next-line no-undef
    fetch(url)
      .then(res => res.json())
      .then((res) => {
        setIndicesTypes(res.indicesTypes);
      }).catch(() => {
        notify('Fetching indices types failed', 'warning');
      });
  }, []);

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  const handleSyncIndexSets = async (values) => {
    const command = 'sync-index-sets';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(values) });
    } catch (e) {
      notify('Syncing failed', 'warning');
      setLoading(false);
    } finally {
      setShowDialog(false);
      notify(`${values.indicesTypes.map(i => capitalize(i)).join(', ')} ${values.indicesTypes.length > 1 ? 'indices' : 'index'} sync scheduled`);
      setLoading(false);
    }
  };

  const handleToggleAllIndices = (e) => {
    e.preventDefault();
    setToggleAllIndices(!allIndices);
  };

  return (
    <React.Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleShowDialog}
      >
        <strong>SYNC INDEX SETS</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleHideDialog}
      >
        <DialogTitle
          className={classes.dialogTitleStyle}
        >
          <span>
            { loading ? 'Syncing index sets...' : 'Syncing index sets' }
          </span>
          { !loading && (
            <Chip
              onClick={handleToggleAllIndices}
              size="small"
              color="primary"
              label={allIndices ? 'Deselect all' : 'Select all'}
            />
          )}
        </DialogTitle>
        <DialogContent>
          { !loading && (
            <DialogContentText>
              Select the index you would like to sync:
            </DialogContentText>
          )}
          { loading && (
            <div style={{ width: '100%', textAlign: 'center', margin: '40px 0' }}>
              <CircularProgress />
            </div>
          )}
          { !loading && (
            <Form
              onSubmit={handleSyncIndexSets}
              initialValues={{ indicesTypes: allIndices ? indicesTypes.map(i => i) : [] }}
              render={({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    {indicesTypes.map(type => (
                      <div key={type}>
                        <label style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                          <Field
                            style={{ marginRight: '10px', padding: '10px' }}
                            name="indicesTypes"
                            component="input"
                            type="checkbox"
                            value={type}
                          />
                          {' '}
                          {capitalize(type)}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div style={{ margin: '30px 0 15px' }}>
                    <Button
                      style={{ marginRight: '20px' }}
                      label="Cancel"
                      type="button"
                      onClick={handleHideDialog}
                      variant="outlined"
                    />
                    <Button
                      style={{ marginRight: '20px' }}
                      variant="contained"
                      disabled={submitting || values.indicesTypes.length === 0}
                      label="Sync"
                      type="submit"
                    />
                  </div>
                </form>
              )}
            />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

SyncIndexSetsButton.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SyncIndexSetsButton);
