import { fromPairs, mapValues } from 'lodash';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import Money from 'finance/assets/js/lib/Money';
import { calcRate } from 'finance/assets/js/lib/utils';

class TransferAllocationRequest {
  constructor({ transferId, allocationPerInvoiceId }) {
    assertAllKeysPresent({ transferId, allocationPerInvoiceId });
    this.details = {
      transferId,
      allocationPerInvoiceId: mapValues(
        allocationPerInvoiceId,
        ({ amount, currency }) => new Money(amount, currency),
      ),
    };
  }

  serialize() {
    const { transferId, allocationPerInvoiceId } = this.details;
    return {
      transferId,
      allocationPerInvoiceId: mapValues(allocationPerInvoiceId, money => ({
        currency: money.getCurrency(),
        amount: money.toString(),
      })),
    };
  }

  getTransferId() {
    const { transferId } = this.details;
    return transferId;
  }

  getInvoiceIds() {
    const { allocationPerInvoiceId } = this.details;
    return Object.keys(allocationPerInvoiceId);
  }

  getAllocationFields({ transactionAmountsPerInvoiceId, invoiceAmountsPerInvoiceId }) {
    assertAllKeysPresent({ transactionAmountsPerInvoiceId, invoiceAmountsPerInvoiceId });
    const invoiceIds = this.getInvoiceIds();

    const missingTransactionAmounts = invoiceIds.filter(id => !transactionAmountsPerInvoiceId[id]);
    if (missingTransactionAmounts.length > 0) {
      throw new Error(`missing transaction amounts for invoices ${missingTransactionAmounts.join(', ')}`);
    }

    const missingInvoiceAmounts = invoiceIds.filter(id => !invoiceAmountsPerInvoiceId[id]);
    if (missingInvoiceAmounts.length > 0) {
      throw new Error(`missing invoice amounts for invoices ${missingInvoiceAmounts.join(', ')}`);
    }

    return fromPairs(invoiceIds.map((invoiceId) => {
      const invoiceAmounts = invoiceAmountsPerInvoiceId[invoiceId];
      const transactionAmounts = transactionAmountsPerInvoiceId[invoiceId];
      return this.getInvoiceAllocationFields({
        invoiceId,
        transactionAmounts,
        invoiceAmounts,
      });
    }));
  }

  getInvoiceAllocationFields({ invoiceId, transactionAmounts, invoiceAmounts }) {
    const { allocationPerInvoiceId } = this.details;
    const allocatedMoney = allocationPerInvoiceId[invoiceId];
    assertAllKeysPresent({ invoiceId, transactionAmounts, invoiceAmounts, allocatedMoney });
    const converted = transactionAmounts.convertToTargetCurrency(
      allocatedMoney.toString(),
      allocatedMoney.getCurrency(),
    );
    const invoiceAmount = transactionAmounts.convertToTargetCurrency(
      invoiceAmounts.getTotal(),
      invoiceAmounts.getCurrency(),
    );

    const remoteShare = calcRate(transactionAmounts.getTargetAmount(), converted).rate;
    const invoiceShare = calcRate(invoiceAmount, converted).rate;
    return {
      allocated_amount: allocatedMoney.toString(),
      allocated_currency: allocatedMoney.getCurrency(),
      detracted_amount: converted,
      // we have received money, therefore our balance is the target currency
      detracted_currency: transactionAmounts.getTargetCurrency(),
      manual_remote_share: remoteShare,
      manual_invoice_share: invoiceShare,
    };
  }
}

export default TransferAllocationRequest;
