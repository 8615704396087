import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import {
  INVOICE_RAISED_STATUS, INVOICE_RAISED_STATUS_LABEL,
  INVOICE_STATUS,
  INVOICE_STATUS_LABEL,
} from 'finance/assets/js/constants';

const statusOptions = Object.keys({
  ...INVOICE_STATUS,
  ...INVOICE_RAISED_STATUS,
}).map(status => ({
  name: (
    INVOICE_RAISED_STATUS_LABEL[INVOICE_RAISED_STATUS[status]]
    || INVOICE_STATUS_LABEL[INVOICE_STATUS[status]]
  ),
  id: INVOICE_RAISED_STATUS[status] || INVOICE_STATUS[status],
}));

// Instant payment and waiting payment are labeled as Raised
// We are filtering them out so that we dont show option Raised 3 times in the filters
const statusOptionWithoutDeferredRaised = statusOptions.filter(option => (
  ![
    INVOICE_RAISED_STATUS.INSTANT_PAYMENT,
    INVOICE_RAISED_STATUS.WAITING_PAYMENT,
  ].includes(option.id)),
);

const ListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={MAX_ORGANIZATIONS_FETCHED}
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
    >
      <SelectInput
        optionText={org => `${org.name} (${org.unique_alias})`}
      />
    </ReferenceInput>
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statusOptionWithoutDeferredRaised}
    />
  </Filter>
);

export default ListFilter;
