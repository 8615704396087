/* eslint import/prefer-default-export: 0  */
import { USER_STATUS } from 'core/assets/js/constants';

export  const USER_STATUS_COLORS = {
  [USER_STATUS.ACTIVE]: '#22c55e',
  [USER_STATUS.DEACTIVATED]: '#7b7b7b',
  [USER_STATUS.DELETED]: 'tomato',
};

export const AOR_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const AOR_STATUS_COLORS = {
  [AOR_STATUS.ENABLED]: '#60DC35',
  [AOR_STATUS.DISABLED]: 'rgba(0, 0, 0, 0.26)',
};
