/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FormControlLabel, Checkbox, TextField, Icon } from '@material-ui/core';
import { TopToolbar, ShowButton } from 'react-admin';

import { INVOICE_STATUS_LABEL, INVOICE_STATUS, TRANSACTION_STATUS, INVOICE_TYPE } from 'finance/assets/js/constants';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import InvoiceDownloadButton from './InvoiceDownloadButton.jsx';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';


const InvoiceEditActions = ({ basePath, permissions, record }) => {
  const [sendNotifications, setRebuildingNotificationsValue] = React.useState(false);
  const [transferwiseTransferId, setTransferwiseTransferId] = React.useState('');

  const {
    id: invoiceId, type, status, number, invoicingSettings, recipientFE,
    invoiceItems, transactions, isLicence, isSubscription, deferredInvoicePayment,
  } = record;

  const isOutBoundInvoice = INVOICE_TYPE.OUTBOUND === type;
  const hasVoidStatus = status === INVOICE_STATUS.VOID;
  const hasCancelledStatus = status === INVOICE_STATUS.CANCELLED;
  const hasPaidStatus = status === INVOICE_STATUS.PAID;

  const recipientOrgName = recipientFE.company.name;
  const hasChildInvoices = invoiceItems?.length > 0;
  const invoiceMode = invoicingSettings.mode;
  const isRaised = [INVOICE_STATUS.RAISED, INVOICE_STATUS.BAD_DEBT].includes(status);

  // hasBeenPaid and hasPaidStatus may not reflect the same situation.
  // E.g a zero amount invoice. The invoice is paid but also has no transactions at all.
  const hasBeenPaid = !isEmpty(transactions) && transactions.some(
    t => t.status === TRANSACTION_STATUS.SUCCEEDED,
  );
  const hasDeferredInvoicePayment = !isEmpty(deferredInvoicePayment);
  const canVoidInvoice = !hasDeferredInvoicePayment;
  const canLinkToTransfer = !hasPaidStatus;
  const canCancelInvoice = isSubscription
    || (!hasCancelledStatus && !hasBeenPaid && !hasVoidStatus && !hasDeferredInvoicePayment);

  const hasDigestNotification = isOutBoundInvoice && !isLicence && !isSubscription;
  return (
    <TopToolbar className="flex-wrap">
      <InvoiceDownloadButton record={record} />
      <ShowButton basePath={basePath} record={record} />
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Mark as void"
          title={`Mark invoice #${number} as void`}
          content={(
            <>
              {
                canVoidInvoice && (
                  <>
                    <p>
                      The relevant contractor invoices, as well as all associated approved
                      worksheets and expenses will be marked as void. Also, the funds granted
                      to the providers when the worksheets and expenses were approved, will be
                      returned back to the related project budget.
                    </p>
                    <p>
                      If you dont want funds to be returned back to the related project budget,
                      then mark this invoice as CANCELLED.
                    </p>
                    {!isOutBoundInvoice && (
                      <div>
                        Also bear in mind that by voiding an inbound invoice you
                        {' '}
                        <strong>might</strong>
                        {' '}
                        also need to
                        <ul>
                          <li>
                            Void the outbound invoice (if all inbound invoices are voided)
                          </li>
                          <li>
                            Rebuild the outbound invoice so that it includes the voided
                            inbound invoice change
                          </li>
                        </ul>
                      </div>
                    )}
                    <p>Are you sure you want to mark this invoice as void?</p>
                  </>
                )
              }
              {
                !canVoidInvoice && hasDeferredInvoicePayment && (
                  <p className="text-warning">
                    The invoice cannot be marked as void because
                    it has an outstanding deferred payment request.
                  </p>
                )
              }
            </>
          )}
          confirm
          icon={<Icon>warning</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="mark-as-void"
          cancelLabel="Close"
          disabled={hasBeenPaid || hasVoidStatus}
          disableInternalCtas={!canVoidInvoice}
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
        && isOutBoundInvoice && status === INVOICE_STATUS.BAD_DEBT && (
        <BulkApiActionButton
          label="Mark as raised"
          title={`Mark invoice #${number} as raised`}
          content={(
            <p>Are you sure you want to mark this invoice as raised?</p>
          )}
          confirm
          icon={<Icon>warning</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="mark-as-raised"
          cancelLabel="Close"
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
        && isOutBoundInvoice && status === INVOICE_STATUS.RAISED && (
        <BulkApiActionButton
          label="Mark as bad debt"
          title={`Mark invoice #${number} as bad debt`}
          content={(
            <p>Are you sure you want to mark this invoice as bad debt?</p>
          )}
          confirm
          icon={<Icon>warning</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="mark-as-bad-debt"
          cancelLabel="Close"
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          disableInternalCtas={!canCancelInvoice}
          label="Mark as cancelled"
          title={`Mark invoice #${number} as cancelled`}
          content={(
            <>
              { !isSubscription && isOutBoundInvoice && (
                <p>
                  Currently an outbound invoice can be cancelled if all related invoices
                  have status of RAISED, EXECUTED or CANCELLED.
                </p>
              )}
              { !canCancelInvoice && hasPaidStatus && (
                <p>
                  Invoice cannot get cancelled.
                  Its status is
                  {' '}
                  { INVOICE_STATUS_LABEL[status] }
                  .
                </p>
              )}
              { !canCancelInvoice && hasDeferredInvoicePayment && (
                <p className="text-warning">
                  The invoice cannot be marked as cancelled because
                  it has an outstanding deferred payment request.
                </p>
              )}
              { canCancelInvoice && !isSubscription && (
                <>
                  <p>
                    The relevant contractor invoices will be marked as cancelled. The funds granted
                    to the providers when the worksheets and expenses were approved, will remain
                    unchanged. If you want them to be returned back to the related project budget,
                    then mark this invoice as void.
                  </p>
                  <p>Are you sure you want to mark this invoice as cancelled?</p>
                </>
              )}
              { canCancelInvoice && isSubscription && (
                <>
                  <p>
                    Are you sure you want to mark this subscription invoice as cancelled?
                  </p>
                </>
              )}
            </>
          )}
          extraActions={hasChildInvoices && isOutBoundInvoice ? [{
            icon: 'warning',
            label: 'Mark as cancelled including freelancer invoices',
            action: 'mark-as-cancelled',
            disabled: !canCancelInvoice,
            payload: {
              ids: [invoiceId],
              cancelChildInvoices: true,
            },
          }] : []}
          confirm
          confirmLabel="Mark as cancelled"
          icon={<Icon>block</Icon>}
          ids={[invoiceId]}
          payload={{
            force: true,
          }}
          resource="invoices"
          action="mark-as-cancelled"
          cancelLabel="Close"
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Update static data"
          title="Update static data of invoice"
          confirmLabel="Update static data"
          content="Are you sure you want to update the static data of this invoice?"
          icon={<Icon>update</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="update-static-data"
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Rebuild"
          title="Rebuild invoice"
          confirmLabel="Rebuild"
          content="Are you sure you want to rebuild this invoice?"
          extraContent={(
            <FormControlLabel
              checked={sendNotifications}
              onChange={e => setRebuildingNotificationsValue(e.target.checked)}
              value="notifyAboutRebuilding"
              control={<Checkbox color="primary" />}
              label="Send notifications to all related parties"
              labelPlacement="end"
            />
          )}
          payload={{
            sendNotifications,
          }}
          icon={<Icon>update</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="rebuild"
        />
      )}
      {hasDigestNotification
        && permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Notify organisation"
          title="Send invoice digest email"
          content={(
            <div>
              <p className="mb-2">
                {`An email with the list of all ${invoiceMode.toUpperCase()} invoices will be sent to the
              following recipients of "${recipientOrgName}":`}
              </p>
              <ul>
                <li>Financial Controller (or Organization Owner)</li>
                <li>Financial Department</li>
              </ul>
              <p>Are you sure you want to create and send this notification?</p>
            </div>
          )}
          icon={<Icon>mail</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="resend-client-notifications"
          successNotifMsg="Digest email sent"
          errorNotifMsg="Digest email not sent"
          disabled={!(isRaised || hasBeenPaid)}
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
        && !hasDeferredInvoicePayment
        && (
          <BulkApiActionButton
            label="Create new transaction"
            title="Create new transaction"
            content="Are you sure you want to create a new transaction for this invoice?"
            icon={<Icon>contactless</Icon>}
            ids={[invoiceId]}
            resource="invoices"
            confirmLabel="create"
            action="create_new_transaction"
            disabled={hasBeenPaid}
          />
        )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Link to transfer"
          title="Link to transfer"
          content={canLinkToTransfer
            ? (
              <p>&quot;Please enter the Transferwise transfer id to link this invoice to&quot;</p>
            ) : (
              <p className="text-warning">
                Linking to transfer has been disabled because invoice is paid.
              </p>
            )
          }
          extraContent={canLinkToTransfer && (
            <TextField
              onChange={e => setTransferwiseTransferId(e.target.value)}
              value={transferwiseTransferId}
              fullWidth
            />
          )}
          disableInternalCtas={!canLinkToTransfer}
          payload={{
            transfers: {
              [transferwiseTransferId]: invoiceId,
            },
          }}
          icon={<Icon>contactless</Icon>}
          ids={[invoiceId]}
          resource="dim_transferwise_transfers"
          action="link-transferwise-transfer"
        />
      )}
      {/* permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Pay by SWIFT"
          title="Pay by SWIFT"
          content="Are you sure you want to create a new transaction for this invoice?"
          extraContent="The new transaction will be created using the SWIFT network."
          confirmLabel="create"
          icon={<Icon>contactless</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="create_new_transaction_using_swift"
          disabled={hasBeenPaid}
        />
      ) */}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Resend notifications"
          title="Resend notifications"
          content="Are you sure you want to resend the notifications for this invoice?"
          icon={<Icon>notifications</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="resend_notifications"
          disabled={hasBeenPaid}
        />
      )}
      {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
        <BulkApiActionButton
          label="Resolve status"
          title={`Resolve status of invoice ${number}`}
          content={(
            <>
              <p>
                By executing this action, the system will check to see if there
                is a related transaction to our database.
              </p>
              <p>
                If for example, there&apos;s a succeeded transaction,
                the invoice will be marked as paid.
              </p>
              <p>
                This action may also trigger inbound transactions when trying to resolve
                the invoice status. E.g when marking an invoice as paid.
              </p>
              <p>Are you sure you want to resolve the status of this invoice?</p>
            </>
          )}
          confirm
          icon={<Icon>update</Icon>}
          ids={[invoiceId]}
          resource="invoices"
          action="resolve-invoice-status"
          cancelLabel="Close"
          disabled={hasPaidStatus}
        />
      )}
    </TopToolbar>
  );
};

InvoiceEditActions.propTypes = {
  basePath: PropTypes.string,
  permissions: PropTypes.object,
  record: PropTypes.object,
};

InvoiceEditActions.defaultProps = {
  permissions: {
    role: null,
  },
  basePath: null,
  record: null,
};

export default InvoiceEditActions;
