import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Button,
  useRefresh,
  useNotify,
  downloadCSV,
} from 'react-admin';
import {
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl, adminResourceApiUrl } from 'admin/urls';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('admin:1099');

const TenNinetyNineReportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgLoaded, setOrgLoaded] = useState(null);
  const [initialValues, setInitialValues] = useState({
    targetYear: moment().year(),
  });
  const refresh = useRefresh();
  const notify = useNotify();

  // todo - refactor to custom hook ?
  // handle fetch org details and update form initial values
  useEffect(() => {
    const fetchOrg = async () => {
      const url = `${adminResourceApiUrl('pricings')}/${orgLoaded}`;
      const res = await httpClient(url, { method: 'GET' });

      try {
        const org = JSON.parse(res.body);

        // update initial values for org returned
        setInitialValues({
          ...initialValues,
          orgId: orgLoaded,
          filerFederalIdNumber: '',
          filerName: org.name,
          filerAddress1: org.serialized_address_components.street,
          filerAddress2: '',
          filerCity: org.serialized_address_components.city,
          filerState: org.serialized_address_components.state || '',
          filerZip: org.serialized_address_components.postal_code,
        });
      } catch (e) {
        logger.log(`Failed to parse JSON for org id ${orgLoaded}`);
      }
    };

    if (orgLoaded !== null) {
      fetchOrg();
    }
  }, [orgLoaded]);

  // memorise form fields to prevent unnecessary re-rendering
  const fields = useMemo(() => [
    // choose org fields
    [{
      name: 'orgId',
      label: 'Organization Id',
      placeholder: 'ie: 74',
    }],
    // org fields
    [{
      name: 'filerFederalIdNumber',
      label: 'Organization federal id number',
      placeholder: 'ie: 00-0000000',
      required: true,
    }, {
      name: 'filerName',
      label: 'Organizatioin name',
      placeholder: 'ie: Bobs Best Products Inc',
    }, {
      name: 'filerAddress1',
      label: 'Address1',
      placeholder: 'ie: 10 The Street',
    }, {
      name: 'filerAddress2',
      label: 'Address2',
      placeholder: '',
    }, {
      name: 'filerCity',
      label: 'City',
      placeholder: 'ie: New York',
      required: true,
    }, {
      name: 'filerState',
      label: 'State',
      placeholder: 'ie: New York',
    }, {
      name: 'filerZip',
      label: 'Zip code',
      placeholder: 'ie: 10001',
    }],
    // customise form fields
    [{
      name: 'targetYear',
      label: 'Year',
      placeholder: 'ie: 2022',
      required: true,
    }],
  ], []);

  const handleClick = useCallback(() => {
    setShowDialog(true);
  }, [setShowDialog]);

  const handleCloseClick = useCallback(() => {
    setShowDialog(false);
    setOrgLoaded(null);
  }, [setShowDialog, setOrgLoaded]);

  const handleGenerateClick = useCallback(async (values) => {
    const command = 'reports/1099';
    const url = adminCommandApiUrl(command, { ...values });
    setLoading(true);
    try {
      const data = await httpClient(url, { method: 'GET' });
      downloadCSV(data.body, '1099');
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
      setOrgLoaded(null);
    }
  }, [setLoading, setShowDialog, refresh, notify]);

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>1099 REPORT</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="1099 report"
      >
        <DialogTitle>Download 1099 report</DialogTitle>
        <DialogContent>
          <Form
            initialValues={initialValues}
            onSubmit={handleGenerateClick}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div>

                  {/* Stage One - Choose org */}

                  {!orgLoaded && (
                    <>
                      <Typography variant="body1" className="mb-3">Choose organization to generate report for</Typography>

                      {fields[0].map(field => (
                        <Field name={field.name}>
                          {({ input, meta }) => (
                            <div style={{ margin: '0 0 20px 0' }}>
                              <label>{field.label}</label>
                              <br />
                              <input
                                style={{
                                  padding: '10px',
                                  width: '300px',
                                }}
                                {...input}
                                type="text"
                                placeholder={field.placeholder}
                              />
                              {meta.error && meta.touched && <span>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      ))}

                      <Button
                        variant="contained"
                        type="button"
                        onClick={() => { setOrgLoaded(values.orgId); }}
                        label="Load organization"
                      />

                      <hr />

                    </>
                  )}

                  {orgLoaded && (
                    <>

                      {/* Stage Two - Edit org details */}

                      <Typography variant="body1" className="mb-3">Check organization details</Typography>

                      {fields[1].map(field => (
                        <Field name={field.name}>
                          {({ input, meta }) => (
                            <div style={{ margin: '0 0 20px 0' }}>
                              <label>{field.label}</label>
                              <br />
                              <input
                                style={{
                                  padding: '10px',
                                  width: '300px',
                                }}
                                {...input}
                                type="text"
                                placeholder={field.placeholder}
                                required={field.required}
                              />
                              {meta.error && meta.touched && <span>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      ))}

                      <hr />

                      {/* Stage Three - Customise report */}

                      <Typography variant="body1" className="mb-3">Customise report</Typography>

                      {fields[2].map(field => (
                        <Field name={field.name}>
                          {({ input, meta }) => (
                            <div style={{ margin: '0 0 20px 0' }}>
                              <label>{field.label}</label>
                              <br />
                              <input
                                style={{
                                  padding: '10px',
                                  width: '300px',
                                }}
                                {...input}
                                type="text"
                                placeholder={field.placeholder}
                                required={field.required}
                              />
                              {meta.error && meta.touched && <span>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      ))}

                    </>
                  )}
                </div>
                <div>
                  {orgLoaded && (<Button variant="contained" disabled={loading} label="Download report" type="submit" />)}
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

TenNinetyNineReportButton.propTypes = {};

TenNinetyNineReportButton.defaultProps = {};

export default TenNinetyNineReportButton;
