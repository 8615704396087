import moment from 'moment';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const DownloadAccountingReportButton = ({ record }) => {
  if (!record) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(null);
  const notify = useNotify();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const command = 'accounting-report-generate';
  const title = 'Download accounting report';

  const reportDate = (record.billing_date && moment(record.billing_date))
    || moment(record.created_at).startOf('month').subtract(1, 'day');
  const isBillingProcess = !!record.billing_date;
  const isStartMonth = (isBillingProcess ? moment(reportDate).add(1, 'day').date() : moment(record.created_at).subtract(1, 'day').date()) === 1;
  if (!isStartMonth) {
    return null;
  }

  const formattedDate = reportDate.format('DD MMM YYYY');

  const handleConfirm = async () => {
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      const body = {
        detailedLogging: true,
        date: reportDate.format('YYYY-MM-DD'),
      };
      const res = await httpClient(url, { method: 'POST', body: JSON.stringify(body) });
      setDownloadId(res.json.downloadId);
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const confirmMessage = `Do you want to create an accouting report for ${formattedDate}`;

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        title="Download accounting report"
      >
        <Icon>cloud_download</Icon>
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {downloadId ? (
            <Link to={`/downloads/${downloadId}/show`}>See your download</Link>
          ) : (
            <Form
              onSubmit={handleConfirm}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <p>{confirmMessage}</p>
                  </div>
                  <div>
                    <Button variant="contained" disabled={loading} label="confirm" type="submit" />
                    <Button label="cancel" type="button" onClick={handleDialogClose} />
                  </div>
                </form>
              )}
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

DownloadAccountingReportButton.propTypes = {
  record: PropTypes.object,
};

DownloadAccountingReportButton.defaultProps = {
  record: null,
};

export default DownloadAccountingReportButton;
