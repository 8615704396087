import React from 'react';
import PropTypes from 'prop-types';

import { parseJson } from 'core/assets/js/lib/utils';
import { FeeScale } from 'finance/assets/js/components/invoicing/FeeScheme.jsx';
import { CURRENCY_SYMBOL, CURRENCY } from 'core/assets/js/constants';

const PricingScaleSchemeField = ({ record: { scheme_dump } }) => {
  const parsed = parseJson(scheme_dump);
  const scaleSteps = Object.keys(parsed).map(key => ({ range: [key], feePercent: parsed[key] }));
  return (
    <>
      <div className="hint">step - fee percent</div>
      <FeeScale scaleSteps={scaleSteps} currency={CURRENCY_SYMBOL[CURRENCY.GBP]} />
    </>
  );
};

PricingScaleSchemeField.propTypes = {
  record: PropTypes.object,
};

PricingScaleSchemeField.defaultProps = {
  record: {},
};

export default PricingScaleSchemeField;
