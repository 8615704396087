import React, { useState } from 'react';
import { Button } from 'react-admin';
import PropTypes from 'prop-types';

import GenerateInvoicesDialog from 'admin/assets/js/resources/invoices/GenerateInvoicesDialog.jsx';

const GenerateOrgInvoices = (props) => {
  const { record } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        label="Generate org invoices"
        disabled
      />
      {
        isOpen && (
          <GenerateInvoicesDialog
            onHandleSetOpen={setIsOpen}
            isOpen={isOpen}
            organization={record}
          />
        )
      }
    </>
  );
};

GenerateOrgInvoices.propTypes = {
  record: PropTypes.object,
};

GenerateOrgInvoices.defaultProps = {
  record: {},
};

export default GenerateOrgInvoices;
