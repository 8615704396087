import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@material-ui/core';

import Money from 'finance/assets/js/lib/Money';
import {
  getAvailableTransferAllocationMoney,
} from 'admin/assets/js/resources/inbound_transfers/utils';

const AllocationAggregatedInfo = ({
  transferAmount,
  transferAmountCurrency,
  amountToAllocate,
  totalAllocatedAmount,
}) => {
  const allocatedAmount = new Money(amountToAllocate, transferAmountCurrency).add(
    totalAllocatedAmount || 0,
  );
  const amountToAllocateMoney = new Money(amountToAllocate, transferAmountCurrency);
  const allocatedPercent = (
    allocatedAmount.toNumber() / (new Money(transferAmount, transferAmountCurrency).toNumber())
  ) * 100;
  const availableAllocationMoney = getAvailableTransferAllocationMoney(
    transferAmount, transferAmountCurrency, totalAllocatedAmount, amountToAllocate,
  );

  return (
    <div
      style={{ width: '200px' }}
      className="text-dark"
    >
      <div className="d-flex justify-content-between align-center">
        Selected invoices:
        {' '}
        <span className="text-right">
          {amountToAllocateMoney.toString({ humanizeAmount: true, withSymbol: true })}
        </span>
      </div>
      <div>
        <div className="d-flex justify-content-between align-center">
          Left to reconcile:
          {' '}
          <span className="text-right">
            {availableAllocationMoney.toString({ withSymbol: true, humanizeAmount: true })}
          </span>
        </div>
      </div>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={allocatedPercent} />
      </Box>
    </div>
  );
};

AllocationAggregatedInfo.propTypes = {
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  amountToAllocate: PropTypes.string,
  totalAllocatedAmount: PropTypes.string,
};

AllocationAggregatedInfo.defaultProps = {
  transferAmount: '0.00',
  transferAmountCurrency: null,
  amountToAllocate: '0.00',
  totalAllocatedAmount: '0.00',
};

export default AllocationAggregatedInfo;
