import React from 'react';
import PropTypes from 'prop-types';

import { TRANSACTION_STATUS_LABEL } from 'finance/assets/js/constants';

const TransactionStatusField = ({ record }) => {
  return (
    <div className="d-flex">
      <div>
        {TRANSACTION_STATUS_LABEL[record.status]}
      </div>
    </div>
  );
};

TransactionStatusField.propTypes = {
  record: PropTypes.object,
};
TransactionStatusField.defaultProps = {
  record: {},
};
export default TransactionStatusField;
