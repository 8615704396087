import React from 'react';
import PropTypes from 'prop-types';
import { fromPairs, toPairs, pick, isEmpty } from 'lodash';
import {
  useNotify,
  useCreate, useUpdate,
  SimpleForm, useRefresh, TextInput, SelectInput, SaveButton, Toolbar,
} from 'react-admin';

import Address from 'core/assets/js/lib/Address';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { adminCommandApiUrl } from 'admin/urls';
import apiClient from 'admin/assets/js/lib/apiClient';

const countryChoices = Object.keys(countryNames).map(k => ({
  id: k, name: countryNames[k],
}));

const validateForm = () => {
  const errors = {};

  return fromPairs(toPairs(errors).filter(([, e]) => !!e));
};

const InvoicingEntityForm = ({ onDialogClose, ...props }) => {
  const { record } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate('invoicing_entities');
  const [update] = useUpdate('invoicing_entities', record?.id);

  return (
    <SimpleForm
      {...props}
      redirect={false}
      toolbar={<Toolbar><SaveButton /></Toolbar>}
      variant="standard"
      validate={formValues => validateForm(formValues)}
      // eslint-disable-next-line consistent-return
      save={(formValues) => {
        const address = new Address('');
        const addressFieldsMapping = {
          addressLine1: 'address_line_1',
          addressLine2: 'address_line_2',
          region: 'region',
          postcode: 'postal_code',
          city: 'city',
          countryCode: 'country_code',
        };
        const passThroughFormFields = ['tax_number', 'name', 'registration_number'];
        Object.keys(addressFieldsMapping).forEach((key) => {
          address[key] = formValues[addressFieldsMapping[key]];
        });
        const preparedFormValues = {
          ...pick(formValues, passThroughFormFields),
          address: address.toObject(),
        };
        if (isEmpty(record)) {
          create({ payload: { data: preparedFormValues } }, {
            onSuccess: ({ data: formData }) => {
              onDialogClose();
              notify('Invoicing entity created', 'info');
              refresh();
              apiClient({
                data: {
                  formData,
                  isNewEntity: true,
                },
                method: 'POST',
                url: adminCommandApiUrl('invoicing-entities/add-activity-record'),
              });
            },
          });
        } else {
          update({ payload: { data: preparedFormValues } }, {
            onSuccess: ({ data: formData }) => {
              onDialogClose();
              notify('Invoicing entity updated', 'info');
              refresh();
              apiClient({
                data: {
                  formData,
                },
                method: 'POST',
                url: adminCommandApiUrl('invoicing-entities/add-activity-record'),
              });
            },
          });
        }
      }}
    >
      <>
        <TextInput
          source="name"
          label="Legal company name"
          required
          helperText="The legally registered name of the entity"
          validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
        />
        <div>
          <TextInput
            source="address_line_1"
            label="Address 1"
            helperText="First line of the registered company address"
            required
            validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
          />
          <TextInput
            source="address_line_2"
            helperText="Second line of the registered company address"
            label="Address 2"
          />
        </div>
        <div>
          <TextInput
            source="region"
            label="Town/County"
            helperText="Registered town or county"
          />
          <TextInput
            source="city"
            label="City"
            required
            helperText="Registered city"
            validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
          />
        </div>
        <div>
          <TextInput
            source="postal_code"
            label="Postal code"
            helperText="Registered postal code"
            required
            validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
          />
          <SelectInput
            source="country_code"
            label="Country"
            choices={countryChoices}
            required
            validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
          />
        </div>
        <div>
          <TextInput
            source="tax_number"
            label="Tax number"
            helperText="Entity Tax Number"
          />
          <TextInput
            source="registration_number"
            label="Registration number"
            helperText="Entity Registration number"
            required
            validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
          />
        </div>
      </>
    </SimpleForm>
  );
};

InvoicingEntityForm.propTypes = {
  classes: PropTypes.object,
  onDialogClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

InvoicingEntityForm.defaultProps = {
  classes: {},
  record: {},
};

export default InvoicingEntityForm;
