import React from 'react';
import { Title } from 'react-admin';
import { Link, withRouter } from 'react-router-dom';

import AMLRecordActions from 'admin/assets/js/components/AMLRecordActions.jsx';
import AMLResultChip from 'admin/assets/js/components/AMLResultChip.jsx';
import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import { formatDate } from 'admin/assets/js/lib/utils';
import { DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import {
  SMART_SEARCH_RESULT_LABEL, SMART_SEARCH_RESULT_VALUES,
} from 'organizations/assets/js/constants';

const resultValues = ['processing', ...SMART_SEARCH_RESULT_VALUES];
const renderResultLabel = value => (
  value === 'processing' ? 'In progress' : SMART_SEARCH_RESULT_LABEL[value]
);

export const UserAMLChecksView = () => (
  <>
    <Title title="Identity checks" />
    <ApiTable
      columns={[
        {
          label: 'Date',
          name: 'created_at',
          options: {
            cellRender: createdAt => formatDate(createdAt, DATETIME_FORMAT_DEFAULT),
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Name',
          name: 'contractor_name',
          options: {
            cellRender: (name, amlCheck) => (
              <Link to={`/full_users/${amlCheck.contractor_user_id}/show`}>{name}</Link>
            ),
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Result',
          name: 'result',
          options: {
            cellRender: (_, amlCheck) => <AMLResultChip amlCheck={amlCheck} />,
            customFilterListOptions: {
              render: value => `Result: ${renderResultLabel(value)}`,
            },
            filter: true,
            filterOptions: {
              names: resultValues,
              renderValue: renderResultLabel,
            },
            sort: true,
          },
        },
        {
          label: 'Manually created by',
          name: 'manually_created_by',
          options: {
            cellRender: (userId, amlCheck) => {
              if (!userId) {
                return null;
              }
              return (
                <Link to={`/full_users/${userId}/show`}>{amlCheck.manually_created_by_name}</Link>
              );
            },
            filter: false,
            sort: false,
          },
        },
        {
          label: 'Document type',
          name: 'document_type',
          options: { filter: false, sort: false },
        },
        {
          label: 'Issuing country',
          name: 'issuing_country',
          options: { filter: false, sort: false },
        },
        {
          label: 'Actions',
          name: 'sent_payload',
          options: {
            cellRender: (_, amlCheck) => <AMLRecordActions amlCheck={amlCheck} />,
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="created_at"
      defaultSortDirection="DESC"
      downloadEnabled={false}
      resource="user_aml_checks"
    />
  </>
);

export default withRouter(UserAMLChecksView);
