import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';


const SplitButton = ({ options }) => {
  const mainOption = options.find(option => option.main) || options[0];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClick = useCallback((option) => {
    option.onHandleClick();
  }, []);

  const handleMenuItemClick = useCallback((option) => {
    handleClick(option);
    setOpen(false);
  }, [setOpen, handleClick]);

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, [setOpen]);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, [setOpen, anchorRef]);

  if (!mainOption) {
    return null;
  }
  return (
    <Grid container direction="column" alignItems="flex-end" style={{ zIndex: 999 }}>
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <Button
            onClick={() => handleClick(mainOption)}
            disabled={mainOption.disabled}
          >
            {mainOption.label}
          </Button>
          {options.length > 1 && (
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.length > 1
                      && options
                        .filter(option => option.key !== mainOption.key)
                        .map(option => (
                          option.component
                            ? option.component
                            : (
                              <MenuItem
                                key={option.label}
                                onClick={() => handleMenuItemClick(option)}
                              >
                                {option.label}
                              </MenuItem>
                            )
                        ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

SplitButton.propTypes = {
  options: PropTypes.array.isRequired,
};

export default SplitButton;
