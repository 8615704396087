import moment from 'moment';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const RunBillingProcessETLButton = ({ record }) => {
  if (!record) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(false);
  const notify = useNotify();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const command = 'etl/billing-process';
  const title = 'Download billing processing';

  const reportDate = (record.billing_date && moment(record.billing_date))
    || moment(record.created_at).startOf('month').subtract(1, 'day');

  const formattedDate = reportDate.format('DD MMM YYYY');

  const content = downloadId
    ? (
      <Link to={`/downloads/${downloadId}/show`}>See your download</Link>
    )
    : `Confirm to run etl billing process for ${formattedDate}`;

  const handleConfirm = async () => {
    const url = adminCommandApiUrl(command, {
      date: reportDate.format('YYYY-MM-DD'),
      includeOrgs: true,
    });
    setLoading(true);
    try {
      const res = await httpClient(url, { method: 'GET' });
      setDownloadId(res.json.downloadId);
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        title="Export data for billing process"
      >
        <Icon>download_for_offline</Icon>
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleDialogClose}>
            <React.Fragment>
              <Icon>error_outline</Icon>
              Cancel
            </React.Fragment>
          </Button>
          <Button
            disabled={loading}
            onClick={handleConfirm}
            autoFocus
          >
            <React.Fragment>
              <Icon>check_circle</Icon>
              Confirm
            </React.Fragment>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

RunBillingProcessETLButton.propTypes = {
  record: PropTypes.object,
};

RunBillingProcessETLButton.defaultProps = {
  record: null,
};

export default RunBillingProcessETLButton;
