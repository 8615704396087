import React from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ReconciliationBulkActions
  from 'admin/assets/js/resources/inbound_transfers/ReconcilliationBulkActions.jsx';

const useStyles = makeStyles(() => ({
  overviewTransferWrapper: {
    position: 'sticky',
    top: '120px',
    backgroundColor: '#fff',
    zIndex: '95',
    paddingBottom: '20px',
  },
}));

const AllocationFormHeader = ({
  organization,
  selectedIds,
  transferAmount,
  transferAmountCurrency,
  loading,
  invalid,
  totalAllocatedAmount,
  amountToAllocate,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.overviewTransferWrapper}>
      <ReconciliationBulkActions
        selectedIds={selectedIds}
        transferAmount={transferAmount}
        transferAmountCurrency={transferAmountCurrency}
        disabled={loading || invalid}
        totalAllocatedAmount={totalAllocatedAmount}
        amountToAllocate={amountToAllocate}
      />
      <div className="font-weight-bold text-dark text-left ml-3">
        Displaying unpaid invoices for
        {' '}
        <Link
          to={`/organizations/${organization.id}/show`}
        >
          {organization.name}
        </Link>
        :
      </div>
    </div>
  );
};

AllocationFormHeader.propTypes = {
  organization: PropTypes.object,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  totalAllocatedAmount: PropTypes.string,
  loading: PropTypes.bool,
  invalid: PropTypes.bool,
  amountToAllocate: PropTypes.string,
};
AllocationFormHeader.defaultProps = {
  organization: {},
  selectedIds: [],
  transferAmount: null,
  transferAmountCurrency: null,
  totalAllocatedAmount: '0.00',
  amountToAllocate: '0.00',
  invalid: false,
  loading: false,
};

export default AllocationFormHeader;
