import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import { useStyles } from 'admin/assets/js/resources/full_users/UserOrgInfo.jsx';


export const AorContractReviewAndSignModal = ({ envelope_signing_embed, onClose }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Dialog
        fullScreen
        onClose={() => {
          setIsOpen(false);
          onClose();
        }}
        open={isOpen}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            Review and Sign contract
            <Button
              className="ml-a"
              onClick={() => {
                setIsOpen(false);
                onClose();
              }}
              color="primary"
            >
              <Icon>close</Icon>
              Close
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          {envelope_signing_embed && (
            <div
              style={{ minHeight: '850px', height: '100%' }}
              className="signable-iframe-container"
              dangerouslySetInnerHTML={{ __html: envelope_signing_embed }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Button
        className={classes.aor_contract_approve}
        variant="outlined"
        size="small"
        onClick={() => setIsOpen(true)}
      >
        <span className="text-uppercase">Review & Sign</span>
      </Button>
    </>
  );
};

AorContractReviewAndSignModal.propTypes = {
  envelope_signing_embed: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

AorContractReviewAndSignModal.defaultProps = {
  onClose: () => {},
};

export default AorContractReviewAndSignModal;
