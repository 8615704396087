import React from 'react';
import { useGetOne } from 'react-admin';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import {
  TRANSACTION_METHOD_LABEL,
  TRANSACTION_STATUS,
} from 'finance/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';
import { DATETIME_FORMAT_HUMAN_FRIENDLY_2 } from 'core/assets/js/constants';
import {
  getTWAllowedDetails,
  getTwDetailLabel,
  getTWDetailsAddress,
} from 'finance/assets/js/lib/utils';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import TransactionStatus from 'admin/assets/js/components/TransactionStatus';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
  },
}));

const TransactionDetails = ({ transaction }) => {
  const classes = useStyles();
  const {
    descriptor,
    id,
    status,
    created_at: createdAt,
    method,
    amounts,
    invoice_id: invoiceId,
    cancel_reason: cancellationReason,
  } = transaction;

  const { data: invoice } = useGetOne('invoices', invoiceId);

  if (isEmpty(transaction) || isEmpty(invoice)) {
    return null;
  }

  const transactionAmounts = new TransactionAmounts(amounts);
  const transactionAmountMoney = transactionAmounts.getOutgoingMoney();
  const ownerFE = new FinancialEntity(invoice.ownerFE);
  const ownerBankAccount = ownerFE.getBank();
  const details = ownerBankAccount.getBankFields();
  const currency = ownerBankAccount.getCurrency();
  const detailsToShow = getTWAllowedDetails(details, currency);
  const ownerIsSystem = ownerFE.isSystem();

  return (
    <Grid item lg={6} xs={12}>
      <Card variant="outlined">
        <CardHeader
          style={{ paddingBottom: 0, marginBottom: 0 }}
          title={(
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 600 }}
            >
              {`Transaction details ${id}`}
            </Typography>
          )}
        />
        <CardContent sx={{ m: 0, p: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                {descriptor}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Amount
              </Typography>
              <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                {transactionAmountMoney.toString({ humanizeAmount: true, withSymbol: true })}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Status
              </Typography>
              <TransactionStatus status={status} />
            </Grid>
            {status === TRANSACTION_STATUS.CANCELLED && (
              <Grid item xs={12} className={classes.column}>
                <Typography variant="subtitle2" color="textSecondary">
                  Cancellation reason
                </Typography>
                <Typography variant="subtitle2">
                  {cancellationReason || '-'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Created at
              </Typography>
              <Typography variant="subtitle2">
                {formatDate(createdAt, DATETIME_FORMAT_HUMAN_FRIENDLY_2)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Reference
              </Typography>
              <Typography variant="subtitle2">
                {ownerBankAccount.getCustomReference() || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Transfer method
              </Typography>
              <Typography variant="subtitle2">
                {TRANSACTION_METHOD_LABEL[method]}
              </Typography>
            </Grid>
            {
              !ownerIsSystem && (
                <Grid item xs={12} className={classes.column}>
                  <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                    {`${ownerFE.getFullProfileName()} (${ownerBankAccount.getDescription()})`}
                  </Typography>
                </Grid>
              )
            }
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Email
              </Typography>
              <Typography variant="subtitle2">
                {ownerFE.getEmail()}
              </Typography>
            </Grid>
            { Object.keys(detailsToShow).map((key) => {
              const formattedCurrency = currency.toLowerCase();
              const detailLabel = getTwDetailLabel(key, formattedCurrency);
              if (!detailsToShow[key]) {
                return null;
              }
              const detailInfo = key === 'address'
                ? getTWDetailsAddress(detailsToShow[key])
                : detailsToShow[key];
              return (
                <Grid item xs={12} className={classes.column} key={key}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {detailLabel}
                  </Typography>
                  <Typography variant="subtitle2">
                    {detailInfo}
                  </Typography>
                </Grid>
              );
            })}
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Sort code
              </Typography>
              <Typography variant="subtitle2">
                {ownerBankAccount.getSortCode() || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Swift code
              </Typography>
              <Typography variant="subtitle2">
                {ownerBankAccount.getSwiftCode() || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.column}>
              <Typography variant="subtitle2" color="textSecondary">
                Bank name
              </Typography>
              <Typography variant="subtitle2">
                {ownerBankAccount.getBankName() || '-'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};


TransactionDetails.propTypes = {
  transaction: PropTypes.object,
};

TransactionDetails.defaultProps = {
  transaction: {},
};

export default TransactionDetails;
