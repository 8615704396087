import React, { useCallback, useState } from 'react';
import {
  FunctionField,
  Show,
} from 'react-admin';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore.js';

import AuditLog from 'finance/assets/js/lib/AuditLog';
import FactAuditLogsHeader from 'admin/assets/js/resources/fact_audit_runs/components/FactAuditLogsHeader.jsx';

/**
 * Layout for show page.
 * Lists the logs for the given run.
 *
 * Each message type is batched together and only a summary line is initially visible.
 * This summary shows the highest audit level of the messages in the group.
 *
 * The summary line is an accordion, clicking it will reveal the individual lines in the group.
 *
 * @param {object} props - component properties.
 * @return {JSX.Element} rendered component.
 */
const FactAuditRunsShow = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = useCallback(panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }, [setExpanded]);

  return (
    <Show
      {...props}
    >
      <FunctionField
        addLabel={false}
        render={record => (
          <>
            <FactAuditLogsHeader data={record} />

            {Object
              .keys(record?.logs ?? {})
              .map(logGroupKey => (
                <Accordion
                  key={logGroupKey}
                  expanded={expanded === logGroupKey}
                  onChange={handleChange(logGroupKey)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className={`m-2 ${AuditLog.getMessageColour(record.logs[logGroupKey].level)}`}>
                      <Typography variant="subtitle2">
                        {AuditLog.getMessageSummaryForGroup(
                          record.logs[logGroupKey].bucket[0],
                          record.logs[logGroupKey].level,
                        )}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {record.logs[logGroupKey].bucket.map((log) => {
                        const auditLog = new AuditLog(log);
                        return (
                          <div id={log.id} className={`m-2 ${auditLog.getMessageColour()}`}>
                            <Typography variant="subtitle2">{auditLog.getFormattedMessage()}</Typography>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))
            }
          </>
        )}
      />
    </Show>
  );
};

export default FactAuditRunsShow;
