import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import MapPayerDialog from './MapPayerDialog.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
  },
}));

const MapPayerButton = (props) => {
  const classes = useStyles();
  const { dimPayer, onSuccess } = props;
  if (!dimPayer) {
    return null;
  }

  const [isMapFormOpen, setMapFormOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        onClick={() => setMapFormOpen(true)}
      >
        <strong>Map it</strong>
      </Button>
      <MapPayerDialog
        isOpen={isMapFormOpen}
        handleClose={() => setMapFormOpen(false)}
        dimPayer={dimPayer}
        onSuccess={() => {
          onSuccess();
          setMapFormOpen(false);
        }}
      />
    </div>
  );
};

MapPayerButton.propTypes = {
  dimPayer: PropTypes.object,
  onSuccess: PropTypes.func,
};

MapPayerButton.defaultProps = {
  dimPayer: null,
  onSuccess: () => {},
};

export default MapPayerButton;
