import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  drawerClasses: {
    background: '#F0F0F0',
  },
  containerClasses: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    marginBottom: '10px',
  },
  dividerClasses: {
    height: '3px',
    backgroundColor: 'gray',
    margin: '10px 0',
  },
  itemClasses: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    paddingRight: '16px',
  },
  inputFields: {
    flex: '0 1 100%',
  },
  formIterator: {
    '& li': {
      display: 'flex',
    },
  },
  iterator: {
    padding: '15px 0',
    '& .ra-input': {
      width: '100%',
      display: 'flex',
    },
    '& section': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    '& span[class^="SimpleFormIterator-action"]': {
      display: 'flex',
    },
    '& p.MuiTypography-root': {
      display: 'none',
    },
  },
  withValue: {
    backgroundColor: '#add0ff !important',
  },
  infoBar: {
    padding: '10px 20px',
    margin: 0,
  },
  infoBarLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '3px',
  },
  infoBarValue: {
    display: 'block',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  warningLabel: {
    color: '#e56874',
    padding: 0,
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '3px',
    minWidth: '100%',
  },
  collapsibleHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  collapsibleRule: {
    width: '100%',
    margin: '20px 16px',
  },
  collapsibleIcon: {
    paddingTop: '9px',
  },
});

export default useStyles;
