import { fetchUtils } from 'react-admin';
import Cookies from 'cookies-js';
import { GET_HTTP_HEADERS } from 'core/assets/js/config/settings';

const httpClient = (url, options = {}) => {
  let httpOptions = { ...options };
  if (!options.headers) {
    httpOptions = { ...httpOptions, headers: GET_HTTP_HEADERS({ Accept: 'application/json' }) };
  }
  const token = Cookies.get('token');
  httpOptions.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, httpOptions);
};

export default httpClient;
