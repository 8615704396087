import { useEffect, useState } from 'react';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const useFetchOrgIdsForAuditing = (invoiceDate) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orgIds, setOrgIds] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);

        const res = await httpClient(adminCommandApiUrl('fetch-org-ids-for-auditing', {
          invoiceDate,
        }), {
          method: 'GET',
        });

        setOrgIds(res.json.orgIds);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [setOrgIds, setLoading, setError, invoiceDate]);

  return { orgIds, loading, error };
};

export default useFetchOrgIdsForAuditing;
