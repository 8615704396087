import React, { Fragment, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, useRefresh, useNotify } from 'react-admin';

import { BILLING_PROCESS_INVOICE_SEGMENTS } from 'finance/assets/js/constants';
import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogContent } from '@material-ui/core';
import { adminCommandApiUrl } from 'admin/urls';

const DryRunInvoicingButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleRunClick = async (values) => {
    const command = 'dry-run-invoicing';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(values) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>DRY RUN INVOICING</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Dry run invoicing"
      >
        <DialogContent>
          <Form onSubmit={handleRunClick}>
            {({ handleSubmit, values }) => {
              const { orgId } = values;
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="orgId">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Organization Ids</label>
                          { !orgId && (
                            <div className="hint" style={{ marginBottom: '10px' }}>
                              Νot selecting specific organization ids means running invoicing
                              for ALL organizations.
                            </div>
                          )}
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                              display: 'block',
                            }}
                            {...input}
                            type="text"
                            placeholder="10,22,33"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="billingDeadline">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label style={{ margin: '0' }}>Billing date</label>
                          <div className="hint" style={{ marginBottom: '10px' }}>
                            Select a billing date only in cases where today
                            is NOT the billing date you want to run invoicing for.
                            If no billing date is selected,
                            the system will select today&apos;s date
                            by default.
                          </div>
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="invoiceSegment" component="select">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Invoice segment</label>
                          <div className="hint" style={{ marginBottom: '10px' }}>
                            Select whether you want all invoices to be considered or
                            just a part of them.
                          </div>
                          <select
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                            required
                          >
                            <option value={undefined}>All</option>
                            <option value={BILLING_PROCESS_INVOICE_SEGMENTS.LICENCE}>
                              Just licence invoices
                            </option>
                            <option value={BILLING_PROCESS_INVOICE_SEGMENTS.PROCESSING}>
                              Just processing invoices
                            </option>
                          </select>
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Button variant="contained" disabled={loading} label="Run" type="submit" />
                    <Button label="cancel" type="button" onClick={handleCloseClick} />
                  </div>
                </form>
              );
            }}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DryRunInvoicingButton;
