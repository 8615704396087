import React from 'react';
import PropTypes from 'prop-types';
import { SimpleShowLayout } from 'react-admin';
import PreformattedField from 'admin/assets/js/components/fields/PreformattedField.jsx';
import OrganizationBillingFreeze from './OrganizationBillingFreeze.jsx';

const OrganizationBillingExpansion = props => (
  <SimpleShowLayout {...props}>
    <OrganizationBillingFreeze />
    <PreformattedField label="Organization billing logs" source="logs" />
  </SimpleShowLayout>
);

OrganizationBillingExpansion.propTypes = {
  record: PropTypes.object,
};

OrganizationBillingExpansion.defaultProps = {
  record: {},
};

export default OrganizationBillingExpansion;
