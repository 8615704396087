import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FunctionField, TextField, NumberField, ReferenceField,
  SimpleShowLayout,
  Show,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import EmptyShowLayout from 'admin/assets/js/layout/EmptyShowLayout.jsx';
import { SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';
import { Link } from 'react-router-dom';

const SubscriptionDetails = ({ id, basePath }) => (
  id ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h2>Subscription details</h2>
        </Grid>
        <Grid item xs={6}>
          <Show
            basePath={basePath}
            resource="subscriptions"
            id={id}
            component={EmptyShowLayout}
          >
            <SimpleShowLayout>
              <FunctionField
                label={null}
                render={record => (moment(record.expires_at).isAfter(moment()) ? (
                  <span>
                    Expiring at
                    {' '}
                    {formatDate(record.expires_at)}
                  </span>
                ) : (
                  <span>Overdue</span>
                ))}
                source="is_trial"
              />
            </SimpleShowLayout>
          </Show>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Show
            basePath={basePath}
            resource="subscriptions"
            id={id}
            component={EmptyShowLayout}
          >
            <SimpleShowLayout>
              <TextField source="id" label="Id" />
              <ReferenceField
                source="organization_id"
                reference="organizations"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="Plan"
                render={record => SUBSCRIPTION_PLAN_LABELS[record.plan]}
                source="plan"
              />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid item xs={6}>
          <Show
            basePath={basePath}
            resource="subscriptions"
            id={id}
            component={EmptyShowLayout}
          >
            <SimpleShowLayout>
              <FunctionField
                label="Trial"
                render={record => (record.is_trial ? (
                  <span>
                    Trial until
                    {' '}
                    {formatDate(record.trial_expiration_date)}
                  </span>
                ) : (
                  <span>Trial expired</span>
                ))}
                source="is_trial"
              />
              <TextField source="next_invoice_due" />
              <NumberField source="next_invoice_amount" />
            </SimpleShowLayout>
          </Show>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Show
            basePath={basePath}
            resource="subscriptions"
            id={id}
            component={EmptyShowLayout}
          >
            <SimpleShowLayout>
              <ReferenceField
                source="created_by"
                reference="users"
                link="show"
              >
                <FunctionField
                  label="Owner"
                  render={user => (
                    <Link to={`/full_users/${user.id}/show`}>{user.email}</Link>
                  )}
                />
              </ReferenceField>
              <FunctionField label="Created" render={record => formatDate(record.created_at)} />
              <ReferenceField
                source="updated_by"
                reference="users"
                link="show"
                label="Last updated by"
              >
                <FunctionField
                  label="Owner"
                  render={user => (
                    <Link to={`/full_users/${user.id}/show`}>{user.email}</Link>
                  )}
                />
              </ReferenceField>
              <FunctionField label="Last updated at" render={record => formatDate(record.updated_at)} />
            </SimpleShowLayout>
          </Show>
        </Grid>
        <Grid item xs={6}>
          <Show
            basePath={basePath}
            resource="subscriptions"
            id={id}
            component={EmptyShowLayout}
          >
            <SimpleShowLayout>
              <FunctionField label="Started" render={record => formatDate(record.started_at)} />
              <FunctionField label="Expires" render={record => formatDate(record.expires_at)} />
            </SimpleShowLayout>
          </Show>
        </Grid>
      </Grid>
    </>
  ) : null
);

SubscriptionDetails.propTypes = {
  basePath: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default SubscriptionDetails;
