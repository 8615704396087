import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { ListButton } from 'react-admin';
import queryString from 'query-string';
import pluralize from 'pluralize';

import { INVOICE_STATUS, INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';

export const STAGE_STATUS = {
  READY: 1,
  PROBLEM: 0,
};

export const STAGE_COLORS = {
  [STAGE_STATUS.READY]: 'green',
  [STAGE_STATUS.PROBLEM]: 'red',
};

const StyledLinearProgress = withStyles(() => ({
  root: {
    height: 8,
    borderRadius: 4,
  },
  bar: {
    borderRadius: 3,
    backgroundColor: '#3f51b5',
  },
}))(LinearProgress);

const TransactionAnalysisField = (props) => {
  if (!props) {
    return null;
  }
  const { analytics } = props;
  if (isEmpty(analytics)) {
    return null;
  }
  const {
    analytics: {
      clientContractorCharges,
      contractorCharges,
      invoiceIdsToCreateTransactionsFor,
      invoiceIdsShouldHaveTransactions,
      invoiceIdsOfDeferredInvoices,
      orgId,
    },
    // eslint-disable-next-line react/prop-types
    billing_date,
  } = props;
  const isContractorAmountsConsistent = clientContractorCharges === contractorCharges;

  // transactions created data preparation
  const deferredInvoicesCount = invoiceIdsOfDeferredInvoices.length;
  const missingTransactionsIds = invoiceIdsToCreateTransactionsFor
    .filter(id => !(invoiceIdsOfDeferredInvoices.includes(id)));
  const missingTransactionsCount = missingTransactionsIds.length;
  const allTransactionsCreated = missingTransactionsCount === 0;

  // find transaction progress percent
  const transactionsCreatedPercent = allTransactionsCreated
    ? 100
    : Math.round((1 - (missingTransactionsCount / invoiceIdsShouldHaveTransactions.length)) * 100);
  const previewContractorInvoicesCount = (
    analytics?.previewContractorCount && parseInt(analytics?.previewContractorCount, 10,
    )) || 0;
  const checks = [
    isContractorAmountsConsistent,
    allTransactionsCreated,
  ];
  const allChecksPassed = checks.every(check => !!check);
  const queryOptions = queryString.stringify({
    order: 'DESC',
    perPage: 25,
    sort: 'id',
    filter: JSON.stringify({
      selectedTab: 'failing',
      organization_id: orgId,
      date: billing_date,
    }),
  });
  const previewQueryOptions = queryString.stringify({
    order: 'DESC',
    perPage: 25,
    sort: 'id',
    filter: JSON.stringify({
      organization_id: orgId,
      date: billing_date,
      status: INVOICE_STATUS.PREVIEW,
    }),
  });
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        {allChecksPassed
          ? (
            <div className="d-flex justify-content-between align-items-center my-1">
              <div className="text-right flex-grow-1 pr-2">All checks passed</div>
              <FiberManualRecordIcon
                style={{ color: STAGE_COLORS[STAGE_STATUS.READY] }}
              />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-between align-items-center my-1">
                <div className="text-right flex-grow-1 pr-2">Contractor amounts consistent</div>
                <FiberManualRecordIcon
                  style={{
                    color: isContractorAmountsConsistent
                      ? STAGE_COLORS[STAGE_STATUS.READY]
                      : STAGE_COLORS[STAGE_STATUS.PROBLEM],
                  }}
                />
              </div>
              {
                deferredInvoicesCount !== invoiceIdsShouldHaveTransactions.length && (
                  <>
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <div className="text-right flex-grow-1 pr-2">
                        {allTransactionsCreated
                          ? <div>All transactions created</div>
                          : (
                            <ListButton
                              basePath={`/invoices?${queryOptions}`}
                              icon={null}
                              style={{ textTransform: 'inherit' }}
                              label={`${pluralize('Transactions', missingTransactionsCount)} to be created ${missingTransactionsCount}/${invoiceIdsShouldHaveTransactions.length}`}
                            />
                          )
                        }
                      </div>
                      <FiberManualRecordIcon
                        style={{
                          color: allTransactionsCreated
                            ? STAGE_COLORS[STAGE_STATUS.READY]
                            : STAGE_COLORS[STAGE_STATUS.PROBLEM],
                        }}
                      />
                    </div>
                    { !allTransactionsCreated && (
                      <StyledLinearProgress variant="determinate" value={transactionsCreatedPercent} />
                    )}
                  </>
                )
              }
            </div>
          )
        }
      </div>
      { !!previewContractorInvoicesCount && (
        <div className="d-flex justify-content-between align-items-center my-1">
          <div className="text-right flex-grow-1 pr-2">
            <ListButton
              basePath={`/invoices?${previewQueryOptions}`}
              icon={null}
              style={{ textTransform: 'inherit' }}
              label={`${INVOICE_STATUS_LABEL[INVOICE_STATUS.PREVIEW]} contractor ${pluralize('invoice', previewContractorInvoicesCount)} ${previewContractorInvoicesCount}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TransactionAnalysisField.propTypes = {
  analytics: PropTypes.object,
};

TransactionAnalysisField.defaultProps = {
  analytics: {},
};

export default TransactionAnalysisField;
