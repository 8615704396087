import React from 'react';
import PropTypes from 'prop-types';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import { useUnselectAll } from 'react-admin';

const OrgBillingTransactionsMarkAsReviewedButton = ({
  selectedIds, permissions,
}) => {
  const numReviewed = (selectedIds && selectedIds.length > 0)
    ? selectedIds.length
    : 0;
  const canReview = (
    permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
  );
  const label = canReview && numReviewed
    ? `Mark ${numReviewed} transactions as reviewed`
    : 'Mark transactions as reviewed';
  const ids = selectedIds || [];
  const unselectAll = useUnselectAll();
  return (
    <BulkApiActionButton
      successNotifMsg="Transactions marked as reviewed"
      disabled={!canReview || !numReviewed}
      label={label}
      title="Review transactions"
      content={`Are you sure you want mark ${numReviewed} selected transactions as reviewed?`}
      icon={null}
      ids={ids}
      resource="organization_billings"
      action="review-transactions"
      onSuccess={() => {
        unselectAll('org_billing_transactions');
      }}
    />
  );
};

OrgBillingTransactionsMarkAsReviewedButton.propTypes = {
  selectedIds: PropTypes.array,
  orgBillingId: PropTypes.number.isRequired,
  permissions: PropTypes.object,
};

OrgBillingTransactionsMarkAsReviewedButton.defaultProps = {
  selectedIds: [],
  permissions: {},
};

export default OrgBillingTransactionsMarkAsReviewedButton;
