import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl } from '@material-ui/core';
import { useNotify } from 'react-admin';

import DateInput from 'admin/assets/js/components/inputs/DateInput.jsx';
import FileInput from 'admin/assets/js/components/inputs/FileInput.jsx';
import Form from 'admin/assets/js/components/Form.jsx';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminAorContractUploadApiUrl } from 'admin/urls';


export const AorContractUploadForm = ({
  setLoading,
  initialValues,
  userCardId,
  loading,
  onCancel,
  onSuccess,
}) => {
  const [files, setFiles] = useState('');
  const notify = useNotify();

  const handleContractUpload = async (values) => {
    setLoading(true);
    // eslint-disable-next-line
    const payload = new FormData();
    // Payload order should remain the same
    payload.append('userCardId', userCardId);
    Object.keys(values).forEach((key) => {
      payload.append(key, values[key]);
    });
    if (files?.[0]) {
      payload.append('file', files[0]);
    }

    const url = adminAorContractUploadApiUrl();
    try {
      await httpClient(url, { method: 'POST', body: payload });
      notify('Contract uploaded successfully');
    } catch (e) {
      notify(`Uploading failed: ${e?.message}`, 'warning');
    } finally {
      onSuccess();
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      FormFieldsRendererComponent={({ errors, setError, updateValue, values }) => {
        const onChange = (name, validate) => ({ target: { value } }) => {
          setError(name, null);
          updateValue(name, value);
          if (validate) {
            const error = validate(value);
            if (error) {
              setError(name, error);
            }
          }
        };

        return (
          <div className="content">
            <FormControl
              className="mb-4 w-100"
              onChange={e => {
                setFiles(e.target.files);
              }}
            >
              <FileInput
                containerClassName="mb-4"
                disabled={loading}
                label="Contract file"
                name="file"
                selectedFiles={files}
                error={errors.file}
              />
            </FormControl>

            <FormControl className="mb-4 w-100">
              <DateInput
                name="signedAt"
                source="signedAt"
                label="Signed at"
                containerClassName="mb-4"
                disabled={loading}
                error={errors.signedAt}
                onChange={onChange('signedAt')}
                required
                value={values.signedAt}
              />
            </FormControl>

            <FormControl className="mb-4 w-100">
              <DateInput
                name="counterSignedAt"
                source="counterSignedAt"
                label="Countersigned at"
                containerClassName="mb-4"
                disabled={loading}
                error={errors.counterSignedAt}
                onChange={onChange('counterSignedAt')}
                required
                value={values.counterSignedAt}
              />
            </FormControl>
          </div>
        );
      }}
      onCancel={onCancel}
      onSubmit={handleContractUpload}
      submitButtonText="Upload"
    />
  );
};

AorContractUploadForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  userCardId: PropTypes.number.isRequired,
};

AorContractUploadForm.defaultProps = {
  initialValues: {},
  onSuccess: () => {},
};

export default AorContractUploadForm;
