import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useGetOne } from 'react-admin';

import Tabs from '@material-ui/core/Tabs';
import { Card, CardHeader, makeStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';

import { TRANSACTION_TABS, TRANSACTION_TABS_LABEL } from 'admin/assets/js/constants';
import TransactionDataTab from 'admin/assets/js/resources/transactions/TransactionDataTab.jsx';
import TransactionDetailsTab from 'admin/assets/js/resources/transactions/TransactionDetailsTab.jsx';
import TransactionViewHeader from 'admin/assets/js/resources/transactions/TransactionViewHeader.jsx';
import TransactionViewActions from 'admin/assets/js/resources/transactions/TransactionViewActions.jsx';

const useStyles = makeStyles({
  header: {
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
    },
  },
});

const CustomTransactionsView = (props) => {
  const { selectedTab } = props;
  const history = useHistory();
  const { id: transactionId } = useParams();
  const { data: transaction } = useGetOne('transactions', transactionId);

  const classes = useStyles();
  const handleChange = useCallback((event, newValue) => {
    history.push(newValue);
  }, [history]);

  if (isEmpty(transaction)) {
    return null;
  }

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
      >
        { Object.keys(TRANSACTION_TABS)
          .map(key => (
            <Tab
              key={TRANSACTION_TABS[key]}
              value={TRANSACTION_TABS[key]}
              label={TRANSACTION_TABS_LABEL[TRANSACTION_TABS[key]]}
            />
          ))}
      </Tabs>
      <Card
        style={{
          backgroundColor: 'transparent', boxShadow: 'none',
        }}
      >
        <CardHeader
          className={classes.header}
          action={(
            <TransactionViewActions
              transaction={transaction}
            />
          )}
          title={<TransactionViewHeader transactionId={transactionId} />}
        />
      </Card>
      {selectedTab === TRANSACTION_TABS.DETAILS && (
        <TransactionDetailsTab transaction={transaction} />
      )}
      {selectedTab === TRANSACTION_TABS.DATA && (
        <TransactionDataTab transaction={transaction} />
      )}
    </>
  );
};

CustomTransactionsView.propTypes = {
  selectedTab: PropTypes.string,
};

CustomTransactionsView.defaultProps = {
  selectedTab: TRANSACTION_TABS.DETAILS,
};

export default CustomTransactionsView;
