/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import {
  TextInput as AdminTextInput,
  useNotify,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TextInput = (props) => {
  const notify = useNotify();
  const { disabled, record, source } = props;
  const copyEnabled = !disabled && !!record[source];
  if (!copyEnabled) {
    return <AdminTextInput {...props} />;
  }
  return (
    <React.Fragment>
      <AdminTextInput {...props} />
      <CopyToClipboard text={record[source]} onCopy={() => notify('Copied to clipboard')}>
        <button type="button" onClick={e => e.preventDefault()}>Copy</button>
      </CopyToClipboard>
    </React.Fragment>
  );
};

export default TextInput;
