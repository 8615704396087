/* eslint
  react/no-multi-comp: "off",
  react/prop-types: "off",
  import/prefer-default-export: "warn"
*/
import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import {
  List, Show,
  Datagrid, Filter,
  TextField, FunctionField, ReferenceField,
  SelectInput, SimpleShowLayout,
  linkToRecord, useUnselectAll,
  TextInput,
  useGetOne,
} from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import TransactionDetailsField from 'admin/assets/js/resources/transactions/TransactionDetailsField.jsx';
import InvoiceAmountsField from 'admin/assets/js/resources/invoices/InvoiceAmountsField.jsx';
import { useStyles } from 'admin/assets/js/layout/themes';
import { removeExtraSpaces } from 'core/assets/js/lib/utils';


import {
  TRANSACTION_METHOD_LABEL,
  TRANSACTION_SEGMENT,
  TRANSACTION_SEGMENT_LABEL,
} from 'finance/assets/js/constants';
import PreformattedField from 'admin/assets/js/components/fields/PreformattedField.jsx';
import OrgBillingTransactionsMarkAsReviewedButton from 'admin/assets/js/resources/organization-billings/OrgBillingTransactionsMarkAsReviewedButton.jsx';
import OrgBillingTransactionsUnmarkAsReviewedButton from 'admin/assets/js/resources/organization-billings/OrgBillingTransactionsUnmarkAsReviewedButton.jsx';
import OrgBillingSummary from 'admin/assets/js/resources/organization-billings/OrgBillingSummary.jsx';
import OrgBillingTransactionsApproveButton from 'admin/assets/js/resources/organization-billings/OrgBillingTransactionsApproveButton.jsx';
import TransactionReviewerField from 'admin/assets/js/resources/transactions/TransactionReviewerField.jsx';

const methodOptions = Object.keys(TRANSACTION_METHOD_LABEL).map(k => ({
  id: k, name: TRANSACTION_METHOD_LABEL[k],
}));

const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput alwaysOn allowEmpty source="method" choices={methodOptions} />
  </Filter>
);

const TransactionTabs = ({ location, history, orgBilling: { analytics } }) => { //eslint-disable-line
  const qs = queryString.parse(location.search);

  const filterOptions = JSON.parse(qs.filter || '{}');
  const orgBillingId = filterOptions.organization_billing_id;
  const unselectAll = useUnselectAll();

  const { transactionSegments: segmentAnalytics } = analytics;
  const tab = filterOptions.segment || TRANSACTION_SEGMENT.PENDING_REVIEW;

  return (
    <Tabs
      centered
      variant="fullWidth"
      style={{ marginBottom: '15px' }}
      value={tab}
      indicatorColor="primary"
      onChange={(event, value) => {
        unselectAll('org_billing_transactions');
        const filter = {
          ...pick(filterOptions, 'billing_process_id', 'organization_id'),
          segment: value,
          organization_billing_id: orgBillingId,
        };
        const query = {
          ...qs,
          filter: JSON.stringify(filter),
          page: '1',
        };
        const path = `${location.pathname}?${queryString.stringify(query)}`;
        history.push(path);
      }}
    >
      {[
        TRANSACTION_SEGMENT.PREVIEW,
        TRANSACTION_SEGMENT.PENDING_REVIEW,
        TRANSACTION_SEGMENT.PENDING_APPROVAL,
        TRANSACTION_SEGMENT.BEING_PROCESSED,
        TRANSACTION_SEGMENT.FINALIZED,
      ].map(seg => (
        <Tab
          key={seg}
          label={`${TRANSACTION_SEGMENT_LABEL[seg]} (${segmentAnalytics[seg].count})`}
          value={seg}
        />
      ))}
    </Tabs>
  );
};


const TransactionListBulkActionButtons = (props) => {
  const {
    orgBillingId, segment,
    basePath, selectedIds, permissions,
  } = props;
  if (segment === TRANSACTION_SEGMENT.BEING_PROCESSED) {
    return null;
  }
  if (segment === TRANSACTION_SEGMENT.PENDING_REVIEW) {
    return (
      <OrgBillingTransactionsMarkAsReviewedButton
        permissions={permissions}
        orgBillingId={orgBillingId}
        selectedIds={selectedIds}
      />
    );
  }
  return (
    <>
      <OrgBillingTransactionsUnmarkAsReviewedButton
        permissions={permissions}
        orgBillingId={orgBillingId}
        selectedIds={selectedIds}
      />
      <OrgBillingTransactionsApproveButton
        permissions={permissions}
        orgBillingId={orgBillingId}
        selectedIds={selectedIds}
        basePath={basePath}
      />
    </>
  );
};

const TransactionList = (props) => {
  const { history, location, match, permissions, basePath } = props;
  const qs = queryString.parse(location.search);
  const filterOptions = JSON.parse(qs.filter || '{}');
  const orgBillingId = filterOptions.organization_billing_id;
  const { segment } = filterOptions;
  const classes = useStyles();

  return (
    <List
      title="Transaction Review"
      filters={<ListFilter />}
      filter={{
        organization_billing_id: orgBillingId,
        segment,
      }}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={(
        [
          TRANSACTION_SEGMENT.PENDING_REVIEW,
          TRANSACTION_SEGMENT.PENDING_APPROVAL,
        ].includes(segment)
          ? (
            <TransactionListBulkActionButtons
              permissions={permissions}
              orgBillingId={orgBillingId}
              segment={segment}
            />
          ) : false)
      }
      resource="org_billing_transactions"
      history={history}
      location={location}
      match={match}
      basePath={basePath}
    >
      <Datagrid>
        <ReferenceField label="Invoice details" source="invoice_id" reference="invoices" link={false}>
          <FunctionField
            render={(record) => {
              const invoiceEditPage = linkToRecord('/invoices', record.id, 'edit');
              const invoiceTitle = record.uniqueNumber !== record.number ? `${record.number} (${record.uniqueNumber})` : record.number;
              const ownerFEName = record.ownerFE.name;
              const ownerFEBankBeneficiary = record.ownerFE.bank.beneficiary;
              const hasDifferentBeneficiary = (
                removeExtraSpaces(ownerFEName.toLowerCase())
                !== removeExtraSpaces(ownerFEBankBeneficiary.toLowerCase())
              );
              return (
                <>
                  <Link to={invoiceEditPage}>{invoiceTitle}</Link>
                  <div className="d-flex">
                    <span className={classes.discreet}>provider</span>
                    <span className={`${classes.info} pl-4 ml-auto`}>
                      {ownerFEName}
                    </span>
                  </div>
                  <div className="d-flex">
                    <span className={classes.discreet}>beneficiary</span>
                    <span className={`${hasDifferentBeneficiary ? classes.danger : classes.info} pl-4 ml-auto`}>
                      {ownerFEBankBeneficiary}
                    </span>
                  </div>
                  <InvoiceAmountsField record={record} showTitle={false} />
                </>
              );
            }}
          />
        </ReferenceField>
        <TransactionDetailsField label="Transaction details" readOnly />
        {[
          TRANSACTION_SEGMENT.PENDING_APPROVAL,
          TRANSACTION_SEGMENT.BEING_PROCESSED,
          TRANSACTION_SEGMENT.FINALIZED,
        ].includes(segment) && (
          <TransactionReviewerField label="Reviewed by" />
        )}
      </Datagrid>
    </List>
  );
};

TransactionList.propTypes = {
  permissions: PropTypes.object.isRequired,
};

const PendingTransactionList = (props) => {
  const { location } = props;
  const qs = queryString.parse(location.search);
  const filterOptions = JSON.parse(qs.filter || '{}');
  const orgBillingId = filterOptions.organization_billing_id;
  const { data: orgBilling, loading, error } = useGetOne('organization_billings', orgBillingId);
  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>
      <TransactionTabs orgBilling={orgBilling} {...props} />
      <OrgBillingSummary {...props} id={orgBillingId} />
      <TransactionList {...props} resource="org_billing_transactions" />
    </>
  );
};

const TransactionShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <PreformattedField label="Amounts" source="amounts_dump" />
    </SimpleShowLayout>
  </Show>
);

export {
  PendingTransactionList as List,
  TransactionShow as Show,
};
