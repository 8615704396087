import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SelectInput,
  SimpleForm,
  useNotify,
  useCreate,
  useGetList,
  FormDataConsumer,
} from 'react-admin';
import { pick } from 'lodash';

import { SYSTEM_BANK_ACCOUNT_INSTITUTIONS } from 'admin/assets/js/resources/system_bank_accounts/constants';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { CURRENCY_LABEL, SYSTEM } from 'core/assets/js/constants';


import BankAccountVariant from 'settings/assets/js/lib/BankAccountVariant';
import SystemBankAccountFormButtons from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountFormButtons.jsx';

const SystemBankAccountForm = ({
  dialogClose, ...props
}) => {
  const notify = useNotify();
  const [create] = useCreate('system_bank_accounts');
  const { isLoading: isLoadingEntities, data: invSysEntities } = useGetList(
    'invoicing_entities',
    { page: 1, perPage: 5 },
    { field: 'id', order: 'DESC' },
  );
  const institutionOptions = useMemo(() => (
    SYSTEM_BANK_ACCOUNT_INSTITUTIONS.map(institution => ({
      id: institution, name: institution,
    }))
  ), []);
  const countryOptions = useMemo(() => (
    Object.keys(countryNames).map(k => ({
      id: k, name: countryNames[k],
    }))
  ), []);
  const currencyOptions = useMemo(() => Object.keys(CURRENCY_LABEL).map(k => ({
    id: k, name: CURRENCY_LABEL[k],
  })), [CURRENCY_LABEL]);

  const invoicingEntityOptions = useMemo(() => {
    if (!isLoadingEntities) {
      return Object.keys(invSysEntities).map(key => ({
        id: invSysEntities[key].id, name: invSysEntities[key].name,
      }));
    }
    return [];
  }, [isLoadingEntities, invSysEntities]);

  return (
    <SimpleForm
      {...props}
      redirect={false}
      variant="standard"
      toolbar={<SystemBankAccountFormButtons dialogClose={dialogClose} />}
      save={(formValues) => {
        const passThroughFormFields = [
          'bankName', 'bic', 'iban', 'accountNumber',
          'bankCode', 'countryCode', 'currency',
        ];
        const variantDetails = {
          ...pick(formValues, passThroughFormFields),
          userId: SYSTEM.USER_ID,
        };
        const bankAccountVariant = new BankAccountVariant(variantDetails);
        let preparedData = {
          invoicing_entity_id: formValues.invoicingEntityId,
        };
        try {
          preparedData = {
            ...preparedData,
            details_dump: JSON.stringify(bankAccountVariant.serialize()),
          };
        } catch (err) {
          notify('Could not create system bank account', 'error');
        }

        create({ payload: { data: preparedData } }, {
          onSuccess: () => {
            dialogClose();
            notify('System bank account created', 'info');
          },
          onFailure: () => {
            notify('Could not create system bank account', 'error');
          },
        });
      }}
    >
      <FormDataConsumer>
        {() => (
          <>
            <SelectInput
              key="bankName"
              source="bankName"
              label="Institution"
              choices={institutionOptions}
              required
              fullWidth
              validate={val => (!val ? 'Field is required' : '')}
            />
            <SelectInput
              key="invoicingEntity"
              source="invoicingEntityId"
              label="Name"
              choices={invoicingEntityOptions}
              required
              fullWidth
              validate={val => (!val ? 'Field is required' : '')}
            />
            <SelectInput
              key="currency"
              source="currency"
              label="Currency"
              choices={currencyOptions}
              required
              fullWidth
              validate={val => (!val ? 'Field is required' : '')}
            />
            <SelectInput
              key="countryCode"
              source="countryCode"
              label="Country"
              choices={countryOptions}
              required
              fullWidth
              validate={val => (!val ? 'Field is required' : '')}
            />
            <TextInput
              label="IBAN"
              source="iban"
              fullWidth
            />
            <TextInput
              label="BIC"
              source="bic"
              fullWidth
            />
            <TextInput
              label="Account Number"
              source="accountNumber"
              fullWidth
            />
            <TextInput
              label="Bank code"
              source="bankCode"
              fullWidth
            />
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

SystemBankAccountForm.propTypes = {
  dialogClose: PropTypes.func.isRequired,
};


export default SystemBankAccountForm;
