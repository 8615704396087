import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Title } from 'react-admin';
import { Card, CardContent, CardHeader, Container, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import FixMissingSignNowDocumentsButton from 'admin/assets/js/components/buttons/FixMissingSignNowDocumentsButton.jsx';
import StatusCard from 'admin/assets/js/components/StatusCard.jsx';
import FreezeAndAuditInvoicingButton from 'admin/assets/js/components/buttons/FreezeAndAuditInvoicingButton.jsx';
import DryRunInvoicingButton from 'admin/assets/js/components/buttons/DryRunInvoicingButton.jsx';
import GeneratePublishedInvoicesButton from 'admin/assets/js/components/buttons/GeneratePublishedInvoicesButton.jsx';
import CreateTransactionsButton from 'admin/assets/js/components/buttons/CreateTransactionsButton.jsx';
import CancelSpecificTransfersButton from 'admin/assets/js/components/buttons/CancelSpecificTransfersButton.jsx';
import FetchSpecificTransfersButton from 'admin/assets/js/components/buttons/FetchSpecificTransfersButton.jsx';
import FetchStatementsButton from 'admin/assets/js/components/buttons/FetchStatementsButton.jsx';
import FetchStatementsAtTimeButton from 'admin/assets/js/components/buttons/FetchStatementsAtTimeButton.jsx';
import ReconcileTransactionsButton from 'admin/assets/js/components/buttons/ReconcileTransactionsButton.jsx';
import ReconcileAllTransactionsButton from 'admin/assets/js/components/buttons/ReconcileAllTransactionsButton.jsx';
import FetchSpecificRevolutTransactionsButton from 'admin/assets/js/components/buttons/FetchSpecificRevolutTransactionsButton.jsx';
import FetchSpecificPayoneerPayoutsButton from 'admin/assets/js/components/buttons/FetchSpecificPayoneerPayoutsButton.jsx';
import IncludeServiceOrdersButton from 'admin/assets/js/components/buttons/IncludeServiceOrdersButton.jsx';
import RebuildInvoicesButton from 'admin/assets/js/components/buttons/RebuildInvoicesButton.jsx';
import FetchSpecificRecipientsButton from 'admin/assets/js/components/buttons/FetchSpecificRecipientsButton.jsx';
import RunDataMigrationButton from 'admin/assets/js/components/buttons/RunDataMigrationButton.jsx';
import SendWorksheetsReminderButton from 'admin/assets/js/components/buttons/SendWorksheetsReminderButton.jsx';
import EnableIndicesButton from 'admin/assets/js/components/buttons/EnableIndicesButton.jsx';
import ESStatsButton from 'admin/assets/js/components/buttons/ESStatsButton.jsx';
import RebuildIndicesButton from 'admin/assets/js/components/buttons/RebuildIndicesButton.jsx';
import SyncIndexSetsButton from 'admin/assets/js/components/buttons/SyncIndexSetsButton.jsx';
import PauseDeploymentsButton from 'admin/assets/js/components/buttons/PauseDeploymentsButton.jsx';
import ApiCommandButton from 'admin/assets/js/components/buttons/ApiCommandButton.jsx';
import { ADMIN_URL, USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import ReCalculateServiceOrderFundsButton from 'admin/assets/js/components/buttons/ReCalculateServiceOrderFundsButton.jsx';
import ReCalculateUserCardProgressButton from 'admin/assets/js/components/buttons/ReCalculateUserCardProgressButton.jsx';
import InvoicingProcessStatusCard from 'admin/assets/js/components/InvoicingProcessStatusCard.jsx';
import FetchSpecificStripeSubscriptionsButton from 'admin/assets/js/components/buttons/FetchSpecificStripeSubscriptionsButton.jsx';
import CaptureFesButton from 'admin/assets/js/components/buttons/CaptureFesButton.jsx';
import { NODE_ENV, ENV_DEVELOPMENT, IS_PRODUCTION } from 'core/assets/js/config/settings';

const Dashboard = ({ permissions }) => {
  const isDevelopment = NODE_ENV === ENV_DEVELOPMENT;
  const [reconcileHours, setReconcileHours] = useState('24');
  const [documentAssignmentId, setDocumentAssignmentId] = useState('');
  return (
    <React.Fragment>
      <Title title="Admin Panel" />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Card style={{ marginBottom: '15px' }}>
            <CardContent>
              <Typography data-testid="welcome-message" style={{ textAlign: 'center' }}>
                Welcome to TalentDesk.io administration
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <InvoicingProcessStatusCard />
        </Grid>
        <Grid item xs={12}>
          <StatusCard />
        </Grid>
        {permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Management commands" />
              <CardContent>
                <RebuildIndicesButton />
                <EnableIndicesButton />
                <ESStatsButton />
                <SyncIndexSetsButton />
                <RunDataMigrationButton />
                <SendWorksheetsReminderButton />
                <ReCalculateUserCardProgressButton />
                <ReCalculateServiceOrderFundsButton />
                <ApiCommandButton
                  label="Clear Wise OAuth token cache"
                  title="Clear Wise OAuth token cache"
                  content="Are you sure you want to clear the Wise OAuth token cache?"
                  command="clear-wise-oauth-token-cache"
                />
                <PauseDeploymentsButton />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Invoice commands" />
              <CardContent>
                <GeneratePublishedInvoicesButton />
                <DryRunInvoicingButton />
                <FreezeAndAuditInvoicingButton />
                <CreateTransactionsButton />
                <RebuildInvoicesButton />
                <RebuildInvoicesButton onlyStaticData />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Finance commands" />
              <CardContent>
                <Container style={{ marginBottom: '1rem', marginLeft: '0', paddingLeft: '0' }}>
                  <ApiCommandButton
                    label="Sync banks"
                    title="Sync banks"
                    content="Are you sure you want to sync banks?"
                    command="sync-banks"
                  />
                  <IncludeServiceOrdersButton />
                  <ReconcileTransactionsButton />
                  <ReconcileAllTransactionsButton />
                  <CaptureFesButton />
                </Container>
                <Container style={{ marginBottom: '1rem', marginLeft: '0', paddingLeft: '0' }}>
                  <FetchSpecificStripeSubscriptionsButton />
                </Container>
                <Container style={{ marginBottom: '1rem', marginLeft: '0', paddingLeft: '0' }}>
                  <ApiCommandButton
                    label="Sync Wise recipients"
                    title="Sync Wise recipients"
                    content="Are you sure you want to sync Wise recipients?"
                    command="sync-transferwise-recipients"
                  />
                  <FetchSpecificRecipientsButton />
                  <FetchSpecificTransfersButton />
                  <FetchStatementsButton />
                  <FetchStatementsAtTimeButton />
                  <ApiCommandButton
                    label="Fetch Wise transfers"
                    title="Fetch Wise transfers"
                    content="Are you sure you want to fetch Wise transfers?"
                    command="fetch-trw-transfers"
                  />
                  <CancelSpecificTransfersButton />
                </Container>
                <Container style={{ marginBottom: '1rem', marginLeft: '0', paddingLeft: '0' }}>
                  <FetchSpecificRevolutTransactionsButton />
                </Container>
                <Container style={{ marginBottom: '1rem', marginLeft: '0', paddingLeft: '0' }}>
                  <FetchSpecificPayoneerPayoutsButton />
                </Container>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Dimensions commands" />
              <CardContent>
                <ApiCommandButton
                  label="Sanitize invoicing settings dimension"
                  title="Sanitize invoicing settings dimension"
                  content="Are you sure you want to sanitize invoicing settings dimension?"
                  command="sanitize-invoicing-settings"
                />
                <ApiCommandButton
                  label="Sanitize financial entities dimension"
                  title="Sanitize financial entities dimension"
                  content="Are you sure you want to sanitize financial entities dimension?"
                  command="sanitize-financial-entities"
                />
                <ApiCommandButton
                  label="Destroy unused quotes"
                  title="Destroy unused quotes"
                  content="Are you sure you want to destroy unused quotes?"
                  command="destroy-unused-quotes"
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Infrastructure commands" />
              <CardContent>
                <ApiCommandButton
                  label="Reboot"
                  title="Reboot"
                  content="Are you sure you want to reboot the application?"
                  command="reboot"
                />
                <ApiCommandButton
                  label="Clear cache"
                  title="Clear all cache"
                  content="Are you sure you want to clear all cache?"
                  command="cache-clear"
                />
                <ApiCommandButton
                  label="Clear sessions cache"
                  title="Clear sessions cache from Redis"
                  content="Are you sure you want to clear session cache from Redis?"
                  command="session-cache-clear"
                />
                <ApiCommandButton
                  label="Clear notifications cache"
                  title="Clear notifications cache"
                  content="Are you sure you want to clear notifications cache?"
                  command="cache-clear"
                  query={{ type: 'notifications' }}
                />
                <ApiCommandButton
                  label="Test Slack message"
                  title="Test Slack message"
                  content={(
                    <>
                      {'Are you sure you want to send a test slack message to the '}
                      <a
                        href="https://talentdesk.slack.com/archives/C056TUM9DGR"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        #slack-messages-test
                      </a>
                      {' channel?'}
                    </>
                  )}
                  command="test-slack-message"
                />
                <ApiCommandButton
                  label="Setup SignNow webhooks"
                  title="Setup SignNow webhooks"
                  content={`
                    This will delete any existing SignNow webhook event subscriptions and create
                    new ones for the events we care about. Are you sure you want to do this?
                  `}
                  command="setup-sign-now-webhooks"
                />
                <ApiCommandButton
                  label="Setup SignNow branding"
                  title="Setup SignNow branding"
                  content={`
                    This will configure our branding for SignNow documents and set it for any
                    existing or future documents
                  `}
                  command="setup-sign-now-branding"
                />
                {isDevelopment && (
                  <a
                    href={`${ADMIN_URL}/queues-board`}
                    className="btn btn-outline-dark m-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>SEE QUEUES BOARD</strong>
                  </a>
                )}
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Development commands" />
              <CardContent>
                {/* <ApiCommandButton
                  label="Test button"
                  title="Test button"
                  content="Are you sure you want to execute this test button?"
                  command="test-command"
                /> */}
                <ApiCommandButton
                  label="Import blocked email domains"
                  title="Import blocked email domains"
                  content={`This will empty the 'blocked_email_domains' table and then import the 96k
                    email domain records from 'db/data/free-email-domains.txt'`}
                  command="import-blocked-email-domains"
                />
                <ApiCommandButton
                  label="Migrate filestack"
                  title="Migrate filestack"
                  content="Are you sure you want to migrate filestack records into our AWS?"
                  command="migrate-filestack"
                />
                {IS_PRODUCTION && <FixMissingSignNowDocumentsButton />}
                {isDevelopment && (
                  <ApiCommandButton
                    label="Send message notifications"
                    title="Send message notifications"
                    content="Are you sure you want to run this task?"
                    command="send-message-notifications"
                  />
                )}
                <ApiCommandButton
                  label="Reconcile pending SignNow signatures"
                  title="Reconcile pending SignNow signatures"
                  content="Are you sure you want to reconcile pending SignNow signatures?"
                  command="reconcile-pending-sign-now-signatures"
                />
                <ApiCommandButton
                  label="Reconcile SignNow documents"
                  title="Reconcile SignNow documents"
                  content={(
                    <>
                      <TextField
                        InputProps={{ inputProps: { min: 1, step: 1 } }}
                        label="Hours to reconcile"
                        onChange={e => setReconcileHours(e.target.value)}
                        type="number"
                        value={reconcileHours}
                      />
                      <p className="mt-5">
                        This will loop through every SignNow document created in the last
                        {` ${reconcileHours} hours and reconcile them with our database records, `}
                        fixing any issues where we can. Are you sure you want to do this?
                      </p>
                    </>
                  )}
                  command={`reconcile-sign-now-documents/${reconcileHours}`}
                />
                <ApiCommandButton
                  label="Recreate document assignment signed file"
                  title="Recreate document assignment signed file"
                  content={(
                    <>
                      <TextField
                        label="Assignment ID"
                        onChange={e => setDocumentAssignmentId(e.target.value)}
                        type="number"
                        value={documentAssignmentId}
                      />
                      <p className="mt-5">
                        This will download a new PDF copy of a signed document assignment from
                        SignNow and update the `document_assignments.signed_file_id` value to it
                      </p>
                    </>
                  )}
                  command={`recreate-document-assignment-signed-file/${documentAssignmentId}`}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  permissions: PropTypes.object,
};

Dashboard.defaultProps = {
  permissions: {
    role: 'admin',
  },
};

export default Dashboard;
