import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Menu } from '@material-ui/core';

import ArchiveButton from 'admin/assets/js/resources/payer_mappings/ArchiveButton.jsx';
import EditButton from 'admin/assets/js/resources/payer_mappings/EditButton.jsx';

const SettingsMappingsMenu = ({ record, resource }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <div className="d-flex justify-content-end">
      <Button
        color="primary"
        onClick={handleClick}
        variant="outlined"
      >
        <Icon
          fontSize="small"
        >
          {anchorEl ? 'arrow_drop_up' : 'arrow_drop_down'}
        </Icon>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: -5,
          horizontal: 'right',
        }}
      >
        <div className="d-flex flex-column">
          <ArchiveButton record={record} resource={resource} />
          <EditButton record={record} resource={resource} />
        </div>
      </Menu>
    </div>
  );
};

SettingsMappingsMenu.propTypes = {
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
};

export default SettingsMappingsMenu;
