/**
 * Based on a classification questionaire's questions and choices, calculate the minimum and maximum
 * score binding
 *
 * @param {Object[]} questions
 * @returns {{ min: Number|Null, max: Number|Null}}
 */// eslint-disable-next-line import/prefer-default-export
export const calculateClassificationQuestionnaireScoreBinding = questions => {
  if (!Array.isArray(questions) || questions.length === 0) {
    return { min: null, max: null };
  }
  let min = null;
  let max = null;
  questions.forEach(question => {
    if (!Array.isArray(question.choices)) {
      return;
    }
    const scores = [];
    question.choices.forEach(choice => {
      const { score } = choice;
      const isString = typeof score === 'string';
      if (!isString && typeof score !== 'number') {
        return;
      }
      const scoreString = isString ? score : score.toString();
      if (/^(-)?\d+$/.test(scoreString)) {
        scores.push(parseInt(scoreString, 10));
      }
    });
    if (scores.length < 2) {
      return;
    }
    if (min === null) {
      min = 0;
    }
    if (max === null) {
      max = 0;
    }
    min += Math.min(...scores);
    max += Math.max(...scores);
  });
  return { min, max };
};
