/* eslint react/no-multi-comp: "off" */ // eslint-disable-line
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { List, Datagrid, TextField, FunctionField, ListButton } from 'react-admin';
import ChevronRight from '@material-ui/icons/ChevronRight';
import queryString from 'query-string';
import Chip from '@material-ui/core/Chip';

import httpClient from 'admin/assets/js/lib/httpClient';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import DownloadAccountingReportButton from 'admin/assets/js/components/buttons/DownloadAccountingReportButton.jsx';

import RunBillingProcessETLButton from 'admin/assets/js/components/buttons/RunBillingProcessETLButton.jsx';
import RunAllBillingProcessesForOrgETLButton from 'admin/assets/js/components/buttons/RunAllBillingProcessesForOrgETLButton.jsx';
import RunBillingProcessesForYearETLButton from 'admin/assets/js/components/buttons/RunBillingProcessesForYearETLButton.jsx';

import { formatDate } from 'admin/assets/js/lib/utils';
import { adminApiUpcomingBillingProcessesUrl } from 'admin/urls';

/**
 * Format a date and indicate if the date is in the future (
 * ie after today, regardless of time of day ).
 *
 * We don't indiciate a date is in the future if it's on the same
 * date but at a later time.  As this may potentially flag
 * today's billing as a future billing, which we don't want.
 *
 * The purpose of indicating future dates is to ignore billing
 * processes in the UI that may appear before today's invoicing.
 * As this has caused confusion in the UI in the past.
 *
 * @param {moment|date|string} date - the date to render.
 * @param {string} format - render format of the date.
 * @return {JSX.Element} mark up to render the date as per the future,
 * including an indicator of future dates.
 */
const indicateFutureDates = (date, format) => {
  // note - date only, don't care about time of day
  const isFutureDate = moment(date).isAfter(moment(), 'day');
  return (
    <>
      {isFutureDate ? (
        <>
          <span style={{ opacity: 0.25 }}>
            {formatDate(date, format)}
          </span>
          <span className="text-danger">
            <strong>
              {' ( future )'}
            </strong>
          </span>
        </>
      ) : (
        formatDate(date, format)
      )}
    </>
  );
};

const BillingProcessesDataGrid = ({ permissions }) => (
  <Datagrid>
    <TextField source="id" label="#" />
    <FunctionField
      source="billing_deadline"
      label="Billing deadline"
      render={record => indicateFutureDates(record.billing_deadline, 'DD MMM YYYY HH:mm:ss')}
    />
    <FunctionField
      source="billing_date"
      label="Billing date"
      render={record => indicateFutureDates(record.billing_date, 'DD MMM YYYY')}
    />
    <FunctionField
      render={(record) => {
        const { id } = record;
        const queryOptions = queryString.stringify({
          order: 'DESC',
          perPage: 25,
          sort: 'id',
          filter: JSON.stringify({
            billing_process_id: id,
          }),
        });
        return (
          <ListButton
            basePath={`/invoices?${queryOptions}`}
            label="See invoices"
            icon={<ChevronRight />}
          />
        );
      }}
    />
    <FunctionField
      render={(record) => {
        const { billing_date: billingDate } = record;
        const queryOptions = queryString.stringify({
          order: 'DESC',
          perPage: 25,
          sort: 'id',
          filter: JSON.stringify({
            billing_date: billingDate,
          }),
        });

        return (
          <ListButton
            basePath={`/organization_billings?${queryOptions}`}
            label="See analysis"
            icon={<ChevronRight />}
          />
        );
      }}
    />
    { permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
      <DownloadAccountingReportButton />
    )}
    { permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER] && (
      <RunBillingProcessETLButton />
    )}
  </Datagrid>
);

BillingProcessesDataGrid.propTypes = {
  permissions: PropTypes.object,
};

BillingProcessesDataGrid.defaultProps = {
  permissions: {
    role: 'admin',
  },
};

const BillingProcessesActions = ({ permissions }) => {
  const [upcomingBillingDeadlines, setBillingDeadlines] = useState([]);
  useEffect(() => {
    (async function getUpcomingProcesses() {
      const url = adminApiUpcomingBillingProcessesUrl();
      const billingProcesses = await httpClient(url, { method: 'GET' });
      setBillingDeadlines([
        ...billingProcesses.json.map(process => process.billingDeadline),
      ]);
    }());
  }, []);

  const isSuperAdmin = permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER];

  const dateFormatting = 'DD MMM';
  const today = moment().format(dateFormatting);
  return isSuperAdmin && (
    <>
      {!isEmpty(upcomingBillingDeadlines) && (
        <div className="mt-2 w-100">
          <span className="mr-3"><strong>Preview invoices for</strong></span>
          { upcomingBillingDeadlines.map((billingDeadline) => {
            const formattedUpcomingDeadline = formatDate(
              billingDeadline, dateFormatting, false,
            );
            const label = formattedUpcomingDeadline === today ? 'Today\'s' : formattedUpcomingDeadline;
            const queryOptions = queryString.stringify({
              order: 'DESC',
              perPage: 25,
              sort: 'id',
              filter: JSON.stringify({
                billingDeadline,
              }),
            });
            return (
              <Link
                key={billingDeadline}
                to={{
                  pathname: '/next_billing_organizations',
                  search: `?${queryOptions}`,
                }}
                className="mr-2"
              >
                <Chip
                  onClick={() => {}}
                  color="primary"
                  size="small"
                  label={label}
                />
              </Link>
            );
          })}
        </div>
      )
      }
      <RunAllBillingProcessesForOrgETLButton />
      <RunBillingProcessesForYearETLButton />
    </>
  );
};

BillingProcessesActions.propTypes = {
  permissions: PropTypes.object,
};

BillingProcessesActions.defaultProps = {
  permissions: {},
};

const BillingProcesses = (props) => {
  return (
    <List
      actions={<BillingProcessesActions {...props} />}
      {...props}
      sort={{ field: 'billing_deadline', order: 'DESC' }}
      bulkActionButtons={false}
      filter={{ is_arbitrary: 0 }}
    >
      <BillingProcessesDataGrid {...props} />
    </List>
  );
};

export {
  // eslint-disable-next-line import/prefer-default-export
  BillingProcesses as List,
};
