import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { COLORS } from 'admin/assets/js/constants';

const ConfirmModal = ({
  cancel,
  confirm,
  content,
  disableCtas,
  showCtaIcons,
  extraContent,
  loading,
  onClose,
  onConfirm,
  open,
  extraActions,
  showConfirm,
  title,
  highlightCancel,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    loading={loading}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="md"
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {content}
      </DialogContentText>
      {extraContent}
    </DialogContent>
    <DialogActions>
      {showConfirm && (
        <Button disabled={disableCtas || loading} onClick={() => onConfirm()} color="primary" autoFocus>
          <>
            {showCtaIcons ? <Icon>check</Icon> : null}
            {confirm}
          </>
        </Button>
      )}
      <Button
        disabled={loading}
        onClick={onClose}
        color="primary"
        style={highlightCancel ? { color: COLORS.DANGER } : {}}
      >
        <>
          {showCtaIcons ? <Icon>close</Icon> : null}
          {cancel}
        </>
      </Button>
      {extraActions.map(({ action, payload, icon, label }) => (
        <Button
          disabled={disableCtas || loading}
          key={action}
          onClick={() => {
            onConfirm(action, payload);
          }}
          color="primary"
        >
          <Icon>{icon}</Icon>
          {label}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

ConfirmModal.propTypes = {
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableCtas: PropTypes.bool,
  showCtaIcons: PropTypes.bool,
  extraActions: PropTypes.array,
  extraContent: PropTypes.node,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  showConfirm: PropTypes.bool,
  title: PropTypes.string,
  highlightCancel: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  cancel: 'Cancel',
  confirm: 'Confirm',
  content: null,
  disableCtas: false,
  showCtaIcons: true,
  extraActions: [],
  extraContent: null,
  loading: false,
  onClose: () => {},
  onConfirm: () => {},
  open: false,
  showConfirm: true,
  title: null,
  highlightCancel: false,
};

export default ConfirmModal;
