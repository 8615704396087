import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { TextField } from '@material-ui/core';

import { adminCommandApiUrl } from 'admin/urls';
import httpClient from 'admin/assets/js/lib/httpClient';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';

const ReCalculateUserCardProgressButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userCardIdsText, setUserCardIdsText] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const label = 'Re-calculate user card progress percentages';

  const closeModal = () => {
    setUserCardIdsText('');
    setModalOpen(false);
  };

  const onConfirm = async () => {
    const command = 'recalculate-onboarding-percentages';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { body: JSON.stringify({ userCardIdsText }), method: 'POST' });
      notify('Provider user card percentages re-calculation started');
      closeModal();
    } catch (e) {
      notify('Recalculating failed', e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => setModalOpen(true)}
      >
        <strong>{label?.toUpperCase()}</strong>
      </a>
      <ConfirmModal
        confirm="Ok"
        content={(
          <>
            This action will recalculate every provider&apos;s user card progress percentage.
            <br />
            <b>Proceed with caution!</b>
            <br />
            It will run in the background and take one second per user card to complete.
            <br />
            You can optionally paste/type in a comma seperated list of user card ids to limit
            the recalculation to:
          </>
        )}
        disableCtas={loading}
        extraContent={(
          <TextField
            className="w-100"
            label="User card ids (e.g. '123, 234')"
            onChange={({ target: { value } }) => setUserCardIdsText(value)}
          />
        )}
        onClose={() => closeModal()}
        onConfirm={onConfirm}
        open={modalOpen}
        title={label}
      />
    </>
  );
};

export default ReCalculateUserCardProgressButton;
