import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  banner: {
    backgroundColor: '#369fdc',
    color: 'white',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
});

const PreviousTransactionBanner = ({ transaction }) => {
  const classes = useStyles();
  const { previousTransactionId } = transaction;
  return previousTransactionId ? (
    <div className={classes.banner}>
      <Info />
      {' This transaction was recreated from transaction '}
      <Link to={`/transactions/${previousTransactionId}/details`}>
        #
        {previousTransactionId}
      </Link>
    </div>
  ) : null;
};

PreviousTransactionBanner.propTypes = {
  transaction: PropTypes.object,
};

PreviousTransactionBanner.defaultProps = {
  transaction: {},
};

export default PreviousTransactionBanner;
