import React from 'react';
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';

import {
  TRANSACTION_METHOD_LABEL,
} from 'finance/assets/js/constants';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const methodOptions = Object.keys(TRANSACTION_METHOD_LABEL).map(k => ({
  id: k, name: TRANSACTION_METHOD_LABEL[k],
}));

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput alwaysOn allowEmpty source="method" choices={methodOptions} />
    <ReferenceInput
      alwaysOn
      allowEmpty
      label="Organization"
      source="organization_id"
      reference="organizations_lite"
      perPage={MAX_ORGANIZATIONS_FETCHED}
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
    >
      <SelectInput
        optionText={org => `${org.name}
         (${org.unique_alias})`}
      />
    </ReferenceInput>
  </Filter>
);

export default ListFilter;
