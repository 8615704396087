import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Button, useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  ICON,
  BS_STYLE,
  BS_SIZE,
  BS_TOOLTIP_PLACEMENT,
} from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import Dialog from '@material-ui/core/Dialog';
import TransactionForm, {
  parseInitialValues,
  parseGroupInitialValues,
} from 'admin/assets/js/components/transactions/TransactionForm.jsx';
import AbbreviatedField from './AbbreviatedField.jsx';
import Banner from 'admin/assets/js/components/Banner.jsx';
import { BANNER_TYPE } from 'admin/assets/js/constants';

const useTransactionFieldStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    '&:hover': {
      '& $createTransactionButton': {
        opacity: 1,
      },
    },
  },
  createTransactionButton: {
    opacity: 1,
    transitionDuration: '300ms',
    '& .btn-primary': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      padding: '8px',
      margin: '5px',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#3F51B5',
        '& .fal': {
          color: 'blue',
        },
      },
    },
  },
}));

const TransactionModalField = ({
  resource, basePath, record: invoice,
  disabledCta, disabledInternalCtas, disabledMessage,
  isIconCta, showTransactionsListIds, cashoutInvoices,
}) => {
  const [selected, setSelected] = useState(null);
  const classes = useTransactionFieldStyles();
  if ((isEmpty(invoice) || !invoice.amounts) && cashoutInvoices?.length === 0) {
    return null;
  }
  let initialValues = {};
  if (cashoutInvoices.length > 0) {
    initialValues = parseGroupInitialValues({
      invoices: cashoutInvoices,
    });
  } else {
    initialValues = parseInitialValues({ invoice });
  }

  const create = !isEmpty(get(selected, 'initialValues'));

  const { isLoading, data } = useGetList(
    'transactions',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'DESC' },
    { invoice_id: invoice.id },
  );

  if (isLoading) {
    return null;
  }

  const transactions = Object.values(data);


  return (
    <div className={classes.container}>
      {!isIconCta ? (
        <Button
          label="Record transaction"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelected({ initialValues });
          }}
          variant="contained"
          color="primary"
          size="small"
        />
      ) : (
        <div className={classes.createTransactionButton}>
          <TDButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelected({ initialValues });
            }}
            disabled={disabledCta}
            tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
            bsSize={BS_SIZE.LARGE}
            variant={BS_STYLE.PRIMARY}
            btnIcon={ICON.ADD_CIRCLE}
            rounded
          />
        </div>
      )}
      <div>
        {showTransactionsListIds && transactions.map(t => (
          <Button
            key={t.id}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelected({ record: t });
            }}
          >
            <AbbreviatedField record={t} source="descriptor" />
          </Button>
        ))}
      </div>
      <Dialog
        open={!isEmpty(selected)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onClose={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setSelected(null);
        }}
      >
        {disabledInternalCtas && disabledMessage && (
          <div className="p-4">
            <Banner
              content={disabledMessage}
              type={BANNER_TYPE.INFO}
            />
          </div>
        )}
        <TransactionForm
          resource={resource}
          basePath={basePath}
          invoice={invoice}
          cashoutInvoices={cashoutInvoices}
          disabled={!create || disabledInternalCtas}
          onClose={() => setSelected(null)}
          {...selected}
        />
      </Dialog>
    </div>
  );
};

TransactionModalField.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  disabledCta: PropTypes.bool,
  disabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabledInternalCtas: PropTypes.bool,
  isIconCta: PropTypes.bool,
  showTransactionsListIds: PropTypes.bool,
  invoiceTotalAmount: PropTypes.string,
  invoiceTotalCurrency: PropTypes.string,
  cashoutInvoices: PropTypes.array,
};

TransactionModalField.defaultProps = {
  disabledCta: false,
  disabledInternalCtas: false,
  disabledMessage: null,
  isIconCta: true,
  showTransactionsListIds: true,
  invoiceTotalAmount: null,
  invoiceTotalCurrency: null,
  cashoutInvoices: [],
  record: {},
};

export default TransactionModalField;
