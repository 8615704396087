import React, { Fragment, useState } from 'react';
import { Form } from 'react-final-form';
import { Button, useRefresh, useNotify } from 'react-admin';
import PropTypes from 'prop-types';

import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogContentText, DialogTitle, DialogContent, Box } from '@material-ui/core';

import { adminCommandApiUrl } from 'admin/urls';

const SendBankAccountCheckReminderButton = ({ userId, bankAccountId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async () => {
    const command = 'bankAccount-send-reminders';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify({ userId, bankAccountId }) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={handleClick} label="Send review request email" />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Send worksheet reminders"
      >
        <DialogTitle>Send bank account review notification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will send a notifications email
            to the provider asking for bank details update.
            Proceed with caution.
          </DialogContentText>
          <Form onSubmit={handleGenerateClick}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box mt={4} mb={2}>
                  <Button variant="contained" disabled={loading} label="Send notification" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </Box>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

SendBankAccountCheckReminderButton.propTypes = {
  bankAccountId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};
export default SendBankAccountCheckReminderButton;
