import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from 'admin/assets/js/layout/themes';
import {
  ReferenceField, FunctionField,
} from 'react-admin';

const InvoiceInfoField = ({ showMeta, ...props }) => {
  const { record } = props;
  const classes = useStyles();
  return (
    <span>
      <ReferenceField
        {...props}
        source="id"
        reference="invoices"
        link={() => `/children_invoices?filter=${encodeURIComponent(JSON.stringify({ parent_id: record.id }))}`}
      >
        <FunctionField
          label="Number"
          render={r => (
            record.uniqueNumber === record.number
              ? (
                <React.Fragment>
                  {r.number}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {r.uniqueNumber}
                  <br />
                  (
                  {r.number}
                  )
                </React.Fragment>
              )
          )}
        />
      </ReferenceField>
      {showMeta && (
        <>
          <br />
          <small>
            <span className={classes.discreet}>
              on
              {' '}
              {record.date}
              <br />
              due at
              {' '}
              {record.dueAt}
            </span>
          </small>
        </>
      )}
    </span>
  );
};

InvoiceInfoField.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
  showMeta: PropTypes.bool,
};

InvoiceInfoField.defaultProps = {
  record: {},
  showMeta: true,
};

export default InvoiceInfoField;
