import React from 'react';
import { useGetOne } from 'react-admin';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import Payer from 'finance/assets/js/lib/Payer';
import ListAsideMessage from './ListAsideMessage.jsx';
import MapPayerButton from './MapPayerButton.jsx';

const useStyles = makeStyles(theme => ({
  listContainer: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  alert: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  alertContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const MapPayerForm = ({ transferId, payerId, onPayerMapping }) => {
  const classes = useStyles();

  const { data: dimPayer, loading, error } = useGetOne('dim_payers', payerId);

  if (!payerId) {
    return null;
  }

  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const payer = new Payer(dimPayer.details);
  const payerName = payer.getName();

  return (
    <>
      <Alert severity="warning" className={classes.alert}>
        <div style={{ marginBottom: '10px' }}>
          <strong>Payer name is not mapped to an organization!</strong>
        </div>
        <div
          className={classes.alertContent}
        >
          <strong>{`${payerName}`}</strong>
          <span style={{ marginLeft: '2px' }}>is not mapped to an organization.</span>
          <MapPayerButton
            dimPayer={dimPayer}
            onSuccess={() => onPayerMapping(transferId)}
          />
        </div>
      </Alert>
      <ListAsideMessage
        classes={classes.listContainer}
        muiIcon="receipt"
        title="Invoices"
        body="You need to map the payer to an organization in order to see unallocated invoices"
      />
    </>
  );
};

MapPayerForm.propTypes = {
  transferId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  payerId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  onPayerMapping: PropTypes.func,
};

MapPayerForm.defaultProps = {
  transferId: null,
  payerId: null,
  onPayerMapping: () => {},
};

export default MapPayerForm;
