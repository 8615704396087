import React from 'react';
import PropTypes from 'prop-types';

import {
  useUnselectAll,
} from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import queryString from 'query-string';

import {
  ON_DEMAND_PAYOUT_SEGMENT,
  ON_DEMAND_PAYOUT_SEGMENT_LABEL,
} from 'finance/assets/js/constants';

const OnDemandPayoutsTabs = ({ location, history, analytics }) => {
  const qs = queryString.parse(location.search);

  const filterOptions = JSON.parse(qs.filter || '{}');
  const unselectAll = useUnselectAll();

  const { onDemandPayoutsSegments: segmentAnalytics } = analytics;
  const tab = filterOptions.segment || ON_DEMAND_PAYOUT_SEGMENT.PENDING_REVIEW;

  return (
    <Tabs
      centered
      variant="fullWidth"
      style={{ marginBottom: '15px' }}
      value={tab}
      indicatorColor="primary"
      onChange={(_, value) => {
        unselectAll('on_demand_payouts');
        const filter = {
          segment: value,
        };
        const query = {
          ...qs,
          filter: JSON.stringify(filter),
          page: '1',
        };
        const path = `${location.pathname}?${queryString.stringify(query)}`;
        history.push(path);
      }}
    >
      {[
        ON_DEMAND_PAYOUT_SEGMENT.PENDING_REVIEW,
        ON_DEMAND_PAYOUT_SEGMENT.PENDING_APPROVAL,
        ON_DEMAND_PAYOUT_SEGMENT.BEING_PROCESSED,
        ON_DEMAND_PAYOUT_SEGMENT.FINALIZED,
      ].map(seg => (
        <Tab
          key={seg}
          label={`${ON_DEMAND_PAYOUT_SEGMENT_LABEL[seg]} (${segmentAnalytics[seg].count})`}
          value={seg}
        />
      ))}
    </Tabs>
  );
};

OnDemandPayoutsTabs.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  analytics: PropTypes.object,
};

OnDemandPayoutsTabs.defaultProps = {
  analytics: {
    onDemandPayoutsSegments: Object
      .values(ON_DEMAND_PAYOUT_SEGMENT)
      .reduce((map, k) => ({ ...map, [k]: { count: 0 } }), {}),
  },
};

export default OnDemandPayoutsTabs;
