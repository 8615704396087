import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  SelectInput,
  SimpleForm,
  useNotify,
  useUpdate,
  useGetList,
  FormDataConsumer,
} from 'react-admin';

import PayerMappingsFormButton from 'admin/assets/js/resources/payer_mappings/PayerMappingsFormButtons.jsx';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';
import { pick, get } from 'lodash';

const PayerMappingsForm = ({
  dialogClose,
  record,
  ...props
}) => {
  const notify = useNotify();
  const [update] = useUpdate('payer_mappings', get(record, 'id'));
  const { loading: isLoadingOrganizations, data: organizations } = useGetList(
    'organizations',
    { page: 1, perPage: MAX_ORGANIZATIONS_FETCHED },
    { field: 'id', order: 'DESC' },
    { status: ORGANIZATION_STATUS.ACTIVE },
  );
  const availableOrganizations = useMemo(() => {
    if (!isLoadingOrganizations) {
      return Object.keys(organizations).map(key => ({
        id: organizations[key].id, name: organizations[key].name,
      }));
    }
    return [];
  }, [isLoadingOrganizations, organizations]);

  return (
    <SimpleForm
      {...props}
      initialValues={{
        organization_id: record.organization_id,
      }}
      redirect={false}
      variant="standard"
      toolbar={(
        <PayerMappingsFormButton
          isLoadingOrganizations={isLoadingOrganizations}
          dialogClose={dialogClose}
        />
      )}
      save={(formValues) => {
        const passThroughFormFields = [
          'organization_id',
        ];
        const data = {
          ...pick(formValues, passThroughFormFields),
        };
        update({ payload: { data } }, {
          onSuccess: () => {
            dialogClose();
            notify('Mapping updated', 'info');
          },
          onFailure: () => {
            notify('Could not edit mapping', 'error');
          },
        });
      }}
    >
      <FormDataConsumer>
        {() => (
          <>
            <p><strong>Payer name:</strong></p>
            {
              isLoadingOrganizations && <div>Loading organizations..</div>
            }
            {
              !isLoadingOrganizations && (
                <SelectInput
                  key="organization_id"
                  source="organization_id"
                  label="Organization"
                  choices={availableOrganizations}
                  required
                  fullWidth
                  validate={val => (!val ? 'Field is required' : '')}
                />
              )
            }
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

PayerMappingsForm.propTypes = {
  dialogClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

PayerMappingsForm.defaultProps = {
  record: {},
};


export default PayerMappingsForm;
