import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const styles = () => ({
  dialogTitleStyle: {
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});

const EnableIndicesButton = ({ classes }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [indicesEnabled, setIndicesEnabled] = useState({});
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    fetch(adminCommandApiUrl('get-indices-enabled'))
      .then(res => res.json())
      .then((res) => {
        setIndicesEnabled(res);
      }).catch(error => {
        notify(error.message, 'warning');
      });
  }, []);

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  const types = Object.keys(indicesEnabled);
  const indicesTypes = types.filter(type => indicesEnabled[type]);

  const handleEnableIndices = async values => {
    setLoading(true);
    try {
      await httpClient(
        adminCommandApiUrl('enable-indices'), { method: 'POST', body: JSON.stringify(values) },
      );
      setIndicesEnabled(types.reduce(
        (acc, type) => {
          acc[type] = values.indicesTypes.includes(type);
          return acc;
        },
        {},
      ));
      setShowDialog(false);
    } catch (e) {
      notify(e.message, 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a
        className="btn btn-outline-dark m-1"
        onClick={handleShowDialog}
        rel="noopener noreferrer"
        target="_blank"
      >
        <strong>ENABLE INDICES</strong>
      </a>
      <Dialog
        fullWidth
        onClose={handleHideDialog}
        open={showDialog}
      >
        <DialogTitle className={classes.dialogTitleStyle}>
          <span>
            {loading ? 'Enabling indices...' : 'Enable indices' }
          </span>
        </DialogTitle>
        <DialogContent>
          {!loading && (
            <DialogContentText>
              Select the ElasticSearch indexes you would like to be enabled. If disabled, all
              searching for that record will use MySQL directly:
            </DialogContentText>
          )}
          { loading && (
            <div style={{ width: '100%', textAlign: 'center', margin: '40px 0' }}>
              <CircularProgress />
            </div>
          )}
          { !loading && (
            <Form
              initialValues={{ indicesTypes }}
              onSubmit={handleEnableIndices}
              render={({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    {types.map(type => (
                      <div key={type}>
                        <label
                          style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
                        >
                          <Field
                            component="input"
                            name="indicesTypes"
                            style={{ marginRight: '10px', padding: '10px' }}
                            type="checkbox"
                            value={type}
                          />
                          {' '}
                          {capitalize(type)}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div style={{ margin: '30px 0 15px' }}>
                    <Button
                      label="Cancel"
                      onClick={handleHideDialog}
                      style={{ marginRight: '20px' }}
                      type="button"
                      variant="outlined"
                    />
                    <Button
                      disabled={submitting || values.indicesTypes.length === 0}
                      label="Save"
                      style={{ marginRight: '20px' }}
                      type="submit"
                      variant="contained"
                    />
                  </div>
                </form>
              )}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

EnableIndicesButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnableIndicesButton);
