import React from 'react';
import PropTypes from 'prop-types';
import BulkApiActionButton from './BulkApiActionButton.jsx';

const ResetPasswordButton = ({ resource, record, onSuccess }) => {
  if (!record) {
    return null;
  }
  return (
    <BulkApiActionButton
      ids={[record.id]}
      resource={resource}
      action="reset-password"
      label="Reset password"
      title="Reset user password"
      successNotifMsg="Reset password email sent"
      content="Are you sure you want to reset this user's password?"
      onSuccess={onSuccess}
    />
  );
};

ResetPasswordButton.propTypes = {
  resource: PropTypes.string,
  record: PropTypes.object,
  onSuccess: PropTypes.func,

};

ResetPasswordButton.defaultProps = {
  resource: null,
  record: null,
  onSuccess: () => {},
};

export default ResetPasswordButton;
