import React from 'react';
import PropTypes from 'prop-types';

import BulkApiActionButton from './BulkApiActionButton.jsx';

const ExcludeFromReportsButton = ({ label, record, reloadApiTable, resource }) => {
  if (!record) {
    return null;
  }

  return (
    <BulkApiActionButton
      action="toggle-exclude-from-reporting"
      content={`
        Are you sure you want to exclude this organisation from the reports?
      `}
      ids={record.id}
      label={label}
      reloadApiTable={reloadApiTable}
      resource={resource}
      title="Exclude from Reports"
    />
  );
};

ExcludeFromReportsButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  reloadApiTable: PropTypes.bool,
  resource: PropTypes.string,
};

ExcludeFromReportsButton.defaultProps = {
  label: 'Exclude from Reports',
  reloadApiTable: false,
  record: null,
  resource: null,
};

export default ExcludeFromReportsButton;
