import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Warning from '@material-ui/icons/Warning';

import httpClient from 'admin/assets/js/lib/httpClient';
import { formatDate } from 'admin/assets/js/lib/utils';
import {
  adminApiLastInvoicingProcessStatusUrl,
  adminCommandApiUrl,
} from 'admin/urls';

const GenerateInvoicesDialog = ({
  organization: { name, id }, onHandleSetOpen, isOpen,
}) => {
  const location = useLocation();
  const parsedHistory = queryString.parse(location.search);
  const { billingDeadline } = JSON.parse(parsedHistory.filter);
  const billingDeadlineFormatted = formatDate(billingDeadline);
  if (!id || !billingDeadline) {
    return null;
  }
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [invoicesAlreadyGenerated, setInvoicesAlreadyGenerated] = useState(false);
  useEffect(() => {
    (async function getLastInvoiceProcessStatus() {
      const url = adminApiLastInvoicingProcessStatusUrl(id, billingDeadline);
      const processStatus = await httpClient(url, { method: 'GET' });
      if (processStatus.json.isCompleted) {
        setInvoicesAlreadyGenerated(true);
      }
    }());
  }, []);

  const handleGenerateClick = async () => {
    const command = 'invoices-generate-published';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, {
        method: 'POST',
        body: JSON.stringify({
          billingDeadline,
          orgId: id.toString(),
        }),
      });
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      onHandleSetOpen(false);
    }
  };
  return (
    <Dialog
      maxWidth="sm"
      onClose={() => onHandleSetOpen(false)}
      open={isOpen}
    >
      <DialogTitle id="alert-dialog-title">
        {`Generate invoices for "${name}"`}
      </DialogTitle>
      <DialogContent>
        {
          invoicesAlreadyGenerated && (
            <Chip
              color="secondary"
              icon={<Warning />}
              label="Invoices have already been generated for this organization."
              size="small"
              style={{ padding: '5px', marginBottom: '10px', backgroundColor: '#dd9e1f' }}
            />
          )
        }
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" component="div">
              Billing date
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" component="div">
              <strong>{billingDeadlineFormatted}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" component="div">
              Organization name
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" component="div">
              <strong>{name}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" component="div">
              Organization id
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" component="div">
              <strong>{id}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="div" gutterBottom>
              By clicking &ldquo;Generate&rdquo; you will be generating invoices and sending
              notification emails to all related parties.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => onHandleSetOpen(false)}
        >
          <>
            <Icon>close</Icon>
            Close
          </>
        </Button>
        <Button
          color="primary"
          disabled={loading}
          onClick={() => handleGenerateClick()}
          variant="contained"
        >
          <>
            Generate
          </>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GenerateInvoicesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHandleSetOpen: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default GenerateInvoicesDialog;
