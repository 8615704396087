import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { financeEntityScheme } from 'finance/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import BankAccountVariant from 'settings/assets/js/lib/BankAccountVariant';
import InvoiceTWBankDetails from 'finance/assets/js/components/invoicing/InvoiceTWBankDetails.jsx';

const InvoiceBankDetails = ({ 'data-testid': dataTestId, owner, bankAccount, showLabel, classes }) => {
  let bank;
  if (bankAccount) {
    bank = new BankAccountVariant(bankAccount);
  } else {
    if (!owner || isEmpty(owner)) {
      return null;
    }
    const ownerFE = new FinancialEntity(owner);
    bank = ownerFE.getBank();
  }
  if (!bank) {
    return null;
  }
  const customReference = bank.getCustomReference();
  const paypalHandle = bank.getPaypalHandle();
  return (
    <div
      className={`${classes} bank-details`}
      data-testid={dataTestId}
      style={{ height: '170px', overflowY: 'auto' }}
    >
      {showLabel && (
        <h5>Electronic Payment Details</h5>
      )}
      {bank.isPaypalAccount() ? (
        <React.Fragment>
          <span className="bank-beneficiary">
            {`PayPal: ${paypalHandle}`}
          </span>
          {customReference && (
            <div className="custom-reference">
              {`Payee reference message: ${customReference}`}
            </div>
          )}
        </React.Fragment>
      ) : (
        <InvoiceTWBankDetails owner={owner} bankAccount={bankAccount} />
      )}
    </div>
  );
};

InvoiceBankDetails.propTypes = {
  'data-testid': PropTypes.string,
  owner: financeEntityScheme,
  bankAccount: bankAccountSpec,
  showLabel: PropTypes.bool,
  classes: PropTypes.string,
};

InvoiceBankDetails.defaultProps = {
  'data-testid': '',
  owner: null,
  bankAccount: null,
  showLabel: true,
  classes: '',
};

export default InvoiceBankDetails;
