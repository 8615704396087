import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-admin';

import { useStyles } from 'admin/assets/js/layout/themes';
import InvoicingModeField from 'admin/assets/js/resources/pricings/InvoicingModeField.jsx';

const OrgNameField = (props) => {
  const { record } = props;
  const classes = useStyles();
  if (!record) {
    return null;
  }
  return (
    <div>
      <Link to={`/organizations/${record.id}/show`}>
        <strong>{`#${record.id} ${record.name}`}</strong>
      </Link>
      <br />
      <span className={classes.discreet}>
        {record.unique_alias}
        {' '}
      </span>
      <div className={classes.discreet}>
        <InvoicingModeField record={record} />
      </div>
    </div>
  );
};

OrgNameField.propTypes = {
  record: PropTypes.object,
};

OrgNameField.defaultProps = {
  record: {},
};

export default OrgNameField;
