import React, { useState } from 'react';
import { isEmail } from 'validator';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import httpClient from 'admin/assets/js/lib/httpClient';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@material-ui/core';

import { Button, useNotify, useRefresh } from 'react-admin';
import { adminBulkActionApiUrl } from 'admin/urls';


const styles = () => ({
  dialogTitleStyle: {
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});

const ChangeOrgOwnerButton = ({ classes, record: { id: orgId } }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  const handleChangeOrgOwner = async ({ email }) => {
    const command = 'change-org-owner';
    const resource = 'organizations';
    const url = adminBulkActionApiUrl(resource, command);
    setLoading(true);
    const payload = { email, orgId };
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(payload) });
      setShowDialog(false);
      notify('Organization owner changed successfully');
      refresh();
    } catch (e) {
      notify(e.body?._error || e.message, 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MenuItem onClick={handleShowDialog}>Change Org Owner</MenuItem>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleHideDialog}
      >
        <DialogTitle
          className={classes.dialogTitleStyle}
        >
          <span>
            { loading ? 'Changing org owner...' : 'Change Org Owner' }
          </span>
        </DialogTitle>
        <DialogContent>
          { !loading && (
            <DialogContentText>
              Provide email address of the new owner
            </DialogContentText>
          )}
          { loading && (
            <div style={{ width: '100%', textAlign: 'center', margin: '40px 0' }}>
              <CircularProgress />
            </div>
          )}
          { !loading && (
            <Form
              onSubmit={handleChangeOrgOwner}
              render={({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <label style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                      New Owner Email
                      <Field
                        style={{ marginLeft: '10px', padding: '10px' }}
                        name="email"
                        component="input"
                        type="email"
                      />
                    </label>
                  </div>
                  <div style={{ margin: '30px 0 15px' }}>
                    <Button
                      style={{ marginRight: '20px' }}
                      label="Cancel"
                      type="button"
                      onClick={handleHideDialog}
                      variant="outlined"
                    />
                    <Button
                      style={{ marginRight: '20px' }}
                      variant="contained"
                      disabled={submitting || isEmpty(values.email) || !isEmail(values.email)}
                      label="Change org owner"
                      type="submit"
                    />
                  </div>
                </form>
              )}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

ChangeOrgOwnerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangeOrgOwnerButton);
