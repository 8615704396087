import React from 'react';
import { BooleanInput, Filter } from 'react-admin';

const ListFilter = (props) => {
  return (
    <Filter {...props}>
      <BooleanInput
        label="Show only my downloads"
        source="showCurrentUserDownloads"
        alwaysOn
      />
    </Filter>
  );
};

export default ListFilter;
