import React from 'react';
import { ReferenceField, FunctionField, Datagrid, List, TextField, NumberField, Pagination } from 'react-admin';
import PropTypes from 'prop-types';
import ListFilter from 'admin/assets/js/resources/payment_methods/ListFilter.jsx';
import BulkApiActionSwitch from 'admin/assets/js/components/switch/BulkApiActionSwitch.jsx';
import {
  makeStyles,
} from '@material-ui/core';
import { PAYMENT_METHODS } from 'settings/assets/js/constants';

const useStyles = makeStyles(() => ({
  switchField: {
    '& .organization_view_switch_field': {
      'border-bottom': 'none',
    },
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    '& .MuiTableCell-head': {
      textAlign: 'center',
    },
  },
}));

const PaymentMethodList = (props) => {
  const { orgId } = props;
  const classes = useStyles();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={orgId ? undefined : <ListFilter />}
      filter={orgId ? { org_id: orgId } : undefined}
      resource="payment_methods"
      pagination={orgId ? null : <Pagination {...props} />}
      actions={false}
    >
      <Datagrid
        className={classes.root}
      >
        {!orgId && (
          <ReferenceField sortable={false} label="Organization" source="org_id" reference="organizations_lite" link={false}>
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField
          className={classes.centerContainer}
          label="Method Name"
          source="method_label"
          sortable={false}
        />
        <FunctionField
          className={`${classes.switchField} ${classes.centerContainer}`}
          source="available"
          label="Active for Organization"
          render={record => (record.available ? (
            <BulkApiActionSwitch
              action="disable-payment-method"
              content={`
                Are you sure you want to disable ${record.method_label} for this organization?
              `}
              ids={record.id}
              resource="organizations"
              title={record.method_label}
              value
              disabled={record.method === PAYMENT_METHODS.BANK_TRANSFER || record.count > 0}
            />
          ) : (
            <BulkApiActionSwitch
              action="enable-payment-method"
              content={`
                Are you sure you want to enable ${record.method_label} for this organization?
              `}
              ids={record.id}
              resource="organizations"
              title={record.method_label}
              value={false}
            />
          ))}
        />

        <FunctionField
          className={`${classes.switchField} ${classes.centerContainer}`}
          source="enabled"
          label="Active for Providers"
          render={record => (record.enabled ? (
            <BulkApiActionSwitch
              action="disable-payment-method-for-users"
              content={`
                Are you sure you want to disable ${record.method_label} for the users of this organization?
              `}
              ids={record.id}
              resource="organizations"
              title={record.method_label}
              value
              disabled={record.method === PAYMENT_METHODS.BANK_TRANSFER || record.count > 0}
            />
          ) : (
            <BulkApiActionSwitch
              action="enable-payment-method-for-users"
              content={`
                Are you sure you want to enable ${record.method_label} for the users of this organization?
              `}
              ids={record.id}
              resource="organizations"
              title={record.method_label}
              value={false}
              disabled={!record.available}
            />
          ))}
        />
        <NumberField
          className={classes.centerContainer}
          source="count"
          label="Provider Usage Count"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

PaymentMethodList.propTypes = {
  basePath: PropTypes.string,
  orgId: PropTypes.number,
};

PaymentMethodList.defaultProps = {
  basePath: null,
  orgId: null,
};

export default PaymentMethodList;
