/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import {
  ListButton,
} from 'react-admin';
import queryString from 'query-string';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { TRANSACTION_SEGMENT_LABEL } from 'finance/assets/js/constants';

const TransactionSegmentsButton = ({ orgBillingId, transactionSegmentAnalytics }) => {
  const { segment, count } = transactionSegmentAnalytics;
  const queryOptions = queryString.stringify({
    filter: JSON.stringify({
      organization_billing_id: orgBillingId,
      segment,
    }),
  });
  const isDisabled = !count;
  return (
    <div className="d-flex align-items-left">
      <ListButton
        basePath={`/org_billing_transactions?${queryOptions}`}
        style={{ textAlign: 'center' }}
        label={`${count} ${TRANSACTION_SEGMENT_LABEL[segment]}`}
        disabled={isDisabled}
        icon={false}
      />
    </div>
  );
};

const OrgBillingTransactionsButton = (passthroughProps) => {
  const {
    record: { id: orgBillingId, analytics: { transactionSegments } },
  } = passthroughProps;
  return (
    <>
      <BulkApiActionButton
        label="Recreate transactions"
        title="Recreate transactions"
        content="Are you sure you want to recreate transactions for this org billing?"
        icon={null}
        ids={[orgBillingId]}
        resource="organization_billings"
        action="recreate-org-billing-transactions"
      />
      <TransactionSegmentsButton
        orgBillingId={orgBillingId}
        transactionSegmentAnalytics={transactionSegments.preview}
      />
      <TransactionSegmentsButton
        orgBillingId={orgBillingId}
        transactionSegmentAnalytics={transactionSegments.pending_review}
      />
      <TransactionSegmentsButton
        orgBillingId={orgBillingId}
        transactionSegmentAnalytics={transactionSegments.pending_approval}
      />
      <TransactionSegmentsButton
        orgBillingId={orgBillingId}
        transactionSegmentAnalytics={transactionSegments.finalized}
      />
    </>
  );
};

export default OrgBillingTransactionsButton;
