import TransferwiseStatement from 'finance/assets/js/lib/TransferwiseStatement';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';

class Payer {
  static fromWiseStatement(statement) {
    if (!statement) {
      throw new Error('statement is required');
    }
    const statementInstance = statement instanceof TransferwiseStatement
      ? statement
      : new TransferwiseStatement(statement);
    return new Payer({
      name: statementInstance.getSenderName(),
      account: statementInstance.getSenderAccount(),
    });
  }

  constructor({ name, account }) {
    assertAllKeysPresent({ name, account });
    this.details = {
      name,
      account,
    };
  }

  serialize() {
    return {
      ...this.details,
    };
  }

  getName() {
    const { name } = this.details;
    return name;
  }

  getAccount() {
    const { account } = this.details;
    return account;
  }

  getObfuscatedAccount() {
    const { account } = this.details;
    const start = account.slice(1, 4);
    const res = `${start}...${account.slice(-3)}`;
    return res;
  }
}

export default Payer;
