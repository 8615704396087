import CustomTransactionsView from 'admin/assets/js/CustomTransactionsView.jsx';
import { TRANSACTION_TABS } from 'admin/assets/js/constants';

const CustomTransactionsDataView = ({ ...props }) => (
  CustomTransactionsView({
    ...props,
    selectedTab: TRANSACTION_TABS.DATA,
  })
);

export default CustomTransactionsDataView;
