import React from 'react';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import { PAYMENT_METHODS, PAYMENT_METHODS_LABEL } from 'settings/assets/js/constants';
import { SelectInput, ReferenceInput, BooleanInput, Filter } from 'react-admin';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const methods = Object.values(PAYMENT_METHODS).map(c => ({
  id: c, name: PAYMENT_METHODS_LABEL[c],
}));


const ListFilter = props => (
  <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        allowEmpty
        label="Organization"
        source="org_id"
        reference="organizations_lite"
        perPage={MAX_ORGANIZATIONS_FETCHED}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
      >
        <SelectInput
          optionText={org => `${org.name}
           (${org.unique_alias})`}
        />
      </ReferenceInput>
      <SelectInput
        label="Method"
        alwaysOn
        allowEmpty
        source="method"
        choices={methods}
      />
      <BooleanInput
        label="Just used"
        alwaysOn
        allowEmpty
        source="just_used"
      />
    </Filter>
  </div>
);

export default ListFilter;
