import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';

import {
  SYSTEM_BANK_ACCOUNT_STATUS,
  SYSTEM_BANK_ACCOUNT_STATUS_LABEL,
} from 'finance/assets/js/constants';


const statusStyles = {
  [SYSTEM_BANK_ACCOUNT_STATUS.ACTIVE]: '#3ABC4E',
  [SYSTEM_BANK_ACCOUNT_STATUS.ARCHIVED]: '#999',
};


const useStyles = makeStyles(() => ({
  statusLabel: {
    color: '#FFF',
    border: 'none',
  },
}));

const SystemBankAccountStatus = ({ systemBankAccount, size }) => {
  const classes = useStyles();
  return (
    <Chip
      className={classes.statusLabel}
      style={{ backgroundColor: statusStyles[systemBankAccount.status] }}
      label={SYSTEM_BANK_ACCOUNT_STATUS_LABEL[systemBankAccount.status]}
      variant="outlined"
      color="primary"
      size={size}
    />
  );
};

SystemBankAccountStatus.propTypes = {
  systemBankAccount: PropTypes.object,
  size: PropTypes.string,
};

SystemBankAccountStatus.defaultProps = {
  systemBankAccount: {},
  size: 'medium',
};

export default SystemBankAccountStatus;
