import React, { useState } from 'react';
import {
  Datagrid, useRefresh,
  List,
  TextField,
} from 'react-admin';
import InvoicingEntityActions from 'admin/assets/js/resources/invoicing_entities/InvoicingEntityActions.jsx';
import InvoicingEntityEditButton from 'admin/assets/js/resources/invoicing_entities/InvoicingEntityEditButton.jsx';
import InvoicingEntityEditDialog from 'admin/assets/js/resources/invoicing_entities/InvoicingEntityEditDialog.jsx';
import PropTypes from 'prop-types';

const InvoicingEntitiesList = (props) => {
  const { basePath } = props;
  const refresh = useRefresh();
  const [invoicingEntityForEditing, setInvoicingEntityForEditing] = useState(null);
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        actions={<InvoicingEntityActions />}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <Datagrid>
          <TextField label="Name" source="name" />
          <TextField source="address.label" label="Address" />
          <TextField source="tax_number" label="Tax number" />
          <TextField source="registration_number" label="Registration number" />
          <InvoicingEntityEditButton onClick={record => setInvoicingEntityForEditing(record)} />
        </Datagrid>
      </List>
      {invoicingEntityForEditing && (
        <InvoicingEntityEditDialog
          basePath={basePath}
          onClose={() => {
            setInvoicingEntityForEditing(null);
            refresh();
          }}
          invoicingEntity={invoicingEntityForEditing}
        />
      )}
    </>
  );
};

InvoicingEntitiesList.propTypes = {
  basePath: PropTypes.string,
};

InvoicingEntitiesList.defaultProps = {
  basePath: null,
};


export default InvoicingEntitiesList;
