import { isNil } from 'lodash';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';

class TransactionState {
  static fromManualTransaction(transaction, { fee, feeCurrency } = {}) {
    if (isNil(transaction.date)) {
      throw new Error('transaction date is required');
    }
    if (isNil(transaction.status)) {
      throw new Error('transaction status is required');
    }
    if (isNil(transaction.transactionAmounts)) {
      throw new Error('transaction amounts is required');
    }
    const { transactionAmounts } = transaction;
    if (fee) {
      transactionAmounts.setFee(fee, feeCurrency);
    }
    return new TransactionState({
      date: transaction.date,
      transactionAmounts,
      status: transaction.status,
      statusChangedAt: transaction.date,
      raisedAt: transaction.date,
      paidAt: transaction.date,
    });
  }

  constructor({
    date,
    transactionAmounts,
    status,
    statusChangedAt,
    raisedAt,
    paidAt,
    refundedAt = null,
  }) {
    const details = {
      date,
      transactionAmounts,
      status,
      statusChangedAt,
      raisedAt,
      paidAt,
      refundedAt,
    };
    // required fields
    ['date', 'transactionAmounts', 'status', 'statusChangedAt'].forEach(([key, value]) => {
      if (isNil(value)) {
        throw new Error(`${key} is required`);
      }
    });
    Object.assign(this, {
      details: {
        ...details,
        transactionAmounts: transactionAmounts instanceof TransactionAmounts
          ? transactionAmounts
          : new TransactionAmounts(transactionAmounts),
      },
    });
  }

  serialize() {
    return {
      ...this.details,
      transactionAmounts: this.details.transactionAmounts.serialize(),
    };
  }

  getTransactionAmounts() {
    const { transactionAmounts } = this.details;
    return transactionAmounts;
  }

  getTransactionFields() {
    const {
      date, transactionAmounts, status,
      statusChangedAt, raisedAt, paidAt, refundedAt,
    } = this.details;
    return {
      date,
      amounts_dump: JSON.stringify(transactionAmounts.serialize(), null, 2),
      status,
      status_changed_at: statusChangedAt,
      raised_at: raisedAt,
      paid_at: paidAt,
      refunded_at: refundedAt,
      amount: transactionAmounts.getOutgoingAmount(),
      currency: transactionAmounts.getOutgoingCurrency(),
    };
  }

  setStatus({ status, statusChangedAt }) {
    assertAllKeysPresent({ status, statusChangedAt });
    this.details.status = status;
    this.details.statusChangedAt = statusChangedAt;
  }
}

export default TransactionState;
