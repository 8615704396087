import React, { Fragment, useState, useCallback } from 'react';
import {
  Button,
  useRefresh,
  useNotify,
  downloadCSV,
} from 'react-admin';
import {
  DialogContent,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Form } from 'react-final-form';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';

const AORTalentdeskInvoicesReportButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const reportTitle = 'AOR contractor invoices addressed to Talentdesk Limited';

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleClick = useCallback(() => {
    setShowDialog(true);
  }, [setShowDialog]);

  const handleGenerate = async (values) => {
    const command = 'reports/aor-invoices-for-talentdesk-limited';
    const url = adminCommandApiUrl(command, { ...values });
    setLoading(true);
    try {
      const data = await httpClient(url, { method: 'GET' });
      downloadCSV(data.body, 'aor-invoices-for-talentdesk-limited');
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>{reportTitle.toUpperCase()}</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        aria-label={reportTitle}
      >
        <DialogTitle>{reportTitle}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleGenerate}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <label style={{ margin: 0 }}>Date start</label>
                  <DatePickerField
                    style={{ marginBottom: '20px' }}
                    name="startDate"
                    type="text"
                  />
                  <label style={{ margin: 0 }}>Date end</label>
                  <DatePickerField
                    style={{ marginBottom: '20px' }}
                    name="endDate"
                    type="text"
                  />
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Download report" type="submit" />
                  <Button label="cancel" type="button" onClick={handleClose} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AORTalentdeskInvoicesReportButton;
