/* eslint react/require-default-props: ["warn"] */ // eslint-disable-line
import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar, Show } from 'react-admin';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import SubscriptionTransactionAddButton from 'admin/assets/js/resources/subscriptions/SubscriptionTransactionAddButton.jsx';
import EmptyShowLayout from 'admin/assets/js/layout/EmptyShowLayout.jsx';
import SubscriptionDetails from './SubscriptionDetails.jsx';
import TransactionsPerSubscriptionList from '../transactions/PerSubscriptionList.jsx';
import InvoicesPerSubscriptionList from '../invoices/PerSubscriptionList.jsx';
import { SUBSCRIPTION_PLANS } from 'finance/assets/js/constants';

const SubscriptionShowLayout = ({ basePath, record }) => (
  record && record.id ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <SubscriptionDetails basePath={basePath} id={record.id} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <CardHeader title="Transactions" />
            <CardContent>
              <TransactionsPerSubscriptionList basePath={basePath} subscriptionId={record.id} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Invoices" />
            <CardContent>
              <InvoicesPerSubscriptionList basePath={basePath} subscriptionId={record.id} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  ) : null
);

SubscriptionShowLayout.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
};

SubscriptionShowLayout.defaultProps = {
  basePath: undefined,
  record: undefined,
};

// during initial renders data may be undefined, so lets display the cta during this time
// however, we must preserve falsy values, so lets use the nullish operator for this test
const canTransactionBeRecordedForSub = data => (data?.plan !== SUBSCRIPTION_PLANS.UNLIMITED)
  ?? true;

const SubscriptionActions = ({ data, basePath }) => (
  <TopToolbar>
    {canTransactionBeRecordedForSub(data) && (
      <SubscriptionTransactionAddButton basePath={basePath} subscription={data} />
    )}
  </TopToolbar>
);

SubscriptionActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
};

// todo - empty placeholder, implement if required
const SubscriptionShow = props => (
  <Show
    component={EmptyShowLayout}
    actions={<SubscriptionActions />}
    {...props}
  >
    <SubscriptionShowLayout />
  </Show>
);

export default SubscriptionShow;
