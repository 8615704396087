import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useRefresh, useUnselectAll } from 'react-admin';
import { MenuItem, Select, Switch } from '@material-ui/core';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminBulkActionApiUrl } from 'admin/urls';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';

const BulkApiActionSwitch = ({
  action,
  content,
  disabled,
  ids,
  label,
  options,
  resource,
  sublabel,
  title,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [selectedValue, setSelectedValue] = useState(value);

  const handleConfirm = async () => {
    const url = adminBulkActionApiUrl(resource, action);
    const body = { ids };
    if (options) {
      body.value = selectedValue;
    }
    setLoading(true);
    const successNotificationMessage = `${resource} updated`;
    const errorNotificationMessage = `Error: ${resource} not updated`;
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(body) });
      refresh();
      notify(successNotificationMessage);
      unselectAll(resource);
    } catch (e) {
      notify(e.body && e.body._error ? e.body._error : errorNotificationMessage, 'warning');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleClick = (event) => {
    if (options) {
      setSelectedValue(event.target.value);
    }
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  useEffect(() => {
    if (options) {
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <>
      <div className="organization_view_switch_field">
        { label && (
          <p className="organization_view_switch_field__label d-flex flex-column">
            <span>{label}</span>
            {sublabel && <span className="sublabel">{sublabel}</span>}
          </p>
        )}
        {options && (
          <Select onChange={handleClick} value={selectedValue}>
            {options.map(o => (
              <MenuItem value={o.value} disabled={o.disabled}>{o.label}</MenuItem>
            ))}
          </Select>
        )}
        {!options && (
          <Switch
            checked={value}
            className="organization_view_switch_field__switch"
            onChange={handleClick}
            disabled={disabled}
          />
        )}
      </div>

      {title && content && (
        <ConfirmModal
          content={(
            options && typeof content === 'string'
              ? content.replace('{value}', options.find(o => o.value === selectedValue)?.label)
              : content
          )}
          loading={loading}
          onClose={() => {
            if (options) {
              setSelectedValue(value);
            }
            handleDialogClose();
          }}
          onConfirm={handleConfirm}
          open={open}
          title={title}
        />
      )}
    </>
  );
};

BulkApiActionSwitch.propTypes = {
  action: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  disabled: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  resource: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

BulkApiActionSwitch.defaultProps = {
  content: null,
  disabled: false,
  options: null,
  sublabel: null,
  title: null,
  value: null,
};

export default BulkApiActionSwitch;
