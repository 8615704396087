import React, { useMemo, useState } from 'react';
import { ExportButton } from 'react-admin';
import PropTypes from 'prop-types';

import SplitButton from 'admin/assets/js/components/buttons/SplitButton.jsx';
import SystemBankAccountCreateFormModal from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountCreateFormModal.jsx';

const SystemBankAccountsListActions = (props) => {
  const { data: systemBankAccounts } = props;
  const [isCreateFormOpen, setCreateFormOpen] = useState(false);

  const options = useMemo(() => ([
    { label: 'Create Account',
      main: true,
      key: 'create-account',
      onHandleClick: () => setCreateFormOpen(true),
    },
    { label: 'Export',
      key: 'export',
      component: (
        <ExportButton
          variant="text"
          style={{
            textTransform: 'capitalize',
            display: 'flex',
            justifyContent: 'start',
            width: '200px',
          }}
          icon={null}
        />
      ),
    },
  ]), [setCreateFormOpen, systemBankAccounts]);
  return (
    <>
      <SplitButton options={options} />
      <SystemBankAccountCreateFormModal
        isOpen={isCreateFormOpen}
        handleClose={() => setCreateFormOpen(false)}
      />
    </>
  );
};

SystemBankAccountsListActions.propTypes = {
  data: PropTypes.object,
};

SystemBankAccountsListActions.defaultProps = {
  data: {},
};

export default SystemBankAccountsListActions;
