import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { useGetOne } from 'react-admin';

const OrgBillingSummary = ({ id }) => {
  const { data, loading } = useGetOne('organization_billings', id);
  if (loading) { return null; }

  const queryOptions = queryString.stringify({
    order: 'DESC',
    perPage: 25,
    sort: 'id',
    filter: JSON.stringify({
      billing_date: data.billingDate,
    }),
  });
  return (
    <span>
      Billing on
      {' '}
      <strong>
        <Link to={`/organization_billings?${queryOptions}`}>{data.billingDate}</Link>
      </strong>
      {' '}
      for
      {' '}
      <strong>{data.orgName}</strong>
      {' ('}
      {data.orgAlias}
      {')'}
    </span>
  );
};

OrgBillingSummary.propTypes = {
  id: PropTypes.number.isRequired,
};

export default OrgBillingSummary;
