import { PropTypes } from 'prop-types';
import React from 'react';
import { Icon } from '@material-ui/core';

import { CLASSIFICATION_QUESTIONNAIRE_INTRO } from 'admin/assets/js/constants';

const ClassificationQuestionnairePreview = ({ questions, showScores }) => (
  <div className="classification-questionnaire-preview">
    <p>{CLASSIFICATION_QUESTIONNAIRE_INTRO}</p>
    {questions.map((question, questionIndex) => (
      <div className="d-flex flex-column mb-3" key={questionIndex}>
        <div className={`font-weight-bold${question.sublabel ? '' : ' mb-2'}`}>
          {`${questionIndex + 1}. ${question.label}`}
        </div>
        {question.sublabel && <div className="pl-3 sublabel mb-2">{question.sublabel}</div>}
        {question.choices.map((choice, choiceIndex) => (
          <div className="d-flex pl-3 py-2 align-items-center" key={choiceIndex}>
            <Icon>radio_button_unchecked</Icon>
            <span className="mx-2">{choice.label}</span>
            {showScores && <span className="score">{`(${choice.score})`}</span>}
          </div>
        ))}
      </div>
    ))}
  </div>
);

ClassificationQuestionnairePreview.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  showScores: PropTypes.bool,
};

ClassificationQuestionnairePreview.defaultProps = {
  questions: [],
  showScores: false,
};

export default ClassificationQuestionnairePreview;
