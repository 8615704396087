// eslint-disable-next-line
/* eslint react/prop-types: "off", camelcase:"off", react/no-multi-comp: "off", import/prefer-default-export: "off" */
import React, { useCallback, useEffect, useState } from 'react';
import { get, pick, uniq, isEmpty } from 'lodash';
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
  SelectInput,
  Toolbar, SaveButton,
  useNotify, useUpdate, useRefresh,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { CURRENCY, CURRENCY_LABEL } from 'core/assets/js/constants';
import {
  DEFAULT_PAYMENT_PROVIDERS,
  DEFAULT_PAYMENT_PROVIDER_LABELS,
  INVOICING_MODE,
  TRANSACTION_MODE,
  TRANSACTION_MODE_RC_LABEL,
  AVAILABLE_RC_TRANSACTION_MODES,
  BALANCE_CURRENCIES,
  INVOICING_DAYS_GROUP,
  INVOICING_DAYS_DEFAULT_SELECTIONS,
  OUTBOUND_INVOICE_TEMPLATE_DESCRIPTION_LABELS,
  OUTBOUND_INVOICE_TEMPLATE_DESCRIPTIONS,
  OUTBOUND_INVOICE_TEMPLATE_LABELS,
  OUTBOUND_INVOICE_TEMPLATES,
} from 'finance/assets/js/constants';
import useStyles from './useStyles';
import { adminCommandApiUrl } from 'admin/urls';
import { ENABLE_REVOLUT_FEATURE } from 'settings/assets/js/constants';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';


const ONCE_PER_MONTH_ID = 'once_per_month';

const currencies = Object.values(CURRENCY).map(c => ({
  id: c, name: CURRENCY_LABEL[c],
}));

const balanceCurrenciesDropdownValues = Object
  .values(BALANCE_CURRENCIES)
  .map(c => ({
    id: c, name: CURRENCY_LABEL[c],
  }));

const transactionModeDropdownValues = AVAILABLE_RC_TRANSACTION_MODES.map(c => ({
  id: c, name: TRANSACTION_MODE_RC_LABEL[c],
  disabled: ![
    TRANSACTION_MODE.FIXED_BALANCE,
    TRANSACTION_MODE.FIXED_INVOICE,
    TRANSACTION_MODE.FIXED_SO_AMOUNT,
  ].includes(c),
}));

const invoicingOptions = [
  { id: INVOICING_MODE.VIA, name: 'Consolidated Invoicing (via)' },
  { id: INVOICING_MODE.DIRECT, name: 'Direct - Client Pays (direct)' },
  { id: INVOICING_MODE.BILL_PAYMENTS, name: 'Direct - TalentDesk Pays (bill_payments)' },
];

const invoicingDayOptionsOnePerMonth = Object.values(
  INVOICING_DAYS_DEFAULT_SELECTIONS[INVOICING_DAYS_GROUP.FOUR_PER_MONTH],
).map(val => ({
  id: val,
  name: val,
}));


const outboundTemplateDropdownValues = Object.values(OUTBOUND_INVOICE_TEMPLATES).map(val => ({
  id: val, name: `${val} - ${OUTBOUND_INVOICE_TEMPLATE_LABELS[val]}`,
}));

const outboundTemplateDescriptionDropdownValues = Object
  .values(OUTBOUND_INVOICE_TEMPLATE_DESCRIPTIONS).map(val => ({
    id: val, name: OUTBOUND_INVOICE_TEMPLATE_DESCRIPTION_LABELS[val],
  }));

const defaultPaymentProviderValues = Object
  .values(DEFAULT_PAYMENT_PROVIDERS).map(val => ({
    id: val, name: DEFAULT_PAYMENT_PROVIDER_LABELS[val],
  }));

const SaveWithoutRedirectButton = ({ handleSubmitWithRedirect, ...props }) => {
  const formState = useFormState();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate('pricings', get(formState.values, 'id'));

  const handleClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const data = {
      ...pick(formState.values, [
        'invoicing_mode',
        'invoicing_system_number',
        'invoicing_system_currency',
        'invoicing_target_currency',
        'invoicing_vat_percent',
        'invoicing_charge_bank_fees',
        'invoicing_balance_currency',
        'invoicing_transaction_mode',
        'paid_trial',
        'invoicing_grace_period',
        'invoicing_outbound_template',
        'invoicing_outbound_template_description',
        'invoicing_fx_markup',
        'hide_providers_invoice_payment_details',
        'invoicing_default_payment_provider',
      ]),
    };
    if (!isEmpty(formState.values.invoicing_frequency_template)) {
      data.invoicing_frequency_dump = (
        formState.values.invoicing_frequency_template === ONCE_PER_MONTH_ID
          ? InvoicingFrequency.customOncePerMonth(formState.values.invoicing_month_day)
          : InvoicingFrequency.fromDump(formState.values.invoicing_frequency_template)
      ).toDump();
    }
    update({ payload: { data } }, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        refresh();
      },
    });
  }, [
    formState.valid,
    formState.values,
    update,
    notify,
  ]);

  return <SaveButton {...props} redirect={false} handleSubmitWithRedirect={handleClick} />;
};

const ToolbarWithoutRedirect = props => {
  return (
    <Toolbar {...props}>
      <SaveWithoutRedirectButton />
    </Toolbar>
  );
};

const InvoicingDaySelection = () => {
  const formState = useFormState();
  if (formState?.values?.invoicing_frequency_template !== ONCE_PER_MONTH_ID) {
    return null;
  }
  return (
    <SelectInput
      label="Invoicing month day"
      helperText="Which day of the month will this organization be invoiced?"
      source="invoicing_month_day"
      choices={invoicingDayOptionsOnePerMonth}
      style={{ maxWidth: '30%' }}
      validate={val => (!val ? 'Select an invoicing day' : null)}
      translateChoice={false}
    />
  );
};

const InvoicingSettingsForm = (props) => {
  const [systemOptions, setSystemOptions] = useState([]);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  useEffect(() => {
    const command = 'invoicing-entities/available-entities';
    const url = adminCommandApiUrl(command);
    // eslint-disable-next-line no-undef
    fetch(url)
      .then(res => res.json())
      .then((res) => {
        setSystemOptions(res.availableEntities.map(entity => ({
          name: entity.name,
          id: entity.registration_number,
        })));
      }).catch(() => {
      });
  }, [setSystemOptions]);

  const toggleAdvanced = useCallback(() => {
    setAdvancedOpen(open => !open);
  }, [setAdvancedOpen]);

  const {
    record: {
      id,
      invoicing_mode,
      invoicing_system_number,
      invoicing_frequency_dump,
      invoicing_target_currency,
      currency,
      invoicing_vat_percent,
      invoicing_system_currency,
      invoicing_charge_bank_fees,
      invoicing_balance_currency,
      invoicing_transaction_mode,
      paid_trial,
      invoicing_grace_period,
      invoicing_outbound_template,
      invoicing_outbound_template_description,
      invoicing_fx_markup,
      hide_providers_invoice_payment_details,
      invoicing_default_payment_provider,
    },
  } = props;
  const classes = useStyles();
  const invoicingFrequencyPOJO = InvoicingFrequency.fromDump(invoicing_frequency_dump);
  const initialValues = {
    id,
    invoicing_mode,
    invoicing_system_number,
    currency,
    invoicing_frequency_template: invoicing_frequency_dump,
    invoicing_month_day: 1,
    invoicing_target_currency,
    invoicing_vat_percent,
    paid_trial,
    invoicing_system_currency,
    invoicing_charge_bank_fees,
    invoicing_balance_currency,
    invoicing_transaction_mode,
    invoicing_grace_period,
    invoicing_outbound_template,
    invoicing_outbound_template_description,
    invoicing_fx_markup,
    hide_providers_invoice_payment_details,
    invoicing_default_payment_provider,
  };

  const isViaMode = invoicing_mode === INVOICING_MODE.VIA;
  const availableTemplates = [
    { id: ONCE_PER_MONTH_ID, name: 'Once per month' },
    ...invoicingFrequencyPOJO.getAvailableFrequencyTemplates(),
  ];
  return (
    <SimpleForm
      toolbar={<ToolbarWithoutRedirect />}
      redirect={false}
      initialValues={initialValues}
    >
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <h3>Currencies</h3>
            <div className={classes.containerClasses}>
              <SelectInput
                label="Organization currency"
                source="currency"
                disabled
                helperText="The organization's currency that is chosen during onboarding.  This value cannot change once set."
                choices={currencies}
                className={classes.itemClasses}
                style={{ maxWidth: '50%' }}
              />
              <SelectInput
                label="Invoicing currency"
                source="invoicing_target_currency"
                helperText="The currency to be used for the organization's invoices."
                choices={currencies}
                className={classes.itemClasses}
                style={{ maxWidth: '50%', paddingRight: 0 }}
              />
            </div>
            <h3>Core settings</h3>
            <div className={classes.containerClasses}>
              <SelectInput
                label="Mode"
                helperText="How will the organization be invoiced for Provider payments?"
                source="invoicing_mode"
                choices={invoicingOptions}
                className={classes.itemClasses}
                style={{ maxWidth: '40%' }}
              />
              <NumberInput
                label="VAT (%)"
                source="invoicing_vat_percent"
                helperText="The sales tax rate to use on invoices the client receives from TalentDesk.io"
                className={classes.itemClasses}
                style={{ maxWidth: '30%' }}
              />
              <SelectInput
                label="Frequency"
                helperText="Will provider payments invoicing be on repeating dates each month, or repeating days of the week?"
                source="invoicing_frequency_template"
                translateChoice={false}
                choices={availableTemplates}
                className={classes.itemClasses}
                style={{ maxWidth: '30%', paddingRight: 0 }}
              />
              <InvoicingDaySelection />
              <SelectInput
                label="Transaction mode"
                source="invoicing_transaction_mode"
                helperText="The mode for how provider payments should be made"
                choices={transactionModeDropdownValues}
                className={classes.itemClasses}
                style={{ maxWidth: '80%' }}
              />
              <NumberInput
                label="FX margin"
                source="invoicing_fx_markup"
                helperText="The margin percentage that is added to all FX conversions for the organization"
                className={classes.itemClasses}
                style={{ maxWidth: '20%', paddingRight: 0 }}
              />
            </div>
            <h3>Invoice details</h3>
            <div className={classes.containerClasses}>
              <NumberInput
                label="Payment terms"
                source="invoicing_grace_period"
                helperText="Set the payment terms for this organization"
                className={classes.itemClasses}
                style={{ maxWidth: '50%' }}
              />
              <SelectInput
                label="Outbound invoice template"
                source="invoicing_outbound_template"
                helperText="Which invoice template should be used to generate outbound invoices for this client?"
                choices={outboundTemplateDropdownValues}
                className={classes.itemClasses}
                style={{ maxWidth: '50%', paddingRight: 0 }}
              />
              {(formData.invoicing_outbound_template === OUTBOUND_INVOICE_TEMPLATES.SIMPLE)  && (
                <SelectInput
                  label="Outbound invoice template description"
                  source="invoicing_outbound_template_description"
                  helperText="What line item description should be used to summarise all invoice items for this client?  This is only used when the outbound template is set to Simple."
                  choices={outboundTemplateDescriptionDropdownValues}
                  className={classes.itemClasses}
                  style={{ maxWidth: '100%', paddingRight: 0 }}
                />
              )}
            </div>
            <div
              onClick={toggleAdvanced}
              className={classes.collapsibleHeader}
            >
              <h3>Advanced</h3>
              <hr className={classes.collapsibleRule} />
              <span className={classes.collapsibleIcon}>
                {advancedOpen ? <RemoveIcon /> : <AddIcon />}
              </span>
            </div>
            <div
              style={{ opacity: advancedOpen ? 1 : 0, height: advancedOpen ? 'auto' : 0 }}
            >
              <p className={classes.warningLabel}>
                Please do not change these settings without consulting the exec team
              </p>
              <div className={classes.containerClasses}>
                <SelectInput
                  label="TalentDesk bank account currency"
                  source="invoicing_system_currency"
                  helperText="Which bank account payment details should be used on invoices sent to the organization?"
                  choices={
                    uniq([invoicing_target_currency, currency, invoicing_system_currency])
                      .map(cur => (
                        { id: cur, name: CURRENCY_LABEL[cur] }
                      ))
                  }
                  className={classes.itemClasses}
                  style={{ maxWidth: '50%' }}
                />
                <SelectInput
                  label="Balance currency"
                  source="invoicing_balance_currency"
                  helperText="From which Wise client account should Providers be paid from?"
                  choices={balanceCurrenciesDropdownValues}
                  className={classes.itemClasses}
                  style={{ maxWidth: '50%', paddingRight: 0 }}
                />
                {ENABLE_REVOLUT_FEATURE && (
                  <SelectInput
                    label="Default Bank Transfer Provider Payout Method"
                    source="invoicing_default_payment_provider"
                    helperText="Which payment provider should be used by default by Mass Payouts when including transfers for Provider payouts."
                    choices={defaultPaymentProviderValues}
                    className={classes.itemClasses}
                    style={{ width: '50%' }}
                  />
                )}
                <SelectInput
                  label="Invoicing system entity"
                  helperText="Which TalentDesk entity should be used for licence fee invoices"
                  source="invoicing_system_number"
                  choices={systemOptions}
                  className={classes.itemClasses}
                  style={{ width: '50%', paddingRight: 0 }}
                />
                <BooleanInput
                  label="Charge bank fees"
                  source="invoicing_charge_bank_fees"
                  helperText="Should bank fees for Provider payouts be charged to the client?  This should not be enabled if the Transaction Mode is set to 'Provider pays fees'.  Note: This is a legacy feature that is not used by default."
                  className={classes.itemClasses}
                  style={{ width: '50%' }}
                />
                <BooleanInput
                  label="Paid trial"
                  source="paid_trial"
                  helperText="Should the client be invoiced for provider payment processing fees during their trial period?"
                  className={classes.itemClasses}
                  style={{ width: '50%', paddingRight: 0 }}
                />
                {
                  isViaMode && (
                    <BooleanInput
                      label="Hide providers invoice payment details"
                      source="hide_providers_invoice_payment_details"
                      helperText="Do not display Provider payment details on their invoices"
                      className={classes.itemClasses}
                      style={{ width: '50%' }}
                    />
                  )
                }
              </div>
            </div>
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default InvoicingSettingsForm;
