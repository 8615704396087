import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@material-ui/core';
import { useNotify } from 'react-admin';

import { Field, Form } from 'react-final-form';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminAorContractRejectApiUrl } from 'admin/urls';


export const AorContractRejectForm = ({
  setLoading,
  initialValues,
  loading,
  onCancel,
  onSuccess,
}) => {
  const notify = useNotify();

  const handleReject = async (values) => {
    const url = adminAorContractRejectApiUrl();
    try {
      await httpClient(url, { method: 'PUT', body: JSON.stringify(values) });
      notify('Contract rejected successfully');
    } catch (e) {
      notify(`Error: ${e?.message}`, 'warning');
    } finally {
      onSuccess();
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleReject}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field name="message">
              {({ input, meta }) => (
                <div style={{ margin: '0 0 20px 0' }}>
                  <label htmlFor="message">Rejection reason</label>
                  <br />
                  <textarea
                    style={{
                      height: '100px',
                      width: '100%',
                    }}
                    {...input}
                    type="text"
                    placeholder="Type your message here..."
                  />
                  <div className="hint" style={{ marginBottom: '10px' }}>
                    This message will be included to the notification email sent to th provider.
                  </div>

                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
          </div>
          <div>
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Reject contract
            </Button>

            <Button
              className="ml-3"
              label="cancel"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

AorContractRejectForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  userCardId: PropTypes.number.isRequired,
};

AorContractRejectForm.defaultProps = {
  initialValues: {},
  onSuccess: () => {},
};

export default AorContractRejectForm;
