import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { CANCELLATION_REASON, TRANSACTION_STATUS } from 'finance/assets/js/constants';

const TransactionsActions = ({ id, status }) => {
  const [reason, setReason] = useState(Object.keys(CANCELLATION_REASON)[0]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <BulkApiActionButton
        title={`Cancel transaction #${id}`}
        content={(
          <>
            <p>{`Transaction ${id} will be cancelled. Proceed with caution.`}</p>
            <select
              name="cancel_reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              {Object.entries(CANCELLATION_REASON)
                .map(([k, v]) => (<option id={k} value={k}>{v}</option>))}
            </select>
          </>
        )}
        icon={<CancelIcon />}
        ids={[id]}
        payload={{ reason: CANCELLATION_REASON[reason] }}
        label="Cancel"
        resource="transactions"
        action="cancel-transactions"
        confirmLabel="Cancel transaction"
        cancelLabel="Close"
        disabled={[
          TRANSACTION_STATUS.SUCCEEDED,
          TRANSACTION_STATUS.FAILED,
          TRANSACTION_STATUS.CANCELLED,
          TRANSACTION_STATUS.REFUNDED,
          TRANSACTION_STATUS.REMOVED,
        ].includes(status)}
      />
    </div>
  );
};

TransactionsActions.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
};

export default TransactionsActions;
