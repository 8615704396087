import { isNil } from 'lodash';

import { ValidationError, ValidationErrorItem } from 'core/assets/js/errors';
import { TRANSACTION_METHOD } from 'finance/assets/js/constants';

/**
 * Wire transfer transaction method pojo.
 * Records all details specific to a wire transfer transaction.
 */
class TransactionMethodWireTransfer {
  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  /**
   * Initialise instance with initial values.
   * @param {object} param0 - instance values.
   */
  init({
    transactionId,
    accountNumber,
    sortCode,
  }) {
    const sanitizedTransactionId = typeof transactionId === 'string'
      ? transactionId.trim() : transactionId;
    const sanitizedAccountNumber = typeof accountNumber === 'string'
      ? accountNumber.trim() : accountNumber;
    const sanitizedSortCode = typeof sortCode === 'string'
      ? sortCode.trim() : sortCode;

    if (isNil(transactionId)) {
      throw new ValidationError('Transaction id is required', [
        new ValidationErrorItem('transactionId', 'Transaction id is required'),
      ]);
    }
    if (!accountNumber) {
      throw new ValidationError('Account number is required', [
        new ValidationErrorItem('accountNumber', 'Account number is required'),
      ]);
    }
    if (!sortCode) {
      throw new ValidationError('Sort code is required', [
        new ValidationErrorItem('sortCode', 'Sort code is required'),
      ]);
    }

    if (!/^\d{7,8}$/.test(sanitizedAccountNumber)) {
      throw new ValidationError('Account number is not valid', [
        new ValidationErrorItem('accountNumber', 'Account number is not valid'),
      ]);
    }

    if (!/^\d{2}-\d{2}-\d{2}$/.test(sanitizedSortCode)) {
      throw new ValidationError('Sort code is not valid', [
        new ValidationErrorItem('sortCode', 'Sort code is not valid'),
      ]);
    }

    this.details = {
      method: TRANSACTION_METHOD.WIRE_TRANSFER,
      transactionId: sanitizedTransactionId,
      accountNumber: sanitizedAccountNumber,
      sortCode: sanitizedSortCode,
    };
  }

  /**
   * Serialize instance.
   * @return {object} serialized instance.
   */
  serialize() {
    const res = { ...this.details };
    return res;
  }

  /**
   * Get a transfer descriptor for this transaction method.
   * @return {string} the transfer descriptor.
   */
  // eslint-disable-next-line class-methods-use-this
  getTransactionDescriptor() {
    return `#${this.details.transactionId}`;
  }

  /**
   * Get the transaction id.
   * @return {string} the transaction id.
   */
  getTransactionId() {
    return this.details.transactionId;
  }
}

export default TransactionMethodWireTransfer;
