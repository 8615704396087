import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton,
  InputAdornment, Table, TableBody, TableCell, TableContainer, TextField, Paper,
  TableRow, TableHead, makeStyles,
} from '@material-ui/core';

import { ICON } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      width: 220,
      margin: 5,
    },
    '& .MuiFormHelperText-root': { marginLeft: 0 },
  },
  totalRow: { backgroundColor: '#ffe' },
  withValue: { borderColor: '#3F51B5 !important' },
}));

const LicenceFeesCalculator = ({ showCalculatorIcon, feeScheme, numProviders, numManagers }) => {
  const classes = useStyles();
  const parsed = new LicenceFeeScheme(feeScheme);
  const currencySymbol = parsed.getCurrencySymbol();

  const [open, showDialog] = useState(false);
  const [values, setValues] = React.useState({
    numProviders,
    numManagers,
  });

  const handleChange = prop => (event) => {
    setValues({ ...values, [prop]: event.target.value || 0 });
  };

  const showBaseLicenceFee = parsed.hasBaseLicence();
  const hasPerProvider = parsed.hasPerProvider();
  const hasPerManager = parsed.hasPerManager();
  const perProviderSeat = parsed.getPerProviderSeat();
  const perManagerSeat = parsed.getPerManagerSeat();

  const baseLicenceFee = parsed.getBaseLicenceFee();
  const totalFee = parsed.getFee({
    numManagers: values.numManagers,
    numProviders: values.numProviders,
  });
  const fees = parsed.apply({
    numManagers: values.numManagers,
    numProviders: values.numProviders,
  });
  const managerSeatsTotalFees = fees.getManagersFee();
  const providerSeatsTotalFees = fees.getProvidersFee();

  return (
    <React.Fragment>
      <IconButton
        className={`pl-0 mt-1 ${showCalculatorIcon ? 'd-block' : 'd-none'}`}
        size="small"
        onClick={() => showDialog(!open)}
      >
        <Icon className={ICON.CALCULATOR} />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => showDialog(false)}
        loading={null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <div className="d-flex align-items-center">
            <Icon className={`${ICON.CALCULATOR} mr-2`} />
            Licence fees calculator
          </div>
        </DialogTitle>
        <DialogContent>
          <form className={classes.root} noValidate autoComplete="off">
            <div className="mb-2"><strong>Applied fees</strong></div>
            {showBaseLicenceFee && (
              <TextField
                className="m-1 d-block"
                id="outlined-basic"
                label="Base licence fee"
                variant="outlined"
                disabled
                defaultValue={baseLicenceFee}
                InputProps={{
                  classes: {
                    notchedOutline: classes.withValue,
                  },
                  startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                }}
              />
            )}
            <TextField
              className="m-1"
              id="outlined-basic"
              label="Per extra manager"
              helperText="Excluding managers already included in base fee (if any)"
              variant="outlined"
              disabled
              defaultValue={perManagerSeat}
              InputProps={{
                classes: {
                  ...hasPerManager ? { notchedOutline: classes.withValue } : {},
                },
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
            />
            <TextField
              className="m-1"
              id="outlined-basic"
              label="Per provider"
              helperText="Excluding providers already included in base fee (if any)"
              variant="outlined"
              disabled
              defaultValue={perProviderSeat}
              InputProps={{
                classes: {
                  ...hasPerProvider ? { notchedOutline: classes.withValue } : {},
                },
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
            />
            <div className="my-1">
              <p className="text-center"><strong>{showBaseLicenceFee ? 'Included in the base licence fee ' : 'Free of charge for up to'}</strong></p>
              <div className="d-flex justify-content-center">
                <div className="mb-2 mr-3 d-flex justify-content-around">
                  <div className="d-flex align-items-center flex-column mr-2">
                    <Icon className={`${ICON.PEOPLE_TIE} text-center`} />
                    Managers
                  </div>
                  <Chip
                    label={parsed.getBaseManagerSeats()}
                    variant="outlined"
                    color={perManagerSeat ? 'primary' : ''}
                  />
                </div>
                <div className="d-flex justify-content-around">
                  <div className="d-flex align-items-center flex-column mr-2">
                    <Icon className={`${ICON.PEOPLE_NINJA} text-center`} />
                    Providers
                  </div>
                  <Chip
                    label={parsed.getBaseProviderSeats()}
                    variant="outlined"
                    color={perProviderSeat ? 'primary' : ''}
                  />
                </div>
              </div>
            </div>
            <div className="mb-1"><strong>Amounts</strong></div>
            <TextField
              id="outlined-basic"
              label="Managers (Qty)"
              variant="outlined"
              defaultValue={values.numManagers}
              onChange={handleChange('numManagers')}
            />
            <TextField
              id="outlined-basic"
              label="Providers (Qty)"
              variant="outlined"
              defaultValue={values.numProviders}
              onChange={handleChange('numProviders')}
            />
          </form>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Fee</strong></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="base-licence-fee">
                  <TableCell component="th" scope="row">
                    <strong>Base licence fee</strong>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={baseLicenceFee}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="manager-seats-fee">
                  <TableCell component="th" scope="row">
                    <strong>Manager seats fee</strong>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={managerSeatsTotalFees}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="provider-seats-fee">
                  <TableCell component="th" scope="row">
                    <strong>Provider seats fee</strong>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={providerSeatsTotalFees}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="total-fees" className={classes.totalRow}>
                  <TableCell component="th" scope="row">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <NumberTpl
                      value={totalFee}
                      prefix={currencySymbol}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showDialog(false)} color="primary">
            <Icon>close</Icon>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};


LicenceFeesCalculator.propTypes = {
  feeScheme: PropTypes.object.isRequired,
  showCalculatorIcon: PropTypes.bool,
  numProviders: PropTypes.number,
  numManagers: PropTypes.number,
};

LicenceFeesCalculator.defaultProps = {
  showCalculatorIcon: true,
  numProviders: 0,
  numManagers: 0,
};

export default LicenceFeesCalculator;
