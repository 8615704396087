import React from 'react';
import { SelectInput, Filter, TextInput } from 'react-admin';

import {
  PAYER_MAPPINGS_STATUS, PAYER_MAPPINGS_STATUS_LABEL,
} from 'finance/assets/js/constants';

const statuses = Object.values(PAYER_MAPPINGS_STATUS).map(c => ({
  id: c, name: PAYER_MAPPINGS_STATUS_LABEL[c],
}));


const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statuses}
    />
  </Filter>
);

export default ListFilter;
