import React from 'react';
import PropTypes from 'prop-types';

import { useList, Datagrid, FunctionField, ListContextProvider } from 'react-admin';

import { AUDIT_LEVEL, AUDIT_MESSAGE_TITLES } from 'finance/assets/js/constants';
import AuditLevel from 'admin/assets/js/resources/fact_audit_log_groups/AuditLevel';
import AuditLog from 'admin/assets/js/resources/fact_audit_log_groups/AuditLog';


const Audits = ({ data }) => {
  // note - useList can only be set once,
  //        so ensure data is loaded before first render
  const listContext = useList({
    data,
    ids: data.map(d => d.id),
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid size="medium" hover={false}>
        <FunctionField
          label="Name"
          render={({ message }) => (
            <span>{AUDIT_MESSAGE_TITLES[message]}</span>
          )}
        />
        <FunctionField
          label="Value"
          render={({ level }) => (
            <AuditLevel level={level} />
          )}
        />
        <FunctionField
          label="Notes"
          render={({ waivedName }) => (
            <span>{waivedName ? `Acknowledged by ${waivedName}` : ''}</span>
          )}
        />
        <FunctionField
          label=""
          render={(log) => (
            <>
              {log.level !== AUDIT_LEVEL.PASS && (
                <AuditLog log={log} />
              )}
            </>
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

Audits.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Audits;
