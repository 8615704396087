import React from 'react';
import { Title } from 'react-admin';
import { Link, withRouter } from 'react-router-dom';
import { Chip } from '@material-ui/core';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import { AOR_CONTRACT_STATUS, AOR_CONTRACT_STATUS_LABEL } from 'documents/assets/js/constants';
import { formatDate, getOrganizationsList } from 'admin/assets/js/lib/utils';
import { AOR_STATUS, AOR_STATUS_COLORS } from 'admin/assets/js/resources/full_users/constants';
import { USER_TABS, USER_INFO_SUB_TABS } from 'admin/assets/js/constants';

export const UserCardAORContractsView = () => {
  const { ids, keyedById } = getOrganizationsList();
  const statusValues = Object.values(AOR_CONTRACT_STATUS);

  return (
    <>
      <Title title="AOR contracts" />

      <ApiTable
        columns={[
          {
            label: 'Organization',
            name: 'organization_id',
            options: {
              cellRender: (orgId, record) => (
                <Link to={`/organizations/${record.organization_id}/show`}>{keyedById[orgId]}</Link>
              ),
              customFilterListOptions: {
                render: orgId => `Organization: ${keyedById[orgId]}`,
              },
              filter: true,
              filterOptions: {
                fullWidth: true,
                names: ids,
                renderValue: orgId => keyedById[orgId],
              },
              sort: false,
            },
          },
          {
            label: 'Provider Name',
            name: 'user_card_id',
            options: {
              cellRender: (name, record) => (
                <Link to={`/full_users/${record?.user?.id}/show`}>{record?.user?.name}</Link>
              ),
              filter: false,
              sort: false,
            },
          },
          {
            label: 'status',
            name: 'status',
            options: {
              cellRender: status => (
                <Chip
                  style={{
                    backgroundColor: status === AOR_CONTRACT_STATUS.ACTIVE
                      ? AOR_STATUS_COLORS[AOR_STATUS.ENABLED]
                      : AOR_STATUS_COLORS[AOR_STATUS.DISABLED],
                    color: 'white',
                  }}
                  size="small"
                  label={AOR_CONTRACT_STATUS_LABEL[status]}
                />
              ),
              customFilterListOptions: {
                render: status => `Status: ${AOR_CONTRACT_STATUS_LABEL[status]}`,
              },
              filterOptions: {
                fullWidth: true,
                names: statusValues,
                renderValue: status => AOR_CONTRACT_STATUS_LABEL[status],
              },
              filter: true,
              sort: true,
            },
          },
          {
            label: 'Date signed',
            name: 'signed_at',
            options: {
              cellRender: (value, record) => formatDate(record.signedAt),
              filter: false,
              sort: true,
            },
          },
          {
            label: 'Date countersigned',
            name: 'countersigned_at',
            options: {
              cellRender: (value, record) => formatDate(record.counterSignedAt),
              filter: false,
              sort: true,
            },
          },
          {
            label: 'Last updated',
            name: 'updatedAt',
            options: {
              cellRender: (updatedAt) => formatDate(updatedAt, 'DD/MM/YY HH:mm'),
              filter: false,
              sort: true,
            },
          },
          {
            label: ' ',
            name: 'status',
            options: {
              cellRender: (status, record) => (
                <div
                  className="text-right"
                  style={{ minWidth: '100px' }}
                >
                  <Link
                    className="btn"
                    to={`/full_users/${record?.user?.id}/show?tab=${USER_TABS.INFO}&subTab=${USER_INFO_SUB_TABS.AOR}`}
                  >
                    View
                  </Link>
                </div>
              ),
              filter: false,
              sort: false,
            },
          },
        ]}
        className="mt-5"
        defaultSortBy="signed_at"
        sortFilterList={false}
        defaultSortDirection="DESC"
        downloadEnabled={false}
        searchEnabled={false}
        draggableColumns={false}
        defaultFilters={{
          status: AOR_CONTRACT_STATUS.PENDING_COUNTERSIGN,
        }}
        resource="user_card_aor_contracts"
      />
    </>
  );
};

export default withRouter(UserCardAORContractsView);
