import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useNotify,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminApiHijackUrl } from 'admin/urls';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

const HijackButton = ({ record, source, label, size, variant, color }) => {
  if (!record) {
    return null;
  }
  const body = { user_id: record[source] };
  const url = adminApiHijackUrl();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleClick = async () => {
    setLoading(true);
    try {
      const res = await httpClient(url, { method: 'POST', body: JSON.stringify(body) });
      const { auth } = res.json;
      if (auth.userProfile && auth.userProfile.homeUrl) {
        WINDOW_OPEN(auth.userProfile.homeUrl);
      } else {
        WINDOW_OPEN('/organizations');
      }
    } catch (e) {
      notify(e.body?._error || e.message || 'Error: cannot hijack user', 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button color={color} variant={variant} size={size} disabled={loading} onClick={handleClick}>
        {label}
      </Button>
    </Fragment>
  );
};


HijackButton.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

HijackButton.defaultProps = {
  record: null,
  source: 'id',
  variant: 'contained',
  color: 'secondary',
  label: 'Hijack',
  size: 'large',
};

export default HijackButton;
