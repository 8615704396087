import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import moment from 'moment';

import { API_DATE_FORMAT, DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';

/**
 * Audit logs header.
 *
 * Show general info about the logs, so the user understands what they are looking at.
 *
 * Structurally this is two vertical 'cards', side by side.
 *
 * @param {Object} data - FactAuditRun REST entity.
 * @return {JSX.Element} rendered component.
 */
const FactAuditLogsHeader = ({ data }) => {
  const formattedRunDate = useMemo(() => moment(data.dateRun)
    .format(DATETIME_FORMAT_DEFAULT), data);
  const formattedBillingProcessDate = useMemo(() => moment(data.billingProcessDate)
    .format(API_DATE_FORMAT), data);

  return (
    <>
      <div>
        <Typography className="pt-3 px-4" variant="h3">Audit Logs</Typography>
      </div>

      <div className="py-3 px-4 d-flex">
        <div className="w-50">
          <Typography className="mb-3" variant="h6">{`Date run - ${formattedRunDate}`}</Typography>
          <Typography variant="h6">{`Billing process date - ${formattedBillingProcessDate}`}</Typography>
        </div>
        <div className="w-50">
          <Typography variant="h6">{`Num logs - ${data.numLogs}`}</Typography>
        </div>
      </div>
    </>
  );
};

FactAuditLogsHeader.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FactAuditLogsHeader;
