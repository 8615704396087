import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Button } from '@material-ui/core';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import { ORGANIZATION_FEATURE_USAGE_TABS } from 'admin/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';

const thisFormatDate = date => formatDate(`${date}-01`, 'MMMM YYYY');

const tab = ORGANIZATION_FEATURE_USAGE_TABS.TIN_MATCHING;

const TINMatchingTab = ({ organization }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { date: selectedDate } = qs.parse(search);

  if (selectedDate) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-left">
          <BackButton />
          <h2 className="m-0">{thisFormatDate(selectedDate)}</h2>
        </div>
        <ApiTable
          columns={[
            {
              label: 'Contractor name',
              name: 'contractor_name',
              options: {
                cellRender: (contractorName, { user_id: userId }) => {
                  if (!userId) {
                    return contractorName;
                  }
                  return (
                    <Link to={`/full_users/${userId}/show`}>
                      {contractorName}
                    </Link>
                  );
                },
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Created',
              name: 'created_at',
              options: {
                cellRender: createdAt => formatDate(createdAt),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Type',
              name: 'type',
              options: {
                filter: false,
                sort: false,
              },
            },
            {
              label: 'Result',
              name: 'result',
              options: {
                filter: false,
                sort: false,
              },
            },
          ]}
          defaultSortBy="created_at"
          defaultSortDirection="DESC"
          extraResourceQueryParams={{ date: selectedDate, organization_id: organization.id, tab }}
          resource="tax_identification_number_validation_requests"
        />
      </>
    );
  }

  return (
    <ApiTable
      columns={[
        {
          label: 'Date',
          name: 'date',
          options: {
            cellRender: thisFormatDate,
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Created for contractors',
          name: 'created_for_contractors',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Created for organization',
          name: 'created_for_organization',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Details',
          name: 'date',
          options: {
            cellRender: date => (
              <Button
                color="primary"
                onClick={() => {
                  history.push(`${pathname}?date=${date}&tab=${tab}`);
                }}
              >
                View
              </Button>
            ),
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="date"
      defaultSortDirection="DESC"
      excludeColumnsFromDownload={['Details']}
      extraResourceQueryParams={{ organization_id: organization.id, tab }}
      resource="tax_identification_number_validation_requests"
      searchEnabled={false}
    />
  );
};

TINMatchingTab.propTypes = {
  organization: PropTypes.object,
};

TINMatchingTab.defaultProps = {
  organization: {},
};

export default withRouter(TINMatchingTab);
