import { useEffect, useState } from 'react';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const useFetchOrgIdsForMissedBilling = (invoiceDate) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [missedDates, setMissedDates] = useState({});

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);

        const res = await httpClient(adminCommandApiUrl('fetch-org-ids-for-missed-billing', {
          invoiceDate,
        }), {
          method: 'GET',
        });

        setMissedDates(res.json.missedDates);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [setMissedDates, setLoading, setError, invoiceDate]);

  return { missedDates, loading, error };
};

export default useFetchOrgIdsForMissedBilling;
