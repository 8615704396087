import React, { Fragment, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, useRefresh, useNotify } from 'react-admin';

import httpClient from 'admin/assets/js/lib/httpClient';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { adminBulkActionApiUrl } from 'admin/urls';

const IncludeServiceOrdersButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleGenerateClick = async (values) => {
    const command = 'include-billing-period';
    const url = adminBulkActionApiUrl('service_orders', command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(values) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClick}
      >
        <strong>INCLUDE SERVICE ORDERS IN LAST BILLING PERIOD</strong>
      </a>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="include in last"
      >
        <DialogTitle>Include service orders in last billing period</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleGenerateClick}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="ids">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <label>Service order ids</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="text"
                          placeholder="10,22,33"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="Save" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default IncludeServiceOrdersButton;
