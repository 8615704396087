/* eslint max-len: "warn", react/prop-types: "warn", import/prefer-default-export: "warn", react/no-multi-comp: "off" */
import queryString from 'query-string';
import React from 'react';
import { get } from 'lodash';
import {
  List, Datagrid, Filter, FunctionField, TextInput, SelectInput,
  ReferenceInput, BooleanInput, ShowButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { formatDate } from 'admin/assets/js/lib/utils';
import ClearFiltersButton from 'admin/assets/js/components/buttons/ClearFiltersButton.jsx';
import InvoiceAmountsFieldPlain from './InvoiceAmountsFieldPlain.jsx';
import InvoicePartiesField from './InvoicePartiesField.jsx';
import InvoiceStatusField from './InvoiceStatusField.jsx';
import InvoiceInfoField from './InvoiceInfoField.jsx';
import InvoiceTransferDetailsPanel from './InvoiceTransferDetailsPanel.jsx';
import TabbedInvoiceDataGrid from './TabbedInvoiceDataGrid.jsx';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

import { INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';
import { InvoiceInfoPanel } from './edit.jsx';
import InvoiceTransactionsField from './InvoiceTransactionsField.jsx';
import { PAYMENT_METHODS_LABEL } from 'settings/assets/js/constants';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const statusOptions = Object.keys(INVOICE_STATUS_LABEL).map(k => ({
  id: k, name: INVOICE_STATUS_LABEL[k],
}));

const paymentMethodOptions = Object.keys(PAYMENT_METHODS_LABEL).map(k => ({
  id: k, name: PAYMENT_METHODS_LABEL[k],
}));

const ListFilter = (props) => {
  const { filterValues, setFilters } = props;
  const { selectedTab } = filterValues;
  const showParent = filterValues.parent_id;
  return (
    <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput
          alwaysOn
          reference="dim_billing_processes"
          source="billing_process_id"
          sort={{ field: 'billing_date', order: 'DESC' }}
          label="Billing date"
        >
          <SelectInput
            optionText={process => formatDate(process.billing_date)}
          />
        </ReferenceInput>
        {!showParent && (
          <ReferenceInput
            alwaysOn
            allowEmpty
            label="Organization"
            source="organization_id"
            reference="organizations_lite"
            perPage={MAX_ORGANIZATIONS_FETCHED}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
          >
            <SelectInput
              optionText={org => `${org.name}
               (${org.unique_alias})`}
            />
          </ReferenceInput>
        )}
        <SelectInput
          label="Status"
          alwaysOn
          allowEmpty
          source="status"
          choices={statusOptions}
        />
        <SelectInput
          label="Payment Method"
          alwaysOn
          allowEmpty
          source="paymentMethod"
          choices={paymentMethodOptions}
        />
        { selectedTab === 'outbound' && (
          <BooleanInput
            label="Licence"
            source="is_licence"
            alwaysOn
          />
        )}
        { selectedTab === 'outbound' && (
          <BooleanInput
            label="Subscription"
            source="is_subscription"
            alwaysOn
          />
        )}
        { selectedTab === 'outbound' && (
          <BooleanInput
            label="Without transaction"
            source="withoutTransaction"
            alwaysOn
          />
        )}
      </Filter>
      <ClearFiltersButton
        onClearFilters={setFilters}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: '99%',
  },
}));

export const InvoiceDataGrid = (props) => {
  const classes = useStyles();

  return (
    <Datagrid
      {...props}
      className={classes.root}
      optimized
      expand={<InvoiceTransferDetailsPanel />}
    >
      <InvoiceInfoField label="Number" />
      <InvoiceAmountsFieldPlain source="total" label="Amount" />
      <InvoiceTransactionsField label="Transactions" sortBy="transaction.id" />
      <InvoiceStatusField label="Status" sortable={false} />
      <FunctionField
        source="paymentMethod"
        label="Payment Method"
        render={record => PAYMENT_METHODS_LABEL[record.paymentMethod]}
        sortable={false}
      />
      <InvoicePartiesField label="Parties" sortable={false} />
      <ShowButton label="" />
    </Datagrid>
  );
};

export const ChildrenInvoiceDataGrid = (props) => {
  const classes = useStyles();

  return (
    <Datagrid
      {...props}
      className={classes.root}
      optimized
      expand={<InvoiceTransferDetailsPanel />}
    >
      <InvoiceInfoField label="Number" />
      <InvoiceAmountsFieldPlain
        showFees={false}
        source="total"
        label="Amount"
      />
      <InvoiceTransactionsField label="Transactions" sortBy="transaction.id" />
      <FunctionField
        source="status"
        label="Status"
        render={record => INVOICE_STATUS_LABEL[record.status]}
        sortable={false}
      />
      <InvoicePartiesField label="Parties" sortable={false} />
      <ShowButton label="" />
    </Datagrid>
  );
};

const InvoiceList = (props) => {
  const { permissions, basePath, resource } = props;
  const query = queryString.parse(get(props, 'location.search', {}));
  const filterValues = JSON.parse(get(query, 'filter', '{}'));
  const showParent = filterValues.parent_id;
  if (!permissions) {
    return null;
  }
  return (
    <React.Fragment>
      {showParent && (
        <React.Fragment>
          <InvoiceInfoPanel
            id={filterValues.parent_id}
            resource={resource}
            basePath={basePath}
            permissions={permissions}
          />
          <hr />
          <h4><strong>CONTRACTOR INVOICES</strong></h4>
        </React.Fragment>
      )}
      <List
        key="invoices"
        {...props}
        filterDefaultValues={{ selectedTab: 'outbound' }}
        filters={<ListFilter />}
        actions={null}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        data-testid="invoices-list"
      >
        <TabbedInvoiceDataGrid
          showTabs={!showParent}
          Grid={!showParent ? InvoiceDataGrid : ChildrenInvoiceDataGrid}
        />
      </List>
    </React.Fragment>
  );
};

export default InvoiceList;
