import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

import { PRICING_SCALE_STATUS_LABEL } from 'finance/assets/js/constants';
import { PRICING_SCALE_STATUS_COLORS } from 'admin/assets/js/resources/pricing_scales/constants';

const PricingScaleStatusField = ({ record: { status } }) => {
  const statusLabel = PRICING_SCALE_STATUS_LABEL[status];
  return (
    <Chip
      style={{ backgroundColor: PRICING_SCALE_STATUS_COLORS[status], color: 'white' }}
      size="small"
      label={statusLabel}
    />
  );
};

PricingScaleStatusField.propTypes = {
  record: PropTypes.object,
};

PricingScaleStatusField.defaultProps = {
  record: {},
};

export default PricingScaleStatusField;
