import { pick } from 'lodash';
import React from 'react';

import ClassificationForm from 'admin/assets/js/components/ClassificationForm.jsx';
import { getClassificationQuestionnaireCurrentVersion } from 'admin/assets/js/ducks/hooks';

const ClassificationNewVersionView = () => {
  const { loading, questionnaire } = getClassificationQuestionnaireCurrentVersion();

  if (loading || !questionnaire) {
    return null;
  }

  return (
    <ClassificationForm
      initialValues={pick(questionnaire, 'questions', 'riskHighThreshold', 'riskModerateThreshold')}
      version={questionnaire.version + 1}
    />
  );
};

export default ClassificationNewVersionView;
