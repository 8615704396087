import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from 'react-admin';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

import SaveRefreshButton from 'admin/assets/js/components/transactions/SaveRefreshButton.jsx';

const TransactionEditToolbar = ({
  invoiceAmounts, exchangeRates,
  onClose,
  ...props
}) => {
  if (!(invoiceAmounts instanceof InvoiceAmounts)) {
    throw new Error('expected instance of InvoiceAmounts');
  }
  return (
    <Toolbar {...props}>
      <SaveRefreshButton
        invoiceAmounts={invoiceAmounts}
        exchangeRates={exchangeRates}
        onClose={onClose}
      />
    </Toolbar>
  );
};

TransactionEditToolbar.propTypes = {
  invoiceAmounts: PropTypes.object.isRequired,
  exchangeRates: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

TransactionEditToolbar.defaultProps = {
  disabled: false,
  onClose: () => {},
};

export default TransactionEditToolbar;
