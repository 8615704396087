import axios from 'axios';
import Cookies from 'cookies-js';

const apiClient = ({ data = {}, headers = {}, method = 'GET', url }) => {
  const token = Cookies.get('token');
  Object.assign(headers, { Accept: 'application/json', Authorization: `Bearer ${token}` });
  return axios({ data, method, url });
};

export default apiClient;
