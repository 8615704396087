import { useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

/**
 * Custom React hook to fetch deployments status.
 *
 * @returns {{
 *   loadingDeploymentStatus: boolean,
 *   deploymentsStatus: string
 * }}
 * An object containing loading status and deployments status.
 */
const useFetchDeploymentsStatus = () => {
  const [loadingDeploymentStatus, setLoadingDeploymentsStatus] = useState(false);
  const [deploymentsStatus, setDeploymentsStatus] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    const fetchStatus = async () => {
      setLoadingDeploymentsStatus(true);
      try {
        const response = await httpClient(adminCommandApiUrl('deployments-status'), {
          method: 'GET',
        });
        setDeploymentsStatus(response.json.status);
      } catch (e) {
        notify('Error: Loading deployments status failed', 'warning');
      } finally {
        setLoadingDeploymentsStatus(false);
        refresh();
      }
    };

    fetchStatus();
  }, [notify, refresh, setLoadingDeploymentsStatus, setDeploymentsStatus]);

  return { loadingDeploymentStatus, deploymentsStatus };
};

export default useFetchDeploymentsStatus;
