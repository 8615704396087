import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useNotify,
} from 'react-admin';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminBulkActionApiUrl } from 'admin/urls';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal';
import useFetchOrgIdsForInvoicing from 'admin/assets/js/components/hooks/useFetchOrgIdsForInvoicing';

// note - lets split the modal from the button to ensure the 'useFetchOrgIdsForInvoicing' only runs
//        when we actual open the modal ( ie display it )
const RunInvoicingModal = ({
  handleDialogClose,
  setLoading,
  loading,
  action,
  ids,
  payload,
  resource,
  successNotifMsg,
  title,
  content,
}) => {
  const notify = useNotify();

  // get org ids invoicing will be run for ( if we run for all )
  const {
    orgIds, loading: loadingOrgs, error: errorLoadingOrgs,
  } = useFetchOrgIdsForInvoicing(payload.date);
  // chose which ids we want to run invoicing for ( selected or all )
  const idsToInvoice = useMemo(() => ((ids && ids.length) ? ids : orgIds), [ids, orgIds]);

  // show modal content based on org id loading state
  const modalMessage = useMemo(() => {
    if (loadingOrgs) {
      return 'Please wait, determing pending organizations to run invoicing for ...';
    }
    if (errorLoadingOrgs) {
      return `Failed to determine pending organizations to run invoicing for ( ${errorLoadingOrgs} ), please try again.  If problem persists contact Payments Dev.`;
    }
    // it's possible all orgs have been invoiced, in which case there's nothing to do
    if (!idsToInvoice || !idsToInvoice.length) {
      return 'No organizations to invoice at this time.';
    }

    // note - use span rather than p, as the modal content appears to already be inside a p tag,
    //        p inside a p results in a DOM validation warning :/
    return (
      <>
        <span className="d-block mb-2">
          Invoicing will be run for the following
          {' '}
          {idsToInvoice.length}
          {' '}
          pending organizations, please check this list is as expected before proceeding -
        </span>
        {idsToInvoice
          .sort((a, b) => a - b)
          .reduce((acc, val, idx) => {
            const numIdsPerLine = 5;
            const lineBreak = !(idx % numIdsPerLine);
            return `${acc}${lineBreak ? '\n' : ', '}${val}`;
          }, '')
          .split('\n')
          .map((val, idx) => (<span key={idx} className="d-block ml-3 mb-0">{val}</span>))
        }
        <span className="d-block mt-2">{content}</span>
      </>
    );
  }, [loadingOrgs, errorLoadingOrgs, idsToInvoice]);

  const handleConfirm = async (
    body = { payload, ids: idsToInvoice },
  ) => {
    const url = adminBulkActionApiUrl(resource, action);
    setLoading(true);
    const successNotificationMessage = successNotifMsg || `${resource} updated`;
    const errorNotificationMessage = `Error: ${resource} not updated`;
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify(body) });
      notify(successNotificationMessage);
    } catch (e) {
      notify(e.body && e.body._error ? e.body._error : errorNotificationMessage, 'warning');
    } finally {
      setLoading(false);
      handleDialogClose();
    }
  };

  return (loadingOrgs || idsToInvoice.length) ? (
    <ConfirmModal
      open
      loading={loading}
      title={title}
      content={modalMessage}
      onConfirm={handleConfirm}
      onClose={handleDialogClose}
      disabled={loading || loadingOrgs || errorLoadingOrgs}
      showConfirm={!loadingOrgs && !errorLoadingOrgs && idsToInvoice.length > 0}
      highlightCancel
    />
  ) : (
    <ConfirmModal
      open
      loading={loading}
      title={title}
      content="There are no pending organizations for invoicing at this time."
      onConfirm={() => {}}
      onClose={handleDialogClose}
      disableCtas
      highlightCancel
    />
  );
};

RunInvoicingModal.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  payload: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  successNotifMsg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const RunInvoicingButton = ({
  action,
  content,
  disabled,
  ids,
  label,
  payload,
  resource,
  successNotifMsg,
  title,
  variant,
  size,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  // note - open render the modal when open ( visible ) as it's includes an 'expensive' hook
  return (
    <>
      <Button
        disabled={disabled || loading}
        label={label}
        onClick={handleClick}
        variant={variant}
        color="primary"
        size={size}
      />
      {title && content && open && (
        <RunInvoicingModal
          handleDialogClose={handleDialogClose}
          setLoading={setLoading}
          loading={loading}
          action={action}
          ids={ids}
          payload={payload}
          resource={resource}
          successNotifMsg={successNotifMsg}
          title={title}
          content={content}
        />
      )}
    </>
  );
};

RunInvoicingButton.propTypes = {
  action: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  disabled: PropTypes.bool,
  ids: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
  payload: PropTypes.object,
  resource: PropTypes.string.isRequired,
  successNotifMsg: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};

RunInvoicingButton.defaultProps = {
  content: null,
  disabled: false,
  label: null,
  payload: {},
  successNotifMsg: null,
  title: null,
  variant: 'text',
  size: 'small',
};

export default RunInvoicingButton;
