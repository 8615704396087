import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';

import {
  CASHOUT_REQUEST_STATUS,
  CASHOUT_REQUEST_STATUS_LABEL,
} from 'finance/assets/js/constants';

const useStyles = makeStyles({
  statusLabel: {
    border: 'none',
    color: '#fff',
    marginBottom: '0px',
    minWidth: '100px',
  },
});

export const CASHOUT_REQUEST_STATUS_STYLES = {
  [CASHOUT_REQUEST_STATUS.APPROVED]: '#dd9e1f',
  [CASHOUT_REQUEST_STATUS.PROCESSED]: '#3ABC4E',
  [CASHOUT_REQUEST_STATUS.DENIED]: '#990000',
  [CASHOUT_REQUEST_STATUS.REQUESTED]: '#1584C6',
};

const CashoutRequestStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <Chip
      color="primary"
      className={classes.statusLabel}
      label={CASHOUT_REQUEST_STATUS_LABEL[status]}
      size="medium"
      style={{ backgroundColor: CASHOUT_REQUEST_STATUS_STYLES[status] }}
      variant="outlined"
    />
  );
};

CashoutRequestStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CashoutRequestStatus;
