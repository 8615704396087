import React from 'react';
import {
  Filter, SelectInput,
  ReferenceInput,
} from 'react-admin';

import { ORGANIZATION_STATUS, ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const statusOptions = Object.keys(ORGANIZATION_STATUS_LABEL).map(k => ({
  id: k, name: ORGANIZATION_STATUS_LABEL[k],
}));

const LicenceListFilter = props => (
  <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        allowEmpty
        label="Organization"
        source="id"
        reference="organizations_lite"
        perPage={MAX_ORGANIZATIONS_FETCHED}
        filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText={org => `${org.name}
            (${org.unique_alias})`}
        />
      </ReferenceInput>
      <SelectInput
        label="Status"
        alwaysOn
        allowEmpty
        source="status"
        choices={statusOptions}
      />
    </Filter>
  </div>
);

export default LicenceListFilter;
