import Big from 'big.js';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import {
  SaveButton,
  useCreate, useUpdate, useRefresh, useNotify,
} from 'react-admin';
import { get } from 'lodash';
import TransactionAmounts from 'finance/assets/js/lib/TransactionAmounts';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

import { TRANSACTION_METHOD } from 'finance/assets/js/constants';

Big.RM = 1;
Big.DP = 2;

const SaveRefreshButton = ({
  invoiceAmounts, exchangeRates,
  onClose,
  handleSubmit,
  disabled,
  ...props
}) => {
  const formState = useFormState();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { error: updateError }] = useUpdate('transactions', get(props, 'record.id'));
  const [create, { error: createError }] = useCreate('transactions');
  const [createGroup, { error: createGroupError }] = useCreate('transaction_groups');
  if (!(invoiceAmounts instanceof InvoiceAmounts)) {
    throw new Error('expected instance of InvoiceAmounts');
  }

  // lock submit when have submitted, however unlock when an error occurs
  // this should allow re-submitting to resolve validation errors
  // but prevent accidental resubmission of the form if a submission is in progress
  const [submitDisabled, setSubmitDisabled] = useState(false);
  useEffect(() => {
    // re-enable submit when an error occurs,
    // this allows resubmission after resolving validation errors
    if (createError || updateError || createGroupError) {
      setSubmitDisabled(false);
    }
  }, [createError, updateError, createGroupError]);

  const handleClick = useCallback(() => {
    // disable immediate resubmission
    setSubmitDisabled(true);

    const data = { ...formState.values };

    // NOTE - if the modal has changed the TrW transfer id, the model will not update correctly
    //        as the tw_transfer_id we send here will overwrite any change we make, so we must send
    //        the updated tw_transfer_id here
    if (data.method === TRANSACTION_METHOD.TRANSFERWISE) {
      data.tw_transfer_id = data.vendor_details?.transferId;
    }

    if (data.method === TRANSACTION_METHOD.PAYONEER) {
      data.pn_client_reference_id = data.vendor_details?.clientReferenceId;
    }

    // NOTE - user may have adjusted the transaction method in the form, this change needs to be
    //        reflected in the transaction details to ensure the correct parser is used
    if (data.vendor_details) {
      data.vendor_details.method = data.method;
    }

    data.amounts = TransactionAmounts.createFromFormData({
      formData: formState.values, invoiceAmounts, exchangeRates,
    }).serialize();

    // mark transaction as manually recorded via modal
    data.manually_recorded = true;

    if (Array.isArray(data.invoice_ids) && data.invoice_ids.length > 0) {
      createGroup({ payload: { data } }, {
        onSuccess: () => {
          onClose();
          notify('ra.notification.updated', 'info', {
            smart_count: 1,
          });
          refresh();
        },
      });
    } else if (!data.id) {
      create({ payload: { data } }, {
        onSuccess: () => {
          notify('ra.notification.updated', 'info', {
            smart_count: 1,
          });
          refresh();
        },
      });
    } else {
      update({ payload: { data } }, {
        onSuccess: () => {
          notify('ra.notification.updated', 'info', {
            smart_count: 1,
          });
          refresh();
        },
      });
    }
  }, [
    formState.valid,
    formState.values,
    update,
    create,
    notify,
    createGroup,
  ]);

  return (
    <div className="d-flex flex-column mt-4 mb-4">
      <div>
        {createError && createError.body && createError.body._error && (
          <p className="text-danger">{createError.body._error}</p>
        )}
        {updateError && updateError.body && updateError.body._error && (
          <p className="text-danger">{updateError.body._error}</p>
        )}
        {createGroupError?.body?._error && (
          <p className="text-danger">{createGroupError.body._error}</p>
        )}
      </div>
      <SaveButton
        {...props}
        redirect={false}
        handleSubmitWithRedirect={handleClick}
        disabled={disabled || submitDisabled}
      />
    </div>
  );
};

SaveRefreshButton.propTypes = {
  handleSubmit: PropTypes.func,
  invoiceAmounts: PropTypes.object.isRequired,
  exchangeRates: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

SaveRefreshButton.defaultProps = {
  handleSubmit: () => {},
  disabled: false,
  onClose: () => {},
};

export default SaveRefreshButton;
