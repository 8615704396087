import React, { useState } from 'react';
import { Title } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { Button, IconButton, Menu, MenuItem, Switch } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { reloadTable } from 'admin/assets/js/lib/utils';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { adminApiBannerDeleteUrl, adminApiBannerToggleActiveUrl } from 'admin/urls';
import { formatDate, getDatetime, parseDate } from 'core/assets/js/lib/utils';
import {
  SITE_WIDE_BANNER_STATUS,
  SITE_WIDE_BANNER_STATUS_COLOR,
  SITE_WIDE_BANNER_STATUS_LABEL,
  SITE_WIDE_BANNER_TYPE,
  SITE_WIDE_BANNER_TYPE_LABEL,
} from 'organizations/assets/js/constants';

const typeValueToLabel = value => SITE_WIDE_BANNER_TYPE_LABEL[value];
const typeValues = Object.values(SITE_WIDE_BANNER_TYPE);
const statusValueToLabel = value => SITE_WIDE_BANNER_STATUS_LABEL[value];
const statusValues = Object.values(SITE_WIDE_BANNER_STATUS);

const getStatus = banner => {
  if (!banner.active) {
    return SITE_WIDE_BANNER_STATUS.OFF;
  }
  if (
    !banner.end_date
    || getDatetime().isBefore(parseDate(banner.end_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ'))
  ) {
    return SITE_WIDE_BANNER_STATUS.ACTIVE;
  }
  return SITE_WIDE_BANNER_STATUS.COMPLETED;
};

export const BannersView = ({ history }) => {
  const [changingActive, setChangingActive] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [bannerOpenMenu, setBannerOpenMenu] = useState(null);
  const notifications = getNotifications();

  const handleCloseBannerMenu = () => {
    setMenuAnchorEl(null);
    setBannerOpenMenu(null);
  };

  const columns = [
    {
      label: 'Title',
      name: 'title',
      options: { filter: false, sort: true },
    },
    {
      label: 'Content',
      name: 'content',
      options: { filter: false, sort: true },
    },
    {
      label: 'Type',
      name: 'type',
      options: {
        cellRender: typeValueToLabel,
        customFilterListOptions: { render: value => `Type: ${typeValueToLabel(value)}` },
        filter: true,
        filterOptions: { names: typeValues, renderValue: typeValueToLabel },
        sort: true,
      },
    },
    {
      label: 'Term',
      name: 'term',
      options: {
        cellRender: (_, banner) => {
          if (!banner.end_date) {
            return 'Indefinite';
          }
          return `${formatDate(banner.start_date)} - ${formatDate(banner.end_date)}`;
        },
        filter: false,
        sort: false,
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        cellRender: (_, banner) => {
          const status = getStatus(banner);
          return (
            <span
              className="site-wide-banner_status"
              style={{ backgroundColor: SITE_WIDE_BANNER_STATUS_COLOR[status] }}
            >
              {SITE_WIDE_BANNER_STATUS_LABEL[status]}
            </span>
          );
        },
        downloadCellRender: (_, banner) => SITE_WIDE_BANNER_STATUS_LABEL[getStatus(banner)],
        customFilterListOptions: { render: value => `Status: ${statusValueToLabel(value)}` },
        filter: true,
        filterOptions: { names: statusValues, renderValue: statusValueToLabel },
        sort: false,
      },
    },
    {
      label: 'Actions',
      name: 'active',
      options: {
        cellRender: (_, banner) => {
          return (
            <div className="d-flex">
              <Switch
                checked={banner.active}
                color="primary"
                disabled={changingActive}
                onChange={async () => {
                  setChangingActive(true);
                  try {
                    await apiClient({
                      method: 'PUT', url: adminApiBannerToggleActiveUrl(banner.id),
                    });
                    reloadTable();
                  } catch (e) {
                    notifications.error(e.message);
                  } finally {
                    setChangingActive(false);
                  }
                }}
              />
              <IconButton
                onClick={event => {
                  setMenuAnchorEl(event.currentTarget);
                  setBannerOpenMenu(banner.id);
                }}
                size="small"
              >
                <ExpandMore />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={bannerOpenMenu === banner.id}
                onClose={handleCloseBannerMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseBannerMenu();
                    history.push(`/banners/${banner.id}/edit`);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    setChangingActive(true);
                    try {
                      await apiClient({
                        method: 'DELETE', url: adminApiBannerDeleteUrl(banner.id),
                      });
                      handleCloseBannerMenu();
                      reloadTable();
                    } catch (e) {
                      notifications.error(e.message);
                    } finally {
                      setChangingActive(false);
                    }
                  }}
                  style={{ color: '#dc3545' }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <>
      <Title title="Banners" />
      <div className="d-flex justify-content-end my-4">
        <Button
          color="primary"
          onClick={() => history.push('/banners/create')}
          variant="contained"
        >
          Create banner
        </Button>
      </div>
      <ApiTable
        columns={columns}
        defaultSortBy="title"
        defaultSortDirection="ASC"
        resource="site_wide_banners"
      />
    </>
  );
};

BannersView.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default withRouter(BannersView);
