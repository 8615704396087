import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {
  Create, ReferenceInput, FormDataConsumer, SelectInput, SimpleForm,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import { INVOICE_SEGMENT, INVOICE_SEGMENT_LABEL } from 'finance/assets/js/constants';
import SystemBankSelectInput from './SystemBankSelectInput.jsx';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

const segments = Object.values(INVOICE_SEGMENT).map(c => ({
  id: c, name: INVOICE_SEGMENT_LABEL[c],
}));

const SystemBankOverrideAddButton = ({ basePath }) => {
  const [showModal, setShowModal] = useState(false);

  const onOpen = useCallback(() => setShowModal(true), [setShowModal]);
  const onClose = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <>
      <Button
        color="primary"
        onClick={onOpen}
        startIcon={<AddIcon />}
      >
        Override
      </Button>

      <Dialog onClose={onClose} open={showModal}>
        <DialogTitle>Add system bank override</DialogTitle>
        <DialogContent>
          <Create
            resource="system_bank_account_overrides"
            basePath={basePath}
            onSuccess={onClose}
          >
            <SimpleForm
              redirect={false}
            >
              <ReferenceInput
                alwaysOn
                allowEmpty
                label="Organization"
                source="organization_id"
                reference="organizations_lite"
                perPage={MAX_ORGANIZATIONS_FETCHED}
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
                helperText="For which organization do you want to define a custom pay-in bank?"
              >
                <SelectInput
                  optionText={org => `${org.name}
                   (${org.unique_alias})`}
                />
              </ReferenceInput>
              <ReferenceInput
                alwaysOn
                allowEmpty
                label="Invoicing Entity"
                source="invoicing_entity_id"
                reference="invoicing_entities"
                perPage={1000}
                sort={{ field: 'name', order: 'ASC' }}
                helperText="For which entity do you want to add an override?"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <SelectInput
                label="invoices"
                source="invoice_segment"
                disabled
                helperText="The invoices to pay in this bank account"
                choices={segments}
                allowEmpty={false}
                defaultValue={INVOICE_SEGMENT.ALL_OUTBOUNDS}
              />
              <ReferenceInput
                alwaysOn
                allowEmpty
                label="Currency"
                source="currency"
                reference="system_bank_account_currencies"
                perPage={1000}
                sort={{ field: 'id', order: 'ASC' }}
                helperText="Currency of the bank account"
              >
                <SelectInput optionText="id" />
              </ReferenceInput>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <ReferenceInput
                    alwaysOn
                    allowEmpty
                    label="Bank"
                    source="system_bank_account_id"
                    reference="system_bank_accounts"
                    perPage={1000}
                    filter={{
                      currency: formData.currency,
                      organization_id: formData.organization_id,
                      invoicing_entity_id: formData.invoicing_entity_id,
                    }}
                    {...rest}
                    disabled={!(
                      formData.currency
                      && formData.organization_id
                      && formData.invoicing_entity_id
                    )}
                  >
                    <SystemBankSelectInput />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
    </>
  );
};

SystemBankOverrideAddButton.propTypes = {
  basePath: PropTypes.string,
};

SystemBankOverrideAddButton.defaultProps = {
  basePath: null,
};

export default SystemBankOverrideAddButton;
