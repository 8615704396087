import React, { Fragment, useState } from 'react';
import {
  Button,
  useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Field } from 'react-final-form';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';
import { TRANSACTION_METHOD } from 'finance/assets/js/constants';

const RunRemoteFeesETLButton = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(false);
  const notify = useNotify();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const command = 'etl/remote-fees';
  const title = 'Download remote fees analysis for all transactions';

  const handleConfirm = async (values) => {
    const { startDate, endDate, method } = values;
    const url = adminCommandApiUrl(command, {
      startDate,
      endDate,
      method,
    });
    setLoading(true);
    try {
      const res = await httpClient(url, { method: 'GET' });
      setDownloadId(res.json.downloadId);
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-outline-dark m-1"
        target="_blank"
        onClick={handleClick}
      >
        <strong>EXPORT REMOTE FEES FOR ALL TRANSACTIONS</strong>
      </button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {downloadId ? (
            <Link to={`/downloads/${downloadId}/show`}>See your download</Link>
          ) : (
            <Form
              initialValues={{ usingOrgCurrency: '1' }}
              onSubmit={handleConfirm}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="startDate">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>From date</label>
                          <br />
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="endDate">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>To date</label>
                          <br />
                          <input
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                          />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="method" component="select">
                      {({ input, meta }) => (
                        <div style={{ margin: '0 0 20px 0' }}>
                          <label>Method</label>
                          <br />
                          <select
                            style={{
                              padding: '10px',
                              width: '300px',
                            }}
                            {...input}
                            type="date"
                            required
                          >
                            <option value={undefined}>All</option>
                            <option value={TRANSACTION_METHOD.WORLDPAY}>Worldpay</option>
                            <option value={TRANSACTION_METHOD.TRANSFERWISE}>Wise</option>
                            <option value={TRANSACTION_METHOD.PAYONEER}>Payoneer</option>
                          </select>
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Button variant="contained" disabled={loading} label="confirm" type="submit" />
                    <Button label="cancel" type="button" onClick={handleDialogClose} />
                  </div>
                </form>
              )}
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

RunRemoteFeesETLButton.propTypes = {};

RunRemoteFeesETLButton.defaultProps = {};

export default RunRemoteFeesETLButton;
