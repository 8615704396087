import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import { SETTINGS_TABS, SETTINGS_TABS_LABEL } from 'admin/assets/js/constants';
import GeneralSettings from 'admin/assets/js/components/Settings.jsx';
import PayerMappingsList from 'admin/assets/js/resources/payer_mappings/list.jsx';

const CustomSettingsGeneralView = (props) => {
  const { selectedTab } = props;
  const history = useHistory();

  const handleChange = useCallback((event, newValue) => {
    history.push(newValue);
  }, [history]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
      >
        { Object.keys(SETTINGS_TABS).map(key => (
          <Tab
            key={SETTINGS_TABS[key]}
            value={SETTINGS_TABS[key]}
            label={SETTINGS_TABS_LABEL[SETTINGS_TABS[key]]}
          />
        ))}
      </Tabs>
      { selectedTab === SETTINGS_TABS.GENERAL && (
        <GeneralSettings />
      )}
      { selectedTab === SETTINGS_TABS.MAPPING && (
        <PayerMappingsList
          resource="payer_mappings"
          basePath="/payer_mappings"
        />
      )}
    </>
  );
};

CustomSettingsGeneralView.propTypes = {
  selectedTab: PropTypes.string,
};

CustomSettingsGeneralView.defaultProps = {
  selectedTab: SETTINGS_TABS.GENERAL,
};

export default CustomSettingsGeneralView;
