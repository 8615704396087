import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import PropTypes from 'prop-types';

const SystemBankAccountCurrenciesList = props => (
  <List
    {...props}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source="id"
      />
    </Datagrid>
  </List>
);

SystemBankAccountCurrenciesList.propTypes = {
  basePath: PropTypes.string,
};

SystemBankAccountCurrenciesList.defaultProps = {
  basePath: null,
};

export default SystemBankAccountCurrenciesList;
