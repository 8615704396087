import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import {
  Button,
  useRefresh,
  useNotify,
} from 'react-admin';
import httpClient from 'admin/assets/js/lib/httpClient';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { MenuItem } from '@material-ui/core';

import { adminCommandApiUrl } from 'admin/urls';

const SendInvoiceDigestButton = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSendClick = async (values) => {
    const command = 'invoices-digest';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST', body: JSON.stringify({ ...values, orgId: record.id }) });
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        Send invoice digest
      </MenuItem>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Send invoice digest"
      >
        <DialogTitle>Send invoice digest</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleSendClick}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="billingDate">
                    {({ input, meta }) => (
                      <div style={{ margin: '0 0 20px 0' }}>
                        <div>
                          <p className="mb-2">
                            {`An email with the list of all ${record.invoicing_mode.toUpperCase()} invoices will be sent to the
                              following recipients of "${record.name}":`}
                          </p>
                          <ul>
                            <li>Financial Controller (or Organization Owner)</li>
                            <li>Financial Department</li>
                          </ul>
                          <p>Are you sure you want to create and send this notification?</p>
                        </div>
                        <label>Billing date</label>
                        <br />
                        <input
                          style={{
                            padding: '10px',
                            width: '300px',
                          }}
                          {...input}
                          type="date"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Button variant="contained" disabled={loading} label="notify" type="submit" />
                  <Button label="cancel" type="button" onClick={handleCloseClick} />
                </div>
              </form>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

SendInvoiceDigestButton.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
};

SendInvoiceDigestButton.defaultProps = {
  record: {},
};

export default SendInvoiceDigestButton;
