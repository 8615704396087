import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { DEPLOYMENTS_STATUS } from 'admin/assets/js/constants';
import useFetchDeploymentsStatus from 'admin/assets/js/components/hooks/useFetchDeploymentStatus.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    '& .MuiPaper-root': {
      justifyContent: 'center',
    },
  },
}));

const DeploymentsStatusBanner = () => {
  const classes = useStyles();
  const { deploymentsStatus } = useFetchDeploymentsStatus();

  if (!deploymentsStatus || deploymentsStatus === DEPLOYMENTS_STATUS.ENABLED) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Alert
        severity="error"
      >
        Deployments have been paused
      </Alert>
    </div>
  );
};

export default DeploymentsStatusBanner;
