import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';

import { AUDIT_LEVEL, AUDIT_LEVEL_LABELS, AUDIT_LEVEL_COLORS } from 'finance/assets/js/constants';

const useStyles = makeStyles({
  chip: {
    color: 'white',
  },
});

const AuditLevel = ({ level }) => {
  const classes = useStyles();
  return (
    <Chip
      size="small"
      label={AUDIT_LEVEL_LABELS[level]}
      className={classes.chip}
      style={{ backgroundColor: AUDIT_LEVEL_COLORS[level] }}
    />
  );
};

AuditLevel.propTypes = {
  level: PropTypes.number,
};

AuditLevel.defaultProps = {
  level: AUDIT_LEVEL.PASS,
};

export default AuditLevel;
