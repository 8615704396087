import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';

import BannerForm from 'admin/assets/js/components/BannerForm.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { adminApiBannerUrl, adminApiBannerEditUrl } from 'admin/urls';
import { API_DATE_FORMAT } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';

export const BannerEditView = ({ history, match: { params: { id } } }) => {
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(null);
  const notifications = getNotifications();

  useEffect(() => {
    apiClient({ url: adminApiBannerUrl(id) })
      .then(response => {
        setBanner(response.data);
      })
      .catch(e => {
        notifications.error(e.message);
      });
  }, []);

  const goBack = () => history.push('/banners');
  const onEdit = async values => {
    setLoading(true);
    try {
      const finalValues = {
        ...pick(
          values,
          'title',
          'button_label',
          'button_url',
          'content',
          'invoicing_modes',
          'dismissable',
          'organizations',
          'start_date',
          'type',
        ),
        show_after_dismissed: values.dismissable && values.show_after_dismissed,
        end_date: values.definite === 'yes' ? values.end_date : null,
        user_roles: values.user_roles.map(type => parseInt(type, 10)),
      };
      await apiClient({ data: finalValues, method: 'PUT', url: adminApiBannerEditUrl(id) });
      goBack();
    } catch (e) {
      notifications.error(e._error || e.message);
    } finally {
      setLoading(false);
    }
  };

  if (!banner) {
    return null;
  }

  return (
    <BannerForm
      initialValues={{
        ...banner,
        definite: banner.end_date ? 'yes' : 'no',
        end_date: banner.end_date ? formatDate(banner.end_date, API_DATE_FORMAT) : null,
        organizations: banner.all_organizations ? 0 : banner.organizations,
        start_date: formatDate(banner.start_date, API_DATE_FORMAT),
        user_roles: banner.user_roles.map(type => type.toString()),
      }}
      loading={loading}
      onCancel={goBack}
      onSubmit={onEdit}
      submitButtonText="Update"
    />
  );
};

BannerEditView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default BannerEditView;
