import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const NotificationsContext = React.createContext(null);
NotificationsContext.displayName = 'NotificationsContext';

const NOTIFICATION_TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const NotificationsProvider = ({ children }) => {
  const [text, setText] = useState(null);
  const [type, setType] = useState(null);
  const [timeoutMS, setTimeoutMS] = useState(null);

  const clear = () => {
    setText(null);
    setType(null);
  };

  const triggerNotification = (thisType, thisText, thisTimeoutMS = 8000) => {
    setType(thisType);
    setText(thisText);
    setTimeoutMS(thisTimeoutMS);
  };

  const contextValue = useMemo(
    () => ({
      clear,
      error: (thisText, thisTimeoutMS = null) => {
        triggerNotification(NOTIFICATION_TYPES.ERROR, thisText, thisTimeoutMS);
      },
      info: (thisText, thisTimeoutMS) => {
        triggerNotification(NOTIFICATION_TYPES.INFO, thisText, thisTimeoutMS);
      },
      success: (thisText, thisTimeoutMS) => {
        triggerNotification(NOTIFICATION_TYPES.SUCCESS, thisText, thisTimeoutMS);
      },
      warning: (thisText, thisTimeoutMS) => {
        triggerNotification(NOTIFICATION_TYPES.WARNING, thisText, thisTimeoutMS);
      },
      text,
      timeoutMS,
      type,
    }),
    [text, timeoutMS, type],
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
};

export const getNotifications = () => {
  const notifications = useContext(NotificationsContext);
  return notifications;
};

export const ShowNotifications = () => {
  const { clear, text, timeoutMS, type } = getNotifications();
  if (!text || !type) {
    return null;
  }
  return (
    <Snackbar autoHideDuration={timeoutMS} onClose={clear} open>
      <Alert onClose={clear} severity={type} variant="filled">{text}</Alert>
    </Snackbar>
  );
};
