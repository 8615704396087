import React, { useState } from 'react';
import { useNotify } from 'react-admin';

import { adminCommandApiUrl } from 'admin/urls';
import httpClient from 'admin/assets/js/lib/httpClient';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';

const ReCalculateServiceOrderFundsButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const label = 'Reset not enough funds service orders';

  const onConfirm = async () => {
    const command = 'reset-out-of-funds-service-orders';
    const url = adminCommandApiUrl(command);
    setLoading(true);
    try {
      await httpClient(url, { method: 'POST' });
      notify('Service orders status re-calculation started');
      setModalOpen(false);
    } catch (e) {
      notify('Recalculating failed', 'warning');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => setModalOpen(true)}
      >
        <strong>{label?.toUpperCase()}</strong>
      </a>
      <ConfirmModal
        confirm="Ok"
        content={(
          <>
            <p>
              This action will set the status to &quot;Submitted&quot; for any SO that
              might got stuck into &quot;insufficient funds&quot; status.
            </p>

            <p>
              It will go through all service orders with status &quot;insufficient funds&quot; and
              reset status to &quot;Submitted&quot; if enough funds are available in the project.
            </p>

            <p>
              <b>Proceed with caution!</b>
            </p>

            <p>
              It will run in the background and might take several minutes to complete.
            </p>
          </>
        )}
        disableCtas={loading}
        onClose={() => setModalOpen(false)}
        onConfirm={onConfirm}
        open={modalOpen}
        title={label}
      />
    </>
  );
};

export default ReCalculateServiceOrderFundsButton;
