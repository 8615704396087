import {
  DOCUMENT_CONTRACT_TYPE_LABEL,
  DOCUMENT_CONTRACT_TYPE_LABEL_WARNING,
  DOCUMENT_CONTRACT_TYPE,
} from './assets/js/constants';

export * from 'documents/assets/js/constants';

export const TEST_DOCUMENT_CONTRACT_TYPE_LABEL = {
  [DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]:
  DOCUMENT_CONTRACT_TYPE_LABEL[DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]
  + DOCUMENT_CONTRACT_TYPE_LABEL_WARNING[DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH],
  [DOCUMENT_CONTRACT_TYPE.ESIGN]:
  DOCUMENT_CONTRACT_TYPE_LABEL[DOCUMENT_CONTRACT_TYPE.ESIGN]
  + DOCUMENT_CONTRACT_TYPE_LABEL_WARNING[DOCUMENT_CONTRACT_TYPE.ESIGN],
};
