import PropTypes from 'prop-types';
import React from 'react';

const ScoreBindingPreview = ({ className, max, min, riskHighThreshold, riskModerateThreshold }) => {
  const classes = ['classification-questionnaire-score-binding-preview', 'd-flex'];
  if (className) {
    classes.push(className);
  }
  return (
    <div className={classes.join(' ')}>
      <div className="high">
        <div className="text-center py-2">HIGH</div>
        <div className="bar" />
        <div className="d-flex justify-content-between mt-1">
          <div>{min}</div>
          <div className="pr-1">{riskHighThreshold}</div>
        </div>
      </div>
      <div className="moderate">
        <div className="text-center py-2">MODERATE</div>
        <div className="bar" />
        <div className="d-flex justify-content-between mt-1">
          <div className="pl-1">{riskHighThreshold + 1}</div>
          <div className="pr-1">{riskModerateThreshold}</div>
        </div>
      </div>
      <div className="low">
        <div className="text-center py-2">LOW</div>
        <div className="bar" />
        <div className="d-flex justify-content-between mt-1">
          <div className="pl-1">{riskModerateThreshold + 1}</div>
          <div>{max}</div>
        </div>
      </div>
    </div>
  );
};

ScoreBindingPreview.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  riskHighThreshold: PropTypes.number.isRequired,
  riskModerateThreshold: PropTypes.number.isRequired,
};

ScoreBindingPreview.defaultProps = {
  className: null,
};

export default ScoreBindingPreview;
