import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import BulkApiActionSwitch from 'admin/assets/js/components/switch/BulkApiActionSwitch.jsx';
import {
  CODAT_INTEGRATIONS_STATUS, CODAT_INTEGRATIONS_STATUS_LABEL,
} from 'admin/assets/js/constants';
import httpClient from 'admin/assets/js/lib/httpClient';
import { adminApiAORTemplatesUrl } from 'admin/urls';
import { SIGNABLE } from 'core/assets/js/config/settings';

const { defaultAOROnboardingTemplateId } = SIGNABLE;

const codatIntegrationsOptions = Object.values(CODAT_INTEGRATIONS_STATUS).map(value => ({
  label: CODAT_INTEGRATIONS_STATUS_LABEL[value], value,
}));

const RESOURCE = 'organizations';

const FeaturesTab = ({ organization }) => {
  const notify = useNotify();

  const {
    all_contractors_are_aor: allContractorsAreAOR,
    aml_onboarding_enabled: amlOnboardingEnabled,
    aor_classification_questionnaire_enabled: aorClassificationQuestionnaireEnabled,
    aor_onboarding_invitations_enabled: aorOnboardingInvitationsEnabled,
    aor_onboarding_template: aorOnboardingTemplate,
    api_access_enabled: apiAccessEnabled,
    bulk_import_enabled: bulkImportEnabled,
    bulk_import_users_enabled: bulkImportUsersEnabled,
    can_customize_notifications: canCustomizeNotifications,
    can_enable_invoice_caps: canEnableInvoiceCaps,
    can_enable_us_tax_number_validation: canEnableUSTaxNumberValidation,
    codat_integrations_status: codatIntegrationsStatus,
    documents_enabled: documentsEnabled,
    documents_with_countersigning_enabled: documentsWithCountersigningEnabled,
    documents_with_esign_enabled: documentsWithEsignEnabled,
    higher_managers_can_see_providers_payment_details: higherManagersCanSeeProvidersPaymentDetails,
    id,
    integrations_enabled: integrationsEnabled,
    name,
    payment_status_widget_enabled: paymentStatusWidgetEnabled,
    project_opportunities_enabled: projectOpportunitiesEnabled,
    roles_enabled: rolesEnabled,
    '1099_filings_enabled': _1099FilingsEnabled,
    task_managers_enabled: taskManagersEnabled,
    us_tax_number_validation_enabled: usTaxNumberValidationEnabled,
    allow_deferred_contractor_payments: deferredContractorPaymentsEnabled,
  } = organization;
  const getStatus = (status => (
    status ? 'deactivate' : 'activate'
  ));

  const projectOpportunitiesStatus = getStatus(projectOpportunitiesEnabled);
  const apiAccessStatus = getStatus(apiAccessEnabled);
  const documentsStatus = getStatus(documentsEnabled);
  const integrationsEnabledStatus = getStatus(integrationsEnabled);
  const higherManagersCanSeeProvidersPaymentDetailsStatus = getStatus(
    higherManagersCanSeeProvidersPaymentDetails,
  );
  const rolesEnabledStatus = getStatus(rolesEnabled);
  const bulkImportEnabledStatus = getStatus(bulkImportEnabled);

  const [aorOnboardingTemplateOptions, setAOROnboardingTemplateOptions] = useState([]);

  useEffect(() => {
    if (!aorOnboardingInvitationsEnabled) {
      return;
    }
    httpClient(adminApiAORTemplatesUrl(), { method: 'GET' })
      .then(({ json }) => {
        setAOROnboardingTemplateOptions(json);
      })
      .catch(error => {
        notify(error.message, 'warning');
      });
  }, [aorOnboardingInvitationsEnabled]);

  return (
    <div className="organization_view_tab">
      <Card className="organization_view_tab__card">
        <CardHeader
          className="organization_view_tab__card__header"
          title={`${name} Features`}
        />

        <CardContent className="organization_view_tab__card__content p-0">
          <BulkApiActionSwitch
            action="toggle-api-access"
            content={`
              Are you sure you want to ${apiAccessStatus} API access for this organization?
            `}
            ids={id}
            label="API Access"
            resource={RESOURCE}
            title="API Access"
            value={apiAccessEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-documents-enabled"
            content={`
              Are you sure you want to ${documentsStatus} documents for this organization?
            `}
            ids={id}
            label="Contract Templates"
            resource={RESOURCE}
            title="Contract Templates"
            value={documentsEnabled}
          />
          {documentsEnabled && (
            <>
              <BulkApiActionSwitch
                action="toggle-documents-with-esign-enabled"
                content={`
                  Are you sure you want to ${getStatus(documentsWithEsignEnabled)} contract templates with
                  esign for this organization?
                `}
                ids={id}
                label="Contract Templates with esign"
                resource={RESOURCE}
                title="Contract Templates with esign"
                value={documentsWithEsignEnabled}
              />
              {documentsWithEsignEnabled && (
                <BulkApiActionSwitch
                  action="toggle-documents-with-countersigning-enabled"
                  content={`
                    Are you sure you want to ${getStatus(documentsWithCountersigningEnabled)}
                    countersigning with esign contract templates for this organization?
                  `}
                  ids={id}
                  label="E-sign Contract Templates with countersigning"
                  resource={RESOURCE}
                  title="E-sign Contract Templates with countersigning"
                  value={documentsWithCountersigningEnabled}
                />
              )}
            </>
          )}
          <BulkApiActionSwitch
            action="toggle-integrations-enabled"
            content={`
              Are you sure you want to ${integrationsEnabledStatus} integrations for this
              organization?
            `}
            ids={id}
            label="Integrations"
            resource={RESOURCE}
            title="Integrations"
            value={integrationsEnabled}
          />
          {integrationsEnabled && (
            <BulkApiActionSwitch
              action="change-codat-integrations-status"
              content={`
                Are you sure you want to change Codat integrations to "{value}", for
                this organization?
              `}
              ids={id}
              label="Codat integrations"
              options={codatIntegrationsOptions}
              resource={RESOURCE}
              title="Codat integrations"
              value={codatIntegrationsStatus}
            />
          )}
          <BulkApiActionSwitch
            action="toggle-project-opportunities"
            content={`
              Are you sure you want to ${projectOpportunitiesStatus}
              project opportunities for this organization?
            `}
            ids={id}
            label="Project Opportunities"
            resource={RESOURCE}
            title="Project Opportunities"
            value={projectOpportunitiesEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-higher-managers-can-see-providers-payment-details"
            content={`
              Are you sure you want to ${higherManagersCanSeeProvidersPaymentDetailsStatus}
              higher managers being able to see providers' payment details for this organization?
            `}
            ids={id}
            label="Higher managers can see providers' payment details"
            resource={RESOURCE}
            title="Higher managers can see providers' payment details"
            value={higherManagersCanSeeProvidersPaymentDetails}
          />
          <BulkApiActionSwitch
            action="toggle-roles"
            content={`
              Are you sure you want to ${rolesEnabledStatus} roles & permissions for this
              organization?
            `}
            ids={id}
            label="Roles & permissions"
            resource={RESOURCE}
            title="Roles & permissions"
            value={rolesEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-bulk-import"
            content={`
              Are you sure you want to ${bulkImportEnabledStatus} bulk imports for this
              organization?
            `}
            ids={id}
            label="Bulk import"
            resource={RESOURCE}
            title="Bulk import"
            value={bulkImportEnabled}
          />
          {bulkImportEnabled && (
            <BulkApiActionSwitch
              action="toggle-bulk-import-users"
              content={`
                Are you sure you want to ${getStatus(bulkImportUsersEnabled)} bulk import users for
                this organization?
              `}
              ids={id}
              label="Bulk import users"
              resource={RESOURCE}
              title="Bulk import users"
              value={bulkImportUsersEnabled}
            />
          )}
          <BulkApiActionSwitch
            action="toggle-payment-status-widget-enabled"
            content={`
              Are you sure you want to ${getStatus(paymentStatusWidgetEnabled)} the payment status
              widget for this organization?
            `}
            ids={id}
            label="Payment status widget"
            resource={RESOURCE}
            title="Payment status widget"
            value={paymentStatusWidgetEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-aor-onboarding-invitations"
            content={(
              <>
                <p>
                  {`Are you sure you want to ${getStatus(aorOnboardingInvitationsEnabled)} AOR `}
                  onboarding invitations for this organization?
                </p>
                {!aorOnboardingInvitationsEnabled && (
                  <>
                    <p>
                      All invited contractors with AOR selected will also be required to complete an
                      identity check.
                    </p>
                    <p>
                      Please remember to set the organization&apos;s AOR pricing, in Payments &gt;
                      Pricing &gt; Org &gt; Licence fees.
                    </p>
                  </>
                )}
              </>
            )}
            ids={id}
            label="AOR onboarding invitations"
            resource={RESOURCE}
            sublabel={!aorOnboardingInvitationsEnabled ? null : `
              Please remember to set the organization's AOR pricing, in Payments > Pricing > Org
              > Licence fees.
            `}
            title="AOR onboarding invitations"
            value={aorOnboardingInvitationsEnabled}
          />
          {aorOnboardingInvitationsEnabled && (
            <>
              <BulkApiActionSwitch
                action="change-aor-onboarding-template"
                content={`
                  Are you sure you want to change the AOR onboarding template to "{value}", for
                  this organization?
                `}
                ids={id}
                label="AOR onboarding template"
                options={aorOnboardingTemplateOptions}
                resource={RESOURCE}
                title="AOR onboarding template"
                value={aorOnboardingTemplate || defaultAOROnboardingTemplateId}
              />
              <BulkApiActionSwitch
                action="toggle-all-contractors-are-aor"
                content={`
                  Are you sure you want to ${getStatus(allContractorsAreAOR)} all contractors being
                  AOR when invited to this organization?
                `}
                ids={id}
                label="100% AOR contractors"
                resource={RESOURCE}
                title="100% AOR contractors"
                value={allContractorsAreAOR}
              />
            </>
          )}
          <BulkApiActionSwitch
            action="toggle-aml-onboarding"
            content={`
              Are you sure you want to ${getStatus(amlOnboardingEnabled)} anti-money laundering
              onboarding for this organization? This will require all invited contractors to
              complete an identity check.
            `}
            ids={id}
            label="Identity check onboarding"
            resource={RESOURCE}
            title="Identity check onboarding"
            value={amlOnboardingEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-can-enable-us-tax-number-validation"
            content={`
              Are you sure you want to ${getStatus(canEnableUSTaxNumberValidation)} the org owner
              being able to enable US tax number validation for this organization?
            `}
            ids={id}
            label="Can enable US tax number validation"
            resource={RESOURCE}
            title="Can enable US tax number validation"
            value={canEnableUSTaxNumberValidation}
          />
          <BulkApiActionSwitch
            action="toggle-1099-filings-enabled"
            content={`
              Are you sure you want to ${getStatus(_1099FilingsEnabled)} 1099 filings
              for users with the right permission for this organization?
            `}
            disabled={!usTaxNumberValidationEnabled}
            ids={id}
            label="1099 filings"
            resource={RESOURCE}
            sublabel={(
              usTaxNumberValidationEnabled
                ? ''
                : 'This organization must have US tax number validation enabled, to use this feature'
            )}
            title="1099 filings"
            value={_1099FilingsEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-invoice-caps"
            content={`
              Are you sure you want to ${getStatus(canEnableInvoiceCaps)} the ability for this
              organization to enable invoice caps?
            `}
            ids={id}
            label="Invoice caps"
            resource={RESOURCE}
            title="Invoice caps"
            value={canEnableInvoiceCaps}
          />
          <BulkApiActionSwitch
            action="toggle-task-managers"
            content={`
              Are you sure you want to ${getStatus(taskManagersEnabled)} task managers for this
              organization?
            `}
            ids={id}
            label="Task managers"
            resource={RESOURCE}
            title="Task managers"
            value={taskManagersEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-aor-classification-questionnaire"
            content={`
              Are you sure you want to ${getStatus(aorClassificationQuestionnaireEnabled)} the AOR
              classification questionnaire for this organization?
            `}
            ids={id}
            label="AOR classification questionnaire"
            resource={RESOURCE}
            title="AOR classification questionnaire"
            value={aorClassificationQuestionnaireEnabled}
          />
          <BulkApiActionSwitch
            action="toggle-customizable-notifications"
            content={`
              Are you sure you want to ${getStatus(canCustomizeNotifications)} customizable
              notifications for this organization?
            `}
            ids={id}
            label="Customizable notifications"
            resource={RESOURCE}
            title="Customizable notifications"
            value={canCustomizeNotifications}
          />
          <BulkApiActionSwitch
            action="toggle-deferred-contractor-payments"
            content={`
              Are you sure you want to ${getStatus(deferredContractorPaymentsEnabled)} deferred
              contractor payments for this organization?
            `}
            ids={id}
            label="Deferred contractor payments"
            resource={RESOURCE}
            title="Deferred contractor payments"
            value={deferredContractorPaymentsEnabled}
          />
        </CardContent>
      </Card>
    </div>
  );
};

FeaturesTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default FeaturesTab;

