import React from 'react';
import PropTypes from 'prop-types';

const EmptyShowLayout = ({ children }) => (
  <>
    {children}
  </>
);

EmptyShowLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EmptyShowLayout.defaultProps = {
  children: [],
};

export default EmptyShowLayout;
