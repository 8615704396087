import React from 'react';
import { CURRENCY, CURRENCY_LABEL } from 'core/assets/js/constants';
import { SelectInput, ReferenceInput, Filter } from 'react-admin';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const currencies = Object.values(CURRENCY).map(c => ({
  id: c, name: CURRENCY_LABEL[c],
}));


const ListFilter = props => (
  <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        allowEmpty
        label="Organization"
        source="organization_id"
        reference="organizations_lite"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ status: ORGANIZATION_STATUS.ACTIVE }}
      >
        <SelectInput
          optionText={org => `${org.name}
           (${org.unique_alias})`}
        />
      </ReferenceInput>
      <SelectInput
        label="Currency"
        alwaysOn
        allowEmpty
        source="currency"
        choices={currencies}
      />
      <ReferenceInput
        label="Invoicing Entity"
        source="invoicing_entity_id"
        reference="invoicing_entities"
        alwaysOn
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  </div>
);

export default ListFilter;
