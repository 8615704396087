import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { List, Datagrid, FunctionField, ReferenceField } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';

import InvoiceAmountsFieldPlain from 'admin/assets/js/resources/invoices/InvoiceAmountsFieldPlain.jsx';
import { PAYMENT_METHODS_LABEL } from 'settings/assets/js/constants';
import InvoicePartiesField from 'admin/assets/js/resources/invoices/InvoicePartiesField.jsx';
import Invoice from 'finance/assets/js/components/Invoice.jsx';
import { formatDate } from 'core/assets/js/lib/utils';

const PageHeader = ({ billingDeadline }) => {
  const formattedBillingDeadline = formatDate(billingDeadline);
  return (
    <h3>
      Previewing invoices for
      {' '}
      {formattedBillingDeadline}
    </h3>
  );
};

PageHeader.propTypes = {
  billingDeadline: PropTypes.string.isRequired,
};

const NextBillingInvoices = (props) => {
  const { location } = props;
  const parsedHistory = queryString.parse(location.search);
  const { billingDeadline } = JSON.parse(parsedHistory.filter);
  if (!billingDeadline) {
    return null;
  }
  const [invoiceModal, setInvoiceModal] = useState({ isOpen: false, data: null });
  const handleCloseInvoice = () => {
    setInvoiceModal({ isOpen: false, data: null });
  };
  return (
    <>
      { invoiceModal.isOpen && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={invoiceModal.isOpen}
          onClose={handleCloseInvoice}
        >
          <DialogTitle style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton className="ml-auto" onClick={handleCloseInvoice}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Invoice
              invoice={invoiceModal.data}
              ownerFE={invoiceModal.data.ownerFE}
              systemFE={invoiceModal.data.systemFE}
              recipientFE={invoiceModal.data.recipientFE}
              invoicingSettings={invoiceModal.data.invoicingSettings}
            />
          </DialogContent>
        </Dialog>
      )}
      <PageHeader billingDeadline={billingDeadline} />
      <List
        {...props}
        bulkActionButtons={false}
        pagination={false}
        actions={null}
      >
        <Datagrid>
          <ReferenceField
            label="Organization"
            source="orgId"
            reference="organizations"
            link="show"
          >
            <FunctionField
              render={_record => <div>{_record.name}</div>}
            />
          </ReferenceField>
          <FunctionField
            label="Number"
            render={_record => (
              <span>
                {_record.uniqueNumber === _record.number ? (
                  <React.Fragment>
                    {_record.number}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {_record.uniqueNumber}
                    <br />
                    (
                    {_record.number}
                    )
                  </React.Fragment>
                )}
                <br />
                <small className="discreet">
                  <span>
                    on
                    {' '}
                    {_record.date}
                    <br />
                    due at
                    {' '}
                    {_record.dueAt}
                  </span>
                </small>
              </span>
            )}
          />
          <InvoiceAmountsFieldPlain source="total" label="Amount" />
          <FunctionField
            source="paymentMethod"
            label="Payment Method"
            render={record => PAYMENT_METHODS_LABEL[record.paymentMethod]}
            sortable={false}
          />
          <InvoicePartiesField label="Parties" sortable={false} />
          <FunctionField
            render={_record => (
              <Button
                onClick={() => setInvoiceModal({ isOpen: true, data: _record })}
              >
                <VisibilityIcon color="primary" />
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

NextBillingInvoices.propTypes = {
  location: PropTypes.object.isRequired,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  NextBillingInvoices as List,
};
