import React from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';


const statusOptions = [{
  id: 'unallocated',
  name: 'Unallocated',
}, {
  id: 'unknown_payer',
  name: 'With unknown payer',
}, {
  id: 'partially_allocated',
  name: 'Partially allocated',
}, {
  id: 'fully_allocated',
  name: 'Fully allocated',
}, {
  id: 'ignored',
  name: 'Ignored',
}];

const ListFilter = props => (
  <Filter {...props}>
    <SelectInput required label="Status" alwaysOn source="status" choices={statusOptions} />
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export default ListFilter;
