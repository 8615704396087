import React from 'react';
import PropTypes from 'prop-types';
import {
  required,
  Filter,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import CustomTooltip from 'admin/assets/js/components/CustomToolTip.jsx';
import { MAX_ORGANIZATIONS_FETCHED } from 'admin/assets/js/constants';

/**
 * List filters for org_billing_analytics list view.
 * Allows for the selection of display currency, either system or org.
 * And the current org to show details of.
 * Both are required, hence the validation.
 * @param {*} props - component properties.
 * @return {JSX.Element} rendered component.
 */
const ListFilter = (props) => {
  const { filterValues: { showAllOrgs } } = props;
  return (
    <Filter
      {...props}
    >
      <ReferenceInput
        alwaysOn
        label="Organization"
        source="organization_id"
        reference="organizations_lite"
        perPage={MAX_ORGANIZATIONS_FETCHED}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ status: !showAllOrgs ? ORGANIZATION_STATUS.ACTIVE : undefined }}
      >
        <SelectInput
          optionText={org => `${org.name} (${org.unique_alias})`}
          validate={required()}
        />
      </ReferenceInput>
      <SelectInput
        alwaysOn
        source="currency"
        choices={[
          { id: 0, name: 'System' },
          { id: 1, name: 'Organization' },
        ]}
        validate={required()}
      />
      <BooleanInput
        alwaysOn
        source="showAllOrgs"
        label={(
          <div className="d-flex align-items-center justify-content-center">
            <span>Show all orgs</span>
            <CustomTooltip
              title="By enabling this filter it will include all organizations in the filter dropdown"
            />
          </div>
        )}
      />
    </Filter>
  );
};

ListFilter.propTypes = {
  filterValues: PropTypes.object,
};

ListFilter.defaultProps = {
  filterValues: {},
};

export default ListFilter;
