import React from 'react';
import {
  List, Datagrid,
  TextField,
  SimpleShowLayout,
  Show,
  ReferenceField,
} from 'react-admin';
import PreformattedField from 'admin/assets/js/components/fields/PreformattedField.jsx';
import InvoiceInfoField from 'admin/assets/js/resources/invoices/InvoiceInfoField.jsx';
import QuoteDetailsField from './QuoteDetailsField.jsx';

const TransferwiseQuoteDetails = props => (
  <SimpleShowLayout {...props}>
    <PreformattedField source="details_dump" />
  </SimpleShowLayout>
);

const TransferwiseQuoteShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="invoice_id"
        reference="invoices"
        link="edit"
      >
        <TextField source="uniqueNumber" />
      </ReferenceField>
      <QuoteDetailsField />
      <PreformattedField source="details_dump" />
    </SimpleShowLayout>
  </Show>
);

const TransferwiseQuoteList = props => (
  <List
    {...props}
    bulkActionButtons={false}
  >
    <Datagrid
      expand={<TransferwiseQuoteDetails />}
    >
      <TextField source="id" />
      <ReferenceField
        source="invoice_id"
        reference="invoices"
        link="edit"
      >
        <InvoiceInfoField />
      </ReferenceField>
    </Datagrid>
  </List>
);

export {
  TransferwiseQuoteList as List,
  TransferwiseQuoteShow as Show,
};
