import PropTypes from 'prop-types';
import React from 'react';
import {
  Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel,
} from '@material-ui/core';

const CheckboxGroupInput = ({
  containerClassName,
  disabled,
  error,
  helperText,
  horizontal,
  label: fieldLabel,
  onChange,
  options,
  required,
  value: initialValue,
}) => {
  const fieldValue = initialValue || [];
  return (
    <FormControl
      className={containerClassName}
      component="fieldset"
      error={error}
      required={required}
      variant="standard"
    >
      <FormLabel component="legend">{fieldLabel}</FormLabel>
      <FormGroup row={horizontal}>
        {options.map(({ label, value }) => (
          <FormControlLabel
            checked={fieldValue.includes(value)}
            control={<Checkbox disabled={disabled} name={value} />}
            key={value}
            label={label}
            onChange={event => {
              const newValue = [...fieldValue];
              if (!event.target.checked && newValue.includes(value)) {
                newValue.splice(newValue.indexOf(value), 1);
              }
              if (event.target.checked && !newValue.includes(value)) {
                newValue.push(value);
              }
              onChange({ target: { value: newValue } });
            }}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CheckboxGroupInput.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  horizontal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired, value: PropTypes.any.isRequired,
  })).isRequired,
  required: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

CheckboxGroupInput.defaultProps = {
  containerClassName: null,
  disabled: false,
  error: null,
  helperText: null,
  horizontal: false,
  required: false,
};

export default CheckboxGroupInput;
