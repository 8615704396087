import React from 'react';
import { Resource } from 'react-admin';
import Icon from '@material-ui/core/Icon';

export const resourcesSpec = {
  activity_records: {
    icon: () => React.createElement(Icon, {}, 'time'),
    title: 'Activity Records',
    module: () => require('admin/assets/js/resources/activity_records'),
  },
  background_jobs: {
    icon: () => React.createElement(Icon, {}, 'timelapse'),
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
    title: 'Background jobs',
  },
  banners: {
    icon: () => React.createElement(Icon, {}, 'info'),
    title: 'Banners',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  blocked_email_domains: {
    icon: () => React.createElement(Icon, {}, 'email'),
    title: 'Blocked email domains',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  classification_questionnaire: {
    icon: () => React.createElement(Icon, {}, 'rule'),
    title: 'Classification',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  codat_webhook_calls: {
    icon: () => React.createElement(Icon, {}, 'call_received'),
    module: () => require('admin/assets/js/resources/codat_webhook_calls/index.js'),
    title: 'Codat webhook calls',
  },
  reports: {
    icon: () => React.createElement(Icon, {}, 'equalizer_icon'),
    module: () => require('admin/assets/js/resources/reports.jsx'),
    title: 'Reports',
  },
  users: {
    icon: () => React.createElement(Icon, {}, 'people'),
    title: 'People',
    module: () => require('admin/assets/js/resources/users.jsx'),
  },
  full_users: {
    icon: () => React.createElement(Icon, {}, 'people'),
    title: 'Users',
    module: () => require('admin/assets/js/resources/full_users/index.js'),
  },
  user_cards: {
    icon: () => React.createElement(Icon, {}, 'people'),
    title: 'User cards',
    module: () => require('admin/assets/js/resources/user_cards/index.js'),
  },
  organizations: {
    icon: () => React.createElement(Icon, {}, 'business'),
    title: 'Organizations',
    module: () => require('admin/assets/js/resources/organizations'),
  },
  organizations_lite: {
    icon: () => React.createElement(Icon, {}, 'business'),
    title: 'Organizations',
    module: () => require('admin/assets/js/resources/organizations'),
  },
  pricings: {
    icon: () => React.createElement(Icon, {}, 'local_offer'),
    title: 'Pricing',
    module: () => require('admin/assets/js/resources/pricings'),
  },
  invoices: {
    icon: () => React.createElement(Icon, {}, 'business'),
    title: 'Invoices',
    module: () => require('admin/assets/js/resources/invoices'),
  },
  run_invoicing: {
    icon: () => React.createElement(Icon, {}, 'handyman'),
    title: 'Run invoicing',
    module: () => require('admin/assets/js/resources/run_invoicing'),
  },
  cashout_requests: {
    icon: () => React.createElement(Icon, {}, 'price_check'),
    title: 'Cash out requests',
    module: () => require('admin/assets/js/resources/cashout_requests/index.js'),
  },
  deferred_invoice_payments: {
    icon: () => React.createElement(Icon, {}, 'business'),
    title: 'Deferred Invoices',
    module: () => require('admin/assets/js/resources/deferred_invoice_payments/index'),
  },
  children_invoices: {
    icon: () => React.createElement(Icon, {}, 'business'),
    title: 'Invoices',
    module: () => require('admin/assets/js/resources/invoices'),
  },
  transactions: {
    icon: () => React.createElement(Icon, {}, 'payment'),
    title: 'Transactions',
    module: () => require('admin/assets/js/resources/transactions/transactions.jsx'),
  },
  transaction_groups: {
    icon: () => React.createElement(Icon, {}, 'payment'),
    title: 'Transaction Groups',
    module: () => require('admin/assets/js/resources/transaction_groups'),
  },
  org_billing_transactions: {
    icon: () => React.createElement(Icon, {}, 'payment'),
    title: 'Transactions per organization billing process',
    module: () => require('admin/assets/js/resources/orgBillingTransactions.jsx'),
  },
  invitations: {
    icon: () => React.createElement(Icon, {}, 'contact_mail'),
    title: 'Invitations',
    module: () => require('admin/assets/js/resources/invitations.jsx'),
  },
  service_orders: {
    icon: () => React.createElement(Icon, {}, 'description'),
    title: 'Billables',
    module: () => require('admin/assets/js/resources/billables.jsx'),
  },
  user_card_aor_contracts: {
    icon: () => React.createElement(Icon, {}, 'description'),
    title: 'AOR Contracts',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  notifications: {
    icon: () => React.createElement(Icon, {}, 'mail_outline'),
    title: 'Notifications',
    module: () => require('admin/assets/js/resources/notifications.jsx'),
  },
  notification_types: {
    icon: () => React.createElement(Icon, {}, 'mail_outline'),
    title: 'Notification types',
    module: () => require('admin/assets/js/resources/notification_types.jsx'),
  },
  dim_billing_processes: {
    icon: () => React.createElement(Icon, {}, 'event_note'),
    title: 'Billing Processes',
    module: () => require('admin/assets/js/resources/dimBillingProcesses.jsx'),
  },
  dim_payers: {
    icon: () => React.createElement(Icon, {}, 'account_box'),
    title: 'Payers',
    module: () => require('admin/assets/js/resources/dimPayers.jsx'),
  },
  next_billing_organizations: {
    icon: () => React.createElement(Icon, {}, 'event_note'),
    title: 'Next billing organizations',
    module: () => require('admin/assets/js/resources/nextBillingOrganizations.jsx'),
  },
  next_billing_invoices: {
    icon: () => React.createElement(Icon, {}, 'event_note'),
    title: 'Next billing invoices',
    module: () => require('admin/assets/js/resources/nextBillingInvoices.jsx'),
  },
  organization_billings: {
    icon: () => React.createElement(Icon, {}, 'receipt'),
    title: 'Organization billings',
    module: () => require('admin/assets/js/resources/organization-billings'),
  },
  subscriptions: {
    icon: () => React.createElement(Icon, {}, 'date_range'),
    title: 'Subscriptions',
    module: () => require('admin/assets/js/resources/subscriptions/subscriptions.jsx'),
  },
  bank_accounts: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Bank accounts',
    module: () => require('admin/assets/js/resources/bank_accounts.jsx'),
  },
  dim_transferwise_recipients: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Transferwise recipients',
    module: () => require('admin/assets/js/resources/dim_transferwise_recipients'),
  },
  dim_transferwise_transfers: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Transferwise recipients',
    module: () => require('admin/assets/js/resources/dim_transferwise_transfers'),
  },
  inbound_transfers: {
    icon: () => React.createElement(Icon, {}, 'add_link'),
    title: 'Reconciliation',
    module: () => require('admin/assets/js/resources/inbound_transfers'),
  },
  dim_transferwise_quotes: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Transferwise quotes',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  downloads: {
    icon: () => React.createElement(Icon, {}, 'download'),
    title: 'Downloads',
    module: () => require('admin/assets/js/resources/downloads/Downloads.jsx'),
  },
  settings: {
    icon: () => React.createElement(Icon, {}, 'settings'),
    title: 'Settings',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  licences: {
    icon: () => React.createElement(Icon, {}, 'local_activity'),
    title: 'Licences',
    module: () => require('admin/assets/js/resources/licences/Licences.jsx'),
  },
  dim_stripe_subscriptions: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Stripe subscriptions',
    module: () => require('admin/assets/js/resources/dim_stripe_subscriptions/DimStripeSubscriptions.js'),
  },
  pricing_scales: {
    icon: () => React.createElement(Icon, {}, 'clear_all'),
    title: 'Pricing scales',
    module: () => require('admin/assets/js/resources/pricing_scales/index.js'),
  },
  invoicing_entities: {
    icon: () => React.createElement(Icon, {}, 'add_business'),
    title: 'Invoicing Entities',
    module: () => require('admin/assets/js/resources/invoicing_entities/index.js'),
  },
  system_bank_accounts: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Sys Bank Accounts ',
    module: () => require('admin/assets/js/resources/system_bank_accounts/index.js'),
  },
  payer_mappings: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'Payer mappings',
    module: () => require('admin/assets/js/resources/payer_mappings/index.js'),
  },
  system_bank_account_overrides: {
    icon: () => React.createElement(Icon, {}, 'account_balance_wallet'),
    title: 'Sys BA Overrides',
    module: () => require('admin/assets/js/resources/system_bank_account_overrides/index.js'),
  },
  payment_methods: {
    icon: () => React.createElement(Icon, {}, 'payment'),
    title: 'Payment methods',
    module: () => require('admin/assets/js/resources/payment_methods/index.js'),
  },
  system_bank_account_currencies: {
    icon: () => React.createElement(Icon, {}, 'account_balance'),
    title: 'System Bank Account Currencies',
    module: () => require('admin/assets/js/resources/system_bank_account_currencies/index.js'),
  },
  org_billing_analytics: {
    icon: () => React.createElement(Icon, {}, 'attach_money'),
    title: 'Org Billing Analytics',
    module: () => require('admin/assets/js/resources/org_billing_analytics/OrgBillingAnalytics.js'),
  },
  org_billing_invoices_analytics: {
    icon: () => React.createElement(Icon, {}, 'attach_money'),
    title: 'Org Billing Invoices Analytics',
    module: () => require('admin/assets/js/resources/org_billing_invoices_analytics/OrgBillingInvoicesAnalytics.js'),
  },
  fact_audit_runs: {
    icon: () => React.createElement(Icon, {}, 'directions_run'),
    title: 'Fact Audit Runs',
    module: () => require('admin/assets/js/resources/fact_audit_runs/FactAuditRuns.js'),
  },
  fact_audit_logs: {
    icon: () => React.createElement(Icon, {}, 'summarize'),
    title: 'Fact Audit Logs',
    module: () => require('admin/assets/js/resources/fact_audit_logs'),
  },
  fact_audit_log_groups: {
    icon: () => React.createElement(Icon, {}, 'summarize'),
    title: 'Fact Audit Log Groups',
    module: () => require('admin/assets/js/resources/fact_audit_log_groups'),
  },
  user_aml_checks: {
    icon: () => React.createElement(Icon, {}, 'paid'),
    title: 'Identity checks',
    module: () => require('admin/assets/js/resources/dim_transferwise_quotes'),
  },
  team: {
    icon: () => React.createElement(Icon, {}, 'supervisor_account_icon'),
    title: 'Team',
  },
  integrations_availability: {
    icon: () => React.createElement(Icon, {}, 'tune'),
    title: 'Availability',
  },
  on_demand_payouts: {
    icon: () => React.createElement(Icon, {}, 'request_quote'),
    title: 'On-demand payouts',
    module: () => require('admin/assets/js/resources/on_demand_payouts/OnDemandPayouts.js'),
  },
};


const getResource = (name, spec) => {
  const module = spec.module ? spec.module() : {};

  const { title } = spec;
  const resourceName = spec.resourceName || spec.name;

  const props = {
    key: name,
    name,
    resourceName,
    list: module.List || undefined,
    create: module.Create || undefined,
    show: module.Show || undefined,
    edit: module.Edit || undefined,
    icon: module.Icon || undefined,
    options: { label: title },
  };
  return React.createElement(Resource, props);
};

const resources = Object.entries(resourcesSpec).map(([name, r]) => getResource(name, r));

export default resources;
