import { TRANSACTION_METHOD } from 'finance/assets/js/constants';
import TransactionMethodWireTransfer from 'finance/assets/js/lib/TransactionMethodWireTransfer';
import TransactionMethodTransferwise from 'finance/assets/js/lib/TransactionMethodTransferwise';
import TransactionMethodPayPal from 'finance/assets/js/lib/TransactionMethodPayPal';
import TransactionMethodPayoneer from 'finance/assets/js/lib/TransactionMethodPayoneer';
import TransactionMethodWorldpay from 'finance/assets/js/lib/TransactionMethodWorldpay';
import TransactionMethodRealNet from 'finance/assets/js/lib/TransactionMethodRealNet';
import TransactionMethodStripe from 'finance/assets/js/lib/TransactionMethodStripe';
import TransactionMethodRevolut from 'finance/assets/js/lib/TransactionMethodRevolut';

/**
 * Helper functions for transaction methods.
 */
class TransactionMethod {
  /**
   * Factory, returns the appropriate transaction method instance for the transaction details.
   * @param {object} transaction - transaction details.
   * @return {object} the transaction instance.
   */
  static createTransactionMethod(transaction) {
    switch (transaction.method) {
      case TRANSACTION_METHOD.WIRE_TRANSFER:
        return new TransactionMethodWireTransfer(transaction);
      case TRANSACTION_METHOD.TRANSFERWISE:
        return new TransactionMethodTransferwise(transaction);
      case TRANSACTION_METHOD.PAYPAL:
        return new TransactionMethodPayPal(transaction);
      case TRANSACTION_METHOD.PAYONEER:
        return new TransactionMethodPayoneer(transaction);
      case TRANSACTION_METHOD.WORLDPAY:
        return new TransactionMethodWorldpay(transaction);
      case TRANSACTION_METHOD.REALNET:
        return new TransactionMethodRealNet(transaction);
      case TRANSACTION_METHOD.STRIPE:
        return new TransactionMethodStripe(transaction);
      case TRANSACTION_METHOD.REVOLUT:
        return new TransactionMethodRevolut(transaction);
      default:
        throw new Error('Unhandled transaction method ', transaction.method);
    }
  }

  /**
   * Get a transaction descriptor for a given transaction.
   * @param {object} transaction - transaction to get descriptor for.
   * @param {TRANSACTION_METHOD} fallbackMethod - return generic descriptor for this method,
   * if the transaction method can't be deduced.
   *
   * @return {string} transaction descriptor.
   */
  static getTransactionDescriptorWithFallbackMethod(transaction, fallbackMethod) {
    try {
      return TransactionMethod
        .createTransactionMethod(transaction)
        .getTransactionDescriptor();
    } catch (e) {
      switch (fallbackMethod) {
        case TRANSACTION_METHOD.TRANSFERWISE: return '#Transferwise';
        case TRANSACTION_METHOD.WIRE_TRANSFER: return '#WireTransfer';
        case TRANSACTION_METHOD.PAYPAL: return '#PayPal';
        case TRANSACTION_METHOD.PAYONEER: return '#Payoneer';
        case TRANSACTION_METHOD.WORLDPAY: return '#Worldpay';
        case TRANSACTION_METHOD.REALNET: return '#RealNet';
        case TRANSACTION_METHOD.STRIPE: return '#Stripe';
        case TRANSACTION_METHOD.REVOLUT: return '#Revolut';
        default: return '#Unknown';
      }
    }
  }
}

export default TransactionMethod;
