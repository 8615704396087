import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Toolbar } from 'react-admin';

const PayerMappingsFormButtons = (props) => {
  const { dialogClose, handleSubmit, isLoadingOrganizations } = props;
  return (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'right',
        backgroundColor: 'transparent',
        borderTop: '1px solid lightgray',
      }}
    >
      <Button
        color="secondary"
        onClick={handleSubmit}
        size="large"
        disabled={isLoadingOrganizations}
      >
        Save
      </Button>
      <Button
        color="secondary"
        onClick={dialogClose}
        size="large"
      >
        Cancel
      </Button>
    </Toolbar>
  );
};

PayerMappingsFormButtons.propTypes = {
  dialogClose: PropTypes.func.isRequired,
  isLoadingOrganizations: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PayerMappingsFormButtons;
