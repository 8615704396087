import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
} from 'react-admin';

import ListFilter from 'admin/assets/js/resources/on_demand_payouts/components/ListFilter.jsx';
import OnDemandPayoutsListBulkActionButtons from 'admin/assets/js/resources/on_demand_payouts/components/OnDemandPayoutsListBulkActionButtons.jsx';
import {
  ON_DEMAND_PAYOUT_SEGMENT,
} from 'finance/assets/js/constants';
import TransactionDetailsField from 'admin/assets/js/resources/transactions/TransactionDetailsField.jsx';
import TransactionReviewerField from 'admin/assets/js/resources/transactions/TransactionReviewerField.jsx';
import Money from 'finance/assets/js/lib/Money';
import CashoutListInvoiceDetails from 'admin/assets/js/resources/on_demand_payouts/components/CashoutListInvoiceDetails.jsx';

const CashoutList = ({ history, location, match, permissions, basePath }) => {
  const qs = queryString.parse(location.search);
  const filterOptions = JSON.parse(qs.filter || '{}');
  const segment = filterOptions.segment || ON_DEMAND_PAYOUT_SEGMENT.PENDING_REVIEW;

  return (
    <List
      className="on-demand-payouts"
      title="On Demand Payouts Transaction Review"
      filters={<ListFilter />}
      filter={{
        segment,
      }}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={(
        [
          ON_DEMAND_PAYOUT_SEGMENT.PENDING_REVIEW,
          ON_DEMAND_PAYOUT_SEGMENT.PENDING_APPROVAL,
        ].includes(segment)
          ? (
            <OnDemandPayoutsListBulkActionButtons
              permissions={permissions}
              segment={segment}
            />
          ) : false)
      }
      resource="on_demand_payouts"
      history={history}
      location={location}
      match={match}
      basePath={basePath}
    >
      <Datagrid
        expand={(
          <div className="d-flex pt-1 on-demand-payouts__columns">
            <div className="d-flex flex-column on-demand-payouts__primary-column">
              <p className="on-demand-payouts__column-title">Payout details</p>
              <FunctionField
                render={record => (
                  <CashoutListInvoiceDetails record={record} />
                )}
              />
            </div>
            <div className="d-flex flex-column on-demand-payouts__primary-column">
              <p className="on-demand-payouts__column-title">Transaction details</p>
              <FunctionField
                render={record => (
                  <div className="on-demand-payouts__transactions">
                    <TransactionDetailsField record={record} label="Transaction details" readOnly />
                  </div>
                )}
              />
            </div>
            {[
              ON_DEMAND_PAYOUT_SEGMENT.PENDING_APPROVAL,
              ON_DEMAND_PAYOUT_SEGMENT.BEING_PROCESSED,
              ON_DEMAND_PAYOUT_SEGMENT.FINALIZED,
            ].includes(segment) && (
              <div className="d-flex flex-column on-demand-payouts__secondary-column">
                <p className="on-demand-payouts__column-title">Reviewed by</p>
                <FunctionField
                  render={record => (
                    <TransactionReviewerField record={record} label="Reviewed by" />
                  )}
                />
              </div>
            )}
          </div>
        )}
      >
        <TextField source="invoices[0].ownerFE.details.name" label="PROVIDER" />
        <FunctionField
          label="AMOUNT"
          render={record => `${new Money(record.amount, record.currency).toString({ humanizeAmount: true, withSymbol: true })}`}
        />
        <FunctionField
          label="INVOICES"
          render={record => record.invoices?.length || 0}
        />
      </Datagrid>
    </List>
  );
};

CashoutList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default CashoutList;
