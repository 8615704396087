/* eslint import/prefer-default-export: "off" */
import { isEqual } from 'lodash';
import { assertConvertsTo } from 'finance/assets/js/lib/utils';
import { assert } from 'finance/assets/js/lib/Assert';
import Money from 'finance/assets/js/lib/Money';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

export const parseSerializedTransactionInvoiceAmounts = (invoiceAmounts) => {
  const serializedTransactionInvoiceAmounts = invoiceAmounts instanceof InvoiceAmounts
    ? {
      ...invoiceAmounts.getInvoiceRateMap().serialize(),
      total: invoiceAmounts.getInvoicedMoney().toString(),
    }
    : invoiceAmounts;

  const {
    total, currency,
    targetCurrency, orgCurrency, balanceCurrency, systemCurrency,
    invoiceToTargetRate, invoiceToOrgRate, invoiceToBalanceRate, invoiceToSystemRate,
  } = serializedTransactionInvoiceAmounts;

  const rates = {
    [systemCurrency]: systemCurrency === currency ? 1.0 : parseFloat(invoiceToSystemRate),
    [targetCurrency]: targetCurrency === currency ? 1.0 : parseFloat(invoiceToTargetRate),
    [balanceCurrency]: balanceCurrency === currency ? 1.0 : parseFloat(invoiceToBalanceRate),
    [orgCurrency]: orgCurrency === currency ? 1.0 : parseFloat(invoiceToOrgRate),
  };

  const totalMoney = new Money(total, currency);
  return {
    total: totalMoney.toString(),
    currency: totalMoney.getCurrency(),
    targetCurrency, orgCurrency, balanceCurrency, systemCurrency,
    invoiceToTargetRate: rates[targetCurrency],
    invoiceToOrgRate: rates[orgCurrency],
    invoiceToBalanceRate: rates[balanceCurrency],
    invoiceToSystemRate: rates[systemCurrency],
  };
};

export const parseSerializedTransactionRates = (serializedTransactionRates) => {
  const {
    balanceToTargetRate,
    balanceToInvoiceRate,
    balanceToInvoiceTargetRate,
    balanceToOrgRate,
    balanceToSystemRate,
  } = serializedTransactionRates;

  return {
    balanceToTargetRate: parseFloat(balanceToTargetRate),
    balanceToInvoiceRate: parseFloat(balanceToInvoiceRate),
    balanceToInvoiceTargetRate: parseFloat(balanceToInvoiceTargetRate),
    balanceToOrgRate: parseFloat(balanceToOrgRate),
    balanceToSystemRate: parseFloat(balanceToSystemRate),
  };
};

export const auditTransactionAmounts = (transactionAmounts, {
  serializedTransactionInvoiceAmounts, rateMapAtInvoiceTime, invoicedMoney,
  transactionMode,
}) => {
  if (transactionAmounts.isZero()) {
    return;
  }

  // make sure that the original serialized version is same as the subsequent one
  assert(isEqual(
    parseSerializedTransactionInvoiceAmounts(serializedTransactionInvoiceAmounts),
    transactionAmounts.serialize().invoiceAmounts,
  ), 'transaction invoice amounts are not equal');

  const { action } = transactionAmounts.details;
  const { balanceToTargetRate } = transactionAmounts.getRates();

  const owedMoney = rateMapAtInvoiceTime.calculateOwedMoney({
    transactionMode,
    invoicedMoney,
  });

  const outgoingCurrency = action.getOutgoingCurrency();
  const netOutgoingAmount = action.getNetOutgoingAmount();
  const targetAmount = action.getTargetAmount();

  if (!rateMapAtInvoiceTime.hasRate(owedMoney.getCurrency(), outgoingCurrency)) {
    // we cannot assert the correctness of conversions at invoice time if we have not kept
    // the FX rates of the currencies used at transaction time. Notice, a transaction may
    // use any arbitrary currencies, even ones unknown at invoice time, therefore, it
    // can be the case that some times we may be missing them. This is not likely, but
    // exists as an edge case
    return;
  }

  assertConvertsTo(netOutgoingAmount, balanceToTargetRate, targetAmount);
};
