/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { adminApiStatusUrl } from 'admin/urls';
import httpClient from 'admin/assets/js/lib/httpClient';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    chipText: {
      fontSize: '14px',
    },
  },
});

const useStyles = makeStyles(t => ({
  card: {
    marginBottom: t.spacing(3),
  },
  chip: {
    marginRight: t.spacing(1),
    marginBottom: t.spacing(1),
  },
}));

export const styles = {
  successChip: {
    color: 'white',
    backgroundColor: 'green',
  },
  errorChip: {
    color: 'white',
    backgroundColor: 'red',
  },
  infoChip: {
    color: 'white',
    backgroundColor: 'orange',
  },
  title: {
    color: 'white',
    lineHeight: '18px',
    textColor: 'green',
    fontSize: '13px',
  },
};

const ServiceList = ({ services }) => {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <CardHeader title="Health check" />
        <CardContent>
          <Dialog onClose={() => setShowError(false)} open={showError}>
            <DialogTitle>{showError && services[showError].error.message}</DialogTitle>
            <DialogContent><pre>{showError && services[showError].error.stack}</pre></DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setShowError(false)}>Close</Button>
            </DialogActions>
          </Dialog>
          {Object.keys(services).map(name => {
            const { error, healthy } = services[name];

            const content = (
              <Chip
                className={classes.chip}
                clickable={!!error}
                icon={<Icon style={{ color: 'white' }}>{healthy ? 'check' : 'cancel'}</Icon>}
                key={name}
                label={<Typography style={styles.title}>{name}</Typography>}
                onClick={() => setShowError(!error || showError === name ? false : name)}
                size="small"
                style={styles[`${healthy ? 'success' : 'error'}Chip`]}
              />
            );

            if (error) {
              return <Tooltip key={name} title={error.message}>{content}</Tooltip>;
            }

            return content;
          })}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

class StatusCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
    };
    httpClient(adminApiStatusUrl())
      .then((response) => {
        this.setState({ status: response.json.status });
      });
  }

  render() {
    const { status } = this.state;
    if (!status) {
      return null;
    }
    return (
      <ServiceList services={status.services} />
    );
  }
}

export default StatusCard;
