import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';

import { INVOICE_STATUS_STYLES } from 'admin/assets/js/constants';
import { INVOICE_RAISED_STATUS_LABEL, INVOICE_STATUS, INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';

const useStyles = makeStyles({
  invoiceStatusLabel: {
    border: 'none',
    color: '#fff',
    marginBottom: '0px',
    minWidth: '100px',
  },
});

const InvoiceStatus = ({ status, raisedStatus, isOverdue }) => {
  const classes = useStyles();

  let label = status === INVOICE_STATUS.RAISED ? INVOICE_RAISED_STATUS_LABEL[raisedStatus]
    : INVOICE_STATUS_LABEL[status];
  if (isOverdue) {
    label = `${label} - Overdue`;
  }
  const backgroundColor = isOverdue ? '#DC3545' : (INVOICE_STATUS_STYLES[status] || 'lightgray');

  return (
    <Chip
      color="primary"
      className={classes.invoiceStatusLabel}
      label={label}
      size="medium"
      style={{ backgroundColor }}
      variant="outlined"
    />
  );
};

InvoiceStatus.propTypes = {
  status: PropTypes.number.isRequired,
  raisedStatus: PropTypes.string.isRequired,
  isOverdue: PropTypes.bool.isRequired,
};

export default InvoiceStatus;
