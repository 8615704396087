// in posts.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Datagrid, Filter,
  TextField,
  ReferenceInput, SelectInput,
  TextInput,
} from 'react-admin';

const Title = ({ record }) => (
  <span>{`Organisation ${record ? `"${record.name}"` : ''}`}</span>
);

Title.propTypes = {
  record: PropTypes.object,
};

Title.defaultProps = {
  record: {},
};

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <ReferenceInput alwaysOn allowEmpty label="Organization" source="organization_id" reference="organizations_lite">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const NotificationTypeList = props => (
  <List
    {...props}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" />
    </Datagrid>
  </List>
);

export {
  NotificationTypeList as List, //eslint-disable-line
};
