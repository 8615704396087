import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import {
  Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField,
} from '@material-ui/core';

import {
  CLASSIFICATION_SUBMISSION_EVENT_TYPE,
  CLASSIFICATION_SUBMISSION_EVENT_TYPE_LABEL,
  CLASSIFICATION_SUBMISSION_RISK,
  CLASSIFICATION_SUBMISSION_RISK_DESCRIPTIONS,
  CLASSIFICATION_SUBMISSION_RISK_LABELS,
} from 'accounts/assets/js/constants';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import ScoreBindingPreview from 'admin/assets/js/components/classification/ScoreBindingPreview.jsx';
import { CLASSIFICATION_TABS } from 'admin/assets/js/constants';
import apiClient from 'admin/assets/js/lib/apiClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import {
  calculateClassificationQuestionnaireScoreBinding,
} from 'admin/assets/js/lib/utils-non-react';
import { adminApiRootUrl, adminApiCreateClassificationSubmissionEventUrl } from 'admin/urls';
import { DATETIME_FORMAT_HUMAN_FRIENDLY } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';

const MODAL_PROPERTIES = {
  [CLASSIFICATION_SUBMISSION_EVENT_TYPE.PUBLISHED]: {
    cancelLabel: "No, don't",
    confirmLabel: 'Yes, publish it',
    title: 'Are you sure you want to publish this questionnaire response?',
  },
  [CLASSIFICATION_SUBMISSION_EVENT_TYPE.RESET]: {
    cancelLabel: "No, don't",
    confirmLabel: 'Yes, reset it',
    title: 'Are you sure you want to reset this questionnaire response?',
  },
  [CLASSIFICATION_SUBMISSION_EVENT_TYPE.NOTE]: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Save',
    title: 'Add note',
  },
};

const ClassificationSubmissionView = () => {
  const [submission, setSubmission] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [note, setNote] = useState('');
  const { id } = useParams();
  const notifications = getNotifications();
  const history = useHistory();

  const loadSubmission = async submissionId => {
    if (!submissionId) {
      return;
    }
    try {
      const { data } = await apiClient({
        url: `${adminApiRootUrl()}/classification_submissions/${submissionId}`,
      });
      setSubmission(data);
    } catch (e) {
      notifications.error(e.response?.data?._error || e.message);
    }
  };

  useEffect(() => {
    loadSubmission(id);
  }, [id]);

  if (!submission) {
    return null;
  }

  const {
    answers, events, organization, questionnaire, risk, score, user: { profile: { fullName } },
  } = submission;
  const { min, max } = calculateClassificationQuestionnaireScoreBinding(questionnaire.questions);

  const openModal = eventType => {
    setModalType(eventType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setModalType(null);
      setNote('');
    }, 500);
  };

  const submitEvent = async type => {
    try {
      await apiClient({
        data: { note, type },
        method: 'POST',
        url: adminApiCreateClassificationSubmissionEventUrl(id),
      });
      await loadSubmission(id);
      closeModal();
    } catch (e) {
      notifications.error(e.response?.data?._error || e.message);
    }
  };

  const noAnswers = answers.length === 0;

  return (
    <div className="d-flex flex-column classification-submission">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <BackButton />
          <h1 className="m-0">{fullName}</h1>
          {risk === CLASSIFICATION_SUBMISSION_RISK.HIGH && (
            <div className="action-required d-inline-block py-1 px-3 ml-3">Action required</div>
          )}
        </div>
        <div className="d-flex align-items-center">
          <p className="m-0 would-you-like">
            Would you like to view other user&apos;s questionnaires?
          </p>
          <Button
            className="ml-3"
            color="primary"
            onClick={() => {
              history.push(`/classification-questionnaire?tab=${CLASSIFICATION_TABS.RESPONSES}`);
            }}
            variant="outlined"
          >
            View all
          </Button>
          <Button
            className="mx-3"
            color="primary"
            disabled={noAnswers}
            onClick={() => openModal(CLASSIFICATION_SUBMISSION_EVENT_TYPE.RESET)}
            variant="contained"
          >
            Reset
          </Button>
          <Button
            className="publish-button"
            disabled={noAnswers || !!submission.published_at}
            onClick={() => openModal(CLASSIFICATION_SUBMISSION_EVENT_TYPE.PUBLISHED)}
            startIcon={<Icon>check</Icon>}
            variant="contained"
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="d-flex flex-column">
          <div>
            {'Organization: '}
            <Link to={`/organizations/${organization.id}/show`}>{organization.name}</Link>
          </div>
          <div>
            {noAnswers && `Date created: ${formatDate(submission.created_at)}`}
            {!noAnswers && `Date completed: ${formatDate(answers[0].created_at)}`}
          </div>
        </div>
        <ScoreBindingPreview
          max={max}
          min={min}
          riskHighThreshold={questionnaire.risk_high_threshold}
          riskModerateThreshold={questionnaire.risk_moderate_threshold}
        />
      </div>
      <Card>
        <table className="w-100">
          {risk && (
            <tr>
              <td className="p-3 font-weight-bold">
                <span>{CLASSIFICATION_SUBMISSION_RISK_DESCRIPTIONS[risk]}</span>
                <span className="float-right">Risk</span>
              </td>
              <td className={`${risk} p-3`}>{CLASSIFICATION_SUBMISSION_RISK_LABELS[risk]}</td>
              <td className={`${risk} p-3`}>{score}</td>
            </tr>
          )}
          <tr>
            <td className="p-3 font-weight-bold">Question</td>
            <td className="p-3 font-weight-bold">Answer</td>
            <td className="p-3 font-weight-bold">Score</td>
          </tr>
          {questionnaire.questions.map((q, questionIndex) => {
            const answer = answers.find(a => a.classification_questionnaire_question_id === q.id);
            const choice = answer && q.choices.find(c => (
              c.id === answer.classification_questionnaire_question_choice_id
            ));
            return (
              <tr key={q.id}>
                <td className={`p-3${choice && choice.score < 0 ? ' high' : ''}`}>
                  {`${questionIndex + 1}. ${q.label}`}
                </td>
                <td className="p-3">{choice?.label}</td>
                <td className="p-3">{choice?.score}</td>
              </tr>
            );
          })}
        </table>
      </Card>
      <Card className="mt-3 d-flex flex-column p-3 events">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="m-0">Notes</h2>
          <Button
            color="primary"
            onClick={() => openModal(CLASSIFICATION_SUBMISSION_EVENT_TYPE.NOTE)}
            startIcon={<Icon>comment</Icon>}
            variant="outlined"
          >
            Add a note
          </Button>
        </div>
        {events.map(event => {
          const classes = [
            'd-flex', 'event', 'align-items-center', 'justify-content-between', 'mt-3',
          ];
          classes.push(event.type);
          return (
            <div className={classes.join(' ')} key={event.id}>
              <div className="dot mr-3" />
              <div className="d-flex flex-column body p-3">
                <div className="d-flex flex-row">
                  {event.type !== CLASSIFICATION_SUBMISSION_EVENT_TYPE.NOTE && (
                    <div className="label mr-2 font-weight-bold">
                      {CLASSIFICATION_SUBMISSION_EVENT_TYPE_LABEL[event.type]}
                    </div>
                  )}
                  <div className="datetime">
                    {formatDate(event.created_at, DATETIME_FORMAT_HUMAN_FRIENDLY)}
                  </div>
                </div>
                <div className="mt-2">{event.text}</div>
              </div>
            </div>
          );
        })}
      </Card>
      <Dialog
        fullWidth
        keepMounted
        maxWidth="sm"
        onClose={closeModal}
        open={isModalOpen}
      >
        <DialogTitle>{MODAL_PROPERTIES[modalType]?.title}</DialogTitle>
        <DialogContent>
          {modalType === CLASSIFICATION_SUBMISSION_EVENT_TYPE.RESET && (
            <p>
              {`You are about to reset this questionnaire response, so ${fullName} can complete it again.`}
            </p>
          )}
          {modalType === CLASSIFICATION_SUBMISSION_EVENT_TYPE.PUBLISHED && (
            <p>
              {`You are about to publish ${fullName}'s questionnaire response so that `}
              {`${organization.name} can see it.`}
            </p>
          )}
          <TextField
            className="w-100"
            error={!note}
            helperText={!note && 'Please enter a note'}
            label="Note"
            multiline
            onChange={event => setNote(event.target.value)}
            placeholder="Add note"
            required
            rows={4}
            value={note}
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeModal}>
            {MODAL_PROPERTIES[modalType]?.cancelLabel}
          </Button>
          <Button color="primary" onClick={() => submitEvent(modalType)}>
            {MODAL_PROPERTIES[modalType]?.confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClassificationSubmissionView;
