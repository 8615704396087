import React from 'react';
import PropTypes from 'prop-types';

import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';
import { TAX_METHOD_LABEL } from 'finance/assets/js/constants';

const InvoiceSingleAmountField = ({ record }) => {
  const invoicingSettingsPOJO = new InvoicingSettings(record.invoicingSettings);
  const taxMethod = invoicingSettingsPOJO.getTaxMethod();
  return <span>{TAX_METHOD_LABEL[taxMethod]}</span>;
};

InvoiceSingleAmountField.propTypes = {
  record: PropTypes.object.isRequired,
};

export default InvoiceSingleAmountField;
