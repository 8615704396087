import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';

import PayerMappingForm from 'admin/assets/js/resources/payer_mappings/PayerMappingForm.jsx';

const useStyles = makeStyles({
  button: {
    '& span': {
      padding: '0 5px',
      textTransform: 'capitalize',
      color: '#000',
    },
  },
});

const EditButton = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDialogClose = useCallback(() => setOpen(false), [setOpen]);
  const handleDialogOpen = useCallback(() => setOpen(true), [setOpen]);

  return (
    <>
      <Button
        label="Edit"
        onClick={handleDialogOpen}
        className={classes.button}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleDialogClose}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            Map to an organization
            <IconButton className="ml-auto" onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <PayerMappingForm
            record={record}
            dialogClose={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

EditButton.propTypes = {
  record: PropTypes.object,
};

EditButton.defaultProps = {
  record: {},
};

export default EditButton;
