import qs from 'query-string';
import React from 'react';
import { Show } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';

import { USER_TABS } from 'admin/assets/js/constants';
import UserShowActions from 'admin/assets/js/resources/full_users/UserShowActions.jsx';
import UserInfo from 'admin/assets/js/resources/full_users/UserInfo.jsx';
import UserOrgInfoContainer from 'admin/assets/js/resources/full_users/UserOrgInfoContainer.jsx';
import UserNotes from 'admin/assets/js/resources/full_users/UserNotes.jsx';

const CustomShowLayout = props => {
  const history = useHistory();
  const location = useLocation();
  const queryStringParsed = qs.parse(location.search);
  const { tab = USER_TABS.INFO } = queryStringParsed;
  return (
    <>
      <Tabs
        className="mb-2"
        onChange={(event, newTab) => {
          const newQueryString = qs.stringify({ tab: newTab });
          history.push(`${location.pathname}?${newQueryString}`);
        }}
        value={tab}
      >
        <Tab label="Info" value={USER_TABS.INFO} />
        <Tab label="Notes" value={USER_TABS.NOTES} />
      </Tabs>
      {tab === USER_TABS.INFO && (
        <>
          <UserInfo {...props} />
          <UserOrgInfoContainer {...props} />
        </>
      )}
      {tab === USER_TABS.NOTES && <UserNotes {...props} />}
    </>
  );
};

export const UserShow = (props) => (
  <Show
    actions={<UserShowActions />}
    component="div"
    {...props}
  >
    <CustomShowLayout />
  </Show>
);

export default UserShow;
