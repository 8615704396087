import React, { useState } from 'react';
import { Button, useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const FixMissingSignNowDocumentsButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();

  const handleHideDialog = () => setShowDialog(false);

  return (
    <React.Fragment>
      <a
        className="btn btn-outline-dark m-1"
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => setShowDialog(true)}
      >
        <strong>FIX MISSING SIGNNOW DOCUMENTS</strong>
      </a>
      <Dialog onClose={handleHideDialog} open={showDialog}>
        <DialogTitle>Fix missing SignNow documents</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to fix document assignments with missing SignNow documents?
          </DialogContentText>
          <Form
            onSubmit={async values => {
              const url = adminCommandApiUrl('fix-missing-sign-now-documents');
              let assignmentIds = new Set();
              values.assignmentIds.split(',').forEach(entry => {
                if (/^\d+$/.test(entry.trim())) {
                  assignmentIds.add(parseInt(entry.trim(), 10));
                }
              });
              assignmentIds = Array.from(assignmentIds);
              if (assignmentIds.length === 0) {
                notify('No assignment ids supplied', 'warning');
              }
              try {
                await httpClient(url, { method: 'POST', body: JSON.stringify({ assignmentIds }) });
                notify(`Started fixing missing SignNow documents for assignments ${assignmentIds}`);
              } catch (e) {
                notify(e.message, 'warning');
              } finally {
                setShowDialog(false);
              }
            }}
            initialValues={{ assignmentIds: '' }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <label style={{ marginRight: '20px' }}>Document assignment ids (csv)</label>
                <Field
                  name="assignmentIds"
                  component="input"
                  value={values.assignmentIds}
                />
                <div style={{ margin: '30px 0 15px' }}>
                  <Button
                    style={{ marginRight: '20px' }}
                    label="Cancel"
                    type="button"
                    onClick={handleHideDialog}
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    disabled={submitting}
                    label="Submit"
                    type="submit"
                  />
                </div>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};


export default FixMissingSignNowDocumentsButton;
