import React from 'react';
import {
  DateField,
  Datagrid,
  List,
  TextField,
  FunctionField,
} from 'react-admin';

import DownloadButton from 'admin/assets/js/components/buttons/DownloadButton.jsx';
import DownloadErrorField from 'admin/assets/js/resources/downloads/DownloadErrorField.jsx';
import ListFilter from 'admin/assets/js/resources/downloads/ListFilter.jsx';


const DownloadList = props => (
  <List
    {...props}
    filters={<ListFilter />}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid>
      <TextField label="URL" source="url" emptyText="...Upload in progress..." />
      <TextField label="Title" source="title" />
      <TextField label="Description" source="description" />
      <FunctionField
        source="requester_id"
        label="Requester"
        render={(_record) => {
          return (
            <div>
              {_record.user.profile.name || ''}
            </div>
          );
        }}
      />
      <DateField label="Created" source="created_at" />
      <DownloadButton />
      <DownloadErrorField
        label="Error"
        source="error"
        sortable={false}
      />
    </Datagrid>
  </List>
);

export default DownloadList;
