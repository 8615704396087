import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  downloadCSV,
  useNotify,
  useRefresh,
  Button,
  TopToolbar,
} from 'react-admin';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import httpClient from 'admin/assets/js/lib/httpClient';
import { adminCommandApiUrl } from 'admin/urls';

const ListActions = (props) => {
  const { data } = props;
  const billingProcessId = data[Object.keys(data)[0]]?.billing_process_id;

  const refresh = useRefresh();
  const notify = useNotify();

  const downloadBillingProcess = useCallback(async (name) => {
    const command = `reports/billing-process-${name}`;
    const url = adminCommandApiUrl(command, { billingProcessId });
    try {
      const res = await httpClient(url, { method: 'GET' });
      downloadCSV(res.body, `report-billing-process-${name}-${billingProcessId}.csv`);
      refresh();
      notify(`${command} performed`);
    } catch (e) {
      notify(`Error: ${command} failed`, 'warning');
    }
  }, [refresh, notify, billingProcessId]);

  return (
    <TopToolbar>
      <Button
        className="p-4"
        onClick={() => downloadBillingProcess('summary')}
        label="Summary"
      >
        <CloudDownloadIcon />
      </Button>
      <Button
        className="p-4"
        onClick={() => downloadBillingProcess('breakdown')}
        label="Breakdown"
      >
        <CloudDownloadIcon />
      </Button>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ListActions;
