import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import InvoiceBankDetails from 'finance/assets/js/components/invoicing/InvoiceBankDetails.jsx';

const SystemBankSelectInput = ({ input, choices, ...props }) => {
  if (!input) {
    return null;
  }
  const { value: selected } = input;
  const selectedBank = choices.find(c => c.id === selected);

  return (
    <>
      <SelectInput
        {...props}
        input={input}
        choices={choices}
        optionText={bank => bank.details.alias}
      />
      {selectedBank && (
        <InvoiceBankDetails bankAccount={selectedBank.details} />
      )}
    </>
  );
};

SystemBankSelectInput.propTypes = {
  choices: PropTypes.array,
  input: PropTypes.object,
};

SystemBankSelectInput.defaultProps = {
  choices: [],
  input: null,
};

export default SystemBankSelectInput;
