import React from 'react';
import { SelectInput, Filter, TextInput } from 'react-admin';

import {
  SYSTEM_BANK_ACCOUNT_STATUS,
  SYSTEM_BANK_ACCOUNT_STATUS_LABEL,
} from 'finance/assets/js/constants';
import { SYSTEM_BANK_ACCOUNT_INSTITUTIONS } from 'admin/assets/js/resources/system_bank_accounts/constants';

const institutionSelectOptions = SYSTEM_BANK_ACCOUNT_INSTITUTIONS.map(institution => ({
  id: institution, name: institution,
}));

const statuses = Object.values(SYSTEM_BANK_ACCOUNT_STATUS).map(c => ({
  id: c, name: SYSTEM_BANK_ACCOUNT_STATUS_LABEL[c],
}));


const ListFilter = props => (
  <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput
        label="Institution"
        alwaysOn
        allowEmpty
        source="bankName"
        choices={institutionSelectOptions}
      />
      <SelectInput
        label="Status"
        alwaysOn
        allowEmpty
        source="status"
        choices={statuses}
      />
    </Filter>
  </div>
);

export default ListFilter;
