import React from 'react';

import SystemBankAccountShowActions from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountShowActions.jsx';

import SystemBankAccountDetails from 'admin/assets/js/resources/system_bank_accounts/SystemBankAccountDetails.jsx';

const SystemBankAccountsCustomShowLayout = props => (
  <>
    <SystemBankAccountShowActions {...props} />
    <SystemBankAccountDetails {...props} />
  </>
);

export default SystemBankAccountsCustomShowLayout;
