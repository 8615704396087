import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const LinearProgressWithLabel = (props) => {
  const { valuePerCent, textClassName } = props;
  const value = Math.round(valuePerCent);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <div className={textClassName}>
        {`${value}%`}
      </div>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  valuePerCent: PropTypes.number.isRequired,
  textClassName: PropTypes.string,
};

LinearProgressWithLabel.defaultProps = {
  textClassName: 'text-dark',
};

export default LinearProgressWithLabel;
