import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import CashoutRequestStatus from 'admin/assets/js/resources/cashout_requests/CashoutRequestStatus.jsx';
import BankAccountVariant from 'settings/assets/js/lib/BankAccountVariant';
import { PAYMENT_METHODS_LABEL } from 'settings/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  fieldValue: {
    textAlign: 'right',
  },
  transactionStatusLabel: {
    color: '#FFF',
    marginLeft: '10px',
    border: 'none',
    width: '100px',
  },
}));

const CashoutRequestViewDetails = ({ cashoutRequest }) => {
  const classes = useStyles();
  if (isEmpty(cashoutRequest)) {
    return null;
  }

  const {
    status, latestBank, createdAt,
    providerUserCard,
  } = cashoutRequest;
  const bankAccountVariant = new BankAccountVariant({
    id: latestBank.id,
    isSystem: false,
    ...latestBank.details,
  });

  return (
    <Card variant="outlined">
      <CardHeader
        style={{ paddingBottom: 0, marginBottom: 0 }}
        title={(
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600 }}
          >
            Details
          </Typography>
        )}
      />
      <CardContent sx={{ m: 0, p: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Status
            </Typography>
            <Typography variant="subtitle2">
              <CashoutRequestStatus status={status} />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Provider
            </Typography>
            <Typography variant="subtitle2">
              {providerUserCard?.user?.profile?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Payment method
            </Typography>
            <Typography variant="subtitle2">
              {PAYMENT_METHODS_LABEL[bankAccountVariant.getPaymentMethod()]}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.column}>
            <Typography variant="subtitle2" color="textSecondary">
              Date requested
            </Typography>
            <Typography variant="subtitle2">
              {formatDate(createdAt)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CashoutRequestViewDetails.propTypes = {
  cashoutRequest: PropTypes.object,
};

CashoutRequestViewDetails.defaultProps = {
  cashoutRequest: {},
};

export default CashoutRequestViewDetails;
