import React from 'react';
import PropTypes from 'prop-types';
import { Show, FunctionField, TabbedShowLayout, Tab } from 'react-admin';

import InfoTab from 'admin/assets/js/resources/organizations/InfoTab.jsx';
import FeaturesTab from 'admin/assets/js/resources/organizations/FeaturesTab.jsx';
import FeatureUsageTab from 'admin/assets/js/resources/organizations/FeatureUsageTab.jsx';
import TeamTab from 'admin/assets/js/resources/organizations/TeamTab.jsx';
import TransactionsTab from 'admin/assets/js/resources/organizations/TransactionsTab.jsx';
import InvoicesTab from 'admin/assets/js/resources/organizations/InvoicesTab.jsx';
import InvoiceRunsTab from 'admin/assets/js/resources/organizations/InvoiceRunsTab.jsx';
import PaymentMethodsTab from 'admin/assets/js/resources/organizations/PaymentMethodsTab.jsx';

const OrganizationShow = (props) => {
  return (
    <Show
      className="organization_view"
      {...props}
    >
      <FunctionField
        label=""
        addLabel={false}
        render={(record => (
          <TabbedShowLayout title="" spacing={2}>
            <Tab label="info">
              <InfoTab organization={record} />
            </Tab>
            <Tab label="features" path="features">
              <FeaturesTab organization={record} />
            </Tab>
            <Tab label="team" path="team">
              <TeamTab organization={record} />
            </Tab>
            <Tab label="transactions" path="transactions">
              <TransactionsTab organization={record} />
            </Tab>
            <Tab label="invoices" path="invoices">
              <InvoicesTab organization={record} />
            </Tab>
            <Tab label="feature usage" path="feature_usage">
              <FeatureUsageTab organization={record} />
            </Tab>
            <Tab label="invoice runs" path="invoice_runs">
              <InvoiceRunsTab organization={record} />
            </Tab>
            <Tab label="payment methods" path="payment_methods">
              <PaymentMethodsTab organization={record} />
            </Tab>
          </TabbedShowLayout>
        ))}
      />
    </Show>
  );
};

OrganizationShow.propTypes = {
  id: PropTypes.string.isRequired,
  record: PropTypes.object,
};

OrganizationShow.defaultProps = {
  record: {},
};

export default OrganizationShow;
