import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import OrgBillingAnalyticsBalanceSheet from 'admin/assets/js/resources/org_billing_analytics/components/OrgBillingAnalyticsBalanceSheet.jsx';

/**
 * Card layout for the 3 info cards - cash in / cash out / fee summary.
 *
 * Note, this is responsive with the 3 cards in a row at large screen sizes,
 * and stacked when the size is lower ( ie md / sm / xs ).
 *
 * There's UI at the top of the page to change billing process by a select of
 * dates for the currently selected org.  Changing the drop down pushes a
 * new URL to the history to display the stats for that billing process.
 *
 * The current selection of the drop is determined by parsing the current URL.
 *
 * @param {Object} analytics - OrgBillingAnalytics REST entity instance.
 * @return {JSX.Element} rendered component.
 */
const OrgBillingAnalyticsShowDetails = ({ analytics }) => {
  const location = useLocation();
  const history = useHistory();

  // handle billing process select changes
  const changeSelectedBillingProcess = useCallback((event) => {
    // parse current url
    const path = location.pathname.split('/');
    const [, orgId] = path[2].split('|');

    // update billing process id for one selected from drop down
    const nextBpId = event.target.value;
    path[2] = `${nextBpId}|${orgId}`;

    // go to page
    history.push({
      pathname: path.join('/'),
      search: location.search,
    });
  }, []);

  // build options for select
  const choices = useMemo(() => Object.keys(analytics.billingProcesses || {})
    .map(k => ({
      id: k,
      date: moment(analytics.billingProcesses[k]),
      name: moment(analytics.billingProcesses[k]).format('DD/MM/YYYY'),
    }))
    // select choices are ordered by id rather than display value
    // so, we need to sort by id, this should be DESC, with the most recent
    // values at the top, and the oldest at the bottom
    .sort((first, second) => second.date - first.date)
    .map(x => ({ id: x.id, name: x.date.format('DD/MM/YYYY') })),
  [analytics]);

  // parse url to determine current selection in select
  const selectedBillingProcess = useMemo(() => {
    const path = location.pathname.split('/');
    const [bpId] = path[2].split('|');
    return bpId;
  }, [analytics]);

  return (
    <>
      <div className="d-flex justify-content-between mt-4">
        <Typography variant="h4">Invoice Run Details - Info</Typography>
        <div className="d-flex align-items-center">
          <Typography className="mr-3" variant="string">Invoice Run</Typography>
          <select
            className="p-2 bg-light border-0 outline-none"
            type="date"
            onChange={changeSelectedBillingProcess}
            value={selectedBillingProcess}
          >
            {choices.map(c => (<option value={c.id}>{c.name}</option>))}
          </select>
        </div>
      </div>
      <OrgBillingAnalyticsBalanceSheet analytics={analytics} />
    </>
  );
};

OrgBillingAnalyticsShowDetails.propTypes = {
  analytics: PropTypes.object.isRequired,
};

export default OrgBillingAnalyticsShowDetails;
