import PropTypes from 'prop-types';
import React from 'react';

import BulkApiActionButton from './BulkApiActionButton.jsx';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const ActivateOrgButton = ({ label, record, reloadApiTable, resource }) => {
  if (!record) {
    return null;
  }

  const isActivated = ORGANIZATION_STATUS.ACTIVE === record.status;

  return (
    <BulkApiActionButton
      action="activate-org"
      content={`
        Once you activate this organization, their users will be able to
        access it. Are you sure you want to activate this organisation?
      `}
      disabled={isActivated}
      ids={record.id}
      label={label}
      reloadApiTable={reloadApiTable}
      resource={resource}
      title="Activate Organisation"
    />
  );
};

ActivateOrgButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  reloadApiTable: PropTypes.bool,
  resource: PropTypes.string,
};

ActivateOrgButton.defaultProps = {
  label: 'Activate organisation',
  reloadApiTable: false,
  record: null,
  resource: null,
};

export default ActivateOrgButton;
