import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const AllocationButton = ({
  selectedIds, disabled,
}) => (
  <Button
    variant="contained"
    color="primary"
    type="submit"
    className="allocation-button"
    disabled={disabled || selectedIds.length === 0}
  >
    Allocate
  </Button>
);

AllocationButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
};

AllocationButton.defaultProps = {
  selectedIds: [],
  disabled: false,
};

export default AllocationButton;
