import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogTitle, DialogContent,
} from '@material-ui/core';

import AorContractRejectForm from 'admin/assets/js/components/AorContractRejectForm.jsx';

const ContractUploadButton = ({ aorContract, onSuccess, disabled, className }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleHideDialog}
      >
        <DialogTitle>
          <span>
            Reject contract
          </span>
        </DialogTitle>

        <DialogContent>
          { loading && (
            <div style={{ width: '100%', textAlign: 'center', margin: '40px 0' }}>
              Loading...
            </div>
          )}
          { !loading && (
            <AorContractRejectForm
              loading={loading}
              onCancel={handleHideDialog}
              setLoading={setLoading}
              initialValues={{
                aorContractId: aorContract?.id,
              }}
              onSuccess={() => {
                handleHideDialog();
                onSuccess();
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Button
        onClick={handleShowDialog}
        disabled={loading || disabled}
        className={className}
        variant="outlined"
        size="small"
      >
        Reject contract
      </Button>
    </>
  );
};

ContractUploadButton.propTypes = {
  aorContract: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
};

ContractUploadButton.defaultProps = {
  className: '',
  disabled: false,
  onSuccess: () => {},
};


export default ContractUploadButton;
