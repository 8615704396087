import React, { useCallback, useState, useRef } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import { TopToolbar } from 'react-admin';
import { isEmpty } from 'lodash';

import { USER_STATUS, USER_STATUS_LABEL } from 'core/assets/js/constants';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import ResetPasswordButton from 'admin/assets/js/components/buttons/ResetPasswordButton.jsx';
import DeactivateUserButton from 'admin/assets/js/components/buttons/DeactivateUserButton.jsx';
import DeleteUserDataButton from 'admin/assets/js/components/buttons/DeleteUserDataButton.jsx';
import HijackButton from 'admin/assets/js/components/buttons/HijackButton.jsx';
import { USER_STATUS_COLORS } from 'admin/assets/js/resources/full_users/constants';

const UserShowActions = ({ data: user, location: { search } }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const backUrl = queryString.parse(search).backUrl || '/users';

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, [setOpen]);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, [setOpen, anchorRef]);

  if (isEmpty(user)) {
    return null;
  }

  return (
    <TopToolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Link
          to={{ pathname: backUrl }}
          style={{ color: '#283593' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeft />
            <span>BACK</span>
          </div>
        </Link>
        {
          user.profile && (
            <React.Fragment>
              <span style={{ marginLeft: '20px' }}>
                <h2 style={{ display: 'inline' }}>{`${user.profile?.firstName} ${user.profile?.lastName}`}</h2>
              </span>
              <span style={{ marginLeft: '20px' }}>
                <Chip
                  style={{ backgroundColor: USER_STATUS_COLORS[user.status], color: 'white' }}
                  size="small"
                  label={USER_STATUS_LABEL[user.status]}
                />
              </span>
            </React.Fragment>
          )
        }
        {
          !user.profile && <span style={{ marginLeft: '20px' }}>No user profile</span>
        }
      </div>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
      >
        <HijackButton record={user} resource="users" label="Impersonate" />
        <Button
          color="primary"
          size="small"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: '1' }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem key="reset-password">
                    <ResetPasswordButton
                      record={user}
                      resource="users"
                      onSuccess={handleToggle}
                    />
                  </MenuItem>
                  {user.status === USER_STATUS.ACTIVE && (
                    <MenuItem key="deactivate-user">
                      <DeactivateUserButton
                        record={user}
                        resource="users"
                        onSuccess={handleToggle}
                      />
                    </MenuItem>
                  )}
                  {user.status === USER_STATUS.DEACTIVATED && (
                    <MenuItem key="reactivate-user">
                      <BulkApiActionButton
                        action="reactivate-user"
                        content={`
                          Are you sure you want to reactivate this user? This will restore their
                          ability to log into TalentDesk. The owner of any organizations they were a
                          member of, will need to reactivate their access to those.
                        `}
                        ids={[user.id]}
                        label="Reactivate user"
                        onSuccess={handleToggle}
                        resource="users"
                        title="Reactivate user"
                      />
                    </MenuItem>
                  )}
                  <MenuItem key="delete-user-data">
                    <DeleteUserDataButton
                      record={user}
                      resource="users"
                      onSuccess={handleToggle}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </TopToolbar>
  );
};

UserShowActions.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(UserShowActions);
