import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import PropTypes from 'prop-types';

import ListFilter from 'admin/assets/js/resources/payer_mappings/ListFilter.jsx';
import SettingsMappingsMenu from 'admin/assets/js/resources/payer_mappings/SettingsMappingsMenu.jsx';
import { PAYER_MAPPINGS_STATUS } from 'finance/assets/js/constants';


const PayerMappingsList = props => (
  <List
    {...props}
    hasList
    hasShow={false}
    hasCreate={false}
    hasEdit={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'ASC' }}
    filterDefaultValues={{
      status: PAYER_MAPPINGS_STATUS.ACTIVE,
    }}
  >
    <Datagrid>
      <ReferenceField
        label="Payer name"
        reference="dim_payers"
        source="dim_payer_id"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Bank account"
        reference="dim_payers"
        source="dim_payer_id"
        link={false}
      >
        <TextField source="account" />
      </ReferenceField>
      <ReferenceField
        label="Organization name"
        reference="organizations"
        source="organization_id"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <SettingsMappingsMenu />
    </Datagrid>
  </List>
);

PayerMappingsList.propTypes = {
  basePath: PropTypes.string,
};

PayerMappingsList.defaultProps = {
  basePath: null,
};

export default PayerMappingsList;
