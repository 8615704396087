import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import ClassificationQuestionnairePreview from 'admin/assets/js/components/ClassificationQuestionnairePreview.jsx';
import ScoreBindingPreview from 'admin/assets/js/components/classification/ScoreBindingPreview.jsx';
import { getClassificationQuestionnaireCurrentVersion } from 'admin/assets/js/ducks/hooks';
import {
  calculateClassificationQuestionnaireScoreBinding,
} from 'admin/assets/js/lib/utils-non-react';

const ClassificationQuestionnaire = () => {
  const history = useHistory();

  const { loading, questionnaire } = getClassificationQuestionnaireCurrentVersion();

  if (loading) {
    return null;
  }

  if (!questionnaire) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">
          <Icon style={{ fontSize: '6rem' }}>space_dashboard</Icon>
          <h3 className="mb-0">Questionnaire</h3>
          <p>Create a questionnaire</p>
          <Button
            color="primary"
            onClick={async () => {
              history.push('/classification-questionnaire-create');
            }}
            variant="contained"
          >
            Create questionnaire
          </Button>
        </div>
      </div>
    );
  }

  const { min, max } = calculateClassificationQuestionnaireScoreBinding(questionnaire.questions);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{`Questionnaire v${questionnaire.version}`}</h1>
        <ScoreBindingPreview
          className="my-3"
          max={max}
          min={min}
          riskHighThreshold={questionnaire.riskHighThreshold}
          riskModerateThreshold={questionnaire.riskModerateThreshold}
        />
        <Button
          color="primary"
          onClick={async () => {
            history.push('/classification-questionnaire-new-version');
          }}
          startIcon={<Icon>add</Icon>}
          variant="contained"
        >
          Create new version
        </Button>
      </div>
      <Card className="p-3">
        <ClassificationQuestionnairePreview questions={questionnaire.questions} showScores />
      </Card>
    </>
  );
};

export default ClassificationQuestionnaire;
