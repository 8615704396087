import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backStyling: {
    margin: '0 10px',
    fontSize: '1rem',
  },
});
const BackButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <IconButton
      color="primary"
      onClick={handleGoBack}
      className={classes.button}
    >
      <ArrowBack />
      <span
        className={classes.backStyling}
      >
        BACK
      </span>
    </IconButton>
  );
};

export default BackButton;
