import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const BooleanField = ({ record, source }) => {
  const value = record[source];
  if (value) {
    return <CheckCircleIcon style={{ color: 'green' }} />;
  }
  return <CancelIcon style={{ color: 'red' }} />;
};

BooleanField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

BooleanField.defaultProps = {
  record: {},
};

export default BooleanField;
