import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Big from 'big.js';
import { fromPairs, isEmpty, pick, toPairs } from 'lodash';
import {
  ArrayInput, useNotify,
  FormDataConsumer, useCreate,
  NumberInput, SelectInput, SimpleForm, SimpleFormIterator, useRefresh,
} from 'react-admin';

import ErrorSerializer from 'core/assets/js/lib/ErrorSerializer';
import {
  PRICING_SCALE_STATUS_LABEL,
  PRICING_SCALE_STATUS,
} from 'finance/assets/js/constants';
import { parseFeeScale, validateScale } from 'admin/assets/js/resources/pricings/ProcessingFeesForm.jsx';
import useStyles from 'admin/assets/js/resources/pricings/useStyles';
import { adminCommandApiUrl } from 'admin/urls';

const statusOptions = Object.keys(PRICING_SCALE_STATUS_LABEL).map(k => ({
  id: k, name: PRICING_SCALE_STATUS_LABEL[k],
}));

const validateVersion = (newVersion, highestExistingVersion) => {
  if (newVersion === 0) {
    return 'Version should be greater than zero ';
  }
  if (!newVersion) {
    return 'Required';
  }
  if (Big(highestExistingVersion).gte(newVersion)) {
    return `Version should be greater than ${highestExistingVersion}`;
  }
  return undefined;
};

const validateForm = (formValues, highestVersionedScale) => {
  const errors = {};
  errors.scheme = validateScale(formValues.scheme);
  errors.version = validateVersion(formValues.version, highestVersionedScale.version);

  return fromPairs(toPairs(errors).filter(([, e]) => !!e));
};

const PricingScaleForm = ({ onDialogClose, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate('pricing_scales');
  const [createError, setCreateError] = useState(null);
  const [highestVersionedScale, setHighestVersionedScale] = useState({});

  useEffect(() => {
    // eslint-disable-next-line no-undef
    fetch(adminCommandApiUrl('pricing-scales/highest-version'))
      .then(res => res.json())
      .then((res) => {
        setHighestVersionedScale(res.highestVersionedScale);
      });
  }, []);

  return (
    <SimpleForm
      {...props}
      redirect={false}
      variant="standard"
      validate={formValues => validateForm(formValues, highestVersionedScale)}
      // eslint-disable-next-line consistent-return
      save={(formValues) => {
        if (!formValues.scheme || isEmpty(formValues.scheme)) {
          return setCreateError({
            _error: 'Pricing scale should contain steps',
          });
        }
        let parsedFeeScale;
        try {
          parsedFeeScale = parseFeeScale(formValues.scheme);
        } catch (err) {
          return setCreateError(ErrorSerializer.serialize(err));
        }
        const formattedFormValues = {
          scheme: parsedFeeScale.serialize(),
          ...pick(formValues, 'version'),
        };
        create({ payload: { data: formattedFormValues } }, {
          onSuccess: ({ data: { version } }) => {
            onDialogClose();
            notify(`Pricing scale version ${version} created`, 'info');
            refresh();
          },
        });
      }}
    >
      <FormDataConsumer>
        {() => {
          return (
            <>
              <div>
                {`The last pricing scale has a version of ${highestVersionedScale.version}.`}
              </div>
              <div className="d-flex align-items-center">
                <SelectInput
                  style={{ flex: 1 }}
                  defaultValue={PRICING_SCALE_STATUS.PREACTIVATED}
                  label="Status"
                  source="status"
                  disabled
                  choices={statusOptions}
                  helperText={`All new pricing scales are set to ${PRICING_SCALE_STATUS_LABEL[PRICING_SCALE_STATUS.PREACTIVATED]}`}
                />
              </div>
              <div className="d-flex align-items-center">
                <NumberInput
                  style={{ flex: 1.5 }}
                  source="version"
                  label="Version"
                  step={0.01}
                  defaultValue={0}
                  required
                  helperText="The pricing scale's version"
                  validate={val => ((!val && val !== 0) ? 'Field is required' : '')}
                />
              </div>
              <div className={classes.containerClasses}>
                <ArrayInput label="Scheme steps" source="scheme" className={classes.iterator}>
                  <SimpleFormIterator className={classes.formIterator}>
                    <NumberInput step={1} label="Over amount" helperText="Over which amount of provider charges we apply this percentage fee?" source="amount" className={classes.inputFields} />
                    <NumberInput step={0.5} label="fee percent" helperText="What is the percentage we charge for this charges tier?" source="percent" className={classes.inputFields} />
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
              <div>
                {createError && createError._error && (
                  <p className="text-danger">{createError._error}</p>
                )}
              </div>
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

PricingScaleForm.propTypes = {
  classes: PropTypes.object,
  onDialogClose: PropTypes.func.isRequired,
  highestVersionedScale: PropTypes.object,
};

PricingScaleForm.defaultProps = {
  classes: {},
  highestVersionedScale: {},
};

export default PricingScaleForm;
