
import React from 'react';
import {
  Show,
  SimpleShowLayout,
} from 'react-admin';

// todo - empty placeholder, implement if required
const LicenceShow = props => (
  <Show
    {...props}
  >
    <SimpleShowLayout />
  </Show>
);

export default LicenceShow;
