/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React from 'react';
import Icon from '@material-ui/core/Icon';
import {
  BooleanInput, DateField, Datagrid,
  Filter, FunctionField, List, ReferenceField,
  Show, SimpleShowLayout, TextField, TextInput,
  TopToolbar,
} from 'react-admin';
import { BANK_ACCOUNT_TYPE, BANK_ACCOUNT_STATUS_LABEL } from 'settings/assets/js/constants';
import { Box } from '@material-ui/core';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { USER_ADMIN_ROLES, USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import SendBankAccountCheckReminderButton from 'admin/assets/js/components/buttons/SendBankAccountCheckReminderButton.jsx';
import ToggleWiseValidationButton from 'admin/assets/js/components/buttons/ToggleWiseValidationButton.jsx';
import { Link } from 'react-router-dom';
import { IS_DEVELOPMENT } from 'core/assets/js/config/settings';

const BankAccountShowActions = ({ resource, data, permissions }) => (
  <TopToolbar>
    {data
      && data.id
      && permissions
      && permissions.role === USER_ADMIN_ROLES_LABEL[USER_ADMIN_ROLES.SUPER]
      && (
        <React.Fragment>
          <Box mr={3}>
            <SendBankAccountCheckReminderButton userId={data.user_id} bankAccountId={data.id} />
          </Box>
          <BulkApiActionButton
            label="Deny automatic payments"
            title="Deny automatic payments"
            content="Are you sure you want to deny automatic payments for this account?"
            icon={<Icon>cancel</Icon>}
            ids={[data.id]}
            resource={resource}
            action="convert-to-base-bank"
            disabled={data.bank_account_type !== BANK_ACCOUNT_TYPE.TRANSFERWISE}
          />
          <BulkApiActionButton
            label="Sanitize"
            title="Sanitize"
            content="Are you sure you want to sanitize this account (validate and re-save)?"
            icon={<Icon>leak_remove</Icon>}
            ids={[data.id]}
            resource={resource}
            action="sanitize-bank"
          />

          <BulkApiActionButton
            label="Link to transferwise"
            title="Link to transferwise"
            content="Are you sure you want to link this account to transferwise?"
            icon={<Icon>link</Icon>}
            ids={[data.id]}
            resource={resource}
            action="create-recipient"
          />

          <span title={data.isUsedAsDefault ? 'This bank account is used as a default for one or more organizations, please set alternatives for this account before trying to delete it.' : undefined}>
            <BulkApiActionButton
              label="Delete bank account"
              title="Delete bank account"
              content="Are you sure you want to delete this bank account?"
              icon={<Icon>delete</Icon>}
              ids={[data.id]}
              resource={resource}
              action="delete-bank-account"
              disabled={data.isUsedAsDefault}
            />
          </span>

          <span title={data.dim_revolut_counter_party_id ? 'This bank account already has a Revolut counter party created for it with these details.' : undefined}>
            <BulkApiActionButton
              label="Create Revolut bank account"
              title="Create Revolut bank account"
              content="Are you sure you want to create a Revolut account for this bank account?"
              icon={<Icon>delete</Icon>}
              ids={[data.id]}
              resource={resource}
              action="create-revolut-bank-account"
              disabled={data.dim_revolut_counter_party_id && !IS_DEVELOPMENT}
            />
          </span>

          {data.bank_account_type === BANK_ACCOUNT_TYPE.PAYONEER && (
            <span title="Fetch latest payee status from Payoneer">
              <BulkApiActionButton
                label="Sync with remote account"
                title="Sync with remote Payoneer account"
                content="Are you sure you want to sync with the remote Payoneer account?"
                icon={<Icon>link</Icon>}
                ids={[data.payoneer_payee_id]}
                resource={resource}
                action="sync-payoneer-recipient"
              />
            </span>
          )}
        </React.Fragment>
      )}
  </TopToolbar>
);
const BankAccountShow = props => {
  const { permissions } = props;
  return (
    <Show
      {...props}
      actions={<BankAccountShowActions permissions={permissions} />}
    >
      <SimpleShowLayout>
        <TextField source="beneficiary" />
        <DateField source="updated_at" />
        <ReferenceField label="Transferwise" source="dim_transferwise_recipient_id" reference="dim_transferwise_recipients" link="show">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField label="Owner" source="user_id" reference="users" link="show">
          <FunctionField
            label="Owner"
            render={user => (
              <Link to={`/full_users/${user.id}/show`}>{user.email}</Link>
            )}
          />
        </ReferenceField>
        <FunctionField
          label="Status"
          render={record => (BANK_ACCOUNT_STATUS_LABEL[record.status] ? BANK_ACCOUNT_STATUS_LABEL[record.status] : `${record.status}`)}
        />
        <FunctionField
          label="Details"
          render={r => (<pre>{r.recipient_dump ? JSON.stringify(JSON.parse(r.recipient_dump), null, 2) : ''}</pre>)}
        />
        <TextField source="dim_revolut_counter_party_id" label="Revolut counter party id" emptyText="-" />
        <TextField source="latest_revolut_counter_party_error_reason" label="Revolut counter party latest error" emptyText="-" />
        <ToggleWiseValidationButton />
      </SimpleShowLayout>
    </Show>
  );
};

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <BooleanInput label="Problematic bank account" source="problematic" alwaysOn />
  </Filter>
);

const BankAccountList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ListFilter />}
  >
    <Datagrid>
      <ReferenceField label="Id" source="id" reference="bank_accounts" link="show">
        <TextField source="beneficiary" />
      </ReferenceField>
      <ReferenceField label="Owner" source="user_id" reference="users" link="show">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Transferwise" source="dim_transferwise_recipient_id" reference="dim_transferwise_recipients" link="show">
        <TextField source="id" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export {
  BankAccountList as List,
  BankAccountShow as Show,
};
