import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

import { styles } from 'admin/assets/js/components/StatusCard.jsx';
import httpClient from 'admin/assets/js/lib/httpClient';
import { formatDate } from 'admin/assets/js/lib/utils';
import { adminApiLastInvoicingProcessStatusUrl } from 'admin/urls';
import queryString from 'query-string';

const InvoicingProcessStatusCard = () => {
  const [invoiceProcessStatus, setLastInvoiceProcessStatus] = useState(null);
  useEffect(() => {
    (async function getLastInvoiceProcessStatus() {
      const url = adminApiLastInvoicingProcessStatusUrl();
      const processStatus = await httpClient(url, { method: 'GET' });
      setLastInvoiceProcessStatus(processStatus.json);
    }());
  }, []);

  if (!invoiceProcessStatus) {
    return null;
  }
  const lastBillingDeadline = invoiceProcessStatus?.billingProcess?.billingDeadline;
  const lastBillingDate = invoiceProcessStatus?.billingProcess?.billingDate;

  if (!lastBillingDate) {
    return null;
  }

  const queryOptions = queryString.stringify({
    order: 'DESC',
    perPage: 25,
    sort: 'id',
    filter: JSON.stringify({
      billing_date: lastBillingDate,
    }),
  });
  const formattedDeadline = formatDate(lastBillingDeadline);
  const dateField = invoiceProcessStatus.isCompleted
    ? (
      <Link
        className="mr-2"
        to={{
          pathname: '/organization_billings',
          search: `?${queryOptions}`,
        }}
      >
        {formattedDeadline}
      </Link>
    ) : formattedDeadline;

  return (
    <Card style={{ marginBottom: '15px' }}>
      <CardContent>
        <Typography style={{ textAlign: 'center' }}>
          Invoicing process for
          {' '}
          {dateField}
          {' status '}
          <Chip
            clickable={false}
            label={<Typography style={styles.title}>{invoiceProcessStatus.isCompleted ? 'completed' : 'pending'}</Typography>}
            size="small"
            style={invoiceProcessStatus.isCompleted ? styles.successChip : styles.infoChip}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default InvoicingProcessStatusCard;
