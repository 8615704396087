import PropTypes from 'prop-types';
import React from 'react';

import BulkApiActionButton from './BulkApiActionButton.jsx';

const IncludeInReportsButton = ({ label, record, reloadApiTable, resource }) => {
  if (!record) {
    return null;
  }

  return (
    <BulkApiActionButton
      action="toggle-exclude-from-reporting"
      content={`
        Are you sure you want to include this organisation in reports?
      `}
      ids={record.id}
      label={label}
      reloadApiTable={reloadApiTable}
      resource={resource}
      title="Include in Reports"
    />
  );
};

IncludeInReportsButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  reloadApiTable: PropTypes.bool,
  resource: PropTypes.string,
};

IncludeInReportsButton.defaultProps = {
  label: 'Include in Reports',
  reloadApiTable: false,
  record: null,
  resource: null,
};

export default IncludeInReportsButton;
